import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Switch, Redirect } from 'react-router-dom';

import MerakiFirewallAutomation from './MerakiFirewallAutomation';

const NetworkAutomation = () => {
    return (
        <div style={{ width: "100%" }}>
            <p className="page-label" style={{ padding: "24px 24px 0 24px" }}>
                Automation Logs
                <i className="pi pi-angle-right" />
                Network Automation
            </p>
            <TabView className="container-fluid">
                <TabPanel header="Meraki Firewall">
                    <MerakiFirewallAutomation/>
                </TabPanel>
            </TabView>
            <Switch>
                <Redirect
                exact
                path="/admin/automation-logs/network-automation"
                to="/admin/automation-logs/network-automation/meraki-firewall"/>
            </Switch>
        </div>
    )
}

export default NetworkAutomation;