import { GET_ALL_STORE_LIST, SEARCHED_STORE_LIST } from './actionTypes';

const initialState = {
    storeList: [],
    storeListErrorMessage: null,
    selectedStoreList: []
}

const searchStoreInputReducer = (state=initialState, action={type:''}) => {
    switch(action.type) {
        case GET_ALL_STORE_LIST.SUCCESS:
            return {
                ...state, storeList: action.payload, storeListErrorMessage: null
            }
        case GET_ALL_STORE_LIST.ERROR:
            return {
                ...state, storeList: [], storeListErrorMessage: action.payload
            }
        case SEARCHED_STORE_LIST.SET:
            return {
                ...state, selectedStoreList: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default searchStoreInputReducer;