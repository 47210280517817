export const GET_SERVICE_MAIN_INCIDENT_USECASES = {
    INIT: "GET_SERVICE_MAIN_INCIDENT_USECASES_INIT",
    SUCCESS: "GET_SERVICE_MAIN_INCIDENT_USECASES_SUCCESS",
    ERROR: "GET_SERVICE_MAIN_INCIDENT_USECASES_ERROR",
};

export const GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS = {
    INIT: "GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS_INIT",
    SUCCESS: "GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS_SUCCESS",
    ERROR: "GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS_ERROR",
};

export const GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS = {
    INIT: "GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS_INIT",
    SUCCESS: "GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS_SUCCESS",
    ERROR: "GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS_ERROR",
};

