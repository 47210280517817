import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import { parseDateTime } from '../../../common/date-time';
import './ShellyFirmwareGroupDetails.scss';

export const ShellyFirmwareGroupDetailDialog = ({ dialogData, setDialogData }) => {
	const [tableFilters, setTableFilters] = useState(null);

	useEffect(() => {
		initializeTableFilter();
	}, [dialogData]);

	const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			id: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			event: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			message: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			timestamp: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			}
		});
	}

	const timeStampTemplate = ({ timestamp }) =>
		timestamp ? (
			parseDateTime(timestamp)
		) : (
			<span style={{ marginLeft: "50%" }}>-</span>
		);

	return (
		<Dialog className="shelly-firmware-group-detail-dialog" header={!!dialogData && `${dialogData.groupName}`}
			visible={!!dialogData} onHide={() => setDialogData(null)}>
			<DataTable
				value={!!dialogData && dialogData.eventLogs} filters={tableFilters} filterDisplay="menu" sortField="timestamp" sortOrder={-1} paginator
				paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
				currentPageReportTemplate="Items per page" rows={10} rowsPerPageOptions={[10, 20, 50]} emptyMessage="No Details found"
			>
				<Column header="Event" field="event" filter />
				<Column header="Message" field="message" filter />
				<Column header="Time Stamp" field="timestamp" body={timeStampTemplate} sortable filter />
			</DataTable>
		</Dialog>
	)
}
export default ShellyFirmwareGroupDetailDialog;