import { call, put } from "redux-saga/effects";

import {
    getAllPendingJobsFailure,
    getAllPendingJobsSuccess,
} from "../components/device-management/pending-jobs/redux/actions";

import PendingJobsService from "../components/device-management/pending-jobs/PendingJobsService";

const pendingJobsService = new PendingJobsService();

/**
 * Worker saga for loading device events
 * @param {Object} action - Redux action
 */
function* loadAllPendingJobs(action) {
    try {
        const pendingJobsList = yield call(pendingJobsService.getPendingJobs, action.deviceNames);
        yield put(getAllPendingJobsSuccess(pendingJobsList));
    } catch (error) {
        yield put(getAllPendingJobsFailure(error.toString()));
    }
}

export { loadAllPendingJobs };
