import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import "./SidebarItemExpanded.scss";

const SidebarItemExpanded = ({
    id,
    header,
    path,
    icon,
    activeIcon,
    child,
    activeMenuItem,
    setActiveMenuItem,
}) => {
    const handleIconClick = () => {
        if (activeMenuItem === id) {
            setActiveMenuItem(-1);
        } else {
            setActiveMenuItem(id);
        }
    };

    const { pathname } = useLocation();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(pathname.includes(path));
    }, [pathname]);

    return (
        <div className="sidebar-item-expanded-container">
            {child.length === 0 ? (
                <NavLink to={path} className="sidebar-item-expanded-label">
                    {isActive ? activeIcon : icon}
                    <span>{header}</span>
                </NavLink>
            ) : (
                <div
                    className={`sidebar-item-expanded-label ${
                        isActive ? "active" : ""
                    }`}
                >
                    {isActive ? activeIcon : icon}
                    <span>{header}</span>
                    <i
                        className={`pi ${
                            activeMenuItem === id
                                ? "pi-angle-up"
                                : "pi-angle-down"
                        }`}
                        onClick={handleIconClick}
                    />
                </div>
            )}
            {activeMenuItem === id && (
                <div className="expanded-list-item">
                    {child.map((childNav) => (
                        <NavLink
                            key={childNav.key}
                            to={childNav.path}
                            className={
                                pathname.includes(childNav.path) ? "active" : ""
                            }
                        >
                            {childNav.header}
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SidebarItemExpanded;
