import { GET_ALL_EVENTS } from './actionsTypes';

export const getAllEventInit = deviceId => ({
    type: GET_ALL_EVENTS.INIT,
    deviceId
});

export const getAllEventSuccess = eventList => ({
    type: GET_ALL_EVENTS.SUCCESS,
    payload: eventList
});

export const getAllEventFailure = errorMessage => ({
    type: GET_ALL_EVENTS.ERROR,
    payload: errorMessage
});