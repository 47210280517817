import { GET_SHELLY_DEVICE_LIST, GET_COMMAND_HISTORY_LIST, SEND_COMMAND } from './actionTypes';

export const getShellyDeviceListInit = storeCodes => ({
    type: GET_SHELLY_DEVICE_LIST.INIT,
    storeCodes
})

export const getShellyDeviceListSuccess = shellyDeviceList => ({
    type: GET_SHELLY_DEVICE_LIST.SUCCESS,
    payload: shellyDeviceList
})

export const getShellyDeviceListFailure = errorMessage => ({
    type: GET_SHELLY_DEVICE_LIST.ERROR,
    payload: errorMessage
})

export const getCommandHistoryListInit = deviceCode => ({
    type: GET_COMMAND_HISTORY_LIST.INIT,
    deviceCode
})

export const getCommandHistoryListSuccess = commmandHistoryList => ({
    type: GET_COMMAND_HISTORY_LIST.SUCCESS,
    payload: commmandHistoryList
})

export const getCommandHistoryListFailure = errorMessage => ({
    type: GET_COMMAND_HISTORY_LIST.ERROR,
    payload: errorMessage
})

export const sendCommandInit = (requestPayload, storeCodes)=> ({
    type: SEND_COMMAND.INIT,
    requestPayload, storeCodes
})

export const sendCommandSuccess = response => ({
    type: SEND_COMMAND.SUCCESS,
    payload: response
})

export const sendCommandFailure = errorMessage => ({
    type: SEND_COMMAND.ERROR,
    payload: errorMessage
})