import axios from 'axios';
import { ApiPath } from '../../common/api-path/ApiPath';

export default class DeviceManagementService {

    async getDevices(storeCodes) {
        const response = await axios.post(`${ApiPath.baseUrl}/devices`, storeCodes, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getDeviceStatus(deviceId) {
        const response = await axios.get(`${ApiPath.baseUrl}/device/${deviceId}`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }    
}