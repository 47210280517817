/**
 * To check whether running on public or private url
 * @returns backend base url
 */
const isPublicUrl = () => {
    return (window.location.origin.includes("aks") || window.location.origin.includes("localhost")) ? false : true;
}

export const ApiPath = {
    baseUrl: isPublicUrl() ? process.env.REACT_APP_BASE_PUBLIC_URL : process.env.REACT_APP_BASE_URL // Albertson's Backend IP Address
}
