import { 
  GET_MERAKI_FIREWALL_AUTOMATION_LOGS,
  POST_MODIFY_MERAKI_FIREWALL_SCHEDULE,
  CANCEL_MERAKI_FIREWALL_SCHEDULE,

} from "./actionTypes";

const initialState = {
    merakiFirewallAutomationLogs: [],
    merakiFirewallAutomationLogsErrorMessage: null,
    modifiedSchedule: null,
    modifiedScheduleSuccessMessage: null,
    modifiedScheduleErrorMessage: null,
    cancelSchedule: null,
    cancelScheduleSuccessMessage: null,
    cancelScheduleErrorMessage: null,

}

const merakiFirewallAutomationReducer = (state = initialState, action = {type: ""}) => {
    switch (action.type) {
    case GET_MERAKI_FIREWALL_AUTOMATION_LOGS.SUCCESS:
      return {
        ...state,
        merakiFirewallAutomationLogs: action.payload,
        merakiFirewallAutomationLogsErrorMessage: null,
      };
    case GET_MERAKI_FIREWALL_AUTOMATION_LOGS.ERROR:
      return {
        ...state,
        merakiFirewallAutomationLogs: [],
        merakiFirewallAutomationLogsErrorMessage: action.payload,
      };
      case POST_MODIFY_MERAKI_FIREWALL_SCHEDULE:
            return {
                ...state,
                modifiedSchedule: action.payload,
            };
      case POST_MODIFY_MERAKI_FIREWALL_SCHEDULE.SUCCESS:
            return{
              ...state,
              modifiedScheduleSuccessMessage: action.payload,

            };
      case POST_MODIFY_MERAKI_FIREWALL_SCHEDULE.ERROR:
            return{
              ...state,
              modifiedScheduleErrorMessage: action.payload,
            }
      case  CANCEL_MERAKI_FIREWALL_SCHEDULE:
        return {
            ...state,
            cancelSchedule: action.payload,
        };
        case  CANCEL_MERAKI_FIREWALL_SCHEDULE.SUCCESS:
        return {
            ...state,
            cancelScheduleSuccessMessage: action.payload,
        };
        case  CANCEL_MERAKI_FIREWALL_SCHEDULE.ERROR:
          return {
              ...state,
              cancelScheduleErrorMessage: action.payload,
          };
    default:
      return { ...state };
    }
};

export default merakiFirewallAutomationReducer;