import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import { parseDateTime } from '../../common/date-time';
import { getRpaDetailByCtaskIdInit } from './redux/actions';
import CopyButton from '../../common/components/copy-button/CopyButton';
import './AutomationLogs.scss';


const PayloadTemplate = ({payload}) => (
    <div className="payload-template">
        <CopyButton className="copy-icon" textToCopy={payload}/>
        <br />
        {payload}
    </div>
)

const AutomationLogsDetailDialog = ({dialogData, setDialogData}) => {

    const logDetailByCtaskId = useSelector(state => state.rpa.logDetailByCtaskId);
    const dispatch = useDispatch();
    const [tableFilters, setTableFilters] = useState(null);
    const detailsByCtaskIdTableRef = useRef(null);


    useEffect(() => {
        fetchDetailByCtaskId();
        initializeTableFilter();
    }, [dialogData]);

    const fetchDetailByCtaskId = () => {
        if(!!dialogData) {
            dispatch(getRpaDetailByCtaskIdInit(dialogData.ctaskId));
        }
    }

    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			deploymentId: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            parentDeploymentId: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            requestId : {
                operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
            },
            status : {
                operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
            },
            level : {
                operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
            },
		})
	}

    const deploymentSentTemplate = ({deploymentSentDate}) => !!deploymentSentDate ? parseDateTime(deploymentSentDate) : <span style={{marginLeft: '50%'}}>-</span>;
    const deploymentProcessTemplate = ({deploymentProcessDate}) => !!deploymentProcessDate ? parseDateTime(deploymentProcessDate) : <span style={{marginLeft: '50%'}}>-</span>;
    const inputPayloadTemplate = ({inputPayload}) => <PayloadTemplate payload={inputPayload} />
    const outputPayloadTemplate = ({outputPayload}) => <PayloadTemplate payload={outputPayload} />

        /**
     * Function to export table data
     */
        const exportDetailsByCtaskId = () => detailsByCtaskIdTableRef.current.exportCSV();

    return (
        <Dialog className="automation-logs-detail-dialog" header={!!dialogData && `${dialogData.ctaskId} | ${dialogData.usecaseType}`}
            visible={!!dialogData} onHide={() => setDialogData(null)}>
            <div className="automation-logs-detail-dialog-table-action">
            <Button icon="pi pi-refresh" className="p-button-text refresh-icon" onClick={fetchDetailByCtaskId} />
            <Button
                    icon="pi pi-download"
                    className="p-button-text"
                    onClick={exportDetailsByCtaskId}
                />
            </div>
            <DataTable value={logDetailByCtaskId} ref={detailsByCtaskIdTableRef} filters={tableFilters} filterDisplay="menu" sortField="deploymentSentDate" sortOrder={-1} paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                currentPageReportTemplate="Items per page" rows={10} rowsPerPageOptions={[10, 20, 50]} emptyMessage="No Details found.">
                <Column header="Deployment ID" field="deploymentId" sortable filter />
                <Column header="Parent Deployment ID" field="parentDeploymentId" sortable filter />
                <Column header="Request ID" field="requestId" sortable filter />
                <Column header="Status" field="status" sortable filter />
                <Column header="Deployment Sent At" field="deploymentSentDate" body={deploymentSentTemplate} sortable />
                <Column header="Deployment Processed At" field="deploymentProcessDate" body={deploymentProcessTemplate} sortable />
                <Column header="Level" field="level" sortable filter />
                <Column header="Input Payload" field="inputPayload" body={inputPayloadTemplate} />
                <Column header="Output Payload" field="outputPayload" body={outputPayloadTemplate} />
            </DataTable>
        </Dialog>
    )
}

export default AutomationLogsDetailDialog;