import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Switch, Redirect } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";

import StoreIdUpdate from "./ncm-update-view/StoreIdUpdate";
import NcmUpdateManage from "./ncm-update-manage/NcmUpdateManage";
import EthernetConfig from "./ethernet-config/EthernetConfig";

const CPDeployment = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (pathname === "/admin/cradlepoint/deployment/view") {
            setActiveIndex(0);
        } else if (pathname === "/admin/cradlepoint/deployment/manage") {
            setActiveIndex(1);
        } else {
            setActiveIndex(2);
        }
    }, [pathname]);

    const handleTabChange = (e) => {
        if (e.index === 0) {
            setActiveIndex(0);
            history.push("/admin/cradlepoint/deployment/view");
        } else if (e.index === 1) {
            setActiveIndex(1);
            history.push("/admin/cradlepoint/deployment/manage");
        } else {
            setActiveIndex(2);
            history.push("/admin/cradlepoint/deployment/ethernet-config");
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <p className="page-label" style={{ padding: "24px 24px 0 24px" }}>
                Cradlepoint
                <i className="pi pi-angle-right" />
                Deployment
            </p>
            <TabView
                activeIndex={activeIndex}
                onTabChange={handleTabChange}
                className="container-fluid"
            >
                <TabPanel header="View">
                    <StoreIdUpdate />
                </TabPanel>
                <TabPanel header="Manage">
                    <NcmUpdateManage />
                </TabPanel>
                <TabPanel header="Ethernet Config">
                    <EthernetConfig />
                </TabPanel>
            </TabView>
            <Switch>
                <Redirect
                    exact
                    path="/admin/cradlepoint/deployment"
                    to="/admin/cradlepoint/deployment/view"
                />
            </Switch>
        </div>
    );
};

export default CPDeployment;
