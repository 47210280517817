import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';

import { getOutputFileListInit } from '../redux/actions';
import { parseDateTime } from '../../../common/date-time';
import '../Cradlepoint.scss';


const CPOutputFileList = ({setShowIncidentDialog}) => {

    const outputFileList = useSelector(state => state.cpUsage.outputFileList);
    const dispatch = useDispatch();

    const [tableFilters, setTableFilters] = useState(null);

    const fetchOutputFileList = () => dispatch(getOutputFileListInit());

    useEffect(() => {
        fetchOutputFileList();
        initializeTableFilter();
    }, []);
    const logsTableRef = useRef(null);


    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			reportUniqueId: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			status: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			}
		})
	}

    const parseTimestamp = ({reportUploadedDate}) => reportUploadedDate ? parseDateTime(reportUploadedDate): '-'
    const parseProcessStatus = rowdata => rowdata.status==='error'?<div className='error-status'>{rowdata.errorMessage}</div>:rowdata.status;
    const parseSourceFile = ({sourcefileDownloadLink}) => { return !!sourcefileDownloadLink ? <a href={sourcefileDownloadLink}>Click here to download</a> : <span>-</span>}
    const parseOutputFile = ({outputFileDownloadLink}) => { return !!outputFileDownloadLink ? <a href={outputFileDownloadLink}>Click here to download</a> : <span>-</span>}
    const parseSummary = ({reportUniqueId}) => <i className="pi pi-ellipsis-h" onClick={() => setShowIncidentDialog(reportUniqueId)}></i>
    
      /**
   * Function to export table data
   */
    const exportOutputFileListLogs = () => logsTableRef.current.exportCSV();
    return (
        <div className="cp-output-file-list-container">
            <span className="cp-output-file-list-title-text">
                Output Files &nbsp;
                <Tooltip target=".cp-output-file-list-info-icon" />
                <i className="pi pi-info-circle cp-output-file-list-info-icon" data-pr-tooltip="Last 3 Months Status" data-pr-position="right" />
            </span>
            <Button 
                icon="pi pi-download"
                className="p-button-text"
                onClick={exportOutputFileListLogs}
                style={{float: "right"}}
            />
            <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={fetchOutputFileList} style={{ float: 'right' }} />
          
            <DataTable value={outputFileList} ref={logsTableRef} className="p-datatable-gridlines" filters={tableFilters} filterDisplay="menu" paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="Items per page" rows={5} rowsPerPageOptions={[5, 10, 20]} sortField="reportUniqueId" sortOrder="-1">
                <Column field="reportUniqueId" header="Unique ID" sortable filter />
                <Column field="reportUploadedDate" header="Timestamp" body={parseTimestamp} sortable />
                <Column field="status" header="Process Status" body={parseProcessStatus} sortable filter />
                <Column field="sourcefileDownloadLink" header="Source File" body={parseSourceFile} sortable />
                <Column field="outputFileDownloadLink" header="Output File" body={parseOutputFile} sortable />
                <Column header="Summary" body={parseSummary} />
            </DataTable>
        </div>
    )
}

export default CPOutputFileList;