import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";

import { Column } from "primereact/column";

const SubPendingJobs = ({ jobDetail }) => {
  const [tableFilters, setTableFilters] = useState(null);
  const subPendingJobDetailsTableRef = useRef(null);

  useEffect(() => {
    initializeTableFilter();
  }, []);

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      hostname: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      service: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      action: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };
  const exportSubPendingJobDetails = () => subPendingJobDetailsTableRef.current.exportCSV();

  return (
    <div className="sub-request-details">
      <div>
        <Button
          icon="pi pi-download"
          className="p-button-text"
          onClick={exportSubPendingJobDetails}
          style={{ float: "right", zIndex: 1 }}
        />
      </div>
      <DataTable
        value={!!jobDetail && jobDetail}
        ref={subPendingJobDetailsTableRef}
        className="sub-request-table"
        filters={tableFilters}
        filterDisplay="menu"
        emptyMessage="No logs found"
      >
        <Column field="hostname" header="Host name" sortable filter />
        <Column field="service" header="Service" sortable filter />
        <Column field="action" header="Action" sortable filter />

      </DataTable>
    </div>
  );
};

export default SubPendingJobs;
