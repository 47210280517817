import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';

import EmailSampleDialog from './EmailSampleDialog';
import { getConfigDivisionDetailsInit, getExclusionRuleInit, postExclusionRuleInit } from './redux/actions';
import { compareArrays } from '../../common/Utils';
import './IncidentConfig.scss';


const IncidentConfig = () => {

    const toastRef = useRef();
    const [firstTimeRendering, setFirstTimeRendering] = useState(false);
    const [showEmailTemplate, setShowEmailTemplate] = useState(false);
    const [division, setDivision] = useState();
    const [storeSelectionList, setStoreSelectionList] = useState(null);
    const [usecase, setUsecase] = useState();
    const [modelSelectionList, setModelSelectionList] = useState(null);
    const usecaseList = useSelector(state => state.incidentConfig.divisionDetails.assetTypes);
    const divisionList = useSelector(state => state.incidentConfig.divisionDetails.divisionStores);
    const exclusionList = useSelector(state => state.incidentConfig.exclusionRule);
    const dispatch = useDispatch();

    /**
     * Get Division and Usecase details
     */
    useEffect(() => {
        if(!usecaseList && !divisionList) {
            dispatch(getConfigDivisionDetailsInit());
        } else {
            setDivision(divisionList[0]);
            setUsecase(usecaseList[0]);
            setFirstTimeRendering(true);
        }
    }, [usecaseList, divisionList, dispatch]);

    useEffect(() => {
        if(firstTimeRendering) {
            fetchExclusionList();
        }
    }, [firstTimeRendering]);


    /**
     * Update ui according to Exclusion rule
     */
    useEffect(() => {
        if(storeSelectionList && modelSelectionList) {
            const storeList = {...storeSelectionList}, modelList = {...modelSelectionList}
            exclusionList.excludedStoreCodes && exclusionList.excludedStoreCodes.forEach(store => {
                storeList[store] = true;
            });
            setStoreSelectionList(storeList);

            exclusionList.incidentExcludedModels && exclusionList.incidentExcludedModels.forEach(model => {
                if(modelList[model]) {
                    modelList[model].incident = false;
                }
            });
            exclusionList.emailEnabledModels && exclusionList.emailEnabledModels.forEach(model => {
                if(modelList[model]) {
                    modelList[model].email = true;
                }
            });
            setModelSelectionList(modelList);
        }
    }, [exclusionList])

    /**
     * Get Exclusion rule
     */
    const fetchExclusionList = () => {
        const storeList = {}, modelList = {};

        if(typeof(division)!=='undefined' && division!==null) {
            if(!!division.stores) {
                division.stores.forEach(store => {
                    storeList[store] = false;
                })
            }
        }
        setStoreSelectionList(storeList);

        if(typeof(usecase)!=='undefined' && usecase!==null) {
            if(!!usecase.models) {
                usecase.models.forEach(model => {
                    modelList[model] = { incident: true, email: false }
                })
            }
        }
        setModelSelectionList(modelList);
        if(!!division && !!usecase) {
            dispatch(getExclusionRuleInit(division.division, usecase.name));
        }   
    }

    /**
     * Update selection for all stores at once
     * @param {boolean} value - true/false 
     */
    const handleAllStoreSelection = value => {
        const storeList = {...storeSelectionList};
        Object.keys(storeList).forEach(store => {
            storeList[store] = value;
        });
        setStoreSelectionList({...storeList});
    }

    /**
     * Select/Unselect store on clicking
     * @param {string} store - Store Number
     */
    const handleStoreButtonClick = store => {
        const storeList = {...storeSelectionList};
        storeList[store] = !storeSelectionList[store];
        setStoreSelectionList({...storeList});
    }

    /**
     * Enable/Disable incident and email for model
     * @param {*} event 
     * @param {string} model - Usecase model
     * @param {string} property - incident/email 
     */
    const handleModelSelectionChange = (event, model, property) => {
        let count = 0;
        for(const store of Object.entries(storeSelectionList)) {
            if(!store[1]) count++;
        }
        if(count===0) {
            confirmDialog({
                header: 'Alert',
                message: 'Please select at-least one store to enable rules',
                icon: 'pi pi-info-circle',
                rejectLabel: " ",
                acceptLabel: "Ok"
            });
            return;
        }
        const modelList = {...modelSelectionList};
        modelList[model][property] = event.value;
        if(property==='incident' && event.value===true) {
            modelList[model]['email'] = false
        }
        setModelSelectionList(modelList);
    }

    /**
     * Save Exclusion rule
     */
    const handleUpdateIncidentConfiguration = event => {
        const payload = {
            excludedStoreCodes: [],
            incidentExcludedModels: [],
            emailEnabledModels: []
        }
        Object.keys(storeSelectionList).forEach(store => {
            if(storeSelectionList[store]) {
                payload.excludedStoreCodes.push(store);
            }
        });
        Object.keys(modelSelectionList).forEach(model => {
            if(!modelSelectionList[model]['incident']) {
                payload.incidentExcludedModels.push(model);
            }
            if(modelSelectionList[model]['email']) {
                payload.emailEnabledModels.push(model);
            }
        });
        if(compareArrays(payload.excludedStoreCodes, exclusionList.excludedStoreCodes)
            && compareArrays(payload.incidentExcludedModels, exclusionList.incidentExcludedModels)
            && compareArrays(payload.emailEnabledModels, exclusionList.emailEnabledModels)) {
                toastRef.current.show({severity: 'warn', summary: 'Alert', detail: 'No Change in Exclusion Rule'});
        } else {
            confirmPopup({
                target: event.target,
                message: 'Do you want to save this exclusion rule?',
                accept: () => {
                    if(!!division && !!usecase) {
                        dispatch(postExclusionRuleInit(payload, division.division, usecase.name))
                    }
                }
            });
        }
    }

    return (
        <div className="incident-config-container">
            <p className="page-label">
                Incident Configuration
            </p>
            <div className="dropdown-container">
                <div className="division-dropdown">
                    <span>Division</span>
                    <Dropdown optionLabel="division" options={divisionList} value={division}
                        onChange={event => {setDivision(event.value); setStoreSelectionList(null); setModelSelectionList(null)}} />
                </div>
                <div className="usecase-dropdown">
                    <span>Device Types</span>
                    <Dropdown optionLabel="name" options={usecaseList} value={usecase}
                        onChange={event => {setUsecase(event.value); setStoreSelectionList(null); setModelSelectionList(null)}} />
                </div>
                <Button label="Get Details" disabled={!division || !usecase} onClick={fetchExclusionList} />                
            </div>
            <div className="incident-config-main">
                <div className="store-container">
                    {
                        storeSelectionList && 
                        <div className="store-container-top">
                            <div className="update-store-selection">
                                <Button label="Enable All Stores" onClick={() => handleAllStoreSelection(false)}/>
                                <Button label="Disable All Stores" onClick={() => handleAllStoreSelection(true)}/>
                            </div>
                            <div className="legend-container">
                                <span className="legend enabled-store"></span> Enabled
                                <span className="legend disabled-store"></span> Disabled
                            </div>
                        </div>
                    }
                    { storeSelectionList && Object.keys(storeSelectionList).sort().map(store => (
                            <Button key={store} label={store} onClick={() => handleStoreButtonClick(store)}
                                className={`store-btn ${storeSelectionList[store]?'disabled-store':'enabled-store'}`} />
                    ))}
                </div>
                { 
                
               !! usecase && usecase.name !== "Breakroom-TV" && modelSelectionList && <div className="model-container">
                    <table>
                        <thead>
                            <tr>
                                <th style={{width:'50%'}}>Model</th>
                                <th>Incident Required</th>
                                <th>Send Email <i className="pi pi-info-circle" onClick={() => setShowEmailTemplate(true)} /> </th>
                                <EmailSampleDialog usecase={!!usecase && usecase.name} showEmailTemplate={showEmailTemplate} setShowEmailTemplate={setShowEmailTemplate} />
                            </tr>
                        </thead>
                        <tbody>
                            {
                                modelSelectionList && Object.keys(modelSelectionList).length===0
                                && <tr><td colspan="3" className="no-model-message">No model data found.</td></tr>
                            }
                            {
                                modelSelectionList && Object.keys(modelSelectionList).map(model => (
                                    <tr key={model}>
                                        <td>{model}</td>
                                        <td>
                                            <InputSwitch checked={modelSelectionList[model]['incident']} 
                                                onChange={e => handleModelSelectionChange(e, model, 'incident')} />
                                        </td>
                                        <td>
                                            <InputSwitch checked={modelSelectionList[model]['email']} disabled={modelSelectionList[model]['incident']}
                                                onChange={e => handleModelSelectionChange(e, model, 'email')} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                }
            </div>
            {
                storeSelectionList && modelSelectionList &&
                <Button label="Update Configuration" className="submit-btn" onClick={handleUpdateIncidentConfiguration} />
            }
            <ConfirmPopup />
            <ConfirmDialog />
            <Toast ref={toastRef} position="bottom-center" />
        </div>
    )
}

export default IncidentConfig;