import React, { forwardRef, useState, useMemo } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useHistory } from "react-router-dom";
import iot from "../../../images/iot.svg";

import AssetTable from "./AssetTable";
import "./AssetList.scss";


const AssetTableContainer = forwardRef(({ title, deviceData, query }, ref) => {
    const history = useHistory();
    const powerBiUrl = process.env.REACT_APP_POWER_BI_URL;

    const params = useMemo(() => {
        if (!query || query.length === 0) {
            return "";
        }
        return "?" + query;
    }, [query]);

    /**
     * To navigate into Device Management details page
     * @param {String} deviceId - Pass the selected Device Id
     */
    const navigateTODevceMgt = (deviceId) => {
        history.push("/admin/device-management/devices/list/" + deviceId + params);
    };
    const navigateToPowerBi =() =>{
        if(powerBiUrl){
            window.open(powerBiUrl, '_blank')
        }
    }
    const printerHeaderClassname =
        title !== "Printer Gateway" ? "printer-header" : "";

    const [incidentType, setIncidentType] = useState("");

    const showOpenIncident = () => {
        setIncidentType("open");
    };

    const showClosedIncident = () => {
        setIncidentType("closed");
    };

    return (
        <div className={`printer ${printerHeaderClassname}`}>
            <div className="printerLink">
                {deviceData.deviceName ? (
                    <button
                        onClick={() => { if(deviceData.deviceName.includes('BREAKROOM-TV')){
                            navigateToPowerBi()
                        } else{
                            navigateTODevceMgt(deviceData.deviceId)
                        }
                    }}
                    >
                        {title === "Printer Gateway" && (
                            <img src={iot} alt="iot" />
                        )}
                        <div className="printer-name">
                            <div>{title}</div>
                            <div>{deviceData.deviceName}</div>
                        </div>
                    </button>
                ) : (
                    <div className="printer-name">
                        <div>{title}</div>
                        <div>{deviceData.deviceName}</div>
                    </div>
                )}
                <Dialog
                    visible={incidentType !== ""}
                    header="Incident detail in last 24 Hours"
                    onHide={() => setIncidentType("")}
                    className="device-incident-popup"
                >
                    <DataTable
                        value={
                            incidentType === "open"
                                ? deviceData.openIncidents
                                : deviceData.closedIncidents
                        }
                        paginator
                        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                        currentPageReportTemplate="Items per page"
                        rows={5}
                        rowsPerPageOptions={[5, 10]}
                    >
                        <Column header="Incident" field="incidentNumber" />
                        <Column header="Status" field="incidentStatus" />
                    </DataTable>
                </Dialog>
                {deviceData.deviceName && !deviceData.deviceName.includes("BREAKROOM-TV") && (
                    <div className="incident">
                        <div className="inc-header">Incident</div>
                        <div
                            className="open-incidents"
                            onClick={showOpenIncident}
                        >
                            <div>
                                {deviceData.openIncidents &&
                                    deviceData.openIncidents.length}
                            </div>
                        </div>
                        <div
                            className="closed-incidents"
                            onClick={showClosedIncident}
                        >
                            <div>
                                {deviceData.closedIncidents &&
                                    deviceData.closedIncidents.length}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {deviceData.assets.length > 0 ? (
                <AssetTable ref={ref} title={title} data={deviceData.assets} />
            ) : (
                <span>{`No ${title}s Found`}</span>
            )}
        </div>
    );
});

export default AssetTableContainer;
