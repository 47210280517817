import {
    GET_DISTINCT_PINGPROBE_ASSET_TYPE,
    GET_PINGPROBE_INVENTORY,
    GET_PINGPROBE_INCIDENTS_BY_ASSET,
    GET_PINGPROBE_ASSET_LAST_PINGTIME,
    GET_ALL_PINGPROBE_SUPPRESSION_RULES,
    GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE,
    SET_PINGPROBE_SUPPRESSION_RULE,
    GET_PING_DETAILS,

} from "./actionTypes";

const initialState = {
    assetTypes: [],
    assetTypesErrorMessage: null,
    assetList: [],
    assetListErrorMessage: null,
    incidentsByAsset: [],
    incidentsByAssetErrorMessage: null,
    pingTime: null,
    pingTimeErrorMessage: null,
    suppressionRule: null,
    suppressionRules: [],
    suppressionRulesErrorMessage: null,
    assetBySuppressionRule: [],
    assetBySuppressionRuleErrorMessage: null,
    pingDetails: [],
    pingDetailsErrorMessage: null,
};

const pingprobeReducer = (
    state = initialState,
    action = { type: "", payload: null }
) => {
    switch (action.type) {
        case GET_DISTINCT_PINGPROBE_ASSET_TYPE.SUCCESS:
            return {
                ...state,
                assetTypes: action.payload,
                assetTypesErrorMessage: null,
            };
        case GET_DISTINCT_PINGPROBE_ASSET_TYPE.ERROR:
            return {
                ...state,
                assetTypes: [],
                assetTypesErrorMessage: action.payload,
            };
        case GET_PINGPROBE_INVENTORY.SUCCESS:
            return {
                ...state,
                assetList: action.payload,
                assetListErrorMessage: null,
            };
        case GET_PINGPROBE_INVENTORY.ERROR:
            return {
                ...state,
                assetList: [],
                assetListErrorMessage: action.payload,
            };
        case GET_PINGPROBE_INCIDENTS_BY_ASSET.SUCCESS:
            return {
                ...state,
                incidentsByAsset: action.payload,
                incidentsByAssetErrorMessage: null,
            };
        case GET_PINGPROBE_INCIDENTS_BY_ASSET.ERROR:
            return {
                ...state,
                incidentsByAsset: [],
                incidentsByAssetErrorMessage: action.payload,
            };
        case GET_PINGPROBE_ASSET_LAST_PINGTIME.SUCCESS:
            return {
                ...state,
                pingTime: action.payload,
                pingTimeErrorMessage: null,
            };
        case GET_PINGPROBE_ASSET_LAST_PINGTIME.ERROR:
            return {
                ...state,
                pingTime: null,
                pingTimeErrorMessage: action.payload,
            };
        case GET_ALL_PINGPROBE_SUPPRESSION_RULES.SUCCESS:
            return {
                ...state,
                suppressionRules: action.payload,
                suppressionRulesErrorMessage: null,
            };
        case GET_ALL_PINGPROBE_SUPPRESSION_RULES.ERROR:
            return {
                ...state,
                suppressionRules: [],
                suppressionRulesErrorMessage: action.payload,
            };
        case GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE.SUCCESS:
            return {
                ...state,
                assetBySuppressionRule: action.payload,
                assetBySuppressionRuleErrorMessage: null,
            };
        case GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE.ERROR:
            return {
                ...state,
                assetBySuppressionRule: [],
                assetBySuppressionRuleErrorMessage: action.payload,
            };
        case SET_PINGPROBE_SUPPRESSION_RULE:
            return {
                ...state,
                suppressionRule: action.payload,
            };
        case GET_PING_DETAILS.SUCCESS:
                return {
                  ...state,
                  pingDetails: action.payload,
                  pingDetailsErrorMessage: null,
                };
        case GET_PING_DETAILS.ERROR:
                return {
                  ...state,
                  pingDetails: [],
                  pingDetailsErrorMessage: action.payload,
                };
        default:
            return {
                ...state,
            };
    }
};

export default pingprobeReducer;
