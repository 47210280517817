import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import watcherSaga from '../sagas';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Mount it on the Store
const Store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// Then run the saga
sagaMiddleware.run(watcherSaga);

export default Store;
