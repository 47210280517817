import Moment from "moment";

/**
 * Global datetime format
 */
export const DATETIME_FORMAT = "DD MMM YYYY, HH:mm:ss.SSS";

export const DATETIME_FORMAT_WITHOUT_SECS_MILLIS = "DD MMM YYYY, HH:mm";

/**
 * Formating datatime
 * @param {Date} timeStr
 */
export const parseDateTime = (timeStr) =>
    Moment(timeStr).format(DATETIME_FORMAT);

export const parseDateTimeWithoutSecsMillis = (timeStr) =>
    Moment(timeStr).format(DATETIME_FORMAT_WITHOUT_SECS_MILLIS);

/**
 * @param {number} month
 * @returns new date obtained by subtracting given months from today
 */
export const subtractMonth = (month) => {
    const date = new Date();
    date.setMonth(date.getMonth() - month);
    return date;
};

/**
 *
 * @param {string} unit - day | month | year | hours | minutes | seconds
 * @param {number} value  - delta value
 * @returns updated datetime obtained after adding provided value to current timestampF
 */
export const addDateTime = (unit, value, date = new Date()) => {
    const modifiedDate = new Date(date);
    switch (unit.toLowerCase()) {
        case "day": {
            modifiedDate.setDate(modifiedDate.getDate() + value);
            break;
        }
        case "month": {
            modifiedDate.setMonth(modifiedDate.getMonth() + value);
            break;
        }
        case "year": {
            modifiedDate.setFullYear(modifiedDate.getFullYear() + value);
            break;
        }
        case "hours": {
            modifiedDate.setHours(modifiedDate.getHours() + value);
            break;
        }
        case "minutes": {
            modifiedDate.setMinutes(modifiedDate.getMinutes() + value);
            break;
        }
        case "seconds": {
            modifiedDate.setSeconds(modifiedDate.getSeconds() + value);
            break;
        }
        default: {
            console.log("Invalid Input!!!");
        }
    }
    return modifiedDate;
};

/**
 *
 * @param {Date} date
 * @returns new datetime by setting minutes and seconds value to 00
 */
export const resetMinutesAndSeconds = (date) => {
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
};
