import {
	GET_SHELLY_FIRMWARE_GROUP_DETAILS,
	POST_SHELLYFIRMWARE_GROUP,
	POST_MODIFY_SHELLYFIRMWARE_GROUP,
	CANCEL_SHELLY_FIRMWARE_GROUP,
	POST_SHELLYFIRMWARE_CAMPAIGN,
	GET_SHELLY_FIRMWARE_CAMPAIGNS,
	GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME,
	GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS,
	GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID,
	GET_OTA_TYPES,
	GET_SHELLY_PLUG_GROUPS,
	GET_SHELLY_PLUG_FIRMWARE_DETAILS,
	CANCEL_SHELLY_FIRMWARE_CAMPAIGN,
	MODIFY_SHELLY_FIRMWARE_CAMPAIGN,
	SET_MODIFY_CAMPAIGN_DETAILS
} from "./actionTypes";

export const getShellyFirmwareGroupDetailsInit = () => ({
	type: GET_SHELLY_FIRMWARE_GROUP_DETAILS.INIT
})

export const getShellyFirmwareGroupDetailsSuccess = (groupDetails) => ({
	type: GET_SHELLY_FIRMWARE_GROUP_DETAILS.SUCCESS,
	payload: groupDetails
})

export const getShellyFirmwareGroupDetailFailure = (errorMessage) => ({
	type: GET_SHELLY_FIRMWARE_GROUP_DETAILS.ERROR,
	payload: errorMessage
})

export const postShellyFirmwareGroupInit = (group) => ({
	type: POST_SHELLYFIRMWARE_GROUP.INIT,
	group,
});

export const postShellyFirmwareGroupSuccess = () => ({
	type: POST_SHELLYFIRMWARE_GROUP.SUCCESS,
});

export const postShellyFirmwareGroupFailure = (errorMessage) => ({
	type: POST_SHELLYFIRMWARE_GROUP.ERROR,
	payload: errorMessage,
});

export const cancelShellyFirmwareGroupInit = (cancelGroup) => ({
	type: CANCEL_SHELLY_FIRMWARE_GROUP.INIT,
	cancelGroup
});

export const cancelShellyFirmwareGroupSuccess = (response) => ({
	type: CANCEL_SHELLY_FIRMWARE_GROUP.SUCCESS,
	payload: response

});

export const cancelShellyFirmwareGroupFailure = (errorMessage) => ({
	type: CANCEL_SHELLY_FIRMWARE_GROUP.ERROR,
	payload: errorMessage,
});

export const postModifyShellyFirmwareGroupInit = (modifyGroup) => ({
	type: POST_MODIFY_SHELLYFIRMWARE_GROUP.INIT,
	modifyGroup,
});

export const postModifyShellyFirmwareGroupSuccess = () => ({
	type: POST_MODIFY_SHELLYFIRMWARE_GROUP.SUCCESS,
});

export const postModifyShellyFirmwareGroupFailure = (errorMessage) => ({
	type: POST_MODIFY_SHELLYFIRMWARE_GROUP.ERROR,
	payload: errorMessage,
});

export const getShellyFirmwareCampaignsInit = (otaType) => ({
	type: GET_SHELLY_FIRMWARE_CAMPAIGNS.INIT,
	otaType
})

export const getShellyFirmwareCampaignsSuccess = (groupDetails) => ({
	type: GET_SHELLY_FIRMWARE_CAMPAIGNS.SUCCESS,
	payload: groupDetails
})

export const getShellyFirmwareCampaignsFailure = (errorMessage) => ({
	type: GET_SHELLY_FIRMWARE_CAMPAIGNS.ERROR,
	payload: errorMessage
})

export const getCampaignEventLogsByCampaignNameInit = (campaignName)=> ({
    type: GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME.INIT,
    campaignName
});

export const getCampaignEventLogsByCampaignNameSuccess = campaignEventLogs => ({
    type: GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME.SUCCESS,
    payload: campaignEventLogs
});

export const getCampaignEventLogsByCampaignNameFailure = errorMessage => ({
    type: GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME.ERROR,
    payload: errorMessage
});

export const getShellyFirmwareCampaignDetailsInit = (campaignName) => ({
	type: GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS.INIT,
	campaignName
})

export const getShellyFirmwareCampaignDetailsSuccess = (campaignDetails) => ({
	type: GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS.SUCCESS,
	payload: campaignDetails
})

export const getShellyFirmwareCampaignDetailsFailure = (errorMessage) => ({
	type:GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS.ERROR,
	payload: errorMessage
})

export const getCampaignDetailsEventLogsByDeviceIdInit = (id)=> ({
    type: GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID.INIT,
    id
});

export const getCampaignDetailsEventLogsByDeviceIdSuccess = campaignDetailsEventLogs => ({
    type: GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID.SUCCESS,
    payload: campaignDetailsEventLogs
});

export const getCampaignDetailsEventLogsByDeviceIdFailure = errorMessage => ({
    type: GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID.ERROR,
    payload: errorMessage
});
export const postShellyFirmwareCampaignInit = (campaign) => ({
	type: POST_SHELLYFIRMWARE_CAMPAIGN.INIT,
	campaign,
});

export const postShellyFirmwareCampaignSuccess = () => ({
	type: POST_SHELLYFIRMWARE_CAMPAIGN.SUCCESS,
});

export const postShellyFirmwareCampaignFailure = (errorMessage) => ({
	type: POST_SHELLYFIRMWARE_CAMPAIGN.ERROR,
	payload: errorMessage,
});

export const getOtaTypesInit = () => ({
	type: GET_OTA_TYPES.INIT
})

export const getOtaTypesSuccess = (otaTypes) => ({
	type: GET_OTA_TYPES.SUCCESS,
	payload: otaTypes
})

export const getOtaTypesFailure = (errorMessage) => ({
	type: GET_OTA_TYPES.ERROR,
	payload: errorMessage
})

export const getShellyPlugGroupsInit = () => ({
	type: GET_SHELLY_PLUG_GROUPS.INIT
})

export const getShellyPlugGroupsSuccess = (shellyPlugGroups) => ({
	type: GET_SHELLY_PLUG_GROUPS.SUCCESS,
	payload: shellyPlugGroups
})

export const getShellyPlugGroupsFailure = (errorMessage) => ({
	type: GET_SHELLY_PLUG_GROUPS.ERROR,
	payload: errorMessage
})

export const getShellyPlugFirmwareDetailsInit = () => ({
	type: GET_SHELLY_PLUG_FIRMWARE_DETAILS.INIT
})

export const getShellyPlugFirmwareDetailsSuccess = (shellyPlugFirmwareDetails) => ({
	type: GET_SHELLY_PLUG_FIRMWARE_DETAILS.SUCCESS,
	payload: shellyPlugFirmwareDetails
})

export const getShellyPlugFirmwareDetailsFailure = (errorMessage) => ({
	type: GET_SHELLY_PLUG_FIRMWARE_DETAILS.ERROR,
	payload: errorMessage
})

export const cancelShellyFirmwareCampaignInit = (campaignName, rowData) => ({
	type: CANCEL_SHELLY_FIRMWARE_CAMPAIGN.INIT,
	campaignName,
	rowData
})

export const cancelShellyFirmwareCampaignSuccess = (cancelMessage) => ({
	type: CANCEL_SHELLY_FIRMWARE_CAMPAIGN.SUCCESS,
	payload: cancelMessage
})

export const cancelShellyFirmwareCampaignFailure = (errorMessage) => ({
	type: CANCEL_SHELLY_FIRMWARE_CAMPAIGN.ERROR,
	payload: errorMessage
})

export const modifyShellyFirmwareCampaignInit = (campaignDetails) => ({
	type: MODIFY_SHELLY_FIRMWARE_CAMPAIGN.INIT,
	campaignDetails
})

export const modifyShellyFirmwareCampaignSuccess = (modifyMessage) => ({
	type: MODIFY_SHELLY_FIRMWARE_CAMPAIGN.SUCCESS,
	payload: modifyMessage
})

export const modifyShellyFirmwareCampaignFailure = (errorMessage) => ({
	type: MODIFY_SHELLY_FIRMWARE_CAMPAIGN.SUCCESS,
	payload: errorMessage
})

export const setModifyCampaignDetails = (campaignDetails) => ({
	type: SET_MODIFY_CAMPAIGN_DETAILS,
	payload: campaignDetails
})




