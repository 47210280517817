import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button'; 
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useDispatch, useSelector } from 'react-redux';

import SearchStoreInput from '../../search-store/SearchStoreInput';
import { getStoreIdUpdateEventListInit } from '../redux/actions';
import { parseDateTime } from '../../../common/date-time';
import '../Cradlepoint.scss';


const StoreIdUpdate = () => {
    
    const selectedStoreList = useSelector(state => state.searchStore.selectedStoreList);
    const storeIdUpdateEventList = useSelector(state => state.cpUsage.storeIdUpdateEventList);
    const dispatch = useDispatch();

    const [tableFilters, setTableFilters] = useState(null);
    const logsTableRef = useRef(null);


    const fetchStoreIdUpdateEventList = () => {
        if(selectedStoreList && selectedStoreList.length>0) {
            const storeCodes = [];
            selectedStoreList.forEach(selectedStore => storeCodes.push(selectedStore.name));
            dispatch(getStoreIdUpdateEventListInit(storeCodes)); 
        }
    }

    useEffect(() => {
        fetchStoreIdUpdateEventList();
        initializeTableFilter();
    }, [])

    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			storeCode: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			wan: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			correlationId: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            message: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			}
		})
	}

    const parseTimestamp = ({eventTimestamp}) => eventTimestamp ? parseDateTime(eventTimestamp): '-';
   /**
   * Function to export table data
   */
   const exportStoreIdUpdateEventListLogs = () => logsTableRef.current.exportCSV();

    return (
        <div className="store-id-update-container">
            <SearchStoreInput handleClick={fetchStoreIdUpdateEventList}/>
            <Button 
                        icon="pi pi-download"
                        className="p-button-text"
                        onClick={exportStoreIdUpdateEventListLogs}
                        style={{float: "right",  zIndex: "10"}}
                    />
            <Button icon="pi pi-refresh" className="p-button-text" style={{float:'right', zIndex: "10"}} onClick={fetchStoreIdUpdateEventList} />
            <div className="store-id-update-table-container">
                <DataTable value={storeIdUpdateEventList} ref={logsTableRef} sortField="eventTimestamp" sortOrder="-1" filters={tableFilters} filterDisplay="menu" paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="Items per page" rows={5} rowsPerPageOptions={[5, 10, 20]}>
                    <Column field="storeCode" header="Store" sortable filter />
                    <Column field="wan" header="WAN" sortable filter />
                    <Column field="correlationId" header="Event Correlation Id" sortable filter />
                    <Column field="message" header="Message" sortable filter />
                    <Column field="eventTimestamp" header="Timestamp" body={parseTimestamp} sortable />
                </DataTable>
            </div>
        </div>
    )
}

export default StoreIdUpdate;