import axios from "axios";
import { ApiPath } from "../../common/api-path/ApiPath";

export default class EpeLogsService {
  async getEpeErrorCodes() {
    const response = await axios.get(`${ApiPath.baseUrl}/epe/usecase`, {
      timeout: 120000,
      timeoutErrorMessage: "request timeout",
    });
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async getEpeLogs(errorCodes, startDate, endDate, offset) {
    const requestConfig = {
      params: { errorCodes, startDate, endDate, offset },
      timeout: 120000,
      timeoutErrorMessage: "request timeout",
    };
    const response = await axios.get(
      `${ApiPath.baseUrl}/epe/events`,
      requestConfig
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }
}
