import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { parseDateTime } from "../../../common/date-time";
import "./ShellyFirmwareCampaignDetails.scss";
import { getCampaignDetailsEventLogsByDeviceIdInit } from "../redux/action";

export const ShellyFirmwareCampaignDetailDialog = ({
  dialogData,
  setDialogData,
}) => {
  const logDetailByDeviceId = useSelector(
    (state) => state.shellyFirmware.logDetailByDeviceId
  );

  const [tableFilters, setTableFilters] = useState(null);
  const dispatch = useDispatch();
  const deviceEventTableRef = useRef(null);

  useEffect(() => {
    fetchDetailByDeviceId();
    initializeTableFilter();
  }, [dialogData]);

  const fetchDetailByDeviceId = () => {
    if (!!dialogData) {
      dispatch(getCampaignDetailsEventLogsByDeviceIdInit(dialogData.id));
    }
  };

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      event: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      message: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      timestamp: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const timeStampTemplate = ({ timestamp }) =>
    timestamp ? (
      parseDateTime(timestamp)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );
  const messagePayloadTemplate = ({ message, payload }) => (
    <>
      {message}
      {!!payload && (
        <>
          <br />
          {payload}
        </>
      )}
    </>
  );

  /**
   * Function to export table data
   */
  const exportDeviceEventLogs = () => deviceEventTableRef.current.exportCSV();

  return (
    <Dialog
      className="shelly-firmware-campaign-detail-dialog"
      header={!!dialogData && `${dialogData.id}`}
      visible={!!dialogData}
      onHide={() => setDialogData(null)}
    >
      <div>
        <Button
          icon="pi pi-download"
          className="p-button-text"
          onClick={exportDeviceEventLogs}
          style={{ float: "right", zIndex: 100 }}
        />
        <Button
          icon="pi pi-refresh"
          className="p-button-text"
          onClick={fetchDetailByDeviceId}
          style={{ float: "right", zIndex: 100 }}
        />
      </div>
      <DataTable
        value={logDetailByDeviceId}
        ref={deviceEventTableRef}
        filters={tableFilters}
        filterDisplay="menu"
        sortField="timestamp"
        sortOrder={-1}
        paginator
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        currentPageReportTemplate="Items per page"
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        emptyMessage="No Details found"
      >
        <Column header="Event" field="event" filter />
        <Column
          header="Message"
          field="message"
          body={messagePayloadTemplate}
          filter
        />
        <Column
          header="Time Stamp"
          field="timestamp"
          body={timeStampTemplate}
          sortable
          filter
        />
      </DataTable>
    </Dialog>
  );
};

export default ShellyFirmwareCampaignDetailDialog;
