import { call, put } from 'redux-saga/effects';

import { 
    getRpaUsecasesSuccess, getRpaUsecasesFailure,
    getRpaSummaryLogsSuccess, getRpaSummaryLogsFailure,
    getRpaDetailByCtaskIdSuccess, getRpaDetailByCtaskIdFailure
} from '../components/rpa/redux/actions';

import AutomationLogsService from '../components/rpa/AutomationLogsService';


const automationLogsService = new AutomationLogsService();

function* loadRpaUsecases() {
    try {
        const rpaUsecases = yield call(automationLogsService.getRpaUsecases);
        yield put(getRpaUsecasesSuccess(rpaUsecases));
    } catch(error) {
        yield put(getRpaUsecasesFailure(error.toString()));
    }
}

function* loadRpaSummaryLogs({usecaseType, startDate, endDate, offset}) {
    try {
        const summaryLogs = yield call(automationLogsService.getSummaryLogs, usecaseType, startDate, endDate, offset);
        yield put(getRpaSummaryLogsSuccess(summaryLogs));
    } catch(error) {
        yield put(getRpaSummaryLogsFailure(error.toString()));
    }
}

function* loadRpaDetailByCtaskId(action) {
    try {
        const rpaDetail = yield call(automationLogsService.getDetailByCtaskId, action.ctaskId);
        yield put(getRpaDetailByCtaskIdSuccess(rpaDetail));
    } catch(error) {
        yield put(getRpaDetailByCtaskIdFailure(error.toString()));
    }
}

export { loadRpaUsecases, loadRpaSummaryLogs, loadRpaDetailByCtaskId };