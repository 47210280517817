import axios from 'axios';
import { ApiPath } from '../../common/api-path/ApiPath';
export default class CommandAndControlService {

    async getShellyDeviceList(storeCodes) {
        const response = await axios.post(`${ApiPath.baseUrl}/modem/devices`, storeCodes, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getCommandHistory(deviceCode) {
        const response = await axios.post(`${ApiPath.baseUrl}/modem/command/history`, {deviceCode}, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async sendCommand(requestPayload) {
        const response = await axios.post(`${ApiPath.baseUrl}/modem/command`, requestPayload, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}