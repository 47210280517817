import { useState, useEffect, memo, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { getDeviceOnboardingLogsInit } from "./redux/actions";
import DeviceOnboardingService from "./DeviceOnboardingService";
import { parseDateTime } from "../../common/date-time";

const service = new DeviceOnboardingService();

const DeviceOnboardingLogs = () => {
    const onboardingLogs = useSelector(
        (state) => state.deviceOnboarding.deviceOnboardingLogs
    );
    const dispatch = useDispatch();
    const [tableFilters, setTableFilters] = useState(null);
    const logsTableRef = useRef(null);


    useEffect(() => {
        fetchOnboardingLogs();
        initializeTableFilter();
    }, []);

    const fetchOnboardingLogs = () => {
        dispatch(getDeviceOnboardingLogsInit());
    };

    const initializeTableFilter = () => {
        setTableFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            deviceType: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            onboardedByUser: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            status: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
        });
    };

    const downloadFile = (deviceTypeId, filePath) => {
        service
            .getOnboardingFileSasUrl(deviceTypeId, filePath)
            .then((url) => window.open(url, "_blank"));
    };

    const statusTemplate = ({ status }) => {
        let color = "";
        if (["UPLOADING_FAILED", "REJECTED"].includes(status)) {
            color = "#c63737";
        } else if (["PROCESSED"].includes(status)) {
            color = "#198901";
        } else if (["UPLOADED", "PROCESSING"].includes(status)) {
            color = "#1b6dd3";
        } else {
            color = "#91720e";
        }
        return <strong style={{ color }}>{status}</strong>;
    };

    const onboardedAtTemplate = ({ createdDateTime }) =>
        !!createdDateTime ? parseDateTime(createdDateTime) : "-";

    const inputFileTemplate = ({ deviceTypeId, inputFilePath }) => (
        <i
            className="pi pi-download"
            onClick={() => downloadFile(deviceTypeId, inputFilePath)}
        />
    );

    const outputFileTemplate = ({ deviceTypeId, outputFilePath }) =>
        (!!outputFilePath &&
        outputFilePath.length > 0) ? (
            <i
                className="pi pi-download"
                onClick={() => downloadFile(deviceTypeId, outputFilePath)}
            />
        ): '-';

       /**
   * Function to export table data
   */
    const exportOnboardingLogs = () => logsTableRef.current.exportCSV();

    return (
        <div className="device-onboarding-log-container">
            <strong>Device Onboarding Logs</strong>
            <Button 
                icon="pi pi-download"
                className="p-button-text"
                onClick={exportOnboardingLogs}
                style={{float: "right"}}
            />
            <Button
                icon="pi pi-refresh"
                className="p-button-text"
                onClick={fetchOnboardingLogs}
            />
            <DataTable
                value={onboardingLogs}
                ref={logsTableRef}
                filters={tableFilters}
                filterDisplay="menu"
                sortField="createdDateTime"
                sortOrder={-1}
                paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                currentPageReportTemplate="Items per page"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No logs found."
            >
                <Column
                    header="Device Type"
                    field="deviceType"
                    sortable
                    filter
                />
                <Column
                    header="Status"
                    field="status"
                    body={statusTemplate}
                    sortable
                    filter
                />
                <Column
                    header="Onboarded By"
                    field="onboardedByUser"
                    sortable
                    filter
                />
                <Column
                    header="Onboarded At"
                    field="createdDateTime"
                    body={onboardedAtTemplate}
                    sortable
                />
                <Column header="Input file" body={inputFileTemplate} />
                <Column header="Output file" body={outputFileTemplate} />
            </DataTable>
        </div>
    );
};

export default memo(DeviceOnboardingLogs);
