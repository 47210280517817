import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Switch, Redirect } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";

import StoreIdUpdate from "../cp-usage-tracker/ncm-update-view/StoreIdUpdate";
import RemainingCCUsecases from "./remaining-cc-usecases/";

const CommandCenterAutomation = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (
            pathname ===
            "/admin/automation-logs/command-center-automation/meraki-port-config"
        ) {
            setActiveIndex(0);
        } else {
            setActiveIndex(1);
        }
    }, [pathname]);

    const handleTabChange = (e) => {
        if (e.index === 0) {
            setActiveIndex(0);
            history.push(
                "/admin/automation-logs/command-center-automation/meraki-port-config"
            );
        } else {
            setActiveIndex(1);
            history.push(
                "/admin/automation-logs/command-center-automation/other-automation"
            );
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <p className="page-label" style={{ padding: "24px 24px 0 24px" }}>
                Automation Logs
                <i className="pi pi-angle-right" />
                Command Center Automation
            </p>
            <TabView
                activeIndex={activeIndex}
                onTabChange={handleTabChange}
                className="container-fluid"
            >
                <TabPanel header="Meraki Port Config">
                    <StoreIdUpdate />
                </TabPanel>
                <TabPanel header="Other Automation">
                    <RemainingCCUsecases />
                </TabPanel>
            </TabView>
            <Switch>
                <Redirect
                    exact
                    path="/admin/automation-logs/command-center-automation"
                    to="/admin/automation-logs/command-center-automation/other-automation"
                />
            </Switch>
        </div>
    );
};

export default CommandCenterAutomation;
