import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import {
  getCCRITUsecasesDetailsInit,
  getCCIncidentUsecasesDetailsInit,
} from "../../redux/actions";
import { parseDateTime } from "../../../../common/date-time";
import { modifiedAtTemplate, receivedAtTemplate } from "../utils";
import "./DetailSummaryDialog.scss";

/**
 * Functional Component to show detailed log of a RITs
 */
const DetailSummaryDialog = ({ dialogData, setDialogData }) => {
  const details = useSelector(
    (state) => state.commandCenter.ritUsecasesDetails
  );
  const eventDetails = useSelector(
    (state) => state.commandCenter.incidentUsecasesDetails
  );
  const dispatch = useDispatch();
  const [tableFilters, setTableFilters] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const detailsSummaryTableRef = useRef(null);
 

  /**
   * Initializing datatable filters
   */
  useEffect(() => {
    initializeTableFilter();
  }, []);

  /**
   * Fetching detailed log of a RITs
   */
  useEffect(() => {
    if (!!dialogData) {
      fetchDetails();
      setSelectedId(null);
    }
  }, [dialogData]);

  /**
   * Function to fetch detailed log of a RITs
   */
  const fetchDetails = () => dispatch(getCCRITUsecasesDetailsInit(dialogData));

  const goBackToDetailDialog = () => {
    setSelectedId(null);
  };

  const handleInfoClick = ({ id }) => {
     setSelectedId(id);
     dispatch(getCCIncidentUsecasesDetailsInit(id));
  };

  /**
   * Function to initialize Table filter
   */
  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dns_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      dns_domain: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      dns_action: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      record_type: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      realm: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      value: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      retry_count: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const timestampTemplate = ({ timestamp }) =>
    !!timestamp ? parseDateTime(timestamp) : <>-</>;

  const eventTemplate = (rowData) => (
    <i
      className="pi pi-info-circle"
      onClick={() => handleInfoClick(rowData)}
    ></i>
  );
  
        /**
     * Function to export table data
     */
        const exportDetailSummaryDialogLogs = () => detailsSummaryTableRef.current.exportCSV();
  return (
    <>
      <Dialog
        className="cc-usecase-detail-dialog"
        visible={!!dialogData}
        onHide={() => setDialogData(null)}
        header={!!selectedId ? selectedId : dialogData}
      >
        {!!selectedId ? (
          <>
          
            <Button
              icon="pi pi-arrow-left"
              className="p-button-text back-icon"
              onClick={goBackToDetailDialog}
            />
            <DataTable value={eventDetails}  style={{width:"100%"}}>
              <Column field="event" header="Event" sortable filter />
              <Column field="message" header="Message" sortable filter />
              <Column
                field="timestamp"
                header="Timestamp"
                body={timestampTemplate}
                sortable
              />
            </DataTable>
          </>
        ) : (
          <>
              <div className="incident-summary-logs-detail-dialog-table-action">

                <Button
                    icon="pi pi-refresh"
                    className="p-button-text refresh-icon"
                    onClick={fetchDetails}
                />
                <Button
                    icon="pi pi-download"
                    className="p-button-text"
                    onClick={exportDetailSummaryDialogLogs}
                    />
                </div>
            <DataTable
              value={details}
              ref={detailsSummaryTableRef}
              filters={tableFilters}
              filterDisplay="menu"
              sortField="received_date"
              sortOrder={-1}
              emptyMessage="No logs found."
            >
              <Column header="Name" field="dns_name" sortable filter />
              <Column header="Domain" field="dns_domain" sortable filter />
              <Column header="Action" field="dns_action" sortable filter />
              <Column header="Status" field="status" sortable filter />
              <Column
                header="Received At"
                field="received_date"
                body={receivedAtTemplate}
                sortable
              />
              <Column
                header="Record Type"
                field="record_type"
                sortable
                filter
              />
              <Column header="Realm" field="realm" sortable filter />
              <Column header="Value" field="value" sortable filter />
              <Column
                header="Modified At"
                field="modified_date"
                body={modifiedAtTemplate}
                sortable
              />
              <Column
                header="Retry Count"
                field="retry_count"
                sortable
                filter
              />
              <Column header="Events" field="id" body={eventTemplate} />
            </DataTable>
          </>
        )}
      </Dialog>
    </>
  );
};

export default DetailSummaryDialog;
