import axios from "axios";
import { ApiPath } from "../../common/api-path/ApiPath";

export const postIncidentSuppressionRequest = async (suppressionRequest) => {
    const response = await axios.post(`${ApiPath.baseUrl}/incsuppression/request`, suppressionRequest, { timeout: 120000, timeoutErrorMessage: "request timeout" });
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
}

export const getIncidentSuppressionLogs = async () => {
    const response = await axios.get(`${ApiPath.baseUrl}/incsuppression/suppressiondetails`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
}

export const getIncidentSuppressionLogsBySnowTicket = async (snowTicket) => {
    const requestConfig = {
        params: {snowTicket},
        timeout: 120000,
        timeoutErrorMessage: "request timeout"
    }
    const response = await axios.get(`${ApiPath.baseUrl}/incsuppression/record`, requestConfig);
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
}

export const getIncidentSuppressionEventLogs = async (suppressionId, usecase) => {
    const requestConfig = {
        params: {referenceId: suppressionId, usecase: usecase},
        timeout: 120000,
        timeoutErrorMessage: "request timeout"
    }
    const response = await axios.get(`${ApiPath.baseUrl}/incsuppression/auditlogs`, requestConfig);
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
}