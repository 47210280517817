import { GET_SHELLY_DEVICE_LIST, GET_COMMAND_HISTORY_LIST, SEND_COMMAND } from './actionTypes';

const initialState = {
    shellyDeviceList: [],
    shellyDeviceListErrorMessage: null,
    commandHistoryList: [],
    commandHistoryListErrorMessage: null,
    sendCommandErrorMessage: null
}

const commandAndControlReducer = (state=initialState, action={type:"", payload: ""}) => {
    switch(action.type) {
        case GET_SHELLY_DEVICE_LIST.SUCCESS:
            return {...state, shellyDeviceList: action.payload, shellyDeviceListErrorMessage: null}
        case GET_SHELLY_DEVICE_LIST.ERROR:
            return {...state, shellyDeviceList: [], shellyDeviceListErrorMessage: action.payload}
        case GET_COMMAND_HISTORY_LIST.SUCCESS: 
            return {...state, commandHistoryList: action.payload, commandHistoryListErrorMessage: null}
        case GET_COMMAND_HISTORY_LIST.ERROR:
            return {...state, commandHistoryList: [], commandHistoryListErrorMessage: action.payload}
        case SEND_COMMAND.SUCCESS: 
            return {...state, sendCommandErrorMessage: null}
        case SEND_COMMAND.ERROR:
            return {...state, sendCommandErrorMessage: action.payload}
        default:
            return {...state}
    }
}

export default commandAndControlReducer;