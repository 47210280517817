export const GET_EPE_ERROR_CODES = {
  INIT: "GET_EPE_ERROR_CODES_INIT",
  SUCCESS: "GET_EPE_ERROR_CODES_SUCCESS",
  ERROR: "GET_EPE_ERROR_CODES_ERROR",
};

export const GET_EPE_LOGS = {
  INIT: "GET_EPE_LOGS_INIT",
  SUCCESS: "GET_EPE_LOGS_SUCCESS",
  ERROR: "GET_EPE_LOGS_ERROR",
};
