import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import validator from "validator";

import { addDateTime, resetMinutesAndSeconds } from "../../../common/date-time";
import {
    postPingprobeSuppressionRuleInit,
    setPingprobeSuppressionRule,
} from "../redux/actions";
import {
    SUPPRESSION_TYPES,
    SUPPRESSION_FIELDS,
    NAME_RULE_TYPES,
    IP_ADDRESS_RULE_TYPES,
} from "../constants";
import "./PingProbeIncidentSuppression.scss";


const defaultState = {
    suppressionType: SUPPRESSION_TYPES[0].value,
    field: SUPPRESSION_FIELDS[0].value,
    ruleType: NAME_RULE_TYPES[0].value,
    ruleValue: "",
    startDateTime: null,
    endDateTime: null,
    snowTicketNumber: "",
    description: "",
};

const valueRegEx = /^[a-zA-Z0-9]+$/;

/**
 * Functional Component to save PingProbe Suppression Rule
 * @returns JSX
 */
const PingProbeIncidentSuppression = () => {
    const suppressionRule = useSelector(
        (state) => state.pingprobe.suppressionRule
    );
    const dispatch = useDispatch();

    const [ruleTypeOptions, setRuleTypeOptions] = useState([]);
    const history = useHistory();

    const {
        values,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        resetForm,
    } = useFormik({
        enableReinitialize: true,
        initialValues: !!suppressionRule ? suppressionRule : defaultState,
        validate: (data) => {
            const errors = {};
            validateRuleType(data, errors);
            validateRuleValue(data, errors);
            validateStartDateTime(data, errors);
            validateEndDateTime(data, errors);
            validateSnowDetails(data, errors);
            return errors;
        },
        onSubmit: (data) => {
            let payload = {
                ...data,
                ruleValue: data.ruleValue.trim(),
                endDateTime:
                    data.suppressionType === "decommission"
                        ? null
                        : data.endDateTime,
            };
            if (payload.ruleType === "in" || payload.ruleType === "contains_all" ) {
                payload = {
                    ...payload,
                    ruleValue: data.ruleValue
                        .split(",")
                        .map((x) => `'${x.trim()}'`)
                        .join(","),
                };
            }
            dispatch(postPingprobeSuppressionRuleInit(payload));
            clearForm();
            history.push("/admin/ping-probe/suppression-logs");
        },
    });

    /**
     * Update Rule type options based on selected field
     */
    useEffect(() => {
        if (values.field === "ip_address") {
            setRuleTypeOptions(IP_ADDRESS_RULE_TYPES);
        } else {
            setRuleTypeOptions(NAME_RULE_TYPES);
        }
    }, [values.field]);

    /**
     * Function to validate Rule type
     * @param {FormData} data
     * @param {Object} errors
     */
    const validateRuleType = (data, errors) => {
        if (
            !data.ruleType ||
            (data.field === "ip_address" &&
                !["equals", "in"].includes(data.ruleType))
        ) {
            errors["ruleType"] = "Please select a valid Rule";
        }
    };

    /**
     * Function to validate Rule value
     * @param {FormData} data
     * @param {Object} errors
     */
    const validateRuleValue = (data, errors) => {
        if (!data.ruleValue || data.ruleValue.trim().length === 0) {
            errors["ruleValue"] = "Please enter a valid Rule Regex";
        }

        if (
            data.ruleType !== "in" && 
            data.ruleType !== "contains_all" &&
            data.field !== "ip_address" &&
            !data.ruleValue.trim().match(valueRegEx)
        ) {
            errors["ruleValue"] =
                "Only alphanumeric characters are allowed for Rule Regex";
        }

        if (data.field === "ip_address") {
            if (
                data.ruleType === "in" &&
                data.ruleValue
                    .split(",")
                    .some((ip) => !validator.isIP(ip.trim()))
            ) {
                errors["ruleValue"] = "Please enter a valid Rule Regex";
            }
            if (
                data.ruleType === "equals" &&
                !validator.isIP(data.ruleValue.trim())
            ) {
                errors["ruleValue"] = "Please enter a valid Rule Regex";
            }
        }
    };

    /**
     * Function to validate Start datetime
     * @param {FormData} data
     * @param {Object} errors
     */
    const validateStartDateTime = (data, errors) => {
        if (!data.startDateTime || data.startDateTime <= new Date()) {
            errors["startDateTime"] =
                "Start Datetime should be greater than present datetime";
        }
    };

    /**
     * Function to validate End datetime
     * @param {FormData} data
     * @param {Object} errors
     */
    const validateEndDateTime = (data, errors) => {
        if (
            data.suppressionType === "suppression" &&
            (!data.endDateTime || data.endDateTime <= data.startDateTime)
        ) {
            errors["endDateTime"] =
                "End Datetime should be greater than Start Datetime";
        }
    };

    /**
     * Function to validate Snow details
     * @param {FormData} data
     * @param {Object} errors
     */
    const validateSnowDetails = (data, errors) => {
        if (!data.snowTicketNumber || data.snowTicketNumber.length === 0) {
            errors["snowTicketNumber"] = "Snow Ticket # cannot be empty";
        }
        if (!data.description || data.description.length === 0) {
            errors["description"] = "Description cannot be empty";
        }
        if (data.description.trim().length > 150) {
            errors["description"] =
                "Max of 150 characters allowed for description";
        }
    };

    /**
     * Function to check if provided form field has error or not
     * @param {string} formField
     * @returns css class name
     */
    const errorClassName = (formField) => {
        return !!touched[formField] && !!errors[formField] ? "p-invalid" : "";
    };

    /**
     * Function to reset form state
     */
    const clearForm = () => {
        dispatch(setPingprobeSuppressionRule(null));
        resetForm();
    };

    return (
        <div className="pingprobe-suppression-container container-fluid">
            <p className="page-label">
                Ping Probe
                <i className="pi pi-angle-right" />
                Suppression Rule
            </p>
            <form onSubmit={handleSubmit} className="container-fluid">
                <div className="row input-field">
                    <div className="col-12 col-lg-2">
                        <label>Rule Type</label>
                    </div>
                    <div className="col-12 col-lg-10">
                        {SUPPRESSION_TYPES.map(({ label, value }) => (
                            <span key={value}>
                                <RadioButton
                                    name="suppressionType"
                                    value={value}
                                    checked={values.suppressionType === value}
                                    onChange={handleChange}
                                />
                                <span>{label}</span>
                            </span>
                        ))}
                    </div>
                </div>
                <div className="row input-field">
                    <div className="col-12 col-lg-2">
                        <label>Field</label>
                    </div>
                    <div className="col-12 col-lg-10">
                        {SUPPRESSION_FIELDS.map(({ label, value }) => (
                            <span key={value}>
                                <RadioButton
                                    name="field"
                                    value={value}
                                    checked={values.field === value}
                                    onChange={handleChange}
                                />
                                <span>{label}</span>
                            </span>
                        ))}
                    </div>
                </div>
                <div className="row input-field">
                    <div className="col-12 col-lg-2">
                        <label>Rule</label>
                    </div>
                    <div className="col-12 col-lg-10">
                        <Dropdown
                            name="ruleType"
                            className={errorClassName("ruleType")}
                            value={values.ruleType}
                            options={ruleTypeOptions}
                            onChange={handleChange}
                        />
                        <span style={{ width: "100%" }}>
                            <InputText
                                name="ruleValue"
                                className={errorClassName("ruleValue")}
                                value={values.ruleValue}
                                onChange={handleChange}
                            />
                            <br />
                            <strong>
                                Note: For In clause use ',' as seperator
                            </strong>
                        </span>
                    </div>
                </div>
                <div className="row input-field">
                    <div className="col-12 col-lg-2">
                        <label>Start Datetime</label>
                    </div>
                    <div className="col-12 col-lg-10">
                        <Calendar
                            name="startDateTime"
                            className={errorClassName("startDateTime")}
                            value={values.startDateTime}
                            onChange={(e) => {
                                const date = e.value;
                                resetMinutesAndSeconds(date);
                                setFieldValue("startDateTime", date);
                            }}
                            minDate={resetMinutesAndSeconds(
                                addDateTime("hours", 1, new Date())
                            )}
                            stepMinute="0"
                            showTime
                            showIcon
                        />
                    </div>
                </div>
                {values.suppressionType === SUPPRESSION_TYPES[0].value && (
                    <div className="row input-field">
                        <div className="col-12 col-lg-2">
                            <label>End Datetime</label>
                        </div>
                        <div className="col-12 col-lg-10">
                            <Calendar
                                name="endDateTime"
                                className={errorClassName("endDateTime")}
                                value={values.endDateTime}
                                onChange={(e) => {
                                    const date = e.value;
                                    resetMinutesAndSeconds(date);
                                    setFieldValue("endDateTime", date);
                                }}
                                minDate={addDateTime(
                                    "hours",
                                    1,
                                    values.startDateTime
                                )}
                                stepMinute="0"
                                showTime
                                showIcon
                            />
                        </div>
                    </div>
                )}
                <div className="row input-field">
                    <div className="col-12 col-lg-2">
                        <label>Snow Ticket #</label>
                    </div>
                    <div className="col-12 col-lg-10">
                        <InputText
                            name="snowTicketNumber"
                            className={errorClassName("snowTicketNumber")}
                            value={values.snowTicketNumber}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row input-field">
                    <div className="col-12 col-lg-2">
                        <label>Description</label>
                    </div>
                    <div className="col-12 col-lg-10">
                        <InputText
                            name="description"
                            className={errorClassName("description")}
                            value={values.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <ul className="row p-error">
                    {Object.keys(errors).map((key) =>
                        !!touched[key] ? (
                            <li key={key} className="col-12">
                                {errors[key]}
                            </li>
                        ) : (
                            ""
                        )
                    )}
                </ul>
                <div className="row input-field">
                    <Button label="Save" type="submit" />
                    <Button
                        label="Clear"
                        type="reset"
                        onClick={clearForm}
                        className="p-button-secondary"
                    />
                </div>
            </form>
        </div>
    );
};

export default PingProbeIncidentSuppression;
