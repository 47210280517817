import React from 'react';

import "./UnauthorisedUser.scss";


const UnauthorisedUser = () => (
    <div className="unauthorised-user-container">
        <span id="message">Access Denied</span>
        <p>To get the access to this portal, please contact <span>Self-Healing.Automation.Support@albertsons.com</span></p>
    </div>
);

export default UnauthorisedUser;