export const GET_SHELLY_DEVICE_LIST = {
    INIT: "GET_SHELLY_DEVICE_LIST_INIT",
    SUCCESS: "GET_SHELLY_DEVICE_LIST_SUCCESS",
    ERROR: "GET_SHELLY_DEVICE_LIST_ERROR"
}

export const GET_COMMAND_HISTORY_LIST = {
    INIT: "GET_COMMAND_HISTORY_LIST_INIT",
    SUCCESS: "GET_COMMAND_HISTORY_LIST_SUCCESS",
    ERROR: "GET_COMMAND_HISTORY_LIST_ERROR"
}

export const SEND_COMMAND = {
    INIT: "SEND_COMMAND_INIT",
    SUCCESS: "SEND_COMMAND_SUCCESS",
    ERROR: "SEND_COMMAND_ERROR"
}