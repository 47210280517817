import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import validator from 'validator/validator';

import {
    getEthernetConfigurationInit, postNcmUpdateInit,
    getCpGroupListInit, getCpGroupNameInit,
} from '../redux/actions';
import { getAllStoreListInit, setSearchedStoreList } from '../../search-store/redux/actions';


const types = [
    {label: 'CP Non Tertiary', value: 'CP non-tertiary'},
    {label: 'CP Tertiary', value: 'CP tertiary'},
]

const wanTypes = [
    {label: 'WAN 1', value: 'wan1'},
    {label: 'WAN 2', value: 'wan2'},
]

const cpFormInitialState = {
    storeCode: "",
    oldMacId: "",
    newMacId: "",
    wan: wanTypes[0].value,
    deploymentType: types[0].value,
    groupName: "",
    publicIp: "",
    gateway: "",
    primaryDns: "",
    secondaryDns: ""
}

const storeRegEx = /^\d{4}$/;
const macRegEx = /([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})/;

const CPDeploymentForm = ({requestType}) => {

   const [formState, setFormState] = useState(cpFormInitialState);
   const [errorField, setErrorField] = useState("");
   const storeList = useSelector(state => state.searchStore.storeList);
   const cpGroupList = useSelector(state => state.cpUsage.cpGroupList);
   const cpGroupName = useSelector(state => state.cpUsage.cpGroupName);
   const ethernetConfig = useSelector(state => state.cpUsage.ethernetConfig);
   const dispatch = useDispatch();
   const history = useHistory();

   /**
    * Fetch all store details
    */
   useEffect(() => {
        if(!storeList || storeList.length===0) {
            dispatch(getAllStoreListInit());
        }
        dispatch(getCpGroupListInit());
   }, [])

   /**
    * Update Ehternet Config
    */
   useEffect(() => {
        if(ethernetConfig) {
            setFormState(_formState => ({
                ..._formState,
                publicIp: ethernetConfig.publicIp,
                gateway: ethernetConfig.gatewayIp,
                primaryDns: ethernetConfig.primaryDns,
                secondaryDns: ethernetConfig.secondaryDns,
            }))
        }
   }, [ethernetConfig]);

   useEffect(() => {
        if(!!cpGroupName && cpGroupName!=="") {
            setFormState(_formState => ({
                ..._formState,
                groupName: cpGroupName
            }))
        }
   }, [cpGroupName]);

   /**
    * Reset Ethernet Config
    */
   const resetEthernetConfig = () => {
        setFormState(_formState => ({
            ..._formState,
            publicIp: "",
            gateway: "",
            primaryDns: "",
            secondaryDns: "",
        }));
   }

   /**
    * Fetch Group Name for the CP
    */
   const getGroupName = (deviceTypeChanged) => {
       if(requestType==="device-replacement" && deviceTypeChanged?.target?.name==="oldMacId") {
           return;
       }
       let macId = null;
       if(requestType==="device-replacement") {
           if(!formState.newMacId.match(macRegEx)) {
                setErrorField("newMacId");
                return;
            } 
            if(errorField==="newMacId") {
                setErrorField("");
            }
            macId = formState.newMacId;
       } else {
            if(!formState.oldMacId.match(macRegEx)) {
                setErrorField("oldMacId");
                return;
            }
            if(errorField==="oldMacId") {
                setErrorField("");
            }
            macId = formState.oldMacId;
       }
       let deviceType = formState.deploymentType;
       if(deviceTypeChanged===true) {
           deviceType = formState.deploymentType===types[1].value?types[0].value:types[1].value;
       }
       dispatch(getCpGroupNameInit(macId, deviceType));
       setFormState(_formState => ({
        ..._formState,
        groupName: cpGroupName
    }))
   }

   /**
    * Fetch Ethernet Config
    */
   const getEthernetConfig = () => {
        if(!formState.storeCode.match(storeRegEx)) {
            setErrorField("storeCode");
            resetEthernetConfig();
        } else {
            setErrorField("");
            dispatch(getEthernetConfigurationInit(formState.storeCode, formState.wan));
        }
    }

    /**
     * Get Event Type
     */
    const getEventType = () => {
        if(requestType==="new-deployment") {
            return "newDeployment";
        }
        if(requestType==="type-change") {
            return "typeChange";
        }
        return "replacement";
    }

    /**
     * Update Form Input Field
     * @param {FormEvent} e 
     */
    const handleChange = e => {
       if(e.target.name==="deploymentType" && e.target.value===types[1].value) {
            getEthernetConfig();
       }
       if(e.target.name==="deploymentType") {
            getGroupName(true);
       }
       if(e.target.name==="storeCode" || e.target.name==="wan") {
            resetEthernetConfig();
       }
       setFormState(_formState => ({
           ..._formState,
           [e.target.name]: e.target.value
       }))
    }

   /**
    * Form Submit
    */
   const handleSubmit = (event) => {
        if(!formState.storeCode.match(storeRegEx)) {
            setErrorField("storeCode");
        } else if(!formState.oldMacId.match(macRegEx)) {
            setErrorField("oldMacId");
        } else if(requestType==="device-replacement" && !formState.newMacId.match(macRegEx)) {
            setErrorField("newMacId");
        }  else if(!formState.groupName || formState.groupName==="") {
            setErrorField("groupName")
        } else if(formState.deploymentType===types[1].value && !validator.isIP(formState.publicIp)) {
            setErrorField("publicIp");
        } else if(formState.deploymentType===types[1].value && !validator.isIP(formState.gateway)) {
            setErrorField("gateway");
        } else if(formState.deploymentType===types[1].value && !validator.isIP(formState.primaryDns)) {
            setErrorField("primaryDns");
        } else if(formState.deploymentType===types[1].value && !validator.isIP(formState.secondaryDns)) {
            setErrorField("secondaryDns");
        } else {
            setErrorField("");
            confirmPopup({
                target: event.target,
                message: 'Please confirm the group name',
                accept: () => {
                    dispatch(postNcmUpdateInit({
                        ...formState,
                        groupId: formState.groupName,
                        eventType: getEventType()
                    }))
                    const store = storeList.filter(_store => _store.name===formState.storeCode);
                    dispatch(setSearchedStoreList(store));
                    history.push("/admin/cradlepoint/cp-deployments/view");
                }
            })
        }
   }

   return (
       <div className="cp-deployment-form-container">
           <div className="flex-container">
                <div className="input-field">
                    <label>Store #</label>
                    <InputText name="storeCode" value={formState.storeCode} onChange={handleChange} className={errorField==="storeCode"?"error-field":""}/>
                </div>
                <div className="input-field">
                    <label>WAN</label>
                    <Dropdown name="wan" value={formState.wan} onChange={handleChange} options={wanTypes} />
                </div>
           </div>
           <div className="input-field">
               <label>{requestType!=="new-deployment" && "Current "}CP MAC</label>
               <InputText name="oldMacId" value={formState.oldMacId}
                    onChange={handleChange} onBlur={getGroupName}
                    className={errorField==="oldMacId"?"error-field":""}/>
           </div>
           {
                requestType==="device-replacement" &&
                <div className="input-field">
                    <label>New CP MAC</label>
                    <InputText name="newMacId" value={formState.newMacId} 
                        onChange={handleChange} onBlur={getGroupName}
                        className={errorField==="newMacId"?"error-field":""}/>
                </div>
           }
           <div className="input-field">
               <label>Type</label>
               <Dropdown name="deploymentType" value={formState.deploymentType} onChange={handleChange} options={types} />
           </div>
           <div className="input-field">
               <label>Group</label>
               <Dropdown name="groupName" value={formState.groupName} onChange={handleChange}
                    options={cpGroupList} optionLabel="name" optionValue="id"
                    className={errorField==="groupName"?"error-field":""} />
           </div>
           {
               formState.deploymentType===types[1].value &&
               <div className="ethernet-config-div">
                   <div className="title">
                       Ethernet Configuration &ensp;
                       <Button icon="pi pi-refresh" className="p-button-text" onClick={getEthernetConfig}/>
                    </div>
                   <div className="input-field">
                        <label>IPv4 Address</label>
                        <InputText name="publicIp" value={formState.publicIp} onChange={handleChange} className={errorField==="publicIp"?"error-field":""}/>
                    </div>
                    <div className="input-field">
                        <label>Gateway IP</label>
                        <InputText name="gateway" value={formState.gateway} onChange={handleChange} className={errorField==="gateway"?"error-field":""}/>
                    </div>
                    <div className="input-field">
                        <label>Primary DNS Server</label>
                        <InputText name="primaryDns" value={formState.primaryDns} onChange={handleChange} className={errorField==="primaryDns"?"error-field":""}/>
                    </div>
                    <div className="input-field">
                        <label>Secondary DNS Server</label>
                        <InputText name="secondaryDns" value={formState.secondaryDns} onChange={handleChange} className={errorField==="secondaryDns"?"error-field":""}/>
                    </div>
               </div>
           }
           {
                errorField!=="" &&
                <div className="error-status">
                    Invalid Input Format
                </div>
            }
           <Button label="Save" onClick={handleSubmit}/>
           <ConfirmPopup />
       </div>
   )
}

export default CPDeploymentForm;