import axios from 'axios';
import { ApiPath } from '../../common/api-path/ApiPath';

export default class AutomationLogsService {

    async getRpaUsecases() {
        const response = await axios.get(`${ApiPath.baseUrl}/bot/usecase`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
    
  	async getSummaryLogs(usecaseType, startDate, endDate, offset) {
        const requestConfig = {
            params: { usecaseType, startDate, endDate, offset },
            timeout: 120000, timeoutErrorMessage: "request timeout"
        }
        const response = await axios.get(`${ApiPath.baseUrl}/bot/summary`, requestConfig);
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getDetailByCtaskId(ctaskId) {
        const response = await axios.get(`${ApiPath.baseUrl}/bot/detail/${ctaskId}`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}