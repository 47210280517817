import { GET_ALL_STORE_LIST, SEARCHED_STORE_LIST } from './actionTypes';

export const getAllStoreListInit = () => ({
    type: GET_ALL_STORE_LIST.INIT
})

export const getAllStoreListSuccess = storeList => ({
    type: GET_ALL_STORE_LIST.SUCCESS,
    payload: storeList
})

export const getAllStoreListFailure = errorMessage => ({
    type: GET_ALL_STORE_LIST.ERROR,
    payload: errorMessage
})

export const setSearchedStoreList = storeList => ({
    type: SEARCHED_STORE_LIST.SET,
    payload: storeList
})