import { 
    GET_OUTPUT_FILE_LIST, POST_OUTPUT_FILE,
    GET_CP_INCIDENT_LIST, POST_CP_INCIDENT_LIST,
    GET_STORE_ID_UPDATE_EVENT_LIST,
    GET_ETHERNET_CONFIGURATION,
    POST_NCM_UPDATE,
    GET_CP_GROUP_LIST,
    GET_CP_GROUP_NAME,
 } from "./actionTypes";

export const getOutputFileListInit = () => ({
    type: GET_OUTPUT_FILE_LIST.INIT
})

export const getOutputFileListSuccess = outputFileList => ({
    type: GET_OUTPUT_FILE_LIST.SUCCESS,
    payload: outputFileList
})

export const getOutputFileListFailure = errorMessage => ({
    type: GET_OUTPUT_FILE_LIST.ERROR,
    payload: errorMessage
})

export const postOutputFileInit = formData => ({
    type: POST_OUTPUT_FILE.INIT,
    formData
})

export const postOutputFileSuccess = () => ({
    type: POST_OUTPUT_FILE.SUCCESS
})

export const postOutputFileFailure = errorMessage => ({
    type: POST_OUTPUT_FILE.ERROR,
    payload: errorMessage
})

export const getCpIncidentListInit = reportUniqueId => ({
    type: GET_CP_INCIDENT_LIST.INIT,
    reportUniqueId
})

export const getCpIncidentListSuccess = cpIncidentList => ({
    type: GET_CP_INCIDENT_LIST.SUCCESS,
    payload: cpIncidentList
})

export const getCpIncidentListFailure = errorMessage => ({
    type: GET_CP_INCIDENT_LIST.ERROR,
    payload: errorMessage
})

export const getCpIncidentListUpdate = cpIncidentList => ({
    type: GET_CP_INCIDENT_LIST.UPDATE,
    payload: cpIncidentList
})

export const postCpIncidentListInit = (cpIncidentList, reportUniqueId) => ({
    type: POST_CP_INCIDENT_LIST.INIT,
    cpIncidentList,
    reportUniqueId
})

export const postCpIncidentListSuccess = () => ({
    type: POST_CP_INCIDENT_LIST.SUCCESS
})

export const postCpIncidentListFailure = errorMessage => ({
    type: POST_CP_INCIDENT_LIST.ERROR,
    payload: errorMessage
})

export const getStoreIdUpdateEventListInit = storeCodes => ({
    type: GET_STORE_ID_UPDATE_EVENT_LIST.INIT,
    storeCodes
})

export const getStoreIdUpdateEventListSuccess = eventList => ({
    type: GET_STORE_ID_UPDATE_EVENT_LIST.SUCCESS,
    payload: eventList
})

export const getStoreIdUpdateEventListFailure = errorMessage => ({
    type: GET_STORE_ID_UPDATE_EVENT_LIST.ERROR,
    payload: errorMessage
})

export const getEthernetConfigurationInit = (store, wan) => ({
    type: GET_ETHERNET_CONFIGURATION.INIT,
    store,
    wan
})

export const getEthernetConfigurationSuccess = ethernetConfig => ({
    type: GET_ETHERNET_CONFIGURATION.SUCCESS,
    payload: ethernetConfig
})

export const getEthernetConfigurationFailue = errorMessage => ({
    type: GET_ETHERNET_CONFIGURATION.ERROR,
    payload: errorMessage
})

export const postNcmUpdateInit = cpDetails => ({
    type: POST_NCM_UPDATE.INIT,
    cpDetails
})

export const postNcmUpdateSuccess = () => ({
    type: POST_NCM_UPDATE.SUCCESS
})

export const postNcmUpdateFailure = errorMessage => ({
    type: POST_NCM_UPDATE.ERROR,
    payload: errorMessage
})

export const getCpGroupListInit = () => ({
    type: GET_CP_GROUP_LIST.INIT
})

export const getCpGroupListSuccess = (groupList) => ({
    type: GET_CP_GROUP_LIST.SUCCESS,
    payload: groupList
})

export const getCpGroupListFailure = (errorMessage) => ({
    type: GET_CP_GROUP_LIST.ERROR,
    payload: errorMessage
})

export const getCpGroupNameInit = (macId, deviceType) => ({
    type: GET_CP_GROUP_NAME.INIT,
    macId, deviceType
})

export const getCpGroupNameSuccess = (groupName) => ({
    type: GET_CP_GROUP_NAME.SUCCESS,
    payload: groupName
})

export const getCpGroupNameFailure = (errorMessage) => ({
    type: GET_CP_GROUP_NAME.ERROR,
    payload: errorMessage
})