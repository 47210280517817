import {
    GET_SERVICE_MAIN_INCIDENT_USECASES,
    GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS,
    GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS
} from "./actionTypes"

const initialState = {
    smincidentUsecases: [],
    smincidentUsecasesErrorMessage: null,
    smincidentUsecasesLogs: [],
    smincidentUsecasesLogsErrorMessage: null,
    smincidentUsecasesDetails: [],
    smincidentUsecasesDetailsErrorMessage: null,
};

const serviceMainReducer = (
    state = initialState,
    action = { type: "", payload: "" }
) => {
    switch (action.type) {
        case GET_SERVICE_MAIN_INCIDENT_USECASES.SUCCESS:
            return {
                ...state,
                smincidentUsecases: action.payload,
                smincidentUsecasesErrorMessage: null,
            };
        case GET_SERVICE_MAIN_INCIDENT_USECASES.ERROR:
            return {
                ...state,
                 smincidentUsecases: [],
                smincidentUsecasesErrorMessage: action.payload,
            };
        case GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS.SUCCESS:
            return {
                ...state,
                smincidentUsecasesLogs: action.payload,
                smincidentUsecasesErrorMessage: null,
            };
        case GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS.ERROR:
            return {
                 ...state,
                 smincidentUsecasesLogs: [],
                smincidentUsecasesErrorMessage: action.payload,
            };
        case GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS.SUCCESS:
            return {
                ...state,
                smincidentUsecasesDetails: action.payload,
                smincidentUsecasesDetailsErrorMessage: null,
            };
        case GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS.ERROR:
            return {
                ...state,
                smincidentUsecasesDetails: [],
                smincidentUsecasesDetailsErrorMessage: action.payload,
            };
        default:
        return { ...state };
    }
}

export default serviceMainReducer;