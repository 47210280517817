import axios from 'axios';
import { ApiPath } from '../../api-path/ApiPath';

export default class DivisionDropdownService {

    async getAllDivisionName() {
        const response = await axios.get(`${ApiPath.baseUrl}/division`, {timeout: 300000, timeoutErrorMessage: "request timeout"});
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}