import { call, put } from "redux-saga/effects";

import {
    getCCIncidentUsecasesSuccess,
    getCCIncidentUsecasesFailure,
    getCCIncidentUsecasesLogsSuccess,
    getCCIncidentUsecasesLogsFailure,
    getCCIncidentUsecasesDetailsSuccess,
    getCCIncidentUsecasesDetailsFailure,
    getCCRITUsecasesLogsSuccess,
    getCCRITUsecasesLogsFailure,
    getCCRITUsecasesDetailsSuccess,
    getCCRITUsecasesDetailsFailure,
} from "../components/command-center-automation/redux/actions";

import CCAutomationService from "../components/command-center-automation/CCAutomationService";

const automationService = new CCAutomationService();

/**
 * Worker saga for loading all CC Usecases
 * @param {Object} action - Redux action
 */
function* loadCCIncidentUsecases() {
    try {
        const usecaseList = yield call(automationService.getCCIncidentUsecases);
        yield put(getCCIncidentUsecasesSuccess(usecaseList));
    } catch (error) {
        yield put(getCCIncidentUsecasesFailure(error.toString()));
    }
}

/**
 * Worker saga for loading incident based CC usecases logs
 * @param {Object} action - Redux action
 */
function* loadCCIncidentUsecasesLogs({
    usecaseType,
    startDate,
    endDate,
    offset,
}) {
    try {
        const logs = yield call(
            automationService.getCCIncidentUsecasesLogs,
            usecaseType,
            startDate,
            endDate,
            offset
        );
        yield put(getCCIncidentUsecasesLogsSuccess(logs));
    } catch (error) {
        yield put(getCCIncidentUsecasesLogsFailure(error.toString()));
    }
}

function* loadCCIncidentUsecasesDetails({ incident }) {
    try {
        const details = yield call(
            automationService.getCCIncidentUsecasesDetails,
            incident
        );
        yield put(getCCIncidentUsecasesDetailsSuccess(details));
    } catch (error) {
        yield put(getCCIncidentUsecasesDetailsFailure(error.toString()));
    }
}

/**
 * Worker saga for loading rit based CC usecases logs
 * @param {Object} action - Redux action
 */
function* loadCCRITUsecasesLogs({ startDate, endDate, offset }) {
    try {
        const logs = yield call(
            automationService.getCCRITUsecasesLogs,
            startDate,
            endDate,
            offset
        );
        yield put(getCCRITUsecasesLogsSuccess(logs));
    } catch (error) {
        yield put(getCCRITUsecasesLogsFailure(error.toString()));
    }
}

function* loadCCRITUsecasesDetails({ requestId }) {
    try {
        const details = yield call(
            automationService.getCCRITUsecasesDetails,
            requestId
        );
        yield put(getCCRITUsecasesDetailsSuccess(details));
    } catch (error) {
        yield put(getCCRITUsecasesDetailsFailure(error.toString()));
    }
}

export {
    loadCCIncidentUsecases,
    loadCCIncidentUsecasesLogs,
    loadCCIncidentUsecasesDetails,
    loadCCRITUsecasesLogs,
    loadCCRITUsecasesDetails,
};
