import { call, put } from "redux-saga/effects";

import { 
    getServiceMainIncidentUsecasesSuccess,
    getServiceMainIncidentUsecasesFailure,
    getserviceMainIncidentUsecasesLogsSuccess,
    getServiceMainIncidentUsecasesLogsFailure,
    getServiceMainIncidentUsecasesDetailsSuccess,
    getServiceMainIncidentUsecasesDetailsFailure,
} from "../components/service-main/redux/action";

import ServiceMainLogsService from "../components/service-main/ServiceMainLogsService"

const serviceMainService = new ServiceMainLogsService();

/**
 * Worker saga for loading all service main Usecases
 * @param {Object} action - Redux action
 */

function* loadServiceMainIncidentUsecases() {
    try {
        const usecaseList = yield call(serviceMainService.getServiceMainIncidentUsecases);
        yield put(getServiceMainIncidentUsecasesSuccess(usecaseList));
    } catch (error) {
        yield put(getServiceMainIncidentUsecasesFailure(error.toString()));
    }
}

/**
 * Worker saga for loading incident based CC usecases logs
 * @param {Object} action - Redux action
 */
function* loadServiceMainIncidentUsecasesLogs({
    usecaseType,
    startDate,
    endDate,
    offset,
}) {
    try {
        const logs = yield call(
            serviceMainService.getServiceMainIncidentUsecasesLogs,
            usecaseType,
            startDate,
            endDate,
            offset
        );
        yield put(getserviceMainIncidentUsecasesLogsSuccess(logs));
    } catch (error) {
        yield put(getServiceMainIncidentUsecasesLogsFailure(error.toString()));
    }
}

function* loadServiceMainIncidentUsecasesDetails({ incident }) {
    try {
        const details = yield call(
            serviceMainService.getServiceMainIncidentUsecasesDetails,
            incident
        );
        yield put(getServiceMainIncidentUsecasesDetailsSuccess(details));
    } catch (error) {
        yield put(getServiceMainIncidentUsecasesDetailsFailure(error.toString()));
    }
}

export {
    loadServiceMainIncidentUsecases,
    loadServiceMainIncidentUsecasesLogs,
    loadServiceMainIncidentUsecasesDetails

};

