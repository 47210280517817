import { useState } from "react";
import { Button } from "primereact/button";

import SidebarItemExpanded from "./sidebar-item-expanded/SidebarItemExpanded";
import SidebatItemCollapsed from "./sidebar-item-collapsed/SidebarItemCollapsed";

import logo from "../../images/logo.png";

import "./Sidebar.scss";

const Sidebar = ({ routes }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState(-1);

    const toogleSidebar = () => {
        setCollapsed((_collapsed) => !_collapsed);
        setActiveMenuItem(-1);
    };

    return (
        <div className={`sidebar ${collapsed ? "sidebar-collapsed" : ""}`}>
            <img src={logo} width="148" height="60" alt='logo' className="logo-image"/>
            <Button
                icon="pi pi-align-justify"
                className="p-button-text"
                onClick={toogleSidebar}
            />
            <nav className="sidebar-links">
                {routes.map((tab) =>
                    collapsed ? (
                        <SidebatItemCollapsed
                            key={tab.key}
                            activeMenuItem={activeMenuItem}
                            setActiveMenuItem={setActiveMenuItem}
                            id={tab.key}
                            {...tab}
                        />
                    ) : (
                        <SidebarItemExpanded
                            key={tab.key}
                            activeMenuItem={activeMenuItem}
                            setActiveMenuItem={setActiveMenuItem}
                            id={tab.key}
                            {...tab}
                        />
                    )
                )}
            </nav>
        </div>
    );
};

export default Sidebar;
