import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Tooltip } from 'primereact/tooltip';
import validator from 'validator/validator';

import CopyButton from '../../../common/components/copy-button/CopyButton';
import { getShellyReplacementExeUrlInit, getFaultyShellyDeviceDetailInit, postFaultyShellyDeviceDetailInit } from '../redux/actions';
import { getDeviceTypeInit } from '../../device-onboarding/redux/actions';
import './ShellyReplacementReplace.scss';

/**
 * Wan types Shown in form
 */
const wanTypes = [
    {label: 'WAN 1', value: '1'},
    {label: 'WAN 2', value: '2'},
]

/**
 * Initial Form State
 */
const initialFormState = {
    storeCode: "",
    faultyShellyId: "",
    ipAddress: "",
    wifiName: "",
    gatewayIP: "",
    wifiPassword: "",
    primaryDns: "",
}

const ShellyReplacementReplace = () => {

    const [formState, setFormState] = useState(initialFormState);
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [wan, setWan] = useState(wanTypes[0].value);
    const [isReviewing, setReviewing] = useState(false);
    const [errorField, setErrorField] = useState("");

    const deviceTypes = useSelector(state => state.deviceOnboarding.deviceTypeList);
    const exeUrl = useSelector(state => state.shellyReplacement.exeUrl);
    const deviceDetail = useSelector(state => state.shellyReplacement.shellyDeviceDetail);
    const otp = useSelector(state => state.shellyReplacement.otp);
    const dispatch = useDispatch();
    
    /**
     * Get different device types by calling api
     */
    useEffect(() => {
        dispatch(getShellyReplacementExeUrlInit());
        if(!deviceTypes || deviceTypes.length===0) {
            dispatch(getDeviceTypeInit());
        }
    }, []);

    /**
     * Select Modem as default device type
     */
    useEffect(() => {
        if(!!deviceTypes && deviceTypes.length!==0) {
            setSelectedDeviceType(deviceTypes[0]);
        }
    }, [deviceTypes]);

    /**
     * Update form state once shelly device information is received
     */
    useEffect(() => {
        if(!!deviceDetail) {
            setFormState(_formState => ({
                ..._formState,
                ...deviceDetail
            }))
        } else {
            setFormState(_formState => ({
                ...initialFormState,
                storeCode: _formState.storeCode,
            }))
        }
    }, [deviceDetail]);

    /**
     * Update Form state based on Input Change
     * @param {FormEvent} e 
     */
    const handleChange = e => {
        setFormState(_formState => ({
            ..._formState,
            [e.target.name]: e.target.value
        }))
    }

    /**
     * Fetch Shelly Device Config 
     */
    const toogleReview = () => {
        if(!formState.storeCode.match(/\d{4}/)) {
            setErrorField("storeCode");
            return;
        }
        if(!isReviewing) {
            const wanNumber = selectedDeviceType?.name.includes("Modem") ? wan : 0;
            dispatch(getFaultyShellyDeviceDetailInit(formState.storeCode, selectedDeviceType?.deviceTypeId, wanNumber));
            setErrorField("");
        }
        setReviewing(_isReviewing => !_isReviewing);
    }

    /**
     * Submit Updated Shelly Config
     */
    const handleFormSubmit = () => {
        if(!validator.isIP(formState.ipAddress)) {
            setErrorField("ipAddress");
        } else if(!validator.isIP(formState.gatewayIP)) {
            setErrorField("gatewayIP");
        } else if(!validator.isIP(formState.primaryDns)) {
            setErrorField("primaryDns");
        } else {              
            dispatch(postFaultyShellyDeviceDetailInit(formState));
            setErrorField("");
        }
    }

    return (
        <div className="shelly-replacement-container container-fluid">
            <div className="row">
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="storeCode">Store #</label>
                    <InputText id="storeCode" name="storeCode" className={errorField==="storeCode"?"error-field":""}
                        value={formState.storeCode} onChange={handleChange} disabled={isReviewing} />
                </div>
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="deviceType">Device Type</label>
                    <Dropdown id="deviceType" name="deviceType" value={selectedDeviceType} onChange={({value}) => setSelectedDeviceType(value)}
                        optionLabel="name" options={deviceTypes} disabled={isReviewing} />
                </div>
            </div>
            <div className="row">
                {
                    !selectedDeviceType?.name.includes("Cradlepoint") && 
                    <div className="col-12 col-lg-6 input-field">
                        <label htmlFor="wan">WAN</label>
                        <Dropdown id="wan" name="wan" value={wan} onChange={({value}) => setWan(value)}
                            options={wanTypes} disabled={isReviewing} />
                    </div>
                }
            </div>
            <Button onClick={toogleReview} className={isReviewing? "p-button-danger": "p-button-primary"}
                label={isReviewing? "Cancel": "Review"} />
            {
                !!isReviewing &&
                <>
                    <div className="row">
                        <div className="col-12 col-lg-6 input-field">
                            <label htmlFor="faultyShellyId">Faulty Shelly Id</label>
                            <InputText id="faultyShellyId" name="faultyShellyId" className={errorField==="faultyShellyId"?"error-field":""}
                                value={formState.faultyShellyId} onChange={handleChange} disabled />
                        </div>
                        <div className="col-12 col-lg-6 input-field">
                            <label htmlFor="ipAddress">IPv4 Address</label>
                            <InputText id="ipAddress" name="ipAddress" className={errorField==="ipAddress"?"error-field":""}
                                value={formState.ipAddress} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 input-field">
                            <label htmlFor="wifiName">
                                Wi-Fi Name &nbsp;&nbsp;
                                <Tooltip target=".wifi-name-info-icon" />
                                <i className="pi pi-info-circle wifi-name-info-icon" data-pr-tooltip="swsecure credential will be used if not Provided" data-pr-position="right" />
                            </label>
                            <InputText id="wifiName" name="wifiName" value={formState.wifiName} onChange={handleChange} />
                        </div>
                        <div className="col-12 col-lg-6 input-field">
                            <label htmlFor="gatewayIP">Gateway IP</label>
                            <InputText id="gatewayIP" name="gatewayIP" className={errorField==="gatewayIP"?"error-field":""}
                                value={formState.gatewayIP} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 input-field">
                            <label htmlFor="wifiPassword">
                                Wi-Fi Password &nbsp;&nbsp;
                                <Tooltip target=".password-info-icon" />
                                <i className="pi pi-info-circle password-info-icon" data-pr-tooltip="Default Password will be used if not Provided" data-pr-position="right" />
                            </label>
                            <Password id="wifiPassword" name="wifiPassword" value={formState.wifiPassword}
                                onChange={handleChange} feedback={false} placeholder="*****" />
                        </div>
                        <div className="col-12 col-lg-6 input-field">
                            <label htmlFor="primaryDns">Primary DNS Server</label>
                            <InputText id="primaryDns" name="primaryDns" className={errorField==="primaryDns"?"error-field":""}
                                value={formState.primaryDns} onChange={handleChange} />
                        </div>
                    </div>
                    <Button label="Generate Token" onClick={handleFormSubmit} disabled={!formState.deviceName}/>
                    {
                        !!otp && 
                        <div className="otp-message">
                            Token for {formState.deviceName} Shelly 
                            &nbsp; <CopyButton textToCopy={otp} /> <br/>
                            <span className="value">{otp}</span>
                        </div>
                    }
                </>
            }
            {
                errorField!=="" &&
                <div className="error-status">
                    Invalid Input Format
                </div>
            }
            <div className="link-container">
                { !!exeUrl && <a href={exeUrl}>Click here to download Executable</a>}
            </div>
        </div>
    )
}

export default ShellyReplacementReplace;