import React from 'react';

import './PageNotFound.scss';

const PageNotFound = () => (
    <div className="page-not-found-container">
        <span>404</span>
        <p>Page Not Found</p>
    </div>
)

export default PageNotFound;