import axios from "axios";
import { ApiPath } from "../../common/api-path/ApiPath";

export const getDistinctPingprobeAssetType = async () => {
    const response = await axios.get(
        `${ApiPath.baseUrl}/ping-probe/inventory/asset-types`
    );
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
};

export const getPingprobeInventory = async (assetTypes) => {
    const response = await axios.get(
        `${ApiPath.baseUrl}/ping-probe/inventory/assets`,
        { params: { assetTypes } }
    );
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
};

export const getPingprobeIncidentByAsset = async (ipAddress) => {
    const response = await axios.get(
        `${ApiPath.baseUrl}/ping-probe/inventory/incident`,
        { params: { ipAddress } }
    );
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
};

export const getPingprobeAssetLastPingTime = async () => {
    const response = await axios.get(
        `${ApiPath.baseUrl}/ping-probe/inventory/last-pingtime`
    );
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
};

export const postPingprobeSuppressionRule = async (suppressionRule) => {
    const response = await axios.post(
        `${ApiPath.baseUrl}/ping-probe/suppression/`,
        suppressionRule
    );
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
};

export const getAllPingprobeSuppressionRules = async () => {
    const response = await axios.get(
        `${ApiPath.baseUrl}/ping-probe/suppression/`
    );
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
};

export const getPingprobeAssetsBySuppressionRule = async (suppressionRule) => {
    const response = await axios.post(
        `${ApiPath.baseUrl}/ping-probe/suppression/assets`,
        suppressionRule
    );
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
};

export const deletePingprobeSuppressionRule = async (suppressionId) => {
    const response = await axios.delete(
        `${ApiPath.baseUrl}/ping-probe/suppression/${suppressionId}`
    );
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
};

export const postPingprobeLivePing = async (ipAddress) => {
    const response = await axios.post(
        `${ApiPath.baseUrl}/ping-probe/createlivepingprobe`,
        ipAddress
    );
    if (response.status >= 400) {
        throw new Error(response.errors);
    }
    return response.data;
};
 export const getPingDetails = async( startDate, endDate, offset) => {
    const requestConfig = {
      params: {startDate, endDate, offset },
      timeout: 120000,
      timeoutErrorMessage: "request timeout",
    };
    const response = await axios.get(
      `${ApiPath.baseUrl}/ping-probe/getallpingprobedetails`,
      requestConfig
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }
