export const GET_CONFIG_DIVISION_DETAILS = {
    INIT: "GET_CONFIG_DIVISION_DETAILS_INIT",
    SUCCESS: "GET_CONFIG_DIVISION_DETAILS_SUCCESS",
    ERROR: "GET_CONFIG_DIVISION_DETAILS_ERROR"
}

export const GET_EXCLUSION_RULE = {
    INIT: "GET_EXCLUSION_RULE_INIT",
    SUCCESS: "GET_EXCLUSION_RULE_SUCCESS",
    ERROR: "GET_EXCLUSION_RULE_ERROR"
}

export const POST_EXCLUSION_RULE = {
    INIT: "POST_EXCLUSION_RULE_INIT",
    SUCCESS: "POST_EXCLUSION_RULE_SUCCESS",
    ERROR: "POST_EXCLUSION_RULE_ERROR"
}