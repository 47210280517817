import { GET_ALL_PENDING_JOBS } from './actionsTypes';

export const getAllPendingJobsInit = deviceNames => ({
    type: GET_ALL_PENDING_JOBS.INIT,
    deviceNames
});

export const  getAllPendingJobsSuccess = pendingJobsList => ({
    type: GET_ALL_PENDING_JOBS.SUCCESS,
    payload: pendingJobsList
});

export const  getAllPendingJobsFailure = errorMessage => ({
    type: GET_ALL_PENDING_JOBS.ERROR,
    payload: errorMessage
});