import axios from "axios";
import { ApiPath } from "../../common/api-path/ApiPath";

export default class ShellyFirmwareService {
  async getShellyFirmwareGroupDetails() {
    const response = await axios.get(
      `${ApiPath.baseUrl}/shelly-upgrade/getallgroupdetails`,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }
  async postCreateGroup(group) {
    const response = await axios.post(
      `${ApiPath.baseUrl}/shelly-upgrade/creategroup`,
      group,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async cancelGroup(cancelGroup) {
    const response = await axios.post(
      `${ApiPath.baseUrl}/shelly-upgrade/cancelgroup`,
      cancelGroup,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async postModifiedGroup(modifyGroup) {
    const response = await axios.post(
      `${ApiPath.baseUrl}/shelly-upgrade/updategroup`,
      modifyGroup,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async getShellyFirmwareCampaigns(otaType) {
    const requestConfig = {
      params: { otaType },
      timeout: 120000,
      timeoutErrorMessage: "request timeout",
    };
    const response = await axios.get(
      `${ApiPath.baseUrl}/shelly-upgrade/getallcampaigns`,
      requestConfig
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async getEventDetailByCampaignName(campaignName) {
    const requestConfig = {
      params: { campaignName },
      timeout: 120000,
      timeoutErrorMessage: "request timeout",
    };
    const response = await axios.get(
      `${ApiPath.baseUrl}/shelly-upgrade/getcampaignlogs`,
      requestConfig
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async getShellyFirmwareCampaignDetails(campaignName) {
    const requestConfig = {
      params: { campaignName },
      timeout: 120000,
      timeoutErrorMessage: "request timeout",
    };
    const response = await axios.get(
      `${ApiPath.baseUrl}/shelly-upgrade/getcampaigndetails`,
      requestConfig
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async getEventDetailByDeviceId(id) {
    const requestConfig = {
      params: { deviceId: id },
      timeout: 120000,
      timeoutErrorMessage: "request timeout",
    };
    const response = await axios.get(
      `${ApiPath.baseUrl}/shelly-upgrade/getdevicelogs`,
      requestConfig
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async postCreateCampaign(campaign) {
    const response = await axios.post(
      `${ApiPath.baseUrl}/shelly-upgrade/createcampaign`,
      campaign,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async getAllOtaTypes() {
    const response = await axios.get(
      `${ApiPath.baseUrl}/shelly-upgrade/getallotatypes`,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async getShellyPlugGroups() {
    const response = await axios.get(
      `${ApiPath.baseUrl}/shelly-upgrade/getallshellygroupnames`,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async getShellyPlugFirmwareDetails() {
    const response = await axios.get(
      `${ApiPath.baseUrl}/shelly-upgrade/getshellyfirmware`,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async cancelShellyFirmwareCampaign(campaignName) {
    const response = await axios.post(
      `${ApiPath.baseUrl}/shelly-upgrade/cancelcampaign`,
      campaignName,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }

  async modifyShellyFirmwareCampaign(campaignDetails) {
    const response = await axios.post(
      `${ApiPath.baseUrl}/shelly-upgrade/updatecampaign`,
      campaignDetails,
      { timeout: 120000, timeoutErrorMessage: "request timeout" }
    );
    if (response.status >= 400) {
      throw new Error(response.errors);
    }
    return response.data;
  }
}
