import {
    GET_SERVICE_MAIN_INCIDENT_USECASES,
    GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS,
    GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS,
} from "./actionTypes"

export const getServiceMainIncidentUsecasesInit = () => ({
    type: GET_SERVICE_MAIN_INCIDENT_USECASES.INIT,
});

export const getServiceMainIncidentUsecasesSuccess = (usecaseList) => ({
    type: GET_SERVICE_MAIN_INCIDENT_USECASES.SUCCESS,
    payload: usecaseList,
});

export const getServiceMainIncidentUsecasesFailure = (errorMessage) => ({
    type: GET_SERVICE_MAIN_INCIDENT_USECASES.ERROR,
    payload: errorMessage,
});

export const getServiceMainIncidentUsecasesLogsInit = (
    usecaseType,
    startDate,
    endDate,
    offset
) => ({
    type: GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS.INIT,
    usecaseType,
    startDate,
    endDate,
    offset,
});

export const getserviceMainIncidentUsecasesLogsSuccess = (usecaseLogs) => ({
    type: GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS.SUCCESS,
    payload: usecaseLogs,
});

export const getServiceMainIncidentUsecasesLogsFailure = (errorMessage) => ({
    type: GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS.ERROR,
    payload: errorMessage,
});

export const getServiceMainIncidentUsecasesDetailsInit = (incident) => ({
    type: GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS.INIT,
    incident,
});

export const getServiceMainIncidentUsecasesDetailsSuccess = (details) => ({
    type: GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS.SUCCESS,
    payload: details,
});

export const getServiceMainIncidentUsecasesDetailsFailure = (errorMessage) => ({
    type: GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS.ERROR,
    payload: errorMessage,
});