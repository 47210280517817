import { Chip } from "primereact/chip";

import "./SelectedChips.scss";


const SelectedChips = ({ label, selectedValues, setSelectedValues }) => {
    const handleRemove = (selectedValue) => {
        setSelectedValues((_selectedValues) =>
            _selectedValues.filter(
                (_selectedValue) => _selectedValue !== selectedValue
            )
        );
    };

    return (
        <div className="col-12 mt-1">
            <small>
                <strong>{label}</strong>
            </small>
            {selectedValues.length === 0 && (
                <div id="no-selection-message">
                    Please select value from dropdown
                </div>
            )}
            <div className="chip-container">
                {selectedValues.map((selectedValue) => (
                    <Chip
                        key={selectedValue}
                        label={selectedValue}
                        removable
                        onRemove={() => handleRemove(selectedValue)}
                    />
                ))}
            </div>
        </div>
    );
};

export default SelectedChips;
