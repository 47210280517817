import React, { useState, useEffect } from "react";
import { useLocation, Switch, Redirect } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import PingProbePingDetails from "./ping-details/PingProbePingDetails";
import PingProbeLivePing from "./live-ping/PingProbeLivePing";
import { Route } from "react-router-dom/cjs/react-router-dom.min";

const LivePing = () => {
  const [tabHeader, setTabHeader] = useState("Live Ping Details");
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/admin/ping-probe/live-ping/create-live-ping") {
      setTabHeader("Create Ping Test");
    } else {
      setTabHeader("Live Ping Details");
    }
  }, [pathname]);

  return (
    <div style={{ width: "100%" }}>
      <p className="page-label" style={{ padding: "22px 18px 0 18px" }}>
        Ping Probe
        <i className="pi pi-angle-right" />
        Live Ping
      </p>
      <TabView>
        <TabPanel header={tabHeader}>
          <Switch>
            <Route path="/admin/ping-probe/live-ping/create-live-ping">
              <PingProbeLivePing />
            </Route>
            <Route path="/admin/ping-probe/live-ping/live-ping-details">
              <PingProbePingDetails />
            </Route>
            <Redirect
              exact
              path="/admin/ping-probe/live-ping"
              to="/admin/ping-probe/live-ping/live-ping-details"
            />
          </Switch>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default LivePing;
