import { call, put } from "redux-saga/effects";

import {
    getAllEventFailure,
    getAllEventSuccess,
} from "../components/device-management/events/redux/actions";

import EventService from "../components/device-management/events/EventsService";
import { parseDateTime } from "../common/date-time";

const eventService = new EventService();

/**
 * Worker saga for loading device events
 * @param {Object} action - Redux action
 */
function* loadAllEvents(action) {
    try {
        const eventList = yield call(eventService.getEvents, action.deviceId);
        const modifiedEventList = eventList.map((event) => ({
            ...event,
            type: (() => {
                if (!event.deviceId.includes("Virtual-Agent")) {
                    return null;
                }
                if (!event.correlationId) {
                    return "Unknown";
                }
                if (
                    event.correlationId.includes("AP_DOWN") ||
                    event.correlationId.includes("DEFAULT_GROUP")
                ) {
                    return "AP";
                }
                return "Controller";
            })(),
            eventTimestamp: event.eventTimestamp
                ? parseDateTime(event.eventTimestamp)
                : "-",
        }));
        yield put(getAllEventSuccess(modifiedEventList));
    } catch (error) {
        yield put(getAllEventFailure(error.toString()));
    }
}

export { loadAllEvents };
