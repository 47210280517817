import { call, put } from "redux-saga/effects";

import { 
    getMerakiFirewallAutomationLogsSuccess, 
    getMerakiFirewallAutomationLogsFailure,
    postModifyMerakiFirewallScheduleSuccess,
    postModifyMerakiFirewallScheduleError,
    cancelMerakiFirewallScheduleSuccess,
    cancelMerakiFirewallScheduleFailure

} from "../components/network-automation/redux/actions";


import MerakiFirewallAutomationService from "../components/network-automation/MerakiFirewallAutomationService";

const merakiFirewallAutomationService = new MerakiFirewallAutomationService();

function* loadMerakiFirewallAutomationLogs({startDate, endDate, offset}) {
    try {
        const automationLogs = yield call(merakiFirewallAutomationService.getMerakiFirewallAutomationLogs, startDate, endDate, offset);
        yield put(getMerakiFirewallAutomationLogsSuccess(automationLogs));
    } catch(error) {
        yield put(getMerakiFirewallAutomationLogsFailure(error.toString()));
    }
}
function* saveModifiedSchedule({ modifiedSchedule }) {
    try {
        yield call(merakiFirewallAutomationService.postModifySchedule, modifiedSchedule);
        yield put(postModifyMerakiFirewallScheduleSuccess());
       setTimeout (()=>{
        window.location.reload();
       }, 2000); 
    } catch (error) {
        yield put(postModifyMerakiFirewallScheduleError(error.toString()));
    }
}

function* cancelMerakiFirewallSchedule({ cancelSchedule }) {
    try {
        yield call(merakiFirewallAutomationService.cancelSchedule, cancelSchedule);
        yield put(cancelMerakiFirewallScheduleSuccess());
        setTimeout (()=>{
            window.location.reload();
           }, 2500); 
    } catch (error) {
        yield put(cancelMerakiFirewallScheduleFailure(error.toString()));
    }
}

export {loadMerakiFirewallAutomationLogs, saveModifiedSchedule, cancelMerakiFirewallSchedule}