import logo from "./images/albertsons-logo.svg";
import userIcon from "./images/user.svg";
import dropdownIcon from "./images/dropdown.svg";
import notificationIcon from "./images/notification.png";
import "primereact/resources/themes/saga-blue/theme.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "./App.scss";
import React, { useEffect } from "react";
import Dashboard from "./components/dashboard/Dashboard";
import { Redirect, Route } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Dropdown } from "react-bootstrap";
import Loader from "./common/components/loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import "./common/Utils/AxiosInterceptor";
import { handleLogin, handleLogout } from "./common/Utils/msalUtils";
import { getUserProfileInit } from "./common/components/profile/profileAction";
import CustomErrorToast from "./common/components/Toast/CustomErrorToast";
import ErrorBoundary from "./common/components/error-boundaries/ErrorBoundary";
import AssetList from "./components/asset/assetList/AssetList";
import UnauthorisedUser from "./common/components/unauthorised-user/UnauthorisedUser";

function App() {
  const loader = useSelector((state) => state.loader.loader);
  const showToast = useSelector((state) => state.toast.show);
  const userRoles = useSelector((state) => state.profile.userRoles);
  const userRolesErrorMessage = useSelector(
    (state) => state.profile.userRolesErrorMessage
  );
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();

  /**
   * Redirect unauthorized user for logIn
   */

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      handleLogin();
    } else if (isAuthenticated && !userRoles) {
      dispatch(getUserProfileInit());
    }
  }, [instance, inProgress, isAuthenticated, dispatch, userRoles]);

  /**
   * Show or Hide loader as per user Interaction
   */

  useEffect(() => {
    console.log(inProgress);

    if (inProgress === InteractionStatus.Startup) {
      dispatch({ type: "INCREMENT" });
    } else if (inProgress === InteractionStatus.None) {
      dispatch({ type: "DECREMENT" });
    }
  }, [inProgress, dispatch]);

  return (
    <>
      <div className="app">
        {loader !== 0 && <Loader />}
        <AuthenticatedTemplate>
          <ErrorBoundary>
            {userRolesErrorMessage ===
            "Error: Request failed with status code 400" ? (
              <UnauthorisedUser />
            ) : (
              <Dashboard>
                <header className="app-header">
                  <img src={logo} width="38" height="38" alt="logo" />
                  <span className="divider-line"></span>
                  <>
                    <span className="page-title">
                      EIoT Configuration Dashboard
                    </span>
                    <div className="user-icon">
                      <img
                        src={notificationIcon}
                        width="17"
                        height="20"
                        alt="notification-bell-icon"
                      />
                      {isAuthenticated && (
                        <>
                          <span className="divider-line"></span>
                          <img
                            src={userIcon}
                            width="32"
                            height="32"
                            alt="user-icon"
                          />
                          <Dropdown className="dropdown-small-screens">
                            <Dropdown.Toggle>
                              <img
                                src={dropdownIcon}
                                width="18"
                                height="11"
                                alt="dropdown-icon"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                {accounts &&
                                  accounts.length > 0 &&
                                  accounts[0].username}
                              </Dropdown.Item>
                              <Dropdown.Item onClick={handleLogout}>
                                Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <Dropdown className="dropdown-large-screens">
                            <Dropdown.Toggle>
                              <span className="user-name">
                                {accounts &&
                                  accounts.length > 0 &&
                                  accounts[0].username}
                              </span>

                              <img
                                src={dropdownIcon}
                                width="18"
                                height="11"
                                alt="dropdown-icon"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={handleLogout}>
                                Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                    </div>
                  </>
                </header>
              </Dashboard>
            )}
          </ErrorBoundary>

          <Route exact path="/">
            <Redirect
              to="/admin/device-management/assets"
              component={AssetList}
            />
          </Route>
        </AuthenticatedTemplate>
      </div>

      {showToast && <CustomErrorToast />}
    </>
  );
}

export default App;