import { FilterMatchMode, FilterOperator } from "primereact/api";

export const SUPPRESSION_TYPES = [
    { label: "Suppression", value: "suppression" },
    { label: "Decommission", value: "decommission" },
];

export const SUPPRESSION_FIELDS = [
    { label: "Asset Name", value: "asset_name" },
    { label: "DNS Name", value: "dns_name" },
    { label: "IP Address", value: "ip_address" },
];

export const NAME_RULE_TYPES = [
    { label: "Equals", value: "equals" },
    { label: "In", value: "in" },
    { label: "Starts With", value: "starts_with" },
    { label: "Ends With", value: "ends_with" },
    { label: "Contains", value: "contains" },
    { label: "Contains All", value: "contains_all" },
];

export const IP_ADDRESS_RULE_TYPES = [
    { label: "Equals", value: "equals" },
    { label: "In", value: "in" },
];

export const tableFilters = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    ipAddress: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    assetType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    assetName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    dnsName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    location: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    severity: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    assignmentGroup: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    incidentNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    field: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    parsedRule: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    suppressionType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
};
