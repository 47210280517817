import { GET_DEVICE_TYPE, GET_DEVICE_ONBOARDING_LOGS } from "./actionTypes";

const initialState = {
    deviceTypeList: [],
    deviceTypeErrorMessage: null,
    deviceOnboardingLogs: [],
    deviceOnboardingLogsErrorMessage: null,
};

const deviceOnboardingReducer = (
    state = initialState,
    action = { type: "" }
) => {
    switch (action.type) {
        case GET_DEVICE_TYPE.SUCCESS:
            return {
                ...state,
                deviceTypeList: [...action.payload],
                deviceTypeErrorMessage: null,
            };
        case GET_DEVICE_TYPE.ERROR:
            return {
                ...state,
                deviceTypeList: [],
                deviceTypeErrorMessage: action.payload,
            };
        case GET_DEVICE_ONBOARDING_LOGS.SUCCESS:
            return {
                ...state,
                deviceOnboardingLogs: action.payload,
                deviceOnboardingLogsErrorMessage: null,
            };
        case GET_DEVICE_ONBOARDING_LOGS.ERROR:
            return {
                ...state,
                deviceOnboardingLogs: [],
                deviceOnboardingLogsErrorMessage: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default deviceOnboardingReducer;
