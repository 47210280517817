import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { getPingprobeAssetsBySuppressionRuleInit } from "../redux/actions";
import { tableFilters } from "../constants";
import "./PingProbeSuppressionLogs.scss";


/**
 * Functional Component to show PingProbe Assets affected by selected Suppression rule
 * @returns JSX
 */
const PingprobeSuppressedAssetlist = ({
    selectedSuppressionRule,
    setSelectedSuppressionRule,
}) => {
    const suppressedAssets = useSelector(
        (state) => state.pingprobe.assetBySuppressionRule
    );
    const dispatch = useDispatch();
    const suppressedAssetsTableRef = useRef(null);


    /**
     * Fetching suppressed asset list
     */
    useEffect(() => {
        if (!!selectedSuppressionRule) {
            dispatch(
                getPingprobeAssetsBySuppressionRuleInit(selectedSuppressionRule)
            );
        }
    }, [selectedSuppressionRule]);

    /**
     * Function to hide dialog
     */
    const handleDialogHide = () => setSelectedSuppressionRule(null);

    /**
     * Function to export table data
     */
    const exportSuppressedAssetDetails = () => suppressedAssetsTableRef.current.exportCSV();

    return (
        <Dialog
            visible={!!selectedSuppressionRule}
            onHide={handleDialogHide}
            header={
                !!selectedSuppressionRule &&
                `Suppression Id: ${selectedSuppressionRule.suppressionId}`
            }
        >
             <Button
                    icon="pi pi-download"
                    className="p-button-text"
                    onClick={exportSuppressedAssetDetails}
                    style={{float:"right", zIndex:10}}
                />
            <DataTable
                value={suppressedAssets}
                ref={suppressedAssetsTableRef}
                filters={tableFilters}
                filterDisplay="menu"
                paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                currentPageReportTemplate="Items per page"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No Asset found."
            >
                <Column header="IP Address" field="ipAddress" sortable filter />
                <Column header="Asset Type" field="assetType" sortable filter />
                <Column header="Asset Name" field="assetName" sortable filter />
                <Column header="DNS Name" field="dnsName" sortable filter />
                <Column header="Location" field="location" sortable filter />
            </DataTable>
        </Dialog>
    );
};

export default PingprobeSuppressedAssetlist;
