import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Moment from "moment";

import DivisionNameDropdown from "../../../common/components/division-dropdown/DivisionNameDropdown";
import { parseDateTime, subtractMonth } from "../../../common/date-time";
import { getShellyDeviceHistoryInit } from "../redux/actions";
import "./ShellyReplacementHistory.scss";


const ShellyReplacementHistory = () => {

    const [selectedDivision, setSelectedDivision] = useState("");
    const [dateRange, setDateRange] = useState([subtractMonth(1), subtractMonth(0)]);
    const [tableFilters, setTableFilters] = useState(null);
    const shellyDeviceHistory = useSelector(state => state.shellyReplacement.shellyDeviceHistory);
    const dispatch = useDispatch();

    useEffect(() => {
        initializeTableFilter();
    }, []);

    /**
     * Initialize table filter
     */
    const initializeTableFilter = () => {
        setTableFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            storeCode: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            shellyId: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            deviceName: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            isReplaced: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            isDeleted: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
        });
    };

    const fetchShellyHistory = () => {
        dispatch(
            getShellyDeviceHistoryInit(
                selectedDivision,
                Moment(dateRange[0]).format("yyyy-MM-DD"),
                !!dateRange[1] ? Moment(dateRange[1]).format("yyyy-MM-DD") : Moment(dateRange[0]).format("yyyy-MM-DD")
            )
        );
    };

    const onboardedOnTemplate = ({ onboardedOn }) =>
        !!onboardedOn ? (
            parseDateTime(onboardedOn)
        ) : (
            <span style={{ marginLeft: "50%" }}>-</span>
        );

    return (
        <div className="shelly-replacement-history-container container-fluid">
            <div className="row">
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="division">Division</label>
                    <DivisionNameDropdown
                        id="division"
                        selectedDivision={selectedDivision}
                        setSelectedDivision={setSelectedDivision}
                    />
                </div>
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="dateRange">Events Date</label>
                    <Calendar
                        id="dateRange"
                        value={dateRange}
                        onChange={(e) => setDateRange(e.value)}
                        selectionMode="range"
                        minDate={subtractMonth(3)}
                        maxDate={subtractMonth(0)}
                        showIcon
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Button
                        label="Get History"
                        onClick={fetchShellyHistory}
                        disabled={!selectedDivision || !dateRange}
                    />
                </div>
            </div>
            {shellyDeviceHistory && shellyDeviceHistory.length!==0 && (
                <DataTable
                    value={shellyDeviceHistory}
                    filters={tableFilters}
                    filterDisplay="menu"
                    sortField="onboardedOn"
                    sortOrder={-1}
                    paginator
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    currentPageReportTemplate="Items per page"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                >
                    <Column header="Store" field="storeCode" sortable filter />
                    <Column header="Shelly ID" field="shellyId" sortable filter />
                    <Column header="Device Name" field="deviceName" sortable filter />
                    <Column header="Replaced via" field="isReplaced" sortable filter />
                    <Column header="Active" field="isDeleted" sortable filter />
                    <Column header="Onboarded On" field="onboardedOn" body={onboardedOnTemplate} sortable />
                </DataTable>
            )}
        </div>
    );
};

export default ShellyReplacementHistory;
