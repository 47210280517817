import axios from 'axios';
import { ApiPath } from '../../../common/api-path/ApiPath';

export default class MessagesService{

    getMessage = async deviceId => {
        const response = await axios.get(`${ApiPath.baseUrl}/telematicmessage/`+deviceId, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}