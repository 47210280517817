import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, Switch, Redirect } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";

import ShellyFirmwareGroupDetails from './shelly-firmware-group-details/ShellyFirmwareGroupDetails';
import ShellyFirmwareCampaignDetails from './shelly-firmware-campaign-details/ShellyFirmwareCampaignDetails';
import "./ShellyFirmware.scss"

const ShellyFirmware = () => {

	const [activeIndex, setActiveIndex] = useState(0);
	const { pathname } = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (pathname === "/admin/ota-firmware/group") {
			setActiveIndex(0);
		}
		else if (pathname === "/admin/ota-firmware/campaign") {
			setActiveIndex(1);
		}
	}, [pathname]);

	const handleTabChange = (e) => {
		if (e.index === 0) {
			setActiveIndex(0);
			history.push("/admin/ota-firmware/group");
		}

		else if (e.index === 1) {
			setActiveIndex(1);
			history.push("/admin/ota-firmware/campaign");
		}
	};

	return (
		<div style={{ width: "100%" }}>
			<p className="page-label" style={{ padding: "24px 24px 0 24px" }}>
				OTA - Firmware
			</p>
			<TabView
				activeIndex={activeIndex}
				onTabChange={handleTabChange}
				className="container-fluid"
			>
				<TabPanel header="Group">
					<ShellyFirmwareGroupDetails />
				</TabPanel>
				<TabPanel header="Campaign">
					<ShellyFirmwareCampaignDetails />
				</TabPanel>
			</TabView>

			<Switch>
				<Redirect
					exact
					path="/admin/ota-firmware"
					to="/admin/ota-firmware/group"
				/>
			</Switch>
		</div>
	)
}

export default ShellyFirmware;