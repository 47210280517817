import { GET_ALL_PENDING_JOBS } from './actionsTypes';

const initialState = {
    pendingJobsList: [],
    pendingJobsListErrorMessage: null
};

const pendingJobsReducer = (state=initialState, action={type:""}) => {
    switch(action.type) {
        case  GET_ALL_PENDING_JOBS.SUCCESS:
            return {
                ...state,
                pendingJobsList: action.payload,
                pendingJobsListErrorMessage: null
            }
        case  GET_ALL_PENDING_JOBS.ERROR:
            return {
                ...state,
                pendingJobsList: [],
                pendingJobsListErrorMessage: action.payload
            }
        default: 
            return {
                ...state
            }
    }
}

export default pendingJobsReducer;