import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { getCCIncidentUsecasesDetailsInit } from "../../redux/actions";
import { parseDateTime } from "../../../../common/date-time";


/**
 * Functional Component to show detailed log of a Incidents
 */
const DetailSummaryDialog = ({ dialogData, setDialogData }) => {
    const details = useSelector(
        (state) => state.commandCenter.incidentUsecasesDetails
    );
    const dispatch = useDispatch();
    const [tableFilters, setTableFilters] = useState(null);
    const detailsSummaryTableRef = useRef(null);


    /**
     * Initializing datatable filters
     */
    useEffect(() => {
        initializeTableFilter();
    }, []);

    /**
     * Fetching detailed log of a Incidents
     */
    useEffect(() => {
        if (!!dialogData) {
            fetchDetails();
        }
    }, [dialogData]);

    /**
     * Function to fetch detailed log of a Incidents
     */
    const fetchDetails = () =>
        dispatch(getCCIncidentUsecasesDetailsInit(dialogData));

    /**
     * Function to initialize Table filter
     */
    const initializeTableFilter = () => {
        setTableFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            event: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            message: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
        });
    };

    /**
     * Function to provide template for Message column in datatable
     * @param raw column
     * @returns templated column
     */
    const messageTemplate = ({ message, payload }) => (
        <>
            {message}
            {!!payload && (
                <>
                    <br />
                    {JSON.stringify(payload)}
                </>
            )}
        </>
    );

    const timestampTemplate = ({ timestamp }) =>
        !!timestamp ? parseDateTime(timestamp) : <>-</>;

    
        /**
     * Function to export table data
     */
        const exportDetailSummaryDialogLogs = () => detailsSummaryTableRef.current.exportCSV();

    return (
        <Dialog
            className="cc-usecase-detail-dialog"
            visible={!!dialogData}
            onHide={() => setDialogData(null)}
            header={dialogData}
        >
        <div className="incident-summary-logs-detail-dialog-table-action">

            <Button
                icon="pi pi-refresh"
                className="p-button-text refresh-icon"
                onClick={fetchDetails}
            />
             <Button
                icon="pi pi-download"
                className="p-button-text"
                onClick={exportDetailSummaryDialogLogs}
                />
        </div>
            <DataTable
                value={details}
                ref={detailsSummaryTableRef}
                filters={tableFilters}
                filterDisplay="menu"
                sortField="timestamp"
                sortOrder={-1}
                emptyMessage="No logs found."
            >
                <Column header="Event" field="event" sortable filter />
                <Column
                    header="Message"
                    field="message"
                    body={messageTemplate}
                    sortable
                    filter
                />
                <Column
                    header="Timestamp"
                    field="timestamp"
                    body={timestampTemplate}
                    sortable
                />
            </DataTable>
        </Dialog>
    );
};

export default DetailSummaryDialog;
