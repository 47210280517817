import { useState } from "react";
import { RadioButton } from "primereact/radiobutton";

import RitSummary from "./rit-summary/RitSummary";
import IncidentSummary from "./incident-summary/IncidentSummary";
import "./index.scss";

const LOGS_OPTIONS = [
    {
        message: "Incident Logs",
        value: "incident",
    },
    {
        message: "RIT Logs",
        value: "rit",
    },
];

/**
 * Functional Component to show Remaining CC usecases detail
 * @returns JSX
 */
const RemainingCCUsecases = () => {
    const [selectedUsecaseType, setSelectedUsecaseType] = useState("incident");

    return (
        <div className="cc-remaining-usecase-container ">
            {LOGS_OPTIONS.map(({ message, value }) => (
                <div key={value} className="usecase-type-radio">
                    <RadioButton
                        value={value}
                        onChange={(e) => setSelectedUsecaseType(e.value)}
                        checked={selectedUsecaseType === value}
                    />
                    {message}
                </div>
            ))}
            {selectedUsecaseType === "rit" ? (
                <RitSummary />
            ) : (
                <IncidentSummary />
            )}
        </div>
    );
};

export default RemainingCCUsecases;
