import { call, put } from 'redux-saga/effects';

import { getAllStoreListSuccess, getAllStoreListFailure } from '../components/search-store/redux/actions';

import SearchStoreInputService from '../components/search-store/SearchStoreInputService';

const searchStoreInputService = new SearchStoreInputService();

/**
 * Worker saga for loading all store details
 */
function* loadAllStoreList() {
    try {
        const storeList = yield call(searchStoreInputService.getStores);
        const modifiedStoreList = storeList.map(store => ({
            name: store.storeCode,
            code: store.storeId
        }))
        yield put(getAllStoreListSuccess(modifiedStoreList));
    } catch(error) {
        yield put(getAllStoreListFailure(error.toString()));
    }
}

export { loadAllStoreList };