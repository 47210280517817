import React, { forwardRef } from 'react';

import AssetTableContainer from './AssetTableContainer';
import ModemTable from './ModemTable';
import './AssetList.scss';

const RenderAssetTableContainer = forwardRef(({title, deviceData, query}, ref) => {
    if(Array.isArray(deviceData)) {
        return <ModemTable ref={ref} title={title} data={deviceData} />;
    }
    return <AssetTableContainer ref={ref} title={title} query={query} deviceData={deviceData} />
    

});

export default RenderAssetTableContainer;