import {
    GET_SHELLY_REPLACEMENT_EXE_URL,
    GET_FAULTY_SHELLY_DEVICE_DETAIL, 
    POST_FAULTY_SHELLY_DEVICE_DETAIL,
    GET_SHELLY_DEVICE_HISTORY
} from './actionTypes';

export const getShellyReplacementExeUrlInit = () => ({
    type: GET_SHELLY_REPLACEMENT_EXE_URL.INIT
});

export const getShellyReplacementExeUrlSuccess = exeUrl => ({
    type: GET_SHELLY_REPLACEMENT_EXE_URL.SUCCESS,
    payload: exeUrl
});

export const getShellyReplacementExeUrlFailure = errorMessage => ({
    type: GET_SHELLY_REPLACEMENT_EXE_URL.ERROR,
    payload: errorMessage
});

export const getFaultyShellyDeviceDetailInit = (storeCode, deviceTypeId, wan) => ({
    type: GET_FAULTY_SHELLY_DEVICE_DETAIL.INIT,
    storeCode, deviceTypeId, wan
});

export const getFaultyShellyDeviceDetailSuccess = deviceDetail => ({
    type: GET_FAULTY_SHELLY_DEVICE_DETAIL.SUCCESS,
    payload: deviceDetail
});

export const getFaultyShellyDeviceDetailFailure = errorMessage => ({
    type: GET_FAULTY_SHELLY_DEVICE_DETAIL.ERROR,
    payload: errorMessage
})

export const postFaultyShellyDeviceDetailInit = deviceDetail => ({
    type: POST_FAULTY_SHELLY_DEVICE_DETAIL.INIT,
    deviceDetail
});

export const postFaultyShellyDeviceDetailSuccess = otp => ({
    type: POST_FAULTY_SHELLY_DEVICE_DETAIL.SUCCESS,
    payload: otp
});

export const postFaultyShellyDeviceDetailFailure = errorMessage => ({
    type: POST_FAULTY_SHELLY_DEVICE_DETAIL.ERROR,
    payload: errorMessage
});

export const getShellyDeviceHistoryInit = (division, startDate, endDate) => ({
    type: GET_SHELLY_DEVICE_HISTORY.INIT,
    division, startDate, endDate
});

export const getShellyDeviceHistorySuccess = shellyDeviceHistory => ({
    type: GET_SHELLY_DEVICE_HISTORY.SUCCESS,
    payload: shellyDeviceHistory
});

export const getShellyDeviceHistoryFailure = errorMessage => ({
    type: GET_SHELLY_DEVICE_HISTORY.ERROR,
    payload: errorMessage
});