import React, {useEffect, useState, useRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Divider } from 'primereact/divider';
import { FilterOperator, FilterMatchMode } from 'primereact/api';
import { useSelector, useDispatch } from 'react-redux';

import { parseDateTime } from '../../../common/date-time';
import { getIncidentSuppressionLogsInit, getIncidentSuppressionLogsBySnowTicketInit } from '../redux/actions';
import IncidentSuppressionLogsDetailDialog from './IncidentSuppressionLogsDetailDialog';
import "./IncidentSuppressionLogs.scss";

const IncidentSuppressionLogs = () => {

    const [selectedSnowTicketNumber, setSelectedSnowTicketNumber] = useState(null);
    const [showIncidentSuppressionLogs, setShowIncidentSuppressionLogs] = useState(true);
    const [dialogData, setDialogData] = useState(null);
    const [tableFilters, setTableFilters] = useState(null);

    const incidentSuppressionLogs = useSelector(state => state.incidentSuppression.incidentSuppressionLogs);
    const incidentSuppressionLogsBySnowTicket = useSelector(state => state.incidentSuppression.incidentSuppressionLogsBySnowTicket);
    const dispatch = useDispatch();
    const tableRef = useRef(null);

    const initializeTableFilter = () => {
        setTableFilters({
            global: {value: null, matchMode: FilterMatchMode.CONTAINS},
            incidentSuppressionType: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              stores: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              deviceType: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              status: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              createdUser: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
              snowTicket: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
              },
        });
    }

    /**
     * Fetching active suppression logs
     */

    useEffect(() => {
        fetchActiveIncidentSuppressionLogs();
        initializeTableFilter();
    },[]);

    const fetchActiveIncidentSuppressionLogs = () => {
        dispatch(getIncidentSuppressionLogsInit());
        setShowIncidentSuppressionLogs(true);
    }

    /**
     * Function to search request by snow ticket number
     */

    const searchRequestBySnowTicketNumber = () => {
        if(selectedSnowTicketNumber !== null && selectedSnowTicketNumber !== '') {
         dispatch(getIncidentSuppressionLogsBySnowTicketInit(selectedSnowTicketNumber));
         setShowIncidentSuppressionLogs(false);
         clearInput();
         }
     }

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') {
            searchRequestBySnowTicketNumber();
            clearInput();
        }
    }

    /**
     * Function to clear input
     */
    const clearInput = () => {
        setSelectedSnowTicketNumber('');
    }

    /**
     * Data handling functions
     */
    const deviceTypeTemplate = ({deviceType}) => {
        return deviceType.join(', ').toUpperCase();
    };

    const storesTemplate = ({stores}) => !!stores ? stores: 'NA';

    const startDateTimeTemplate = ({startDateTime}) => !!startDateTime ? parseDateTime(startDateTime): '-';

    const endDateTimeTemplate = ({endDateTime}) => !!endDateTime ? parseDateTime(endDateTime): 'NA';

    const requestCreatedAtTemplate = ({createdDateTime}) => !!createdDateTime ? parseDateTime(createdDateTime): '-';

    const actionsTemplate = (rowData) => {
        return (
            <div className="incident-suppression-actions-containter">
            <Button
            name="view"
            className="p-button-text"
            icon="pi pi-info-circle"
            onClick={() => setDialogData(rowData)}/>
            </div>
        )
    }

    const paginatorLeft = () => {
        return (
            <span style={{verticalAlign: "middle"}}>
                <i className="pi pi-info-circle event-table-info-icon" style={{verticalAlign: "middle"}}/>
                <span style={{paddingLeft: "3px", verticalAlign: "middle"}}>Table contains only Active requests, search by INC/RIT/CHG number for other requests</span>
            </span>
        )
    }

    return (
        <div className="incident-suppression-logs-container container-fluid">
            <p className="page-label">
                EIOT Incident Suppression
                <i className="pi pi-angle-right"/>
                Suppression Logs
            </p>
            <div className="incident-suppression-logs-content">
                <IncidentSuppressionLogsDetailDialog dialogData={dialogData} setDialogData={setDialogData} />
                <div className="incident-suppression-logs-action">
                <span className="status-info-text">
                    Status Info &nbsp;
                    <Tooltip target=".status-info" >
                        <div className="status-info-tooltip">
                            <span style={{fontWeight: "bold"}}>Received : </span><span>Suppression Request Received and Scheduled</span>
                            <Divider style={{marginTop: "5px", marginBottom: "5px"}}/>
                            <span style={{fontWeight: "bold"}}>Active : </span><span>Suppression done</span>
                            <Divider style={{marginTop: "5px", marginBottom: "5px"}}/>
                            <span style={{fontWeight: "bold"}}>Inactive : </span><span>Suppression rule uplifted as end date time elapsed. Please search by SNOW Ticket # for Inactive requests</span>
                        </div>
                    </Tooltip>
                    <i className="pi pi-info-circle status-info" data-pr-position="top"/>
                </span>
                    <InputText
                    placeholder="Search by INC/RIT/CHG #"
                    style={{width: '18rem', height: '3rem'}}
                    value={selectedSnowTicketNumber || ''}
                    onChange={(e) => setSelectedSnowTicketNumber(e.target.value)}
                    onKeyDown={handleKeyDown}
                    />
                    <Button
                    name="search"
                    label="Search"
                    icon="pi pi-search"
                    className="filter-button p-button-outlined p-button-sm"
                    onClick={searchRequestBySnowTicketNumber}
                    />
                    <Button
                    name="refresh"
                    className="p-button-text"
                    icon="pi pi-refresh"
                    onClick={fetchActiveIncidentSuppressionLogs}
                    />
                    <Button
                    name="download"
                    className="p-button-text"
                    icon="pi pi-download"
                    onClick={() => tableRef.current.exportCSV()}
                    />
                </div>
                <DataTable
                    value={showIncidentSuppressionLogs ? incidentSuppressionLogs: incidentSuppressionLogsBySnowTicket}
                    ref={tableRef}
                    className="p-datatable-gridlines"
                    filters={tableFilters}
                    filterDisplay='menu'
                    sortField="startDateTime"
                    sortOrder={-1}
                    paginator
                    paginatorLeft = {showIncidentSuppressionLogs && paginatorLeft}
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    currentPageReportTemplate="Items per page"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    emptyMessage="No logs found."
                >
                    <Column header="Suppression Type" field="incidentSuppressionType" sortable filter />
                    <Column header="Store(s)" field="stores" body={storesTemplate} sortable filter />
                    <Column header="Device(s)" field="deviceType" body={deviceTypeTemplate} filter />
                    <Column header="Start Date" field="startDateTime" body={startDateTimeTemplate} sortable />
                    <Column header="End Date" field="endDateTime" body={endDateTimeTemplate} sortable />
                    <Column header="Status" field="status" filter />
                    <Column header="Request Created By" field="createdUser" sortable filter />
                    <Column header="Created At" field="createdDateTime" body={requestCreatedAtTemplate} sortable />
                    <Column header="SNOW Ticket #" field="snowTicket" sortable filter />
                    <Column header="Actions" body={actionsTemplate} />

                </DataTable>
            </div>
        </div>
    )
}

export default IncidentSuppressionLogs;