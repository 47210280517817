import { GET_ALL_EVENTS } from './actionsTypes';

const initialState = {
    eventList: [],
    eventListErrorMessage: null
};

const eventReducer = (state=initialState, action={type:""}) => {
    switch(action.type) {
        case GET_ALL_EVENTS.SUCCESS:
            return {
                ...state,
                eventList: action.payload,
                eventListErrorMessage: null
            }
        case GET_ALL_EVENTS.ERROR:
            return {
                ...state,
                eventList: [],
                eventListErrorMessage: action.payload
            }
        default: 
            return {
                ...state
            }
    }
}

export default eventReducer;