import {
    GET_SHELLY_REPLACEMENT_EXE_URL,
    GET_FAULTY_SHELLY_DEVICE_DETAIL, 
    POST_FAULTY_SHELLY_DEVICE_DETAIL,
    GET_SHELLY_DEVICE_HISTORY 
} from './actionTypes';


const initialState = {
    exeUrl: null,
    exeUrlErrorMessage: null,
    shellyDeviceDetail: null,
    shellyDeviceDetailErrorMessage: null,
    otp: null,
    otpErrorMessage: null,
    shellyDeviceHistory: [],
    shellyDeviceHistoryErrorMessage: null,
}

const shellyReplacementReducer = (state=initialState, action={type:"", payload: ""}) => {
    switch(action.type) {
        case GET_SHELLY_REPLACEMENT_EXE_URL.SUCCESS:
            return {
                ...state, exeUrl: action.payload, exeUrlErrorMessage: null
            }
        case GET_SHELLY_REPLACEMENT_EXE_URL.ERROR:
            return {
                ...state, exeUrl: null, exeUrlErrorMessage: action.payload
            }
        case GET_FAULTY_SHELLY_DEVICE_DETAIL.SUCCESS:
            return {
                ...state, shellyDeviceDetail: action.payload, shellyDeviceDetailErrorMessage: null, otp: null
            }
        case GET_FAULTY_SHELLY_DEVICE_DETAIL.ERROR:
            return {
                ...state, shellyDeviceDetail: null, shellyDeviceDetailErrorMessage: action.payload, otp: null
            }
        case POST_FAULTY_SHELLY_DEVICE_DETAIL.SUCCESS:
            return {
                ...state, otp: action.payload, otpErrorMessage: null
            }
        case POST_FAULTY_SHELLY_DEVICE_DETAIL.ERROR:
            return {
                ...state, otp: null, otpErrorMessage: action.payload
            }
        case GET_SHELLY_DEVICE_HISTORY.SUCCESS:
            return {
                ...state, shellyDeviceHistory: action.payload, shellyDeviceHistoryErrorMessage: null
            }
        case GET_SHELLY_DEVICE_HISTORY.ERROR:
            return {
                ...state, shellyDeviceHistory: [], shellyDeviceHistoryErrorMessage: action.payload
            }
        default:
            return {...state}
    }
}

export default shellyReplacementReducer;