import { GET_EPE_ERROR_CODES, GET_EPE_LOGS } from "./actionTypes";

export const getEpeErrorCodesInit = () => ({
  type: GET_EPE_ERROR_CODES.INIT,
});

export const getEpeErrorCodesSuccess = (errorCodeList) => ({
  type: GET_EPE_ERROR_CODES.SUCCESS,
  payload: errorCodeList,
});

export const getEpeErrorCodesFailure = (errorMessage) => ({
  type: GET_EPE_ERROR_CODES.ERROR,
  payload: errorMessage,
});

export const getEpeLogsInit = (errorCodes, startDate, endDate, offset) => ({
  type: GET_EPE_LOGS.INIT,
  errorCodes,
  startDate,
  endDate,
  offset,
});

export const getEpeLogsSuccess = (epeLogs) => ({
  type: GET_EPE_LOGS.SUCCESS,
  payload: epeLogs,
});

export const getEpeLogsFailure = (errorMessage) => ({
  type: GET_EPE_LOGS.ERROR,
  payload: errorMessage,
});
