export const GET_DISTINCT_PINGPROBE_ASSET_TYPE = {
    INIT: "GET_DISTINCT_PINGPROBE_ASSET_LIST_INIT",
    SUCCESS: "GET_DISTINCT_PINGPROBE_ASSET_LIST_SUCCESS",
    ERROR: "GET_DISTINCT_PINGPROBE_ASSET_LIST_ERROR",
};

export const GET_PINGPROBE_INVENTORY = {
    INIT: "GET_PINGPROBE_INVENTORY_INIT",
    SUCCESS: "GET_PINGPROBE_INVENTORY_SUCCESS",
    ERROR: "GET_PINGPROBE_INVENTORY_ERROR",
};

export const GET_PINGPROBE_INCIDENTS_BY_ASSET = {
    INIT: "GET_PINGPROBE_INCIDENTS_BY_ASSET_INIT",
    SUCCESS: "GET_PINGPROBE_INCIDENTS_BY_ASSET_SUCCESS",
    ERROR: "GET_PINGPROBE_INCIDENTS_BY_ASSET_ERROR",
};

export const GET_PINGPROBE_ASSET_LAST_PINGTIME = {
    INIT: "GET_PINGPROBE_ASSET_LAST_PINGTIME_INIT",
    SUCCESS: "GET_PINGPROBE_ASSET_LAST_PINGTIME_SUCCESS",
    ERROR: "GET_PINGPROBE_ASSET_LAST_PINGTIME_ERROR",
};

export const POST_PINGPROBE_SUPPRESSION_RULE = {
    INIT: "POST_PINGPROBE_SUPPRESSION_RULE_INIT",
    SUCCESS: "POST_PINGPROBE_SUPPRESSION_RULE_SUCCESS",
    ERROR: "POST_PINGPROBE_SUPPRESSION_RULE_ERROR",
};

export const GET_ALL_PINGPROBE_SUPPRESSION_RULES = {
    INIT: "GET_ALL_PINGPROBE_SUPPRESSION_RULES_INIT",
    SUCCESS: "GET_ALL_PINGPROBE_SUPPRESSION_RULES_SUCCESS",
    ERROR: "GET_ALL_PINGPROBE_SUPPRESSION_RULES_ERROR",
};

export const GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE = {
    INIT: "GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE_INIT",
    SUCCESS: "GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE_SUCCESS",
    ERROR: "GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE_ERROR",
};

export const DELETE_PINGPROBE_SUPPRESSION_RULE = {
    INIT: "DELETE_PINGPROBE_SUPPRESSION_RULE_INIT",
    SUCCESS: "DELETE_PINGPROBE_SUPPRESSION_RULE_SUCCESS",
    ERROR: "DELETE_PINGPROBE_SUPPRESSION_RULE_ERROR"
}

export const SET_PINGPROBE_SUPPRESSION_RULE = "SET_PINGPROBE_SUPPRESSION_RULE";

export const POST_PINGPROBE_LIVE_PING = {
    INIT: "POST_PINGPROBE_LIVE_PING_INIT",
    SUCCESS: "POST_PINGPROBE_LIVE_PING_SUCCESS",
    ERROR: "POST_PINGPROBE_LIVE_PING_ERROR",
};
export const GET_PING_DETAILS = {
    INIT: "GET_PING_DETAILS_INIT",
    SUCCESS: "GET_PING_DETAILS_SUCCESS",
    ERROR: "GET_PING_DETAILS_ERROR",
  };