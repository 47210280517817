import { call, put } from 'redux-saga/effects';

import { getUserProfileSuccess, getUserProfileFailure } from '../common/components/profile/profileAction';

import ProfileService from '../common/components/profile/profileService';


const profileService = new ProfileService();

/**
 * Worker saga for loading user profile
 */
function* loadUserProfile() {
    try {
        const userProfileResponse = yield call(profileService.getUserProfile);
        yield put(getUserProfileSuccess(userProfileResponse));
    } catch(error) {
        yield put(getUserProfileFailure(error.toString()));
    }
}

export { loadUserProfile };