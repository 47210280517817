import axios from 'axios';
import { ApiPath } from '../../common/api-path/ApiPath';

export default class IncidentConfigService {

    async getDivisionDetail() {
        const response = await axios.get(`${ApiPath.baseUrl}/division/stores`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getExclusionRule(division, assetType) {
        const response = await axios.get(`${ApiPath.baseUrl}/exclusionRule/${division}/${assetType}`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async postExclusionRule(exclusionRule, division, assetType) {
        const response = await axios.post(
            `${ApiPath.baseUrl}/exclusionRule/${division}/${assetType}`, 
            exclusionRule,
            { timeout: 120000, timeoutErrorMessage: "request timeout" }
        );
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}