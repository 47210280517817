import React from 'react';

import './ErrorBoundary.scss';


class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        }
    }

    /**
     * Update state so next render shows fallback UI
     * @param {error} - Error thrown while rendering
     */
    static getDerivedStateFromError(_error) {
        return { hasError: true };
    }

    /**
     * Log error to console
     * @param {error} - Error thrown while rendering
     * @param {info} - Stores componentStack Trace
     */
    componentDidCatch(error, info) {
        console.log(error);
        console.log(info)
    }

    render() {
        if(this.state.hasError) {
            return <div className="error-boundary">Opps! Something went wrong</div>
        }
        return this.props.children;
    }
}

export default ErrorBoundary;