import { call, put } from 'redux-saga/effects';

import { getAllMessageFailure, getAllMessagesSuccess } from '../components/device-management/messages/redux/actions';

import MessageService from '../components/device-management/messages/MessagesService';
import { parseDateTime } from '../common/date-time';

const messageService = new MessageService();

/**
 * Worker saga for loading device messages
 * @param {Object} action - Redux action
 */
function* loadAllMessages(action) {
    try {
        const messageList = yield call(messageService.getMessage, action.deviceId);
        const modifiedMessageList = messageList.map((message) => ({
            ...message,
            telematicTimestamp: message.telematicTimestamp ? parseDateTime(message.telematicTimestamp) : '-'
        }))
        yield put(getAllMessagesSuccess(modifiedMessageList));
    } catch (error) {
        yield put(getAllMessageFailure(error.toString()));
    }
}

export { loadAllMessages };