import { PublicClientApplication } from '@azure/msal-browser';

import { loginRequest, msalConfig } from "../../authConfig";


export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * function for login
 */
export const handleLogin = () => {
    msalInstance.loginRedirect(loginRequest)
        .catch(error => console.log(error));
}

/**
 * function for logout
 */
export const handleLogout = () => {
    msalInstance.logoutRedirect()
        .catch(error => console.log(error));
}

/**
 *  Get access token 
 */
export const getAccessToken = () => {

    const accounts = msalInstance.getAllAccounts();

    const request = {
        ...loginRequest,
        account: accounts[0]
    }

    return msalInstance.acquireTokenSilent(request)
        .then(response => response.accessToken)
        .catch(_error => {
            msalInstance.acquireTokenRedirect(request)
                .then(response => response.accessToken)
        });
}

/**
 *  Get LDAP Id
 */
export const getLdapId = () => {

    const accounts = msalInstance.getAllAccounts();
    const username = accounts[0].username;
    return username.slice(0, username.lastIndexOf('@'));
}