import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator, PrimeIcons } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { Tooltip } from "primereact/tooltip";

import { parseDateTime, addDateTime } from "../../../common/date-time";
import {
  getOtaTypesInit,
  getShellyFirmwareCampaignsInit,
  cancelShellyFirmwareCampaignInit,
  setModifyCampaignDetails,
  getShellyPlugGroupsInit,
  getShellyPlugFirmwareDetailsInit,
  postShellyFirmwareCampaignInit,
  modifyShellyFirmwareCampaignInit,
} from "../redux/action";
import ShellyFirmwareCampaignDevices from "./ShellyFirmwareCampaignDevices";
import ShellyFirmwareCampaignEventLogs from "./ShellyFirmwareCampaignEventLogs";
import "./ShellyFirmwareCampaignDetails.scss";

const ShellyFirmwareCampaignDetails = () => {
  const [selectedOtaTypes, setSelectedOtaTypes] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [tableFilters, setTableFilters] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const [showCampaignForm, setShowCampaignForm] = useState(false);
  const shellyFirmwareCampaignDetailsTableRef = useRef(null);

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const confirmPopupRef = useRef(null);

  const otaTypes = useSelector((state) => state.shellyFirmware.otaTypes);

  const shellyPlugGroups = useSelector(
    (state) => state.shellyFirmware.shellyPlugGroups
  );

  const shellyPlugFirmwareDetails = useSelector(
    (state) => state.shellyFirmware.shellyPlugFirmwareDetails
  );

  const campaignDetails = useSelector(
    (state) => state.shellyFirmware.setModifyCampaignDetails
  );
  const shellyFirmwareCampaigns = useSelector(
    (state) => state.shellyFirmware.shellyFirmwareCampaigns
  );

  let defaultState = {
    otaType: otaTypes[0],
    campaignName: "",
    groupName: "",
    targetVersion: "",
    imageUrl: "",
    scheduledDateTime: null,
    strictUpdate: true,
    ritNumber: "",
    userComments: "",
  };

  useEffect(() => {
    dispatch(getOtaTypesInit());
    dispatch(getShellyPlugGroupsInit());
    dispatch(getShellyPlugFirmwareDetailsInit());
    initializeTableFilter();
  }, []);

  useEffect(() => {
    setSelectedOtaTypes([...otaTypes]);
    if (otaTypes.length > 0) {
      fetchShellyFirmwareCampaigns(true);
    }
  }, [otaTypes]);

  const {
    values,
    setValues,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    resetForm,
  } = useFormik({
    dirty: true,
    enableReinitialize: true,
    initialValues: !!campaignDetails ? campaignDetails : defaultState,
    validate: (data) => {
      const errors = {};
      validateOtaType(data, errors);
      validateCampaignName(data, errors);
      validateGroup(data, errors);
      validateTargetVersion(data, errors);
      validateScheduledDateTime(data, errors);
      validateRitNumber(data, errors);
      validateUserComments(data, errors);
      return errors;
    },
    onSubmit: () => {
      setVisible(true);
    },
  });

  const submitHandler = () => {
    const payload = {
      ...values,
      otaType: values.otaType.trim(),
      campaignName: values.campaignName.trim(),
      groupName: values.groupName.trim(),
      targetVersion: values.targetVersion.trim(),
      imageUrl: values.imageUrl.trim(),
      ritNumber: values.ritNumber.trim(),
      userComments: values.userComments.trim(),
    };
    !!campaignDetails
      ? dispatch(modifyShellyFirmwareCampaignInit(payload))
      : dispatch(postShellyFirmwareCampaignInit(payload));
    clearForm();
    setShowCampaignForm(false);
  };

  const cancelHandler = () => {
    clearForm();
    setShowCampaignForm(false);
  };

  const validateOtaType = (data, errors) => {
    if (!data.otaType || data.otaType.length === 0) {
      errors["otaType"] = "OTA Type cannot be empty";
    }
  };

  const validateCampaignName = (data, errors) => {
    if (!data.campaignName || data.campaignName.length === 0) {
      errors["campaignName"] = "Campaign Name cannot be empty";
    }
  };

  const validateGroup = (data, errors) => {
    if (!data.groupName || data.groupName.length === 0) {
      errors["groupName"] = "Group cannot be empty";
    }
  };

  const validateTargetVersion = (data, errors) => {
    if (!data.targetVersion || data.targetVersion.length === 0) {
      errors["targetVersion"] = "Target Version cannot be empty";
    }
  };

  const validateScheduledDateTime = (data, errors) => {
    if (!!campaignDetails) {
      if (
        !data.scheduledDateTime ||
        data.scheduledDateTime <= addDateTime("minutes", 10, new Date())
      ) {
        errors["scheduledDateTime"] =
          "Scheduled Datetime should be greater than 10 mins from Current Datetime";
      }
    } else {
      if (
        !data.scheduledDateTime ||
        data.scheduledDateTime <= addDateTime("minutes", 30, new Date())
      ) {
        errors["scheduledDateTime"] =
          "Scheduled Datetime should be greater than 30 mins from Current Datetime";
      }
    }
  };

  const validateRitNumber = (data, errors) => {
    if (!data.ritNumber || data.ritNumber.length === 0) {
      errors["ritNumber"] = "Change/RIT Number cannot be empty";
    }
  };

  const validateUserComments = (data, errors) => {
    if (!!campaignDetails) {
      if (!data.userComments || data.userComments.length === 0) {
        errors["userComments"] =
          "Comments cannot be empty while modifying details";
      }
    }
  };

  const errorClassName = (formField) => {
    return !!touched[formField] && !!errors[formField] ? "p-invalid" : "";
  };

  const clearForm = () => {
    dispatch(setModifyCampaignDetails(null));
    resetForm();
  };

  const targetVersionHandler = (e) => {
    const selectedImage = shellyPlugFirmwareDetails.find(
      (item) => item.versionNumber === e.target.value
    );
    setValues({
      ...values,
      targetVersion: e.target.value,
      imageUrl: selectedImage.imageUrl,
    });
  };

  const fetchShellyFirmwareCampaigns = (initialLoad) => {
    let otaType = selectedOtaTypes.join(",");
    if (initialLoad === true) {
      otaType = otaTypes.join(",");
    }
    dispatch(getShellyFirmwareCampaignsInit(otaType));
  };

  const createCampaignHandler = () => {
    setShowCampaignForm(true);
    dispatch(setModifyCampaignDetails(null));
  };

  const rowExpansionTemplate = ({ campaignName }) => (
    <ShellyFirmwareCampaignDevices campaignName={campaignName} />
  );

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      otaType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      campaignName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      groupName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      targetVersion: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      scheduledDateTime: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      imageUrl: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      ritNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const scheduledAtTemplate = ({ scheduledDateTime }) =>
    scheduledDateTime ? (
      parseDateTime(scheduledDateTime)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const ritNumberuserCommentsTemplate = ({ ritNumber, userComments }) => (
    <>
      {ritNumber}
      {!!userComments && (
        <>
          {" "}
          <span>-</span>
          <br />
          {userComments}
        </>
      )}
    </>
  );

  const targetVersionTemplate = (rowData) => {
    if (rowData.strictUpdate === true) {
      return (
        <div>
          {rowData.targetVersion}
          <Tooltip target=".strict-update-info-icon" />
          <i
            className="pi pi-lock strict-update-info-icon"
            data-pr-tooltip="Strict Version is True"
            data-pr-position="right"
            style={{ marginLeft: "8px", fontSize: "0.8rem" }}
          />
        </div>
      );
    }
    return rowData.targetVersion;
  };

  const exportCampaignDetails = () =>
    shellyFirmwareCampaignDetailsTableRef.current.exportCSV();

  const actionsTemplate = (rowData) => {
    if (!rowData) {
      return null;
    }
    const isDisabled = rowData.status !== "Scheduled";
    return (
      <div className="shelly-campaign-details-action-icons">
        <Button
          icon={PrimeIcons.PENCIL}
          className="p-button-text"
          disabled={isDisabled}
          onClick={() => modifyCampaignHandler(rowData)}
        />
        <Button
          icon={PrimeIcons.TIMES_CIRCLE}
          className="p-button-text p-button-danger"
          disabled={isDisabled}
          onClick={(event) =>
            confirmPopup({
              target: event.currentTarget,
              icon: "pi pi-info-circle",
              message: "Do you want to cancel this schedule?",
              accept: () => cancelCampaignHandler(rowData),
            })
          }
        />
        <Button
          icon="pi pi-eye"
          className="p-button-text"
          onClick={() => setDialogData(rowData)}
        />
      </div>
    );
  };

  const cancelCampaignHandler = (rowData) => {
    const cancelCampaignName = {
      campaignName: rowData.campaignName,
    };
    dispatch(cancelShellyFirmwareCampaignInit(cancelCampaignName,rowData));
  };

  const modifyCampaignHandler = (rowData) => {
    const campaignDetails = {
      campaignName: rowData.campaignName,
      groupName: rowData.groupName,
      targetVersion: rowData.targetVersion,
      imageUrl: rowData.imageUrl,
      scheduledDateTime: new Date(rowData.scheduledDateTime),
      strictUpdate: rowData.strictUpdate,
      ritNumber: rowData.ritNumber,
      userComments: rowData.userComments,
      otaType: rowData.otaType,
    };

    dispatch(setModifyCampaignDetails(campaignDetails));
    setShowCampaignForm(true);
  };

  const onRowExpand = (event) => {
    let _expandedRows = {};
    _expandedRows[`${event.data.campaignName}`] = true;
    setExpandedRows(_expandedRows);
  }

  const onRowCollapse = (event) => {
    setExpandedRows(null);
  }

  return (
    <div>
      {showCampaignForm ? (
        <div className="shelly-firmware-campaign-container">
          <form onSubmit={handleSubmit}>
            <ConfirmPopup
              target={confirmPopupRef.current}
              visible={visible}
              onHide={() => setVisible(false)}
              message="Please make sure Change/RIT Number is approved before submitting"
              icon="pi pi-info-circle"
              accept={submitHandler}
            />
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 input-field">
                <label>OTA Type</label>
                <Dropdown
                  name="otaType"
                  className={errorClassName("otaType")}
                  value={values.otaType}
                  options={otaTypes}
                  onChange={handleChange}
                  disabled={!!campaignDetails}
                />
              </div>
            </div>
            {!!touched.otaType && errors.otaType && (
              <div className="p-error">{errors.otaType}</div>
            )}
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 input-field">
                <label>Campaign Name</label>
                <InputText
                  name="campaignName"
                  className={errorClassName("campaignName")}
                  value={values.campaignName}
                  onChange={handleChange}
                  disabled={!!campaignDetails}
                />
              </div>
            </div>
            {!!touched.campaignName && errors.campaignName && (
              <div className="p-error">{errors.campaignName}</div>
            )}
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 input-field">
                <label>Group</label>
                <Dropdown
                  name="groupName"
                  className={errorClassName("groupName")}
                  value={values.groupName}
                  options={shellyPlugGroups}
                  onChange={handleChange}
                />
              </div>
            </div>
            {!!touched.groupName && errors.groupName && (
              <div className="p-error">{errors.groupName}</div>
            )}
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 input-field">
                <label>Target Version</label>
                <Dropdown
                  name="targetVersion"
                  className={errorClassName("targetVersion")}
                  value={values.targetVersion}
                  options={shellyPlugFirmwareDetails.map(
                    (options) => options.versionNumber
                  )}
                  onChange={(e) => targetVersionHandler(e)}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3 input-field">
                <label>Image URL</label>
                <InputText
                  name="imageUrl"
                  className={errorClassName("imageUrl")}
                  value={values.imageUrl}
                  disabled
                />
              </div>
            </div>
            {!!touched.targetVersion && errors.targetVersion && (
              <div className="p-error">{errors.targetVersion}</div>
            )}
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 input-field">
                <label>Scheduled Datetime</label>
                <Calendar
                  name="scheduledDateTime"
                  className={errorClassName("scheduledDateTime")}
                  value={values.scheduledDateTime}
                  onChange={(e) => {
                    const date = e.value;
                    setFieldValue("scheduledDateTime", date);
                  }}
                  minDate={new Date()}
                  showIcon
                  showTime
                />
              </div>
            </div>
            {!!touched.scheduledDateTime && errors.scheduledDateTime && (
              <div className="p-error">{errors.scheduledDateTime}</div>
            )}
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 input-field">
                <label>Strict Version</label>
                <InputSwitch
                  name="strictUpdate"
                  checked={values.strictUpdate}
                  onChange={(e) => {
                    setFieldValue("strictUpdate", e.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 input-field">
                <label>Change/RIT Number</label>
                <InputText
                  name="ritNumber"
                  className={errorClassName("ritNumber")}
                  value={values.ritNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-3 input-field">
                <label>Comments</label>
                <InputText
                  name="userComments"
                  className={errorClassName("userComments")}
                  value={values.userComments}
                  onChange={handleChange}
                />
              </div>
              {!!touched.userComments && errors.userComments && (
                <small className="p-error">{errors.userComments}</small>
              )}
            </div>
            {!!touched.ritNumber && errors.ritNumber && (
              <div className="p-error">{errors.ritNumber}</div>
            )}
            <div className="row">
              <div className="col-12 col-lg-6 mb-3 input-field">
                <Button
                  label="Cancel"
                  className="p-button-danger"
                  onClick={() => cancelHandler()}
                />
                <Button
                  ref={confirmPopupRef}
                  label={
                    !!campaignDetails ? "Modify Campaign" : "Create Campaign"
                  }
                  type="submit"
                />
                <Button
                  label="Clear"
                  type="reset"
                  onClick={clearForm}
                  className="p-button-secondary"
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="shelly-firmware-campaign-details">
          <ShellyFirmwareCampaignEventLogs
            dialogData={dialogData}
            setDialogData={setDialogData}
          />
          <div className="shelly-firmware-campaign-details-content">
            <div className="shelly-firmware-campaign-details-action row">
              <div className="col-12 col-lg-6 input-field">
                <label htmlFor="otaType">OTA Type: </label>
                <MultiSelect
                  id="otaType"
                  value={selectedOtaTypes}
                  options={otaTypes} //from api state
                  onChange={(e) => setSelectedOtaTypes(e.value)}
                  style={{marginBottom:"16px"}}
                />
              </div>
              <div className="col-12 col-lg-6 create-campaign-button">
                <Button
                  label="Create Campaign"
                  onClick={createCampaignHandler}
                />
              </div>
              <div className="col-12" >
                <Button
                  label="Get Logs"
                  onClick={fetchShellyFirmwareCampaigns}
                  disabled={selectedOtaTypes.length === 0}
                />
                <Button
                  icon="pi pi-download"
                  className="p-button-text"
                  onClick={exportCampaignDetails}
                  style={{ float: "right" }}
                />
                <Button
                  icon="pi pi-refresh"
                  className="p-button-text"
                  onClick={fetchShellyFirmwareCampaigns}
                  style={{ float: "right" }}
                />
              </div>
            </div>
            <ConfirmPopup />
            <DataTable
              value={shellyFirmwareCampaigns}
              ref={shellyFirmwareCampaignDetailsTableRef}
              className="p-datatable-gridlines"
              filters={tableFilters}
              filterDisplay="menu"
              sortField="scheduledDateTime"
              sortOrder={-1}
              paginator
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
              currentPageReportTemplate="Items per page"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              emptyMessage="No Data found"
              expandedRows={expandedRows}
              // onRowToggle={(e) => setExpandedRows(e.data)}
              onRowExpand={onRowExpand}
              onRowCollapse={onRowCollapse}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="campaignName"
            >
              <Column
                header="Campaign Details"
                expander
                style={{ width: "7em" }}
              />
              <Column header="OTA Type" field="otaType" sortable filter />
              <Column
                header="Campaign Name"
                field="campaignName"
                sortable
                filter
              />
              <Column header="Group Name" field="groupName" sortable filter />
              <Column
                header="Target Version"
                field="targetVersion"
                body={targetVersionTemplate}
                filter
              />
              <Column header="Image URL" field="imageUrl" filter />
              <Column
                header="Scheduled At"
                field="scheduledDateTime"
                body={scheduledAtTemplate}
                sortable
                filter
              />
              <Column header="Status" field="status" filter />
              <Column
                header="RIT - Comments"
                field="ritNumber"
                body={ritNumberuserCommentsTemplate}
                filter
              />
              <Column header="Actions" body={actionsTemplate} />
            </DataTable>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShellyFirmwareCampaignDetails;
