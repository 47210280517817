import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import DeviceMangementList from './DeviceMangementList';
import DeviceMangementListItem from './DeviceMangementListItem';
import './DeviceManagement.scss';

function DeviceManagement() {
    return (
        <Switch>
            <Redirect exact from="/admin/device-management/devices" to="/admin/device-management/devices/list" />
            <Route exact path="/admin/device-management/devices/list" component={DeviceMangementList} />
            <Route exact path="/admin/device-management/devices/list/:deviceId" component={DeviceMangementListItem} />
        </Switch>
    );
}

export default DeviceManagement;