import axios from 'axios';
import { ApiPath } from '../../common/api-path/ApiPath';

export default class ShellyReplacementService {

    async getShellyReplacementExeUrl() {
        const response = await axios.get(`${ApiPath.baseUrl}/shelly-replacement/executable`, {timeout: 120000, timeoutErrorMessage: "request timeout"});
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getFaultyShellyDeviceDetail(storeCode, deviceTypeId, wan) {
        const requestConfig = {
            params: { storeCode, deviceTypeId, wan },
            timeout: 120000, timeoutErrorMessage: "request timeout"
        }
        const response = await axios.get(`${ApiPath.baseUrl}/shelly-replacement/shelly-device`, requestConfig);
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async postFaultyShellyDeviceDetail(deviceDetail) {
        const response = await axios.post(`${ApiPath.baseUrl}/shelly-replacement/staging`, deviceDetail, {timeout: 120000, timeoutErrorMessage: "request timeout"});
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getShellyDeviceHistory(division, startDate, endDate) {
        const requestConfig = {
            params: { division, startDate, endDate },
            timeout: 120000, timeoutErrorMessage: "request timeout",
        }
        const response = await axios.get(`${ApiPath.baseUrl}/shelly-replacement/history`, requestConfig);
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}