export const GET_SHELLY_FIRMWARE_GROUP_DETAILS = {
  INIT: "GET_SHELLY_FIRMWARE_GROUP_DETAILS_INIT",
  SUCCESS: "GET_SHELLY_FIRMWARE_GROUP_DETAILS_SUCCESS",
  ERROR: "GET_SHELLY_FIRMWARE_GROUP_DETAILS_ERROR",
};

export const POST_SHELLYFIRMWARE_GROUP = {
  INIT: "POST_SHELLYFIRMWARE_GROUP_INIT",
  SUCCESS: "POST_SHELLYFIRMWARE_GROUP_SUCCESS",
  ERROR: "POST_SHELLYFIRMWARE_GROUP_ERROR",
};

export const CANCEL_SHELLY_FIRMWARE_GROUP = {
  INIT: "CANCEL_SHELLY_FIRMWARE_GROUP_INIT",
  SUCCESS: "CANCEL_SHELLY_FIRMWARE_GROUP_SUCCESS",
  ERROR: "CANCEL_SHELLY_FIRMWARE_GROUP_ERROR",
};

export const POST_MODIFY_SHELLYFIRMWARE_GROUP = {
  INIT: "POST_MODIFY_SHELLYFIRMWARE_GROUP_INIT",
  SUCCESS: "POST_MODIFY_SHELLYFIRMWARE_GROUP_SUCCESS",
  ERROR: "POST_MODIFY_SHELLYFIRMWARE_GROUP_ERROR",
};

export const GET_OTA_TYPES = {
  INIT: "GET_OTA_TYPES_INIT",
  SUCCESS: "GET_OTA_TYPES_SUCCCESS",
  ERROR: "GET_OTA_TYPES_ERROR",
};

export const GET_SHELLY_FIRMWARE_CAMPAIGNS = {
  INIT: "GET_SHELLY_FIRMWARE_CAMPAIGNS_INIT",
  SUCCESS: "GET_SHELLY_FIRMWARE_CAMPAIGNS_SUCCESS",
  ERROR: "GET_SHELLY_FIRMWARE_CAMPAIGNS_ERROR",
};

export const GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME = {
  INIT: "GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME_INIT",
  SUCCESS: "GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME_SUCCESS",
  ERROR: "GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME_ERROR",
};

export const GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS = {
  INIT: "GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS_INIT",
  SUCCESS: "GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS_SUCCESS",
  ERROR: "GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS_ERROR",
};

export const GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID = {
  INIT: "GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID_INIT",
  SUCCESS: "GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID_SUCCESS",
  ERROR: "GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID_ERROR",
};

export const GET_SHELLY_PLUG_GROUPS = {
  INIT: "GET_SHELLY_PLUG_GROUPS_INIT",
  SUCCESS: "GET_SHELLY_PLUG_GROUPS_SUCCESS",
  ERROR: "GET_SHELLY_PLUG_GROUPS_ERROR",
};

export const GET_SHELLY_PLUG_FIRMWARE_DETAILS = {
  INIT: "GET_SHELLY_PLUG_FIRMWARE_DETAILS_INIT",
  SUCCESS: "GET_SHELLY_PLUG_FIRMWARE_DETAILS_SUCCESS",
  ERROR: "GET_SHELLY_PLUG_FIRMWARE_DETAILS_ERROR",
};

export const POST_SHELLYFIRMWARE_CAMPAIGN = {
  INIT: "POST_SHELLYFIRMWARE_CAMPAIGN_INIT",
  SUCCESS: "POST_SHELLYFIRMWARE_CAMPAIGN_SUCCESS",
  ERROR: "POST_SHELLYFIRMWARE_CAMPAIGN_ERROR",
};

export const CANCEL_SHELLY_FIRMWARE_CAMPAIGN = {
  INIT: "CANCEL_SHELLY_FIRMWARE_CAMPAIGN_INIT",
  SUCCESS: "CANCEL_SHELLY_FIRMWARE_CAMPAIGN_SUCCESS",
  ERROR: "CANCEL_SHELLY_FIRMWARE_CAMPAIGN_ERROR",
};

export const MODIFY_SHELLY_FIRMWARE_CAMPAIGN = {
  INIT: "MODIFY_SHELLY_FIRMWARE_CAMPAIGN_INIT",
  SUCCESS: "MODIFY_SHELLY_FIRMWARE_CAMPAIGN_SUCCESS",
  ERROR: "MODIFY_SHELLY_FIRMWARE_CAMPAIGN_ERROR",
};

export const SET_MODIFY_CAMPAIGN_DETAILS = "SET_MODIFY_CAMPAIGN_DETAILS";
