import {
    GET_DISTINCT_PINGPROBE_ASSET_TYPE,
    GET_PINGPROBE_INVENTORY,
    GET_PINGPROBE_INCIDENTS_BY_ASSET,
    GET_PINGPROBE_ASSET_LAST_PINGTIME,
    POST_PINGPROBE_SUPPRESSION_RULE,
    GET_ALL_PINGPROBE_SUPPRESSION_RULES,
    GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE,
    DELETE_PINGPROBE_SUPPRESSION_RULE,
    SET_PINGPROBE_SUPPRESSION_RULE,
    POST_PINGPROBE_LIVE_PING,
    GET_PING_DETAILS,
} from "./actionTypes";

export const getDistinctPingprobeAssetTypeInit = () => ({
    type: GET_DISTINCT_PINGPROBE_ASSET_TYPE.INIT,
});

export const getDistinctPingprobeAssetTypeSuccess = (assetTypes) => ({
    type: GET_DISTINCT_PINGPROBE_ASSET_TYPE.SUCCESS,
    payload: assetTypes,
});

export const getDistinctPingprobeAssetTypeFailure = (errorMessage) => ({
    type: GET_DISTINCT_PINGPROBE_ASSET_TYPE.ERROR,
    payload: errorMessage,
});

export const getPingprobeInventoryInit = (assetTypes) => ({
    type: GET_PINGPROBE_INVENTORY.INIT,
    assetTypes,
});

export const getPingprobeInventorySuccess = (assetList) => ({
    type: GET_PINGPROBE_INVENTORY.SUCCESS,
    payload: assetList,
});

export const getPingprobeInventoryFailure = (errorMessage) => ({
    type: GET_PINGPROBE_INVENTORY.ERROR,
    payload: errorMessage,
});

export const getPingprobeIncidentByAssetInit = (ipAddress) => ({
    type: GET_PINGPROBE_INCIDENTS_BY_ASSET.INIT,
    ipAddress,
});

export const getPingprobeIncidentByAssetSuccess = (incidentList) => ({
    type: GET_PINGPROBE_INCIDENTS_BY_ASSET.SUCCESS,
    payload: incidentList,
});

export const getPingprobeIncidentByAssetFailure = (errorMessage) => ({
    type: GET_PINGPROBE_INCIDENTS_BY_ASSET.ERROR,
    payload: errorMessage,
});

export const getPingprobeAssetLastPingTimeInit = () => ({
    type: GET_PINGPROBE_ASSET_LAST_PINGTIME.INIT,
});

export const getPingprobeAssetLastPingTimeSuccess = (pingTime) => ({
    type: GET_PINGPROBE_ASSET_LAST_PINGTIME.SUCCESS,
    payload: pingTime,
});

export const getPingprobeAssetLastPingTimeFailure = (errorMessage) => ({
    type: GET_PINGPROBE_ASSET_LAST_PINGTIME.ERROR,
    payload: errorMessage,
});

export const postPingprobeSuppressionRuleInit = (suppressionRule) => ({
    type: POST_PINGPROBE_SUPPRESSION_RULE.INIT,
    suppressionRule,
});

export const postPingprobeSuppressionRuleSuccess = () => ({
    type: POST_PINGPROBE_SUPPRESSION_RULE.SUCCESS,
});

export const postPingprobeSuppressionRuleFailure = (errorMessage) => ({
    type: POST_PINGPROBE_SUPPRESSION_RULE.ERROR,
    payload: errorMessage,
});

export const getAllPingprobeSuppressionRulesInit = () => ({
    type: GET_ALL_PINGPROBE_SUPPRESSION_RULES.INIT,
});

export const getAllPingprobeSuppressionRulesSuccess = (suppressionRules) => ({
    type: GET_ALL_PINGPROBE_SUPPRESSION_RULES.SUCCESS,
    payload: suppressionRules,
});

export const getAllPingprobeSuppressionRulesFailure = (errorMessage) => ({
    type: GET_ALL_PINGPROBE_SUPPRESSION_RULES.ERROR,
    payload: errorMessage,
});

export const getPingprobeAssetsBySuppressionRuleInit = (suppressionRule) => ({
    type: GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE.INIT,
    suppressionRule,
});

export const getPingprobeAssetsBySuppressionRuleSuccess = (assetList) => ({
    type: GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE.SUCCESS,
    payload: assetList,
});

export const getPingprobeAssetsBySuppressionRuleFailure = (errorMessage) => ({
    type: GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE.ERROR,
    payload: errorMessage,
});

export const deletePingprobeSuppressionRuleInit = (suppressionId) => ({
    type: DELETE_PINGPROBE_SUPPRESSION_RULE.INIT,
    suppressionId,
});

export const deletePingprobeSuppressionRuleSuccess = () => ({
    type: DELETE_PINGPROBE_SUPPRESSION_RULE.SUCCESS,
});

export const deletePingprobeSuppressionRuleFailure = (errorMessage) => ({
    type: DELETE_PINGPROBE_SUPPRESSION_RULE.ERROR,
    payload: errorMessage,
});

export const setPingprobeSuppressionRule = (suppressionRule) => ({
    type: SET_PINGPROBE_SUPPRESSION_RULE,
    payload: suppressionRule,
});

export const postPingprobeLivePingInit = (ipAddress) => ({
    type: POST_PINGPROBE_LIVE_PING.INIT,
    ipAddress,
});

export const postPingprobeLivePingSuccess = () => ({
    type: POST_PINGPROBE_LIVE_PING.SUCCESS,
});

export const postPingprobeLivePingFailure = (errorMessage) => ({
    type: POST_PINGPROBE_LIVE_PING.ERROR,
    payload: errorMessage,
});

export const getLivePingDetailsInit = ( startDate, endDate, offset) => ({
    type: GET_PING_DETAILS.INIT,
    startDate,
    endDate,
    offset,
  });
  
  export const getLivePingDetailsSuccess = (pingDetails) => ({
    type: GET_PING_DETAILS.SUCCESS,
    payload: pingDetails,
  });
  
  export const getLivePingDetailsFailure = (errorMessage) => ({
    type: GET_PING_DETAILS.ERROR,
    payload: errorMessage,
  });
