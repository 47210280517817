import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';

import { getAllStoreListInit, setSearchedStoreList } from './redux/actions'; 


const SearchStoreInput = ({handleClick}) => {

    const [filteredStores, setFilteredStores] = useState(null);

    const storeList = useSelector(state => state.searchStore.storeList);
    const selectedStoreList = useSelector(state => state.searchStore.selectedStoreList);
    const dispatch = useDispatch();

    useEffect(() => {
        storeList.length===0 && dispatch(getAllStoreListInit());
    }, [storeList, dispatch])

    /**
	 * As User types in Input search will filter the stores
	 */
    const searchStore = event => {
        setTimeout(() => {
            let _filteredStores = [];
            if(!event.query.trim().length) {
                _filteredStores = [...storeList];
            } else {
                _filteredStores = storeList.filter(store => 
                    store.name.toLowerCase().startsWith(event.query.toLowerCase())
                )
            }
            setFilteredStores([..._filteredStores]);
        }, 250)
    }

    return (
        <div className="search-store-input-container">
            <div className="search">
                <AutoComplete value={selectedStoreList} onChange={e => dispatch(setSearchedStoreList(e.value))}
                    suggestions={filteredStores} completeMethod={searchStore} field="name" multiple />
                <Button label="Search" icon="pi pi-search" onClick={handleClick} className="filter-button p-button-outlined p-button-sm" />
            </div>
        </div>
    )
}

export default SearchStoreInput;