import { call, put } from 'redux-saga/effects';

import {
    getShellyReplacementExeUrlSuccess, getShellyReplacementExeUrlFailure,
    getFaultyShellyDeviceDetailSuccess, getFaultyShellyDeviceDetailFailure,
    postFaultyShellyDeviceDetailSuccess, postFaultyShellyDeviceDetailFailure,
    getShellyDeviceHistorySuccess, getShellyDeviceHistoryFailure
} from '../components/shelly-replacement/redux/actions';

import ShellyReplacementService from '../components/shelly-replacement/ShellyReplacementService';


const shellyReplacementService = new ShellyReplacementService();

/**
 * Worker saga for loading Shelly Replacement Executable URL
 */
function* loadShellyReplacementExeUrl() {
    try {
        const exeUrl = yield call(shellyReplacementService.getShellyReplacementExeUrl);
        yield put(getShellyReplacementExeUrlSuccess(exeUrl));
    } catch(error) {
        yield put(getShellyReplacementExeUrlFailure(error.toString()));
    }
}

/**
 * Worker saga for loading faulty Shelly Device Detail
 * @param {Object} action - Redux action
 */
function* loadFaultyShellyDeviceDetail({storeCode, deviceTypeId, wan}) {
    try {
        const deviceDetail = yield call(shellyReplacementService.getFaultyShellyDeviceDetail, storeCode, deviceTypeId, wan);
        const updatedDeviceDetail = {
            ...deviceDetail,
            faultyShellyId: deviceDetail.shellyId
        }
        yield put(getFaultyShellyDeviceDetailSuccess(updatedDeviceDetail));
    } catch(error) {
        yield put(getFaultyShellyDeviceDetailFailure(error.toString()));
    }
}

/**
 * Worker saga for saving faulty Shelly Device Detail
 * @param {Object} action - Redux action
 */
function* postFaultyShellyDeviceDetail({deviceDetail}) {
    try {
        const otp = yield call(shellyReplacementService.postFaultyShellyDeviceDetail, deviceDetail);
        yield put(postFaultyShellyDeviceDetailSuccess(otp));
    } catch(error) {
        yield put(postFaultyShellyDeviceDetailFailure(error.toString()));
    }
}

/**
 * Worker saga for loading Shelly Device History
 * @param {Object} action - Redux action
 */
function* loadShellyDeviceHistory({division, startDate, endDate}) {
    try {
        const shellyDeviceHistory = yield call(shellyReplacementService.getShellyDeviceHistory, division, startDate, endDate);
        const parsedShellyDeviceHistory = shellyDeviceHistory.map(device => ({
            ...device,
            isDeleted: device.isDeleted===false? "Active": "Inactive",
            isReplaced: device.isReplaced===true? "Replacement": "Onboarding"
        }))
        yield put(getShellyDeviceHistorySuccess(parsedShellyDeviceHistory));
    } catch(error) {
        yield put(getShellyDeviceHistoryFailure(error.toString()));
    }
}

export {
    loadShellyReplacementExeUrl,
    loadFaultyShellyDeviceDetail,
    postFaultyShellyDeviceDetail,
    loadShellyDeviceHistory
};