import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import Moment from "moment";

import { parseDateTime, addDateTime } from "../../../common/date-time";
import { getLivePingDetailsInit } from "../redux/actions";

import SubRequestDetails from "./SubRequestDetails";
import "./PingProbePingDetails.scss";

const PingProbePingDetails = () => {
  const pingDetails = useSelector((state) => state.pingprobe.pingDetails);

  const history = useHistory();
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState([
    addDateTime("day", -14),
    addDateTime("day", 0),
  ]);

  const [expandedRows, setExpandedRows] = useState(null);
  const [tableFilters, setTableFilters] = useState(null);
  const pingDetailsTableRef = useRef(null);

  useEffect(() => {
    initializeTableFilter();
    fetchPingDetails();
  }, []);

  const fetchPingDetails = () => {
    const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
    const endDate = !!dateRange[1]
      ? Moment(dateRange[1]).format("yyyy-MM-DD")
      : Moment(dateRange[0]).format("yyyy-MM-DD");
    const offset = Moment(new Date()).utcOffset();
    dispatch(getLivePingDetailsInit(startDate, endDate, offset));
  };

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      requestId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      ipAddress: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  /**
   * Function to export table data
   */
  const exportPingDetails = () => pingDetailsTableRef.current.exportCSV();

  const rowExpansionTemplate = ({ pingProbeRequestDetails }) => (
    <SubRequestDetails subRequestDetails={pingProbeRequestDetails} />
  );

  const redirectToLivePing = () => {
    history.push("/admin/ping-probe/live-ping/create-live-ping");
  };

  const requestTimeTemplate = ({ createdDateTime }) =>
    createdDateTime ? (
      parseDateTime(createdDateTime)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );
  return (
    <div className="pingprobe-ping-details-container container-fluid">
      <div className="row summary-action">
        <div className="col-12 col-lg-6 input-field">
          <label htmlFor="dateRange">Events Date</label>
          <Calendar
            id="dateRange"
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            minDate={addDateTime("month", -3)}
            maxDate={addDateTime("month", 0)}
            showIcon
          />
        </div>
        <div className="col-12 col-lg-6">
          <Button
            label="Create Ping Test"
            onClick={redirectToLivePing}
            style={{ float: "right" }}
          />
        </div>
      </div>
      <div className="row summary-action">
        <div className="col-12 ">
          <Button label="Get Details" onClick={fetchPingDetails} />
          <Button
            icon="pi pi-download"
            className="p-button-text"
            onClick={exportPingDetails}
            style={{ float: "right" }}
          />
          <Button
            icon="pi pi-refresh"
            className="p-button-text"
            onClick={fetchPingDetails}
            style={{ float: "right" }}
          />
        </div>
      </div>
      <div className="ping-probe-details-table">
        <DataTable
          value={pingDetails}
          ref={pingDetailsTableRef}
          className="p-datatable-gridlines"
          sortField="createdDateTime"
          sortOrder={-1}
          filters={tableFilters}
          filterDisplay="menu"
          paginator
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Items per page"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          emptyMessage="No logs found"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "5em" }} />
          <Column field="requestId" header="Request ID" sortable filter />
          <Column field="ipAddress" header="IP Address(s)" sortable filter />
          <Column field="status" header="Request Status" sortable filter />
          <Column
            field="createdDateTime"
            header="Request Time"
            body={requestTimeTemplate}
            sortable
          />
          <Column field="createdByUser" header="Created By User" sortable />
        </DataTable>
      </div>
    </div>
  );
};

export default PingProbePingDetails;
