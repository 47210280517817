import { call, put } from 'redux-saga/effects';

import { 
    getStoreCompleteDetailFailure, 
    getStoreCompleteDetailSuccess 
} from '../components/asset/redux/actions';

import AssetService from '../components/asset/service/AssetService';


const assetService = new AssetService();

/**
 * Worker saga for loading store details by store id
 * @param {Object} action - Redux action
 */
function* loadStoresDetailById(action) {
    try {
        const storeResponse = yield call(assetService.getAssetById, action.assetIds);
        const storesDetail = storeResponse.map(store => ({
            storeCode: store.storeCode,
            storeName: store.storeName,
            storeAddress: store.address,
            printers: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'Printer'.toLowerCase())?.deviceDetailsList,
            rxPrinters: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'RxPrinter'.toLowerCase())?.deviceDetailsList,
            modems: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'IP Router'.toLowerCase())?.deviceDetailsList,
            posPrinters: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'POS Printer'.toLowerCase())?.deviceDetailsList,
            posControllers: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'POS Controller'.toLowerCase())?.deviceDetailsList,
            scales: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'Scale'.toLowerCase())?.deviceDetailsList,
            isps: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'ISP'.toLowerCase())?.deviceDetailsList,
            cradlepoint: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'CradlePoint'.toLowerCase())?.deviceDetailsList,
            wireless: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'wireless'.toLowerCase())?.deviceDetailsList,
            switches: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'switch'.toLowerCase())?.deviceDetailsList,
            arubaController: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'aruba controller'.toLowerCase())?.deviceDetailsList,
            stingray: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'stingray music system'.toLowerCase())?.deviceDetailsList,
            breakroomTv: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'Breakroom-TV'.toLowerCase())?.deviceDetailsList,
            grafanaAgent: store.assetDeviceDetailList.find(device => device.type.toLowerCase() === 'GrafanaAgentCtrl'.toLowerCase())?.deviceDetailsList,
            rxPeripheralDevices: store.assetDeviceDetailList.find(device => device.type.toLowerCase()=== 'RxPeripheral'.toLowerCase())?.deviceDetailsList,
           
        }));
        yield put(getStoreCompleteDetailSuccess(storesDetail));
    } catch (error) {
        yield put(getStoreCompleteDetailFailure(error.toString()))
    }
}

export {
    loadStoresDetailById
};