import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator, PrimeIcons } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Moment from "moment";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

import CopyButton from "../../common/components/copy-button/CopyButton";
import { parseDateTime, addDateTime } from "../../common/date-time";
import {
  getMerakiFirewallAutomationLogsInit,
  cancelMerakiFirewallScheduleInit,
} from "./redux/actions";
import MerakiFirewallRITChildDetails from "./MerakiFirewallRITChildDetails";
import "./MerakiFirewallAutomation.scss";

const MerakiFirewallAutomation = () => {
  const dispatch = useDispatch();
  const merakiFirewallAutomationLogs = useSelector(
    (state) => state.merakiFirewall.merakiFirewallAutomationLogs
  );

  const [tableFilters, setTableFilters] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [dateRange, setDateRange] = useState([
    addDateTime("day", -14),
    addDateTime("day", 0),
  ]);

  const logsTableRef = useRef(null);

  useEffect(() => {
    initializeTableFilter();
    fetchMerakiFirewallAutomationLogs();
  }, []);

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const fetchMerakiFirewallAutomationLogs = () => {
    const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
    const endDate = !!dateRange[1]
      ? Moment(dateRange[1]).format("yyyy-MM-DD")
      : Moment(dateRange[0]).format("yyyy-MM-DD");
    const offset = Moment(new Date()).utcOffset();
    dispatch(getMerakiFirewallAutomationLogsInit(startDate, endDate, offset));
  };

  const actionTemplate = (rowData) => {
    if (!rowData) {
      return null;
    }

    const isDisabled = rowData.status !== "Received";
    return (
      <Button
        icon={PrimeIcons.TIMES_CIRCLE}
        className=" p-button-text p-button-danger"
        disabled={isDisabled}
        onClick={(event) =>
          confirmPopup({
            target: event.currentTarget,
            icon: "pi pi-info-circle",
            message: "Do you want to cancel this schedule  ?",
            accept: () => handleRitCancelClick(rowData),
          })
        }
      />
    );
  };

  const handleRitCancelClick = (rowData) => {
    const cancelSchedule = {
      task: rowData.id,
      level: rowData.type,
      requester: rowData.requester,
    };

    dispatch(cancelMerakiFirewallScheduleInit(cancelSchedule));
  };

  const rowExpansionTemplate = ({ children }) => (
    <MerakiFirewallRITChildDetails subTaskDetails={children} />
  );

  const receivedAtTemplate = ({ received_date }) =>
    !!received_date ? (
      parseDateTime(received_date)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const modifiedAtTemplate = ({ modified_date }) =>
    !!modified_date ? (
      parseDateTime(modified_date)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const inputPayloadTemplate = ({ input_payload }) => (
    <div className="payload-template">
      <CopyButton className="copy-icon" textToCopy={input_payload} />
      <br />
      {input_payload}
    </div>
  );

  /**
   * Function to export table data
   */
  const exportMerakiFirewallLogs = () => logsTableRef.current.exportCSV();

  return (
    <div className="meraki-firewall-automation-container">
      <div className="meraki-firewall-automation-action row">
        <div className="col-12 col-lg-6 input-field">
          <label htmlFor="dateRange">Events Date</label>
          <Calendar
            id="dateRange"
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            minDate={addDateTime("month", -3)}
            maxDate={addDateTime("month", 0)}
            showIcon
          />
        </div>
        <div className="col-12">
          <Button
            label="Get Logs"
            onClick={fetchMerakiFirewallAutomationLogs}
          />
          <Button
            icon="pi pi-download"
            className="p-button-text"
            onClick={exportMerakiFirewallLogs}
            style={{ float: "right" }}
          />
          <Button
            id="refreshBtn"
            icon="pi pi-refresh"
            className="p-button-text"
            onClick={fetchMerakiFirewallAutomationLogs}
            style={{ float: "right" }}
          />
        </div>
      </div>
      <ConfirmPopup />
      <div className="meraki-firewall-automation-logs-table">
        <DataTable
          value={merakiFirewallAutomationLogs}
          ref={logsTableRef}
          className="p-datatable-gridlines"
          filters={tableFilters}
          filterDisplay="menu"
          sortField="received_date"
          sortOrder={-1}
          paginator
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Items per page"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          emptyMessage="No logs found"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "5em" }} />
          <Column header="Request" field="id" sortable filter />
          <Column
            header="Received At"
            field="received_date"
            body={receivedAtTemplate}
            sortable
            sortOrder={-1}
          />
          <Column header="Status" field="status" sortable filter />
          <Column header="Requester" field="requester" sortable />
          <Column
            header="Modified At"
            field="modified_date"
            body={modifiedAtTemplate}
            sortable
          />
          <Column
            header="Input Payload"
            field="input_payload"
            body={inputPayloadTemplate}
          />
          <Column header="Action" body={(rowData) => actionTemplate(rowData)} />
        </DataTable>
      </div>
    </div>
  );
};

export default MerakiFirewallAutomation;
