import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useHistory } from "react-router-dom";
import Moment from 'moment';

import { parseDateTime } from '../../common/date-time';
import { getAllDeviceInit } from './redux/actions';
import { useSelector, useDispatch } from 'react-redux'
import SearchStoreInput from '../search-store/SearchStoreInput';
import modem from '../../images/Modem.svg'
import printer from '../../images/Printer.svg'
import isp from '../../images/server.svg';
import rems from '../../images/rems.svg';
import scale from '../../images/scale.svg';
import cp from '../../images/cp.svg';
import AccessPoint from '../../images/AccessPoint.svg';
import NetworkSwitch from '../../images/NetworkSwitch.svg';
import MusicIcon from '../../images/music.svg';


function DeviceMangementList() {

    const deviceData = useSelector(state => state.deviceManagement.deviceList);
    const selectedStores = useSelector(state => state.searchStore.selectedStoreList);
    const dispatch = useDispatch();
    const logsTableRef = useRef(null);

    const [deviceSelection, setDeviceSelection] = useState(null);
    const [tableFilters, setTableFilters] = useState(null);
    const history = useHistory();
    const powerBiUrl = process.env.REACT_APP_POWER_BI_URL;


    /*
    *  To display devices from selected store
    */
    useEffect(() => {
        fetchDeviceDetail();
        initializeTableFilter();
    },[]);

    const fetchDeviceDetail = () => {
        if(selectedStores && selectedStores.length>0) {
            const storeCodes = []
            selectedStores.forEach(selectedStore => {
                storeCodes.push(selectedStore.name);
            });
            dispatch(getAllDeviceInit(storeCodes));
        }
    }

    /**
    * To display the respective asset icon with row data
    * @param {Array} rowData - List of Device
    */
    const deviceNameTemplate = rowData => {
        let image;
        if(!rowData.deviceType) {
            image = '';
        } else if (rowData.deviceType.includes('Modem')) {
            image = modem;
        } else if (rowData.deviceType.includes('Printer')) {
            image = printer;
        } else if (rowData.deviceType.includes('ISP')) {
            image = isp;
        } else if (rowData.deviceType.includes('Rems')) {
            image = rems;
        } else if (rowData.deviceType.includes('Scale')) {
            image = scale;
        } else if (rowData.deviceType.toLowerCase().includes('cradlepoint')) {
            image = cp;
        } else if (["Aruba Controller", "wireless"].includes(rowData.deviceType)) {
            image = AccessPoint;
        } else if (rowData.deviceType.toLowerCase().includes('switch')) {
            image = NetworkSwitch;
        } else if (rowData.deviceType.toLowerCase().includes('music')) {
            image = MusicIcon;
        } else {
            image = ''
        }
        
        return (
            <React.Fragment>
                <img src={image} width="15" style={{ verticalAlign: 'middle' }} alt=''/>
                <span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{rowData.deviceCode}</span>
            </React.Fragment>
        );
    }

    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			deviceCode: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			deviceName: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			}
		})
	}

    /**x
    * To navigate Device Management Details page
    * @param {Array} e - Selected Device Id
    */
    const deviceSelect = (e) => {
        if(e.value.deviceName.includes("BREAKROOM-TV")){
            window.open(powerBiUrl, '_blank')
        }else{
        setDeviceSelection(e.value);
        history.push("/admin/device-management/devices/list/" + e.value.deviceId);
        }
    }

    /**
    * Parse the Date format
    * @param {Array} rowData - List of Device
    */
    const parseLastReportedDate = (rowdata) => {
        const hours = Moment().diff(Moment(rowdata.lastReportedDate), 'hours');
        let color = '';

        if (hours >= 24) {
            color = 'darkgrey';
        }
        else if (hours >= 8) {
            color = 'orange';
        } else {
            color = 'green';
        }

        const formatDate = rowdata.lastReportedDate ? parseDateTime(rowdata.lastReportedDate) : '-'
        return (<div style={{ color: color }}>{formatDate}</div>)
    }

    /**
    * Parse the Date Time format
    * @param {Array} rowData - List of Device
    */
    const parseLastUpdateTime = (rowdata) => {
        return rowdata.lastUpdateTime ? parseDateTime(rowdata.lastUpdateTime) : '-';
    }


    const parseVersion = (rowdata ) =>{ 
    return rowdata.version ? rowdata.version : "N.A";
    }
        /**
   * Function to export table data
   */
    const exportDeviceDetails = () => logsTableRef.current.exportCSV();


    /**
    * IOT Device List view
    */
    return (          
        <div className="device-list">
            <p className="page-label">
				Device Management
				<i className="pi pi-angle-right" />
				Devices
			</p>
            <SearchStoreInput handleClick={fetchDeviceDetail} />
            <div className="device-list-title">IoT Devices List</div>
            <Button icon="pi pi-download"className="p-button-text"onClick={exportDeviceDetails}style={{float: "right"}} />
            <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={fetchDeviceDetail} style={{ float: 'right' }} />
            <div className="legend-div">
                <div className='green-legend-div'>
                <span className="green-legend"></span><span>Device Reported in Last 8 Hours </span>
                </div>
                <div className='orange-legend-div'>
                <span className="orange-legend"></span><span>Device Not Reported in Last 8 Hours</span>
                </div>
                <div className='grey-legend-div'>
                <span className="grey-legend"></span><span>Device Not Reported for More Than 24 Hours</span>
                </div>
            </div>
            <div className="device-table">
                <DataTable value={deviceData} ref={logsTableRef} selection={deviceSelection} onSelectionChange={deviceSelect} selectionMode="single"
                    dataKey="id" className="p-datatable-gridlines" filters={tableFilters} filterDisplay="menu" paginator
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    currentPageReportTemplate="Items per page" rows={10} rowsPerPageOptions={[10, 20, 50]} emptyMessage="No devices found.">
                    <Column field="deviceCode" header="IoT Device Id" className="device-name-column" body={deviceNameTemplate} sortable filter />
                    <Column field="deviceName" header="IoT Device Name" className="device-name-column" sortable filter />
                    <Column field="version" header="Version" body={parseVersion} sortable filter />
                    <Column field="lastReportedDate" header="Last Reported Date" body={parseLastReportedDate} sortable />
                    <Column field="lastUpdateTime" header="Last Modified Date" body={parseLastUpdateTime} sortable />
                </DataTable>
            </div>    
        </div>
    );
}

export default DeviceMangementList;