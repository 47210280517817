import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from "primereact/dialog";

import { parseDateTime } from '../../common/date-time';
import { getCommandHistoryListInit } from './redux/actions';
import './CommandAndControl.scss';


const CommandHistoryDialog = ({showCommandHistory, setShowCommandHistory}) => {

    const historyCommandList = useSelector(state => state.commandAndControl.commandHistoryList);
    const shellyDeviceList = useSelector(state => state.commandAndControl.shellyDeviceList);
    const [deviceName, setDeviceName] = useState("");
    const [tableFilters, setTableFilters] = useState(null);
    const dispatch = useDispatch();
    const logsTableRef = useRef(null);


    useEffect(() => {
        initializeTableFilter();
    }, []);

    useEffect(() => {
        fetchCommandHistory();
    }, [showCommandHistory]);

    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			command: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
			},
			commandStatus: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
			},
			user: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
			}
		})
	}

    const fetchCommandHistory = () => {
        if(!!showCommandHistory) {
            dispatch(getCommandHistoryListInit(showCommandHistory));
            setDeviceName(shellyDeviceList.filter(device => device.deviceCode===showCommandHistory)[0]?.deviceName);
        }
    }

    const parseTimestamp = ({timestamp}) => {
        return timestamp ? parseDateTime(timestamp) : '-';
    }
        /**
   * Function to export table data
   */
        const exportCommandHistory = () => logsTableRef.current.exportCSV();


    return (
        <Dialog visible={!!showCommandHistory} onHide={() => setShowCommandHistory(false)}
            header={`${deviceName} (${showCommandHistory}) - Command History`} className="command-history-dialog-container">
            <Button icon="pi pi-download"className="p-button-text" style={{float:'right', zIndex:10}} onClick={exportCommandHistory} />
            <Button type="button" icon="pi pi-refresh" className="p-button-text" style={{float:'right', zIndex:10}} onClick={fetchCommandHistory} />
            <DataTable value={historyCommandList} ref={logsTableRef} sortField="timestamp" sortOrder="-1" filters={tableFilters} filterDisplay="menu" paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="Items per page" rows={5} rowsPerPageOptions={[5, 10, 20]}>
                <Column header="Command" field="command" sortable filter />
                <Column header="Timestamp" field="timestamp" body={parseTimestamp} sortable />
                <Column header="Command Status" field="commandStatus" sortable filter />
                <Column header="User" field="user" sortable filter />
            </DataTable>
        </Dialog>
    )
}

export default CommandHistoryDialog;