import {
  GET_GA_REMOTE_COMMAND_DIVISION_DETAILS,
  POST_CREATE_JOB,
  GET_JOB_DETAILS,
  RESET_CREATE_JOB_SUCCESS_MESSAGE,
  RESET_MODIFY_JOB_DETAILS,
  GET_SERVER_DETAILS_BY_JOB_ID,
  CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE,
  SET_MODIFY_JOB_DETAILS,
  SET_CLONE_JOB_DETAILS,
  RESET_CLONE_JOB_DETAILS,
} from "./actionTypes";

const initialState = {
  divisionDetails: { serverTypes: null, divisionStores: null },
  divisionDetailsErrorMessage: null,
  createJobSuccessMessage: null,
  createJobErrorMessage: null,
  jobDetails: [],
  jobDetailsErrorMessage: null,
  serverDetailByJobId: [],
  serverDetailByJobIdErrorMessage: null,
  cancelGrafanaAgentRemoteCommandScheduleSuccessMessage: null,
  cancelGrafanaAgentRemoteCommandScheduleErrorMessage: null,
  setModifyJobDetails: null,
  setCloneJobDetails: null,
};

const gaRemoteCommandReducer = (
  state = initialState,
  action = { type: "", payload: null }
) => {
  switch (action.type) {
    case GET_GA_REMOTE_COMMAND_DIVISION_DETAILS.SUCCESS:
      return {
        ...state,
        divisionDetails: action.payload,
        divisionDetailsErrorMessage: null,
      };
    case GET_GA_REMOTE_COMMAND_DIVISION_DETAILS.ERROR:
      return {
        ...state,
        divisionDetails: { serverTypes: null, divisionStores: null },
        divisionDetailsErrorMessage: action.payload,
      };

    case POST_CREATE_JOB.SUCCESS:
      return {
        ...state,
        createJobSuccessMessage: action.payload,
        createJobErrorMessage: null,
      };
    case POST_CREATE_JOB.ERROR:
      return {
        ...state,
        createJobSuccessMessage: null,
        createJobErrorMessage: action.payload,
      };
    case RESET_CREATE_JOB_SUCCESS_MESSAGE:
      return {
        ...state,
        createJobSuccessMessage: null,
      };
 
    case RESET_MODIFY_JOB_DETAILS:
      return {
        ...state,
        setModifyJobDetails: null,
      };
  
    case GET_JOB_DETAILS.SUCCESS:
      return {
        ...state,
        jobDetails: action.payload,
        jobDetailsErrorMessage: null,
      };
    case GET_JOB_DETAILS.ERROR:
      return {
        ...state,
        jobDetails: [],
        jobDetailsErrorMessage: action.payload,
      };
    case GET_SERVER_DETAILS_BY_JOB_ID.SUCCESS:
      return {
        ...state,
        serverDetailByJobId: action.payload,
        serverDetailByJobIdErrorMessage: null,
      };
    case GET_SERVER_DETAILS_BY_JOB_ID.ERROR:
      return {
        ...state,
        serverDetailByJobId: [],
        serverDetailByJobIdErrorMessage: action.payload,
      };
    case CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE.SUCCESS:
      return {
        ...state,
        cancelGrafanaAgentRemoteCommandScheduleSuccessMessage: action.payload,
        cancelGrafanaAgentRemoteCommandScheduleErrorMessage: null,
      };
    case CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE.ERROR:
      return {
        ...state,
        cancelGrafanaAgentRemoteCommandScheduleSuccessMessage: null,
        cancelGrafanaAgentRemoteCommandScheduleErrorMessage: action.payload,
      };
    case SET_MODIFY_JOB_DETAILS:
      return {
        ...state,
        setModifyJobDetails: action.payload,
      };
    case SET_CLONE_JOB_DETAILS:
      return {
        ...state,
        setCloneJobDetails: action.payload,
      };
    case RESET_CLONE_JOB_DETAILS:
      return {
        ...state,
        setCloneJobDetails: null,
      };
    default:
      return { ...state };
  }
};

export default gaRemoteCommandReducer;
