import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { getEthernetConfigurationInit } from "../redux/actions";
import "./EthernetConfig.scss";


const wanTypes = [
    { label: "WAN 1", value: "wan1" },
    { label: "WAN 2", value: "wan2" },
];

const storeRegEx = /^\d{4}$/;

const EthernetConfigDetailTemplate = ({ label, value }) => {
    return (
        <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
                <strong>{label}</strong>
            </div>
            <div className="col-12 col-md-4 col-lg-3">{value}</div>
        </div>
    );
};

const EthernetConfig = () => {
    const [storeCode, setStoreCode] = useState("");
    const [wan, setWan] = useState(wanTypes[0].value);
    const [errorField, setErrorField] = useState("");

    const ethernetConfig = useSelector((state) => state.cpUsage.ethernetConfig);
    const dispatch = useDispatch();

    const getEthernetConfig = () => {
        if (!storeCode.match(storeRegEx)) {
            setErrorField("storeCode");
        } else {
            setErrorField("");
            dispatch(getEthernetConfigurationInit(storeCode, wan));
        }
    };

    return (
        <div className="ethernet-config-container container-fluid">
            <div className="row">
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="storeCode">Store #</label>
                    <InputText
                        id="storeCode"
                        className={errorField === "storeCode" ? "error-field" : ""}
                        value={storeCode}
                        onChange={(e) => setStoreCode(e.target.value)}
                    />
                </div>
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="wan">WAN</label>
                    <Dropdown
                        id="wan"
                        value={wan}
                        onChange={({ value }) => setWan(value)}
                        options={wanTypes}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    {errorField !== "" && (
                        <span className="error-status">
                            Invalid Input Format
                        </span>
                    )}
                    <br />
                    <Button label="Get Details" onClick={getEthernetConfig} />
                </div>
            </div>
            {!!ethernetConfig && (
                <div className="ethernet-config-details">
                    <h4>Ethernet Configuration</h4>
                    <EthernetConfigDetailTemplate
                        label="IPv4 Address"
                        value={ethernetConfig.publicIp}
                    />
                    <EthernetConfigDetailTemplate
                        label="Gateway IP"
                        value={ethernetConfig.gatewayIp}
                    />
                    <EthernetConfigDetailTemplate
                        label="Primary DNS Server"
                        value={ethernetConfig.primaryDns}
                    />
                    <EthernetConfigDetailTemplate
                        label="Secondary DNS Server"
                        value={ethernetConfig.secondaryDns}
                    />
                </div>
            )}
        </div>
    );
};

export default EthernetConfig;
