import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FilterMatchMode, FilterOperator } from "primereact/api";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

import { Column } from "primereact/column";
import { parseDateTime } from "../../../common/date-time";

import { getServerDetailsByJobIdInit } from "../redux/actions";
import "./GARemoteCommandJobDetails.scss";

const SubJobDetails = ({ jobId }) => {
	const [tableFilters, setTableFilters] = useState(null);
	const subJobDetailsTableRef = useRef(null);
	const dispatch = useDispatch();
	const serverDetails = useSelector(
		(state) => state.gaRemoteCommand.serverDetailByJobId
	);

	useEffect(() => {
		fetchServerDetailsByJobId();
		initializeTableFilter();
	}, []);

	const fetchServerDetailsByJobId = () => {
		dispatch(getServerDetailsByJobIdInit(jobId));
	};
	const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			serverId: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
			serviceType: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
			status: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
			message: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
			agentStatus: {
				operator: FilterOperator.AND,
				constraints: [
					{ value: null, matchMode: FilterMatchMode.CONTAINS },
				],
			},
		});
	};

	const agentStatusTemplate = ({ agentStatus, executedDateTime }) => (
		<>
			{agentStatus}
			{!!executedDateTime && (
				<>
					{" "}
					<span>-</span>
					<br />
					{parseDateTime(executedDateTime)}
				</>
			)}
		</>
	);
	const exportSubJobDetails = () => subJobDetailsTableRef.current.exportCSV();

	const executedTimeTemplate = ({ executedDateTime }) =>
		executedDateTime ? (
			parseDateTime(executedDateTime)
		) : (
			<span style={{ marginLeft: "50%" }}>-</span>
		);

	const messageTemplate = ({ message }) => {
		return (
			<>
				<Tooltip target=".tooltip-container" />
				<div
					className="tooltip-container"
					data-pr-tooltip={message}
					data-pr-position="top"
				>
					<div className="ellipsis-text">{message}</div>
					{/* <div className="tooltip-text">{message}</div> */}
				</div>
			</>
		);
	};
	return (
		<div className="sub-job-details">
			<div>
				<Button
					icon="pi pi-download"
					className="p-button-text"
					onClick={exportSubJobDetails}
					style={{ float: "right", zIndex: 1 }}
				/>
				<Button
					icon="pi pi-refresh"
					className="p-button-text"
					onClick={fetchServerDetailsByJobId}
					style={{ float: "right", zIndex: 1 }}
				/>
			</div>
			<DataTable
				value={serverDetails}
				ref={subJobDetailsTableRef}
				sortField="serverId"
				sortOrder={1}
				className="sub-job-table"
				filters={tableFilters}
				filterDisplay="menu"
				paginator
				paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
				currentPageReportTemplate="Items per page"
				rows={5}
				rowsPerPageOptions={[5, 10, 15]}
				emptyMessage="No logs found"
			>
				<Column field="serverId" header="Host Name" sortable filter />
				<Column
					field="serviceType"
					header="Service Type"
					sortable
					filter
				/>
				<Column
					field="executedDateTime"
					header="Executed At"
					sortable
					body={executedTimeTemplate}
				/>
				<Column field="status" header="Status" sortable filter />
				<Column
					field="message"
					header="Message"
					body={messageTemplate}
					sortable
					filter
				/>
				{serverDetails.some((item) => item.agentStatus !== null) && (
					<Column
						field="agentStatus"
						header="Agent Status- Last Updated Time"
						body={agentStatusTemplate}
						sortable
						filter
					/>
				)}
			</DataTable>
		</div>
	);
};

export default SubJobDetails;
