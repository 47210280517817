import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";

import { getAllDivisionNamesInit } from "./redux/actions";
import './DivisionDropdown.scss';


const DivisionNameDropdown = ({ selectedDivision, setSelectedDivision }) => {
    const divisionList = useSelector(state => state.divisionDropdown.divisionNameList);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!divisionList || divisionList.length === 0) {
            dispatch(getAllDivisionNamesInit());
        }
    }, []);

    return (
        <Dropdown
            className="division-name-dropdown"
            options={divisionList}
            value={selectedDivision}
            onChange={(e) => setSelectedDivision(e.value)}
        />
    );
};

export default memo(DivisionNameDropdown);
