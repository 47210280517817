import React, { forwardRef } from 'react';

import RenderAssetTableContainer from './RenderAssetTableContainer';
import './AssetList.scss';


const AssetListTemplate = forwardRef(({assetList}, ref) => {

    const getIncident = (incident, incidentType) => {
        if (!incident) {
            return [];
        }
        if (incidentType==="open") {
            return incident.filter(({incidentStatus}) => incidentStatus==="New" || incidentStatus==="In Progress");
        } 
        return incident.filter(({incidentStatus}) => incidentStatus==="Resolved" || incidentStatus==="Closed");
    }


    const mapAsset = (asset)=> ({
                    assetId : asset.assetId,
                    modelId: asset.modelId,
                    assetClass: asset.assetType,
                    serialNumber: asset.serialNumber,
                    manufacturer:asset.manufacturer,
                    costCenter: asset.costCenter,
                    state: asset.operationalStatus,
                    ipAddress: asset.ipAddress,
                    switchName: asset.switchName,
                    port: asset.port,
                    assetName: asset.assetName || asset.serialNumber
    });
  
    /**
     * Format Asset data to display into table
     * @param {Array} data - List of Assets
     */
    const getDeviceDetail = devices => {
        if(!devices || devices.length===0) {
            return {
                deviceId: '',
                deviceCode: '',
                deviceName: '',
                assets: [],
                openIncidents: [],
                closedIncidents: []
            };
        }
         const includesRxperipheral = devices.length ===3 && devices.map(rxdeviceName=> (rxdeviceName.deviceName.toLowerCase().includes('barcodescanner') || rxdeviceName.deviceName.toLowerCase().includes('imagescanner') || rxdeviceName.deviceName.toLowerCase().includes('fingerprintscanner')) );
  
        if(devices.length > 1 && includesRxperipheral) {
            return devices.map(device => ({

                        deviceId: device.deviceId,
                        deviceCode: device.deviceCode,
                        deviceName: device.deviceName,
                        openIncidents: getIncident(device.incident, "open"),
                        closedIncidents: getIncident(device.incident, "closed"),
                        assets: device.assetList.map(mapAsset),
            }));
        }

       if(devices.length > 1){
            return devices.map(device => ({

                        deviceId: device.deviceId,
                        deviceCode: device.deviceCode,
                        deviceName: device.deviceName,
                        openIncidents: getIncident(device.incident, "open"),
                        closedIncidents: getIncident(device.incident, "closed"),
                        assetId : device.assetList[0] && device.assetList[0].assetId,
                        modelId : device.assetList[0] && device.assetList[0].modelId,
                        assetClass: device.assetList[0] && device.assetList[0].assetType,
                        serialNumber : device.assetList[0] && device.assetList[0].serialNumber,
                        costCenter : device.assetList[0] && device.assetList[0].costCenter,
                        state : device.assetList[0] && device.assetList[0].operationalStatus,
                        assetName : device.assetList[0] && (device.assetList[0].assetName || device.assetList[0].serialNumber),
                    }));
                }
   
        return {
                 deviceId: devices[0].deviceId,
            deviceCode: devices[0].deviceCode,
            deviceName: devices[0].deviceName,
            openIncidents: getIncident(devices[0].incident, "open"),
            closedIncidents: getIncident(devices[0].incident, "closed"),
            assets: devices[0].assetList.map(mapAsset),
        }
    }



    const printerData = getDeviceDetail(assetList.printers);
    const rxPrinterData = getDeviceDetail(assetList.rxPrinters);
    const modemData = getDeviceDetail(assetList.modems);
    const posPrinterData = getDeviceDetail(assetList.posPrinters);
    const posControllerData = getDeviceDetail(assetList.posControllers);
    const scaleData = getDeviceDetail(assetList.scales);
    const ispData = getDeviceDetail(assetList.isps);
    const cradlepointData = getDeviceDetail(assetList.cradlepoint);
    const wirelessData = getDeviceDetail(assetList.wireless);
    const switchesData = getDeviceDetail(assetList.switches);
    const arubaControllerData = getDeviceDetail(assetList.arubaController);
    const stingrayData = getDeviceDetail(assetList.stingray);
    const breakroomTvData = getDeviceDetail(assetList.breakroomTv);
    const grafanaAgentData = getDeviceDetail(assetList.grafanaAgent);
    const rxPeripheralDevicesData = getDeviceDetail(assetList.rxPeripheralDevices);
    
    return (
        <div className="expand-body">
            <RenderAssetTableContainer ref={ref} title="Printer Gateway" deviceData={printerData} />
			<RenderAssetTableContainer ref={ref} title="RX Printer" deviceData={rxPrinterData} />
            <RenderAssetTableContainer ref={ref} title="RX Peripheral"deviceData={rxPeripheralDevicesData} />
            <RenderAssetTableContainer ref={ref} title="Modem" deviceData={modemData} />
			<RenderAssetTableContainer ref={ref} title="POS Printer" deviceData={posPrinterData} />
			<RenderAssetTableContainer ref={ref} title="POS Controller" deviceData={posControllerData} />
            <RenderAssetTableContainer ref={ref} title="Scale" deviceData={scaleData} />
            <RenderAssetTableContainer ref={ref} title="ISP" deviceData={ispData} />
            <RenderAssetTableContainer ref={ref} title="CradlePoint" deviceData={cradlepointData} />
            <RenderAssetTableContainer ref={ref} title="IP Switch" deviceData={switchesData} />
            <RenderAssetTableContainer ref={ref} title="Wireless Access Point" query="type=ap" deviceData={wirelessData} />
            <RenderAssetTableContainer ref={ref} title="Aruba Controller" query="type=controller" deviceData={arubaControllerData} />
            <RenderAssetTableContainer ref={ref} title="Stingray Music System" deviceData={stingrayData} />
            <RenderAssetTableContainer ref={ref} title="Breakroom TV" deviceData={breakroomTvData} />
            <RenderAssetTableContainer ref={ref} title="Grafana Agent" deviceData={grafanaAgentData} />
        </div>
    )
})

export default AssetListTemplate;