import { GET_ALL_MESSAGES } from './actionTypes';

const initialState = {
    messageList: [],
    messageListErrorMessage: null
}

const messageReducer = (state=initialState, action={type:""}) => {
    switch(action.type) {
        case GET_ALL_MESSAGES.SUCCESS:
            return {
                ...state,
                messageList: action.payload,
                messageListErrorMessage: null
            }
        case GET_ALL_MESSAGES.ERROR:
            return {
                ...state,
                messageList: [],
                messageListErrorMessage: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default messageReducer;