import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { parseDateTime } from "../../common/date-time";
import { getServiceMainIncidentUsecasesDetailsInit } from "./redux/action"

const DetailServiceMainDialog = ({ dialogData, setDialogData }) => {

    const details = useSelector(
        (state) => state.serviceMain.smincidentUsecasesDetails
    );
    const dispatch = useDispatch();
    const [tableFilters, setTableFilters] = useState(null);

    /**
     * Initializing datatable filters
     */
    useEffect(() => {
        initializeTableFilter();
    }, []);

    /**
     * Fetching detailed log of a Incidents
     */
    useEffect(() => {
        if (!!dialogData) {
            fetchDetails();
        }
    }, [dialogData]);


       /**
     * Function to fetch detailed log of a Incidents
     */
       const fetchDetails = () =>
       dispatch(getServiceMainIncidentUsecasesDetailsInit(dialogData));

       
    /**
     * Function to initialize Table filter
     */
    const initializeTableFilter = () => {
        setTableFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            event: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
            message: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
        });
    };

       /**
     * Function to provide template for Message column in datatable
     * @param raw column
     * @returns templated column
     */
    const messageTemplate = ({ message, payload }) => (
        <>
            {message}
            {!!payload && (
                <>
                    <br />
                    {JSON.stringify(payload)}
                </>
            )}
        </>
    );

    const timestampTemplate = ({ timestamp }) =>
    !!timestamp ? parseDateTime(timestamp) : <>-</>;

  return (
    <div>
      <Dialog
            className="cc-usecase-detail-dialog"
            visible={!!dialogData}
            onHide={() => setDialogData(null)}
            header={dialogData}
        >
            <Button
                icon="pi pi-refresh"
                className="p-button-text refresh-icon"
                onClick={fetchDetails}
            />
            <DataTable
                value={details}
                filters={tableFilters}
                filterDisplay="menu"
                sortField="timestamp"
                sortOrder={-1}
                emptyMessage="No logs found."
            >
                 <Column
                    header="Timestamp"
                    field="timestamp"
                    body={timestampTemplate}
                    sortable
                />
                <Column header="Event" field="event" sortable filter />
                <Column
                    header="Message"
                    field="message"
                    body={messageTemplate}
                    sortable
                    filter
                />
               
            </DataTable>
        </Dialog>
    </div>
  )
}

export default DetailServiceMainDialog
