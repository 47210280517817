import { GET_STORE_COMPLETE_DETAIL } from "./actionTypes"

const initialState = {
    storesCompleteDetailList: [],
    storesCompleteDetailListErrorMessage: null,
}

const assetReducer = (state=initialState, action={type:""}) => {
    switch(action.type) {
        case GET_STORE_COMPLETE_DETAIL.SUCCESS:
            return {
                ...state, storesCompleteDetailList: action.payload, storesCompleteDetailListErrorMessage: null
            }
        case GET_STORE_COMPLETE_DETAIL.ERROR:
            return {
                ...state, storesCompleteDetailList: [], storesCompleteDetailListErrorMessage: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default assetReducer;