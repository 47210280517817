import React, { forwardRef, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useHistory } from "react-router-dom";

import AssetTable from './AssetTable';
import iot from '../../../images/iot.svg'
import './AssetList.scss';


/**
 * Generate table for Modems
 * @param {Array} modems - List of Modems
 */
const ModemTable = forwardRef(({title, data}, ref) => {

    const history = useHistory();

    /**
     * To navigate into Device Management details page
     * @param {String} deviceId - Pass the selected Device Id
     */
    const navigateTODeviceMgt = (deviceId) => {
        history.push("/admin/device-management/devices/list/" + deviceId);
    }

    const initialModemState = data.map(() => "");

    const [incidentType, setIncidentType] = useState([...initialModemState]);

    const showOpenIncident = index => {
        console.log("Show Open Incident of " + index);
        const modemState = [...initialModemState];
        modemState[index] = "open";
        setIncidentType(modemState);
    }

    const showClosedIncident = index => {
        console.log("Show Closed Incident of " + index);
        const modemState = [...initialModemState];
        modemState[index] = "closed";
        setIncidentType(modemState);
    }

  return data.map((modem, index) => {
   
    const isRxPeripheral =
      modem.deviceName &&
      (modem.deviceName.toLowerCase().includes("barcodescanner") || 
      modem.deviceName.toLowerCase().includes("imagescanner") || 
      modem.deviceName.toLowerCase().includes("fingerprintscanner")) ;
    console.log("Show incident", incidentType, title);
    return (
      <div className="Modem" key={modem.deviceName || index}>
        <br></br>
        <div className="printerLink">
          <button
            onClick={() =>
              navigateTODeviceMgt(modem.deviceId || modem.deviceCode)
            }
          >
          {!isRxPeripheral && <img src={iot} alt="" />}
            <div className="printer-name">
              <div> {title} </div>
              <div>{modem.deviceName}</div>
            </div>
          </button>

          <Dialog
            visible={incidentType[index] !== ""}
            header="Incident detail in last 24 Hours"
            onHide={() => setIncidentType([...initialModemState])}
            className="device-incident-popup"
          >
            <DataTable
              value={
                incidentType[index] === "open"
                  ? modem.openIncidents
                  : modem.closedIncidents
              }
              paginator
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
              currentPageReportTemplate="Items per page"
              rows={5}
              rowsPerPageOptions={[5, 10]}
            >
              <Column header="Incident" field="incidentNumber" />
              <Column header="Status" field="incidentStatus" />
            </DataTable>
          </Dialog>
          {modem.deviceName && (
            <div className="incident">
              <div className="inc-header">Incident</div>
              {modem.deviceName && !(modem.deviceName.toLowerCase().includes("barcodescanner") ||
               modem.deviceName.toLowerCase().includes("imagescanner") || 
               modem.deviceName.toLowerCase().includes("fingerprintscanner")
              ) && (
              <div
                className="open-incidents"
                onClick={() => showOpenIncident(index)}
              >
                <div>{modem.openIncidents && modem.openIncidents.length}</div>
              </div>
              )}
              <div
                className="closed-incidents"
                onClick={() => showClosedIncident(index)}
              >
                <div>
                  {modem.closedIncidents && modem.closedIncidents.length}
                </div>
              </div>
            </div>
          )}
        </div>
        {isRxPeripheral  && modem.assets && modem.assets.length > 0? (
          <AssetTable ref={ref} data={modem.assets} title={title} />
        ) : (
          <AssetTable ref={ref} data={modem.assetId ? [modem] : []} />
        )}
      </div>
    );
  });
});

export default ModemTable;