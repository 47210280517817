import { call, put } from 'redux-saga/effects';

import { 
    getAllSnowAppsSuccess, getAllSnowAppsFailure,
    getAllSnowWrapperLogsSuccess, getAllSnowWrapperLogsFailure
} from '../components/snow-wrapper/redux/actions';

import SnowWrapperService from '../components/snow-wrapper/SnowWrapperService';


const snowWrapperService = new SnowWrapperService();

/**
 * Worker saga for loading all snow apps
 */
function* loadAllSnowApps() {
    try {
        const snowAppList = yield call(snowWrapperService.getAllSnowApps);
        yield put(getAllSnowAppsSuccess(snowAppList));
    } catch(error) {
        yield put(getAllSnowAppsFailure(error.toString()));
    }
}

/**
 * Worker saga for loading all snow wrapper logs
 */
function* loadAllSnowWrapperLogs({appName, startDate, endDate, offset}) {
    try {
        const allSnowWrapperLogs = yield call(snowWrapperService.getAllSnowWrapperLogs, appName, startDate, endDate, offset);
        yield put(getAllSnowWrapperLogsSuccess(allSnowWrapperLogs));
    } catch(error) {
        yield put(getAllSnowWrapperLogsFailure(error.toString()));
    }
}

export { loadAllSnowApps, loadAllSnowWrapperLogs };