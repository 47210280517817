import axios from 'axios';
import { ApiPath } from '../../../common/api-path/ApiPath';

export default class PendingJobsService{
    getPendingJobs = async deviceNames => {
        const requestConfig = {
            params: { deviceName: deviceNames },
            timeout: 120000,
            timeoutErrorMessage: 'request timeout'
        };
    const response = await axios.get(`${ApiPath.baseUrl}/grafanaAgent/pendingjob`,
    requestConfig);

        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}


