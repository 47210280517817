import { call, put } from 'redux-saga/effects';

import {
    getConfigDivisionDetailsSuccess, getConfigDivisionDetailsFailure,
    getExclusionRuleSuccess, getExclusionRuleFailure,
    postExclusionRuleSuccess, postExclusionRuleFailure
} from '../components/incident-config/redux/actions';

import IncidentConfigService from '../components/incident-config/IncidentConfigService';


const incidentConfigService = new IncidentConfigService();

/**
 * Worker saga for loading division detail in Incident Config Page
 */
function* loadConfigDivisionDetails() {
    try {
        const divisionDetail = yield call(incidentConfigService.getDivisionDetail);
        yield put(getConfigDivisionDetailsSuccess(divisionDetail));
    } catch(error) {
        yield put(getConfigDivisionDetailsFailure(error.toString()));
    }
}

/**
 * Worker saga for loading Exclusion rule
 * @param {Object} action - Redux action
 */
function* loadExclusionRule(action) {
    try {
        const exclusionRule = yield call(incidentConfigService.getExclusionRule, action.division, action.assetType);
        yield put(getExclusionRuleSuccess(exclusionRule));
    } catch(error) {
        yield put(getExclusionRuleFailure(error.toString()));
    }
}

/**
 * Worker saga for saving Exclusion rule
 * @param {Object} action - Redux action
 */
function* postExclusionRule(action) {
    try {
        const exclusionRule = yield call(incidentConfigService.postExclusionRule, action.exclusionRule, action.division, action.assetType);
        yield put(postExclusionRuleSuccess(exclusionRule));
        try {
            const updatedExclusionRule = yield call(incidentConfigService.getExclusionRule, action.division, action.assetType);
            yield put(getExclusionRuleSuccess(updatedExclusionRule));
        } catch(error) {
            yield put(getExclusionRuleFailure(error.toString()));
        }
    } catch(error) {
        yield put(postExclusionRuleFailure(error.toString()));
    }
}

export { loadConfigDivisionDetails, loadExclusionRule, postExclusionRule };