import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { parseDateTime } from "../../common/date-time";
import EpeLogsDetailDialog from "./EpeLogsDetailDialog";
import "./EpeLogs.scss";

const SubEventDetails = ({ subEventLogs }) => {
  const [tableFilters, setTableFilters] = useState(null);
  const [dialogData, setDialogData] = useState(null);

  useEffect(() => {
    initializeTableFilter();
  }, []);

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      sub_event_id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      server_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      incident_number: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const showEventLogs = ({ sub_event_id, eventLogs }) => {
    !!eventLogs &&
      eventLogs.sort(
        (eventLog1, eventLog2) =>
          new Date(eventLog1.timestamp) - new Date(eventLog2.timestamp)
      );
    setDialogData({ sub_event_id, eventLogs });
  };

  const receivedDateTemplate = ({ receivedDate }) =>
    receivedDate ? (
      parseDateTime(receivedDate)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );
  const modifiedDateTemplate = ({ modifiedDate }) =>
    modifiedDate ? (
      parseDateTime(modifiedDate)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );
  const incidentTemplate = ({ incident_number }) =>
    incident_number ? (
      incident_number
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );
  const eventLogsTemplate = ({ sub_event_id, eventLogs }) => (
    <i
      className="pi pi-info-circle"
      onClick={() => showEventLogs({ sub_event_id, eventLogs })}
    />
  );

  return (
    <div className="sub-event-details">
      <EpeLogsDetailDialog
        dialogData={dialogData}
        setDialogData={setDialogData}
      ></EpeLogsDetailDialog>
      <DataTable
        value={!!subEventLogs && subEventLogs}
        className="sub-events-table"
        filters={tableFilters}
        filterDisplay="menu"
        emptyMessage="No logs found"
      >
        <Column field="sub_event_id" header="Subevent ID" sortable filter />
        <Column field="server_name" header="Server Name" sortable filter />
        <Column
          field="receivedDate"
          header="Created At"
          body={receivedDateTemplate}
          sortable
        />
        <Column
          field="incident_number"
          header="Incident #"
          body={incidentTemplate}
          sortable
          filter
        />
        <Column field="status" header="Status" sortable filter />
        <Column
          field="modifiedDate"
          header="Modified At"
          body={modifiedDateTemplate}
          sortable
        />
        <Column header="Details" body={eventLogsTemplate} />
      </DataTable>
    </div>
  );
};

export default SubEventDetails;
