import { call, put } from "redux-saga/effects";

import { 
    getEpeErrorCodesSuccess, getEpeErrorCodesFailure,
    getEpeLogsSuccess, getEpeLogsFailure
} from "../components/epe/redux/actions";

import EpeLogsService from "../components/epe/EpeLogsService";

const epeLogsService = new EpeLogsService();

/**
 * Worker saga for loading all error codes
 */
function* loadEpeErrorCodes() {
    try {
        const errorCodeList = yield call(epeLogsService.getEpeErrorCodes)
        yield put(getEpeErrorCodesSuccess(errorCodeList));
    }
    catch(error) {
        yield put(getEpeErrorCodesFailure(error.toString()))
    }
}

/**
 * Worker saga for loading epe logs
 */
function* loadEpeLogs({errorCodes, startDate, endDate, offset}) {
    try {
        const epeLogs = yield call(epeLogsService.getEpeLogs, errorCodes, startDate, endDate, offset);
        yield put(getEpeLogsSuccess(epeLogs));
    }
    catch(error) {
        yield put(getEpeLogsFailure(error.toString()));
    }
}

export { loadEpeErrorCodes, loadEpeLogs };