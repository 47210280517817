import {
    GET_RPA_USECASES,
    GET_RPA_SUMMARY_LOGS,
    GET_RPA_DETAIL_BY_CTASK_ID
} from './actionTypes';

const initialState = {
    rpaUsecases: [],
    rpaUsecasesErrorMessage: null, 
    summaryLogs: [],
    summaryLogsErrorMessage: null,
    logDetailByCtaskId: [],
    logDetailByCtaskIdErrorMessage: null
}

const rpaReducer = (state=initialState, action={type:"", payload: ""}) => {
    switch(action.type) {
        case GET_RPA_USECASES.SUCCESS:
            return {
                ...state, rpaUsecases: action.payload, rpaUsecasesErrorMessage: null
            }
        case GET_RPA_USECASES.ERROR:
            return {
                ...state, rpaUsecases: [], rpaUsecasesErrorMessage: action.payload
            }
        case GET_RPA_SUMMARY_LOGS.SUCCESS:
            return {
                ...state, summaryLogs: action.payload, summaryLogsErrorMessage: null
            }
        case GET_RPA_SUMMARY_LOGS.ERROR:
            return {
                ...state, summaryLogs: [], summaryLogsErrorMessage: action.payload
            }
        case GET_RPA_DETAIL_BY_CTASK_ID.SUCCESS:
            return {
                ...state, logDetailByCtaskId: action.payload, logDetailByCtaskIdErrorMessage: null
            }
        case GET_RPA_DETAIL_BY_CTASK_ID.ERROR:
            return {
                ...state, logDetailByCtaskId: [], logDetailByCtaskIdErrorMessage: action.payload
            }
        default:
            return {...state}
    }
}

export default rpaReducer;