import React from "react";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

import { parseDateTime } from "../../../common/date-time";

import "./GARemoteCommandJobDetails.scss";

const GARemoteCommandJobDetailDialog = ({ dialogData, setDialogData }) => {
	console.log("dialogData", dialogData);

	/**
	 * Function to export table data
	 */

	const createdAt = dialogData?.createdDateTime;
	const parsedCreatedAt = parseDateTime(createdAt);
	const modifiedByUser = dialogData?.modifiedBy;
	const createdByUser = dialogData?.createdBy;

	const jobId = dialogData?.jobId;
	const selectedStores = dialogData?.selectedStore;
	const selectedStoreTemplate = selectedStores?.join(", ");
	const chgNumber = dialogData?.itsmId;

	console.log(createdAt);
	return (
		<Dialog
			className=" ga-remote-command-detail-dialog"
			visible={!!dialogData}
			onHide={() => setDialogData(null)}
		>
			<h3 style={{ color: "#0053a0" }}>Job Id : {jobId}</h3>
			<Divider type="dashed" />
			<div>
				<p>
					{" "}
					<span style={{ fontWeight: 700 }}> Created At : </span>{" "}
					{parsedCreatedAt}
				</p>
				<Divider />
				<p>
					{" "}
					<span style={{ fontWeight: 700 }}> Created By: </span>{" "}
					{createdByUser}
				</p>
				<Divider />
				<p>
					{" "}
					<span style={{ fontWeight: 700 }}> Modified By: </span>{" "}
					{modifiedByUser}
				</p>
				<Divider />
				<p>
					{" "}
					<span style={{ fontWeight: 700 }}>
						{" "}
						RIT/INC/Change Number:{" "}
					</span>{" "}
					{chgNumber}
				</p>
				<Divider />
				<p>
					{" "}
					<span style={{ fontWeight: 700 }}>
						{" "}
						Selected stores:{" "}
					</span>{" "}
					{selectedStoreTemplate}
				</p>
			</div>
		</Dialog>
	);
};
export default GARemoteCommandJobDetailDialog;
