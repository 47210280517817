import React, { useState, useEffect, useRef} from 'react';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getAllEventInit } from './redux/actions';
import { DATETIME_FORMAT } from '../../../common/date-time';
import useQuery from '../../../common/hooks/useQuery';

import './Events.scss';


const Events = ({deviceId}) => {

  const eventDtRef = useRef(null);
  const eventData = useSelector(state =>  state.event.eventList);
  const deviceType = useSelector(state => state.deviceManagement.deviceItem ? state.deviceManagement.deviceItem.deviceType : "");
  const deviceName = useSelector(state => state.deviceManagement.deviceItem ? state.deviceManagement.deviceItem.deviceName : "");
  const dispatch = useDispatch();

  const [tableFilters, setTableFilters] = useState(null);

  const query = useQuery();

  const fetchEventData = () => dispatch(getAllEventInit(deviceId));

  /**
  * Fetch IOt device events data
  */
  useEffect(() => { 
    fetchEventData();
    initializeTableFilter();
  }, [deviceId])

  useEffect(() => {
    if(!tableFilters) {
      initializeTableFilter();
    }
    if(deviceName.includes("Virtual-Agent")) {
      let typeFilterValue = null;
      if(query.get("type")==="ap") {
        typeFilterValue = "AP";
      } else if(query.get("type")==="controller") {
        typeFilterValue = "Controller";
      } else {
        typeFilterValue = null;
      }
      setTableFilters(filters => ({
        ...filters,
        type: {
          operator: FilterOperator.AND,
          constraints: [{ value: typeFilterValue, matchMode: FilterMatchMode.CONTAINS }]
        },
      }))
    }
  }, [query]);

  const exportCSV = () => {
    eventDtRef.current.exportCSV();
  }
  
  const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			message: {
				operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
      type: {
				operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			code: {
				operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
      source: {
				operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
      correlationId: {
				operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
		})
	}

  const paginatorLeft = <>
    <Tooltip target=".event-table-info-icon" />
    <i className="pi pi-info-circle event-table-info-icon" data-pr-tooltip="Last 2 Week Data" data-pr-position="right" />
  </>;
  
 /**
  * 
  * @param {HTMLFormEvent} e 
  * Custom sort function
  */
  const eventTimeStampSort = (e) => {
    return e?.data?.sort((data1, data2) => {
      const value1 = moment(data1[e.field], DATETIME_FORMAT);
      const value2 = moment(data2[e.field], DATETIME_FORMAT);
      const result = value1 > value2 ? 1 : -1;
      return (e.order*result);
    }) 
  }

  /**
  * Display events table view
  */
  return (
    <div>
      <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={fetchEventData} style={{ float: 'right' }} />
      <Button type="button" icon="pi pi-download" className="p-button-text" onClick={exportCSV} style={{ float: 'right'}}/>
      <br/> <br/>
      <DataTable ref ={eventDtRef} exportFilename={`${deviceName}-Events`} value={eventData} className="p-datatable-gridlines" sortField="eventTimestamp" sortOrder={-1} paginator
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        currentPageReportTemplate="Items per page" rows={10} rowsPerPageOptions={[10, 20, 50]} paginatorLeft={paginatorLeft}
        filters={tableFilters} filterDisplay="menu" emptyMessage="No events found.">
        <Column field="message" header="Message" className="preserve-whitespace" sortable filter />
        { deviceName.includes("Virtual-Agent") && <Column field="type" header="Type" sortable filter />}
        <Column field="eventTimestamp" header="Timestamp" sortable="custom" sortFunction={eventTimeStampSort}/>
        <Column field="code" header="Code" sortable filter />
        {(deviceType==="RxPeripheral") &&  <Column field="source" header="Workstation" sortable filter />}
        { (deviceType==="switch" || deviceType==="wireless" || deviceType==="Cradlepoint Smart Switch") && <Column field="source" header="Source" sortable filter /> }
        { (deviceType==="switch" || deviceType==="wireless" || deviceType==="Cradlepoint Smart Switch") && <Column field="correlationId" header="Correlation ID" sortable filter /> }
      </DataTable>
    </div>)
}

export default Events;