import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";

import CopyButton from "../../common/components/copy-button/CopyButton";
import { parseDateTime } from "../../common/date-time";
import Moment from "moment";
import moment from "moment-timezone";
import {
  postModifyMerakiFirewallScheduleInit,
  cancelMerakiFirewallScheduleInit,
} from "./redux/actions";
import { useDispatch } from "react-redux";
import "./MerakiFirewallRITChildDetails.scss";
import MerakiFirewallAutomationService from "./MerakiFirewallAutomationService";

const service = new MerakiFirewallAutomationService();

const MerakiFirewallRITChildDetails = ({ subTaskDetails }) => {
  const toastRef = useRef();
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [isConfirmationPopupVisible, setConfirmationPopupVisible] =
    useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [pstTimeForConfirmation, setPstTimeForConfirmation] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const actionsTemplate = (rowData) => {
    if (!rowData) {
      return null;
    }

    const currentTime = new Date();
    const scheduledTime = new Date(rowData.scheduled_time);
    const isScheduledInPast = Moment(scheduledTime).isBefore(
      currentTime,
      "day"
    );
    const isDisabled = isScheduledInPast || rowData.status !== "Schedule";

    return (
      <div className="action-icons">
        <Button
          icon={PrimeIcons.PENCIL}
          className={`p-button-text ${isDisabled ? "disabled" : ""}`}
          onClick={() => openModifyDialog(rowData)}
          disabled={isDisabled}
        />
        <Button
          icon={PrimeIcons.TIMES_CIRCLE}
          className={`p-button-text p-button-danger ${
            isDisabled ? "disabled" : ""
          }`}
          disabled={isDisabled}
          onClick={(event) =>
            confirmPopup({
              target: event.currentTarget,
              icon: "pi pi-info-circle",
              message: "Do you want to cancel this schedule ?",
              accept: () => handleCancelClick(rowData),
            })
          }
        />
        <Button
          icon={PrimeIcons.EYE}
          className="p-button-text"
          onClick={() => openLiveLogsLink(rowData.log_file_path)}
          disabled={!rowData.log_file_path}
        />
      </div>
    );
  };

  const openLiveLogsLink = (filePath) => {
    //Whenever the file is updated it was throwing error, added a unique id at the end of url
    //recommeneded by : https://stackoverflow.com/questions/43706605/azure-file-storage-error-condition-headers-are-not-supported
    service
      .getMerakiFirewallLiveLogsUrl(filePath)
      .then((url) =>
        window.open(url + `&uniqueid=${new Date().getTime()}`, "_blank")
      );
  };

  const handleCancelClick = (rowData) => {
    const cancelSchedule = {
      task: rowData.id,
      level: rowData.type,
      requester: rowData.requester,
    };

    dispatch(cancelMerakiFirewallScheduleInit(cancelSchedule));
  };

  const openModifyDialog = (rowData) => {
    setSelectedRowData(rowData);
    setDialogVisible(true);
  };

  const closeModifyDialog = () => {
    setDialogVisible(false);
  };

  const saveModifyDialog = () => {
    setDialogVisible(false);
    if (selectedDateTime) {
      //convert selectedDateTime to PST
      const pstDateTime = moment(selectedDateTime).tz("America/Los_Angeles");
      setPstTimeForConfirmation(pstDateTime.format("DD-MMM-YYYY hh:mm A "));
      openConfirmationPopup();
    }
  };

  const openConfirmationPopup = () => {
    setConfirmationPopupVisible(true);
  };

  const closeConfirmationPopup = () => {
    setConfirmationPopupVisible(false);
  };

  const handleConfirmationAccept = (selectedRowData) => {
    if (selectedRowData && selectedDateTime) {
      //Convert PST time to UTC with offset

      const modifiedDate = moment(selectedDateTime).format(
        "yyyy-MM-DD HH:mm:ss"
      );
      const childId = selectedRowData.id;
      const requester = selectedRowData.requester;
      const oldTimeStamp = selectedRowData.scheduled_time;
      const offset = Moment(new Date()).utcOffset();

      const modifiedSchedule = {
        childId,
        timeStamp: modifiedDate,
        requester,
        oldTimeStamp,
        offset,
      };
      //dispatch the action to save the modified schedule
      dispatch(postModifyMerakiFirewallScheduleInit(modifiedSchedule));
      closeConfirmationPopup();
    }
  };

  const [tableFilters] = useState(null);
  const receivedAtTemplate = ({ received_date }) =>
    !!received_date ? (
      parseDateTime(received_date)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const scheduledAtTemplate = ({ scheduled_time }) =>
    !!scheduled_time ? (
      parseDateTime(scheduled_time + "+00")
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const modifiedAtTemplate = ({ modified_date }) =>
    !!modified_date ? (
      parseDateTime(modified_date)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const inputPayloadTemplate = ({ input_payload }) => (
    <div className="payload-template">
      <CopyButton className="copy-icon" textToCopy={input_payload} />
      <br />
      {input_payload}
    </div>
  );

  return (
    <div className="subtask-detail-logs-content">
      <DataTable
        value={!!subTaskDetails && subTaskDetails}
        className="RIT-child-details-table"
        filters={tableFilters}
        filterDisplay="menu"
        sortField="received_date"
        sortOrder={-1}
        paginator
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Items per page"
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        emptyMessage="No logs found"
      >
        <Column header="Sub Task" field="id" sortable filter />
        <Column
          header="Received At"
          field="received_date"
          body={receivedAtTemplate}
          sortable
        />
        <Column
          header="Schedule At"
          field="scheduled_time"
          body={scheduledAtTemplate}
          sortable
        />
        <Column header="Status" field="status" sortable filter />
        <Column header="Requester" field="requester" sortable filter />
        <Column
          header="Modified At"
          field="modified_date"
          body={modifiedAtTemplate}
          sortable
        />
        <Column
          header="Input Payload"
          field="input_payload"
          body={inputPayloadTemplate}
        />
        <Column header="Actions" body={(rowData) => actionsTemplate(rowData)} />
      </DataTable>

      <Dialog
        visible={isDialogVisible}
        onHide={closeModifyDialog}
        header={
          <span className="modify-schedule-header"> Modify Schedule</span>
        }
        footer={
          <div>
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => saveModifyDialog()}
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={closeModifyDialog}
            />
          </div>
        }
      >
        <div>
          <h5 className="modify-schedule-subtitle">
            {" "}
            Please select date and time
          </h5>
          <h6 className="note">
            {" "}
            Note: Please ensure that the time selected is at least 5 minutes
            later than the current time{" "}
          </h6>
          <Calendar
            ref={calendarRef}
            showTime
            value={selectedDateTime}
            onChange={(e) => setSelectedDateTime(e.value)}
            minDate={new Date()}
            inline
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>
      </Dialog>
      <ConfirmPopup
        visible={isConfirmationPopupVisible}
        onHide={closeConfirmationPopup}
        message={`Are you sure you want to modify the schedule to  ${pstTimeForConfirmation} PST?`}
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-primary"
        rejectLabel="No"
        acceptLabel="Yes"
        accept={() => handleConfirmationAccept(selectedRowData)}
        appendTo={document.body}
        className="custom-popup"
      />
      <ConfirmPopup />
      <Toast ref={toastRef} position="bottom-center" />
    </div>
  );
};

export default MerakiFirewallRITChildDetails;
