import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';


const CopyButton = ({textToCopy}) => {

    const toastRef = useRef(null);

    const handleClick = () => {
        navigator.clipboard.writeText(textToCopy);
        toastRef.current.show({severity:'success', summary: 'Text Copied', detail:'', life: 1000});
    }

    return (
        <>
            <i className="pi pi-copy copy-icon" onClick={handleClick}/>
            <Toast ref={toastRef} position="bottom-center" />
        </>
    )
}

export default CopyButton;