import { 
    GET_OUTPUT_FILE_LIST, POST_OUTPUT_FILE,
    GET_CP_INCIDENT_LIST, POST_CP_INCIDENT_LIST,
    GET_STORE_ID_UPDATE_EVENT_LIST,
    GET_ETHERNET_CONFIGURATION,
    POST_NCM_UPDATE,
    GET_CP_GROUP_LIST,
    GET_CP_GROUP_NAME,
} from './actionTypes';

const initialState = {
    outputFileList: [],
    outputFileListErrorMessage: null,
    postoutputFileErrorMessage: null,
    cpIncidentList: [],
    cpIncidentListErrorMessage: null,
    postCpIncidentListErrorMessage: null,
    storeIdUpdateEventList: [],
    storeIdUpdateEventListErrorMessage: null,
    ethernetConfig: null,
    ethernetConfigErrorMessage: null,
    ncmUpdateManageErrorMessage: null,
    cpGroupList: [],
    cpGroupListErrorMessage: null,
    cpGroupName: null,
    cpGroupNameErrorMessage: null
}

const cpUsageReducer = (state=initialState, action={type: ""}) => {
    switch(action.type) {
        case GET_OUTPUT_FILE_LIST.SUCCESS:
            return {
                ...state, outputFileList: action.payload, outputFileListErrorMessage: null
            }
        case GET_OUTPUT_FILE_LIST.ERROR: 
            return {
                ...state, outputFileList: [], outputFileListErrorMessage: action.payload
            }
        case POST_OUTPUT_FILE.SUCCESS:
            return {
                ...state, postoutputFileErrorMessage: null
            }
        case POST_OUTPUT_FILE.ERROR:
            return {
                ...state, postoutputFileErrorMessage: action.payload
            }
        case GET_CP_INCIDENT_LIST.SUCCESS:
        case GET_CP_INCIDENT_LIST.UPDATE:
            return {
                ...state, cpIncidentList: action.payload, cpIncidentListErrorMessage: null
            }
        case GET_CP_INCIDENT_LIST.ERROR:
            return {
                ...state, cpIncidentList: [], cpIncidentListErrorMessage: action.payload
            }
        case POST_CP_INCIDENT_LIST.SUCCESS:
            return {
                ...state, postCpIncidentListErrorMessage: null
            }
        case POST_CP_INCIDENT_LIST.ERROR:
            return {
                ...state, postCpIncidentListErrorMessage: action.payload
            }
        case GET_STORE_ID_UPDATE_EVENT_LIST.SUCCESS:
            return {
                ...state, storeIdUpdateEventList: action.payload, storeIdUpdateEventListErrorMessage: null
            }
        case GET_STORE_ID_UPDATE_EVENT_LIST.ERROR:
            return {
                ...state, storeIdUpdateEventList: [], storeIdUpdateEventListErrorMessage: action.payload
            }
        case GET_ETHERNET_CONFIGURATION.SUCCESS:
            return {
                ...state, ethernetConfig: action.payload, ethernetConfigErrorMessage: null
            }
        case GET_ETHERNET_CONFIGURATION.ERROR:
            return {
                ...state, ethernetConfig: null, ethernetConfigErrorMessage: action.payload
            }
        case POST_NCM_UPDATE.SUCCESS:
            return {
                ...state, ncmUpdateManageErrorMessage: null
            }
        case POST_NCM_UPDATE.ERROR:
            return {
                ...state, ncmUpdateManageErrorMessage: action.payload
            }
        case GET_CP_GROUP_LIST.SUCCESS:
            return {
                ...state, cpGroupList: action.payload, cpGroupListErrorMessage: null
            }
        case GET_CP_GROUP_LIST.ERROR:
            return {
                ...state, cpGroupList: [], cpGroupListErrorMessage: action.payload
            }
        case GET_CP_GROUP_NAME.SUCCESS:
            return {
                ...state, cpGroupName: action.payload, cpGroupNameErrorMessage: null
            }
        case GET_CP_GROUP_NAME.ERROR:
            return {
                ...state, cpGroupName: null, cpGroupNameErrorMessage: action.payload
            }
        default: 
            return {
                ...state
            }
    }
}

export default cpUsageReducer;