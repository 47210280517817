import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useSelector, useDispatch } from 'react-redux';
import Moment from "moment";

import { parseDateTime, addDateTime} from '../../common/date-time';
import { getAllSnowAppsInit, getAllSnowWrapperLogsInit } from './redux/actions';
import SnowWrapperDetailDialog from './SnowWrapperDetailDialog';
import './SnowWrapper.scss';


const SnowWrapper = () => {

    const snowAppList = useSelector(state => state.snowWrapper.snowAppList);
    const snowWrapperLogs = useSelector(state => state.snowWrapper.snowWrapperLogs);
    const dispatch = useDispatch();
    const [selectedSnowApp, setSelectedSnowApp] = useState([]);
    const [dateRange, setDateRange] = useState([
        addDateTime("day", -2),
        addDateTime("day", 0),
    ]);
    const [tableFilters, setTableFilters] = useState(null);
    const [dialogData, setDialogData] = useState(null);

    const toastRef = useRef(null);
    const logsTableRef = useRef(null);

    useEffect(() => {
        dispatch(getAllSnowAppsInit());
        initializeTableFilter();
    }, []);

    /**
     * Select all snow apps by default
     */
    useEffect(() => {
        setSelectedSnowApp([...snowAppList]);
        if(snowAppList.length>0) {
            fetchSnowWrapperLogs(true);
        }
    }, [snowAppList]);

    /**
     * Fetch Snow logs
     */
    const fetchSnowWrapperLogs = (initialLoad) => {
        let snowApps = selectedSnowApp.join(",");
        if(selectedSnowApp.length===0 && initialLoad!==true) {
            toastRef.current.show({severity: 'error', summary: 'Error', detail: 'No App Selected', life: 2000});
            return;
        } else if(initialLoad===true) {
            snowApps = snowAppList.join(",");
        }
        const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
        const endDate = !!dateRange[1] ? Moment(dateRange[1]).format("yyyy-MM-DD") : Moment(dateRange[0]).format("yyyy-MM-DD");
        const offset = Moment(new Date()).utcOffset();
        dispatch(getAllSnowWrapperLogsInit(snowApps, startDate, endDate, offset));
    }

    /**
     * Initialize table filter
     */
    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			appName: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            requestId: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            queueStatus : {
                operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
            },
		})
	}

    /**
     * Columns template 
     */
    const kafkaQueuedTimestampTemplate = ({kafkaQueuedTimestamp}) => kafkaQueuedTimestamp ? parseDateTime(kafkaQueuedTimestamp) : <span style={{marginLeft: '50%'}}>-</span>; 
    const requestReceivedAtTemplate = ({requestReceived}) => requestReceived ? parseDateTime(requestReceived) : <span style={{marginLeft: '50%'}}>-</span>;
    const requestQueuedAtTemplate = ({requestQueued}) => requestQueued ? parseDateTime(requestQueued) : <span style={{marginLeft: '50%'}}>-</span>;
    const requestProcessedAtTemplate = ({requestProcessed}) => requestProcessed ? parseDateTime(requestProcessed) : <span style={{marginLeft: '50%'}}>-</span>;
    const requestResponseTemplate = ({request, response}) => <i className="pi pi-eye" onClick={() => setDialogData({request, response})}></i>

    /**
   * Function to export table data
   */
const exportSnowWrapperLogs = () => logsTableRef.current.exportCSV();

    return (
        <div className="snow-wrapper-container container-fluid">
            <Toast ref={toastRef} position="bottom-center" />
            <p className="page-label">
                SNOW Wrapper
            </p>
            <SnowWrapperDetailDialog dialogData={dialogData} setDialogData={setDialogData} />
            <div className="snow-wrapper-action row">
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="snow-apps">App Name</label>
                    <MultiSelect
                        id="snow-apps"
                        value={selectedSnowApp}
                        options={snowAppList}
                        onChange={e => setSelectedSnowApp(e.value)} />
                </div>
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="dateRange">Events Date</label>
                    <Calendar
                        id="dateRange"
                        value={dateRange}
                        onChange={(e) => setDateRange(e.value)}
                        selectionMode="range"
                        minDate={addDateTime("month", -3)}
                        maxDate={addDateTime("month", 0)}
                        showIcon />
                </div>
                <div className="col-12">
                    <Button label="Get Logs" onClick={fetchSnowWrapperLogs} />
                    <Tooltip target=".message-table-info-icon" />
        <i className="pi pi-info-circle message-table-info-icon" data-pr-tooltip="Max records shown/downloadable: 10,000" data-pr-position="right" style={{marginLeft:"7px"}} />
                    <Button 
                        icon="pi pi-download"
                        className="p-button-text"
                        onClick={exportSnowWrapperLogs}
                        style={{float: "right"}}
                    />
                    <Button 
                        id='refreshBtn'
                        icon="pi pi-refresh"
                        className="p-button-text"
                        onClick={fetchSnowWrapperLogs}                       
                        style={{float: "right"}}
                    />
                </div>
            </div>
            <DataTable value={snowWrapperLogs} ref={logsTableRef}  filters={tableFilters} filterDisplay="menu" sortField="kafkaQueuedTimestamp" sortOrder={-1} paginator
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    currentPageReportTemplate="Items per page" rows={10} rowsPerPageOptions={[10, 20, 50]} emptyMessage="No logs found.">
                <Column header="App Name" field="appName" sortable filter />
                <Column header="Request ID" field="requestId" sortable filter />
                <Column header="Status" field="queueStatus" sortable filter />
                <Column header="Request Queued At" field="kafkaQueuedTimestamp" body={kafkaQueuedTimestampTemplate} sortable />
                <Column header="Request Received At" field="requestReceived" body={requestReceivedAtTemplate} sortable />
                <Column header="Request Processed At" field="requestProcessed" body={requestProcessedAtTemplate} sortable />
                <Column header="Request Retried At" field="requestQueued" body={requestQueuedAtTemplate} sortable />
                <Column header="Request / Response" body={requestResponseTemplate} />
            </DataTable>
        </div>
    )
}

export default SnowWrapper;