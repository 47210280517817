export const GET_CC_RIT_USECASES = {
    INIT: "GET_CC_RIT_USECASES_INIT",
    SUCCESS: "GET_CC_RIT_USECASES_SUCCESS",
    ERROR: "GET_CC_RIT_USECASES_ERROR",
};

export const GET_CC_RIT_USECASES_LOGS = {
    INIT: "GET_CC_RIT_USECASES_LOGS_INIT",
    SUCCESS: "GET_CC_RIT_USECASES_LOGS_SUCCESS",
    ERROR: "GET_CC_RIT_USECASES_LOGS_ERROR",
};

export const GET_CC_RIT_USECASES_DETAILS = {
    INIT: "GET_CC_RIT_USECASES_DETAILS_INIT",
    SUCCESS: "GET_CC_RIT_USECASES_DETAILS_SUCCESS",
    ERROR: "GET_CC_RIT_USECASES_DETAILS_ERROR",
};

export const GET_CC_INCIDENT_USECASES = {
    INIT: "GET_CC_INCIDENT_USECASES_INIT",
    SUCCESS: "GET_CC_INCIDENT_USECASES_SUCCESS",
    ERROR: "GET_CC_INCIDENT_USECASES_ERROR",
};

export const GET_CC_INCIDENT_USECASES_LOGS = {
    INIT: "GET_CC_INCIDENT_USECASES_LOGS_INIT",
    SUCCESS: "GET_CC_INCIDENT_USECASES_LOGS_SUCCESS",
    ERROR: "GET_CC_INCIDENT_USECASES_LOGS_ERROR",
};

export const GET_CC_INCIDENT_USECASES_DETAILS = {
    INIT: "GET_CC_INCIDENT_USECASES_DETAILS_INIT",
    SUCCESS: "GET_CC_INCIDENT_USECASES_DETAILS_SUCCESS",
    ERROR: "GET_CC_INCIDENT_USECASES_DETAILS_ERROR",
};
