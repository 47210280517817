export const POST_INCIDENT_SUPPRESSION_REQUEST = {
    INIT: 'POST_INCIDENT_SUPPRESSION_REQUEST_INIT',
    SUCCESS: 'POST_INCIDENT_SUPPRESSION_REQUEST_SUCCESS',
    ERROR: 'POST_INCIDENT_SUPPRESSION_REQUEST_ERROR'
}

export const SET_INCIDENT_SUPPRESSION_REQUEST = "SET_INCIDENT_SUPPRESSION_REQUEST";

export const GET_INCIDENT_SUPPRESSION_LOGS = { 
    INIT: 'GET_INCIDENT_SUPPRESSION_LOGS_INIT',
    SUCCESS: 'GET_INCIDENT_SUPPRESSION_LOGS_SUCCESS',
    ERROR: 'GET_INCIDENT_SUPPRESSION_LOGS_ERROR'
}

export const GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET = {
    INIT: 'GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET_INIT',
    SUCCESS: 'GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET_SUCCESS',
    ERROR: 'GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET_ERROR'
}

export const GET_INCIDENT_SUPPRESSION_EVENT_LOGS = {
    INIT: 'GET_INCIDENT_SUPPRESSION_EVENT_LOGS_INIT',
    SUCCESS: 'GET_INCIDENT_SUPPRESSION_EVENT_LOGS_SUCCESS',
    ERROR: 'GET_INCIDENT_SUPPRESSION_EVENT_LOGS_ERROR'
}