import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Moment from "moment";

import { addDateTime } from "../../common/date-time";
import {
  getServiceMainIncidentUsecasesInit,
  getServiceMainIncidentUsecasesLogsInit,
} from "./redux/action";
import {
  alertPayloadTemplate,
  changeRequestTemplate,
  commonTableFilters,
  modifiedAtTemplate,
  receivedAtTemplate,
} from "./smutils";

import SelectedChips from "../../common/components/selected-chips/SelectedChips";
import DetailServiceMainDialog from "./DetailServiceMainDialog";
import "./ServiceMainLogs.scss";

const ServiceMainLogs = () => {
  const dispatch = useDispatch();
  const [selectedUsecases, setSelectedUsecases] = useState([]);
  const [dateRange, setDateRange] = useState([
    addDateTime("day", -14),
    addDateTime("day", 0),
  ]);
  const [tableFilters, setTableFilters] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const serviceMainLogsTableRef = useRef(null);

  const smusecaseList = useSelector(
    (state) => state.serviceMain.smincidentUsecases
  );
  const smincidentLogs = useSelector(
    (state) => state.serviceMain.smincidentUsecasesLogs
  );

  /**
   * Fetch usecases list and initialize table filter once Component is mounted
   */
  useEffect(() => {
    dispatch(getServiceMainIncidentUsecasesInit());
    initializeTableFilter();
  }, []);

  /**
   * Marking all usecases as selected once loaded
   */
  useEffect(() => {
    setSelectedUsecases([...smusecaseList]);
    if (smusecaseList.length > 0) {
      fetchLogs(true);
    }
  }, [smusecaseList]);

  /**
   * Function to initialize Table filter
   */
  const initializeTableFilter = () => {
    setTableFilters({
      ...commonTableFilters,
      incident_number: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  /**
   * Function to fetch logs based on selected usecase and datetime
   * @param {boolean} initialLoad - Flag to define whether the function is called at the time of loading usecase list
   */
  const fetchLogs = (initialLoad) => {
    let usecaseType = selectedUsecases.join(",");
    if (initialLoad === true) {
      usecaseType = smusecaseList.join(",");
    }
    const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
    const endDate = !!dateRange[1]
      ? Moment(dateRange[1]).format("yyyy-MM-DD")
      : Moment(dateRange[0]).format("yyyy-MM-DD");
    const offset = Moment(new Date()).utcOffset();
    dispatch(
      getServiceMainIncidentUsecasesLogsInit(
        usecaseType,
        startDate,
        endDate,
        offset
      )
    );
  };

  /**
   * Function to provide template for Details column in datatable
   * @param raw column
   * @returns templated column
   */
  const detailTemplate = ({ incident_number }) => (
    <i
      className="pi pi-info-circle"
      onClick={() => setDialogData(incident_number)}
    ></i>
  );

  const exportServiceMainLogs = () =>
    serviceMainLogsTableRef.current.exportCSV();

  return (
    <div className="service-main-logs-container  ">
      <p className="page-label">
        Automation Logs
        <i className="pi pi-angle-right" />
        Service Main
      </p>
      <DetailServiceMainDialog
        dialogData={dialogData}
        setDialogData={setDialogData}
      />

      <div className="service-main-logs-action row">
        <div className="col-12 col-lg-6 input-field">
          <label htmlFor="usecase">Process Types: </label>
          <MultiSelect
            id="usecase"
            value={selectedUsecases}
            options={smusecaseList} //from api state
            onChange={(e) => setSelectedUsecases(e.value)}
          />
        </div>
        <div className="col-12 col-lg-6 input-field">
          <label htmlFor="dateRange">Events Date</label>
          <Calendar
            id="dateRange"
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            minDate={addDateTime("month", -3)}
            maxDate={addDateTime("month", 0)}
            showIcon
          />
        </div>
        <div className="col-12">
          <SelectedChips
            label="Selected Process types"
            selectedValues={selectedUsecases}
            setSelectedValues={setSelectedUsecases}
          />
        </div>
        <div className="col-12">
          <Button
            label="Get Logs"
            onClick={fetchLogs}
            disabled={selectedUsecases.length === 0}
          />
          <Button
            icon="pi pi-download"
            className="p-button-text"
            onClick={exportServiceMainLogs}
            style={{ float: "right" }}
          />
          <Button
            icon="pi pi-refresh"
            className="p-button-text"
            onClick={fetchLogs}
            style={{ float: "right" }}
          />
        </div>
      </div>
      <DataTable
        value={smincidentLogs}
        filters={tableFilters}
        ref={serviceMainLogsTableRef}
        filterDisplay="menu"
        sortField="received_date"
        sortOrder={-1}
        paginator
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        currentPageReportTemplate="Items per page"
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        emptyMessage="No logs found."
      >
        <Column header="Incident" field="incident_number" sortable filter />
        <Column header="Process Type" field="usecase" sortable filter />
        <Column
          header="Received At"
          field="received_date"
          body={receivedAtTemplate}
          sortable
        />
        <Column header="Status" field="status" sortable filter />
        <Column
          header="Description"
          field="description"
          body={(rowData) => (rowData.description ? rowData.description : "-")}
          sortable
          filter
        />
        <Column
          header="Change Request"
          field="cr_number"
          body={changeRequestTemplate}
          sortable
          filter
        />
        <Column header="Retry Count" field="retry_count" sortable filter />
        <Column
          header="Modified At"
          field="modified_date"
          body={modifiedAtTemplate}
          sortable
        />
        <Column
          header="Alert Payload"
          field="alert_payload"
          body={alertPayloadTemplate}
        />
        <Column
          header="ITSM Retry Count"
          field="itsm_retry_count"
          sortable
          filter
        />
        <Column header="Details" body={detailTemplate} />
      </DataTable>
    </div>
  );
};

export default ServiceMainLogs;
