import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Timeline } from "primereact/timeline";
import { Button } from "primereact/button";
import { useSelector, useDispatch } from "react-redux";

import { parseDateTime } from "../../../common/date-time";
import { getIncidentSuppressionEventLogsInit } from "../redux/actions";
import "./IncidentSuppressionLogs.scss";

const IncidentSuppressionLogsDetailDialog = ({dialogData, setDialogData}) => {

    const incidentSuppressionEventLogs = useSelector(state => state.incidentSuppression.incidentSuppressionEventLogs);
    const dispatch = useDispatch();
    const usecase = "incident_suppression"

    useEffect(() => {
        fetchIncidentSuppressionEventLogs();
    }, [dialogData]);

    const fetchIncidentSuppressionEventLogs = () => {
        if(!!dialogData){
            dispatch(getIncidentSuppressionEventLogsInit(dialogData.suppressionId, usecase));
        }
    }

    const eventContent = (eventLog) => {
        return (
            <div style={{ marginBottom: "8px" }}>
                <div style={{ fontWeight: "bold" }}>{eventLog.logMessage}</div>
                <div>{parseDateTime(eventLog.logTimestamp)}</div>
            </div>
        );
    }

    return (
            <Dialog
            style={{ width: "50%"}}
            visible={!!dialogData}
            onHide={() => setDialogData(null)}
            header={!!dialogData &&`${dialogData.incidentSuppressionType} Suppression: ${dialogData.suppressionId}`}
            >
            <div className="detail-dialog-action-buttons">
                <Button
                className="p-button-text"
                icon="pi pi-refresh"
                onClick={fetchIncidentSuppressionEventLogs}
                style={{ textAlign: "right" }}
                />
            </div>
            <div>
            <Timeline value={incidentSuppressionEventLogs} align="alternate" content={eventContent}/>
            </div>
            </Dialog>
    );
}

export default IncidentSuppressionLogsDetailDialog;