import React, { useEffect, useState } from 'react';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';

import { getLdapId } from '../../../common/Utils/msalUtils';
import { getCpIncidentListInit, getCpIncidentListUpdate, postCpIncidentListInit } from '../redux/actions';
import { parseDateTime } from '../../../common/date-time';
import '../Cradlepoint.scss';


const IncidentDialog = ({showIncidentDialog, setShowIncidentDialog}) => {

    const incidentList = useSelector(state => state.cpUsage.cpIncidentList);
    const dispatch = useDispatch();

    const [selectedIncidentCount, setSelectedIncidentCount] = useState(0);
    const [tableFilters, setTableFilters] = useState(null);

    const fetchIncidentList = () => dispatch(getCpIncidentListInit(showIncidentDialog));

    useEffect(() => {
        initializeTableFilter();
    }, [])

    useEffect(() => {
        if(!!showIncidentDialog) {
            fetchIncidentList();
        }
        setSelectedIncidentCount(0);
    }, [showIncidentDialog]);

    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			wireless_number: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			mdn: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			group_name: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            store_id: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			plan_size: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			usage_details: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            incident_number: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			comments: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			}
		})
	}

    const handleSubmit = () => {
        const selectedIncident = incidentList.filter(incident => incident.isSelected);
        const requestPayload = {
            data: selectedIncident,
            watcher_id: getLdapId()
        }
        dispatch(postCpIncidentListInit(requestPayload, showIncidentDialog));
        setSelectedIncidentCount(0);
    }

    const onCheckboxSelectionChange = (rowdata, event) => {
        const tempIncidentList = []
        incidentList.forEach(incident => {
            if(incident.id===rowdata.id) {
                tempIncidentList.push({...incident, isSelected:event.checked})
                if(event.checked) {
                    setSelectedIncidentCount(count => count+1)
                } else {
                    setSelectedIncidentCount(count => count-1)
                }
            } else {
                tempIncidentList.push({...incident})
            }
        })
        dispatch(getCpIncidentListUpdate([...tempIncidentList]))
    }
    
    const selectRowTemplate = rowdata => (
        <Checkbox className={rowdata.enable?"enabled-checkbox":"disabled-checkbox"} disabled={!rowdata.enable}
            checked={rowdata.isSelected} onChange={event => onCheckboxSelectionChange(rowdata, event)}/>
    )

    const lastIncidentTemplate = rowdata => (
        <span>
            {!!rowdata.incident_number?`${rowdata.incident_number} - ${parseDateTime(rowdata.incident_created_date)}`:'-'}
        </span>
    )

    const footer = (
        <div className="cp-incident-dialog-footer">
            <div>
                Selected {selectedIncidentCount} / {incidentList.length}
            </div>
            <div>
                <Button label="Create Incident" onClick={handleSubmit} />
            </div>
        </div>
    )
    
    return (
        <Dialog className="cp-incident-dialog" header="Incident Summary" footer={footer} visible={!!showIncidentDialog} onHide={() => setShowIncidentDialog(false)}>
            <Button icon="pi pi-refresh" className="p-button-text cp-incident-dialog-refresh-btn" onClick={fetchIncidentList} />
            <DataTable value={incidentList} sortField="wireless_number" sortOrder="-1" filters={tableFilters} filterDisplay="menu" paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="Items per page" rows={5} rowsPerPageOptions={[5, 10, 20]} className="cp-incident-dialog-datatable">
                <Column field="isSelected" body={selectRowTemplate}/>
                <Column field="wireless_number" header="Wireless Number" sortable filter />
                <Column field="mdn" header="MDN Number" sortable filter />
                <Column field="group_name" header="Group Name" sortable filter />
                <Column field="store_id" header="Store ID" sortable filter />
                <Column field="plan_size" header="Plan Size" sortable filter />
                <Column field="usage_details" header="Usage Details" sortable filter />
                <Column field="incident_number" header="Last Incident" body={lastIncidentTemplate} sortable filter />
                <Column field="comments" header="Comment" sortable filter />
            </DataTable>
        </Dialog>
    )
}

export default IncidentDialog;