import { GET_ALL_DEVICES, 
    GET_DEVICE_STATUS
} from './actionTypes';

export const getAllDeviceInit = storeCodes => ({
    type: GET_ALL_DEVICES.INIT,
    storeCodes
});

export const getAllDeviceSuccess = deviceList => ({
    type: GET_ALL_DEVICES.SUCCESS,
    payload: deviceList
});

export const getAllDeviceFailure = errorMessage => ({
    type: GET_ALL_DEVICES.ERROR,
    payload: errorMessage
});

export const getDeviceStatusInit = deviceId => ({
    type: GET_DEVICE_STATUS.INIT,
    deviceId
});

export const getDeviceStatusSuccess = deviceItem => ({
    type: GET_DEVICE_STATUS.SUCCESS,
    payload: deviceItem
});

export const getDeviceStatusFailure = errorMessage => ({
    type: GET_DEVICE_STATUS.ERROR,
    payload: errorMessage
});