import { GET_STORE_COMPLETE_DETAIL } from './actionTypes';

export const getStoreCompleteDetailInit = assetIds => ({
    type: GET_STORE_COMPLETE_DETAIL.INIT,
    assetIds
})

export const getStoreCompleteDetailSuccess = storesDetail => ({
    type: GET_STORE_COMPLETE_DETAIL.SUCCESS,
    payload: storesDetail
})

export const getStoreCompleteDetailFailure = errorMessage => ({
    type: GET_STORE_COMPLETE_DETAIL.ERROR,
    payload: errorMessage
})