import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

import { useFormik } from "formik";
import validator from "validator";

import Moment from "moment";

import { addDateTime } from "../../../common/date-time";
import "./PingProbeLivePing.scss";
import {
  postPingprobeLivePingInit,
  getLivePingDetailsInit,
} from "../redux/actions";

const defaultState = {
  ipAddress: "",
};

const PingProbeLivePing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dateRange] = useState([
    addDateTime("day", -14),
    addDateTime("day", 0),
  ]);

  const fetchPingDetails = () => {
    const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
    const endDate = !!dateRange[1]
      ? Moment(dateRange[1]).format("yyyy-MM-DD")
      : Moment(dateRange[0]).format("yyyy-MM-DD");
    const offset = Moment(new Date()).utcOffset();
    dispatch(getLivePingDetailsInit(startDate, endDate, offset));
  };

  const { touched, handleChange, handleSubmit, errors } = useFormik({
    enableReinitialize: true,
    initialValues: defaultState,
    validate: (data) => {
      const validationErrors = {};

      // Validate ip Address

      if (!data.ipAddress || data.ipAddress.trim().length === 0) {
        validationErrors["ipAddress"] = "Please enter an IP address";
      } else {
        const ipAddresses = data.ipAddress
          .split(/[,\n]/)
          .map((ip) => ip.trim())
          .filter((ip) => ip !== ""); //Filter out empty strings
        for (const ip of ipAddresses) {
          if (!validator.isIP(ip)) {
            validationErrors["ipAddress"] = "Please enter valid IP Address";
            break;
          }
        }
      }
      return validationErrors;
    },
    onSubmit: (data) => {
      let payload = {
        ipAddress: data.ipAddress
          .split(/\r?\n/)
          .map((x) => x.trim())
          .filter((x) => x !== "")
          .join(","),
      };
      dispatch(postPingprobeLivePingInit(payload));
      history.push("/admin/ping-probe/live-ping/live-ping-details");
      fetchPingDetails();
      setTimeout(() => {
        fetchPingDetails();
      }, 10000);
    },
  });

  const errorClassName = (formField) =>
    !!touched[formField] && !!errors[formField] ? "p-invalid" : "";
  const redirectToLivePingDetails = () => {
    history.push("/admin/ping-probe/live-ping/live-ping-details");
  };
  return (
    <div className="pingprobe-liveping-container container-fluid">
      <form onSubmit={handleSubmit} className="container-fluid">
        <div className="row input-field">
          <div className="col-12 col-lg-2">
            <label>IP Address(s)</label>
          </div>
          <div className="col-12 col-lg-6">
            <InputTextarea
              id="ipAddress"
              name="ipAddress"
              className={errorClassName("ipAddress")}
              onChange={handleChange}
              rows={10}
              cols={30}
            />
            <br />
            <strong>
              Note: For multiple IP Address(s) use ',' or line break as separator
            </strong>
          </div>
        </div>
        <ul className="row p-error">
          {Object.keys(errors).map((key) =>
            !!touched[key] ? (
              <li key={key} className="col-12">
                {errors[key]}
              </li>
            ) : (
              ""
            )
          )}
        </ul>
        <div className="live-ping-btn">
          <Button
            label="Cancel"
            onClick={redirectToLivePingDetails}
            className="p-button-danger"
          />
          <Button label="Submit" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default PingProbeLivePing;
