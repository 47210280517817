import React, { useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';

import CPDeploymentForm from './CPDeploymentForm';
import './NcmUpdateManage.scss';


const NcmUpdateManage = () => {

    const [requestType, setRequestType] = useState("new-deployment");

    return (
        <div className="ncm-update-manage-container">
            <div className="request-type-container">
                <label>Request Type</label>
                <div className="radio-btn-containers">
                    <div className="radio-btn-field">
                        <RadioButton id="new-deployment" value="new-deployment" onChange={e=>setRequestType(e.value)}
                            checked={requestType==="new-deployment"} />
                        <label htmlFor="new-deployment">New Deployment</label>
                    </div>
                    <div className="radio-btn-field">
                        <RadioButton id="type-change" value="type-change" onChange={e=>setRequestType(e.value)}
                            checked={requestType==="type-change"} />
                        <label htmlFor="type-change">Type Change</label>
                    </div>
                    <div className="radio-btn-field">
                        <RadioButton id="device-replacement" value="device-replacement" onChange={e=>setRequestType(e.value)}
                            checked={requestType==="device-replacement"} />    
                        <label htmlFor="device-replacement">Device Replacement</label>
                    </div>
                </div>
            </div>
            <CPDeploymentForm requestType={requestType}/>
        </div>
        
    )

}

export default NcmUpdateManage;