import { GET_CONFIG_DIVISION_DETAILS, GET_EXCLUSION_RULE } from './actionTypes';

const initialState = {
    divisionDetails: {assetTypes: null, divisionStores: null},
    divisionDetailsErrorMessage: null,
    exclusionRule: { excludedStoreCodes: [], incidentExcludedModels: [], emailEnabledModels: [] },
    exclusionRuleErrorMessage: null
}

const incidentConfigReducer = (state=initialState, action={type: "", payload: null}) => {
    switch(action.type) {
        case GET_CONFIG_DIVISION_DETAILS.SUCCESS: 
            return {
                ...state, divisionDetails: action.payload, divisionDetailsErrorMessage: null
            }
        case GET_CONFIG_DIVISION_DETAILS.ERROR:
            return {
                ...state,
                divisionDetails:{assetTypes: null, divisionStores: null},
                divisionDetailsErrorMessage: action.payload
            }
        case GET_EXCLUSION_RULE.SUCCESS:
            return {
                ...state, exclusionRule: action.payload, exclusionRuleErrorMessage: null 
            }
        case GET_EXCLUSION_RULE.ERROR:
            return {
                ...state,
                exclusionRule: { excludedStoreCodes: [], incidentExcludedModels: [], emailEnabledModels: [] },
                exclusionRuleErrorMessage: action.payload
            }
        default: 
            return {...state}
    }
}

export default incidentConfigReducer;