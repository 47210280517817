export const GET_ALL_SNOW_APPS = {
    INIT: "GET_ALL_SNOW_APPS_INIT",
    SUCCESS: "GET_ALL_SNOW_APPS_SUCCESS",
    ERROR: "GET_ALL_SNOW_APPS"
}

export const GET_ALL_SNOW_WRAPPER_LOGS = {
    INIT: "GET_ALL_SNOW_WRAPPER_LOGS_INIT",
    SUCCESS: "GET_ALL_SNOW_WRAPPER_LOGS_SUCCESS",
    ERROR: "GET_ALL_SNOW_WRAPPER_LOGS"
}