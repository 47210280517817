const initialState = {
    show: false,
    message: null
}

export default function toastReducer(state = initialState, action={type:""}) {
    switch (action.type) {
        case 'SHOW':
            return {
                ...state,
                show: true,
                message: action.payload
            }
        case 'HIDE':
            return {
                ...state,
                show: false,
                message: null
            }
        default:
            return {
                ...state
            }
    }
}