import axios from 'axios';
import { ApiPath } from '../../../common/api-path/ApiPath';

export default class EventsService{
    getEvents = async deviceId => {
        const idType = isNaN(deviceId) ? 'devicecode/' : 'deviceid/';
        const response = await axios.get(`${ApiPath.baseUrl}/eventmessage/`+idType+deviceId, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}