export const GET_DEVICE_TYPE = {
    INIT: "GET_DEVICE_TYPE_INIT",
    SUCCESS: "GET_DEVICE_TYPE_SUCCESS",
    ERROR: "GET_DEVICE_TYPE_ERROR",
};

export const GET_DEVICE_ONBOARDING_LOGS = {
    INIT: "GET_DEVICE_ONBOARDING_LOGS_INIT",
    SUCCESS: "GET_DEVICE_ONBOARDING_LOGS_SUCCESS",
    ERROR: "GET_DEVICE_ONBOARDING_LOGS_ERROR",
};
