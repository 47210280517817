import axios from 'axios';
import { ApiPath } from '../../../common/api-path/ApiPath';

export default class AssetService {

    async getAssetById(assetIds) {
        const response = await axios.post(`${ApiPath.baseUrl}/store/completedetails`, assetIds, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}