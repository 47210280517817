import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Switch, Redirect } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";

import ShellyReplacementReplace from "./shelly-replacement-replace/ShellyReplacementReplace";
import ShellyReplacementHistory from "./shelly-replacement-history/ShellyReplacementHistory";

const ShellyReplacement = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (pathname === "/admin/device-management/shelly-replacement/replace") {
      setActiveIndex(0);
    } else {
      setActiveIndex(1);
    }
  }, [pathname]);

  const handleTabChange = (e) => {
    if (e.index === 0) {
      setActiveIndex(0);
      history.push("/admin/device-management/shelly-replacement/replace");
    } else {
      setActiveIndex(1);
      history.push("/admin/device-management/shelly-replacement/history");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <p className="page-label" style={{ padding: "24px 24px 0 24px" }}>
        Device Management
        <i className="pi pi-angle-right" />
        Shelly Replacement
      </p>

      <TabView
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
        className="container-fluid"
      >
        <TabPanel header="Replace">
          <ShellyReplacementReplace />
        </TabPanel>

        <TabPanel header="History">
          <ShellyReplacementHistory />
        </TabPanel>
      </TabView>

      <Switch>
        <Redirect
          exact
          path="/admin/device-management/shelly-replacement"
          to="/admin/device-management/shelly-replacement/replace"
        />
      </Switch>
    </div>
  );
};

export default ShellyReplacement;