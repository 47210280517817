import { all, takeLatest } from 'redux-saga/effects';

import { GET_STORE_COMPLETE_DETAIL } from '../components/asset/redux/actionTypes';
import { GET_ALL_DEVICES, GET_DEVICE_STATUS } from '../components/device-management/redux/actionTypes';
import { GET_DEVICE_TYPE, GET_DEVICE_ONBOARDING_LOGS } from '../components/device-onboarding/redux/actionTypes';
import { GET_ALL_EVENTS } from '../components/device-management/events/redux/actionsTypes';
import { GET_ALL_MESSAGES } from '../components/device-management/messages/redux/actionTypes';
import { GET_ALL_PENDING_JOBS } from '../components/device-management/pending-jobs/redux/actionsTypes';
import {
    GET_CP_INCIDENT_LIST, GET_OUTPUT_FILE_LIST, GET_STORE_ID_UPDATE_EVENT_LIST, POST_CP_INCIDENT_LIST,
    POST_OUTPUT_FILE, GET_ETHERNET_CONFIGURATION, POST_NCM_UPDATE, GET_CP_GROUP_LIST, GET_CP_GROUP_NAME,
} from '../components/cp-usage-tracker/redux/actionTypes';
import { GET_ALL_STORE_LIST } from '../components/search-store/redux/actionTypes';
import { GET_CONFIG_DIVISION_DETAILS, GET_EXCLUSION_RULE, POST_EXCLUSION_RULE } from '../components/incident-config/redux/actionTypes';
import { GET_USER_PROFILE } from '../common/components/profile/profileAction';
import { GET_SHELLY_DEVICE_LIST, GET_COMMAND_HISTORY_LIST, SEND_COMMAND } from '../components/command-and-control/redux/actionTypes';
import { GET_ALL_SNOW_APPS, GET_ALL_SNOW_WRAPPER_LOGS } from '../components/snow-wrapper/redux/actionTypes';
import { 
    GET_RPA_USECASES,
    GET_RPA_SUMMARY_LOGS,
    GET_RPA_DETAIL_BY_CTASK_ID
} from '../components/rpa/redux/actionTypes';
import { 
    GET_MERAKI_FIREWALL_AUTOMATION_LOGS,
    POST_MODIFY_MERAKI_FIREWALL_SCHEDULE,
    CANCEL_MERAKI_FIREWALL_SCHEDULE,
 } from '../components/network-automation/redux/actionTypes';
import {
    GET_SHELLY_REPLACEMENT_EXE_URL,
    GET_FAULTY_SHELLY_DEVICE_DETAIL,
    POST_FAULTY_SHELLY_DEVICE_DETAIL,
    GET_SHELLY_DEVICE_HISTORY
} from '../components/shelly-replacement/redux/actionTypes';
import { GET_ALL_DIVISION_NAMES } from '../common/components/division-dropdown/redux/actionTypes';
import {
    GET_CC_INCIDENT_USECASES,
    GET_CC_INCIDENT_USECASES_LOGS,
    GET_CC_INCIDENT_USECASES_DETAILS,
    GET_CC_RIT_USECASES_LOGS,
    GET_CC_RIT_USECASES_DETAILS,
} from '../components/command-center-automation/redux/actionTypes';
import {
    GET_SERVICE_MAIN_INCIDENT_USECASES,
    GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS,
    GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS,
} from "../components/service-main/redux/actionTypes";
import {
    GET_DISTINCT_PINGPROBE_ASSET_TYPE, GET_PINGPROBE_INVENTORY, GET_PINGPROBE_INCIDENTS_BY_ASSET,
    POST_PINGPROBE_SUPPRESSION_RULE, GET_ALL_PINGPROBE_SUPPRESSION_RULES, GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE,
    DELETE_PINGPROBE_SUPPRESSION_RULE, GET_PINGPROBE_ASSET_LAST_PINGTIME,POST_PINGPROBE_LIVE_PING,GET_PING_DETAILS,
} from '../components/pingprobe/redux/actionTypes';
import { GET_EPE_ERROR_CODES, GET_EPE_LOGS } from '../components/epe/redux/actionTypes';
import { GET_SHELLY_FIRMWARE_GROUP_DETAILS,
    POST_SHELLYFIRMWARE_GROUP,
    POST_MODIFY_SHELLYFIRMWARE_GROUP,
    POST_SHELLYFIRMWARE_CAMPAIGN,
    GET_SHELLY_FIRMWARE_CAMPAIGNS,
    GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME,
    GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID,
    GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS,
    GET_OTA_TYPES,
    GET_SHELLY_PLUG_GROUPS,
    GET_SHELLY_PLUG_FIRMWARE_DETAILS,
    CANCEL_SHELLY_FIRMWARE_CAMPAIGN,
    CANCEL_SHELLY_FIRMWARE_GROUP,
    MODIFY_SHELLY_FIRMWARE_CAMPAIGN
} from "../components/shelly-firmware/redux/actionTypes"

import { GET_GA_REMOTE_COMMAND_DIVISION_DETAILS, 
    POST_CREATE_JOB, 
    GET_JOB_DETAILS, 
    GET_SERVER_DETAILS_BY_JOB_ID,
    CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE,
    
} from "../components/ares/redux/actionTypes"

import { POST_INCIDENT_SUPPRESSION_REQUEST, 
    GET_INCIDENT_SUPPRESSION_LOGS,
    GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET,
    GET_INCIDENT_SUPPRESSION_EVENT_LOGS,
} from '../components/incident-suppression/redux/actionTypes';

import { loadStoresDetailById } from './assetSaga';
import { loadAllDevices, loadDeviceStatus } from './deviceManagementSaga';
import { loadAllEvents } from './eventSaga';
import { loadAllMessages } from './messageSaga';
import { loadAllPendingJobs } from './pendingJobsSaga';
import { loadDeviceTypes, loadDeviceOnboardingLogs } from './deviceOnboardingSaga';
import {
    loadCpIncidentList, loadOutputFileList, loadStoreIdUpdateEventList,
    postCpIncidentList, postOutputFile, loadEthernetConfig, postNcmUpdateManage,
    loadCpGroupList, loadCpGroupName
} from './cradlepointSaga';
import { loadAllStoreList } from './searchStoreInputSaga';
import { loadConfigDivisionDetails, loadExclusionRule, postExclusionRule } from './incidentConfigSaga';
import { loadUserProfile } from './profileSaga';
import { loadShellyDeviceList, loadCommandHistoryList, loadSendCommand } from './commandAndControlSaga';
import { loadAllSnowApps, loadAllSnowWrapperLogs } from './snowWrapperSaga';
import {
    loadRpaUsecases,
    loadRpaSummaryLogs,
    loadRpaDetailByCtaskId } from './rpaSaga';
import { 
    loadMerakiFirewallAutomationLogs,
    saveModifiedSchedule,
    cancelMerakiFirewallSchedule,
} from './merakiFirewallAutomationSaga';
import {
    loadShellyReplacementExeUrl,
    loadFaultyShellyDeviceDetail,
    postFaultyShellyDeviceDetail,
    loadShellyDeviceHistory
} from './shellyReplacementSaga';
import { loadAllDivisionNames } from './divisionDropdownSaga';
import { 
    loadCCIncidentUsecases,
    loadCCIncidentUsecasesLogs,
    loadCCIncidentUsecasesDetails,
    loadCCRITUsecasesLogs,
    loadCCRITUsecasesDetails,
} from './commandCenterSaga';
import {
    loadServiceMainIncidentUsecases,
    loadServiceMainIncidentUsecasesLogs,
    loadServiceMainIncidentUsecasesDetails,
} from "./serviceMainSaga"
import {
    loadDistinctPingprobeAssetType,
    loadPingprobeInventory,
    loadPingprobeIncidentByAsset,
    loadPingprobeAssetLastPingTime,
    savePingprobeSuppressionRule,
    loadAllPingprobeSuppressionRules,
    loadPingprobeAssetsBySuppressionRule,
    removePingprobeSuppressionRule,
    postCreatePingprobeLivePing,
    loadPingDetails,
} from './pingprobeSaga';
import { loadEpeErrorCodes, loadEpeLogs } from './epeLogsSaga';
import {
    loadShellyFirmwareGroupDetails, 
    saveCreatedGroup,
    saveModifiedGroup,
    saveCreatedCampaign,
    loadShellyFirmwareCampaigns,
    loadCampaignEventLogsByCampaignName,
    loadShellyFirmwareCampaignDetails,
    loadCampaignDetailsEventLogsByDeviceId,
    loadAllOtaTypes,
    loadShellyPlugGroups, 
    loadShellyPlugFirmwareDetails,
    cancelShellyFirmwareGroup,
    cancelShellyFirmwareCampaignName,
    modifyShellyFirmwareCampaignDetails
     } from './ShellyFirmwareSaga';
import { loadGaRemoteCommandDivisionDetails,
     postCreatedJob, 
     loadJobDetails, 
     loadServerDetailsByJobId,
     cancelGrafanaAgentScheduledJob} from "./gaRemoteCommandSaga";
import { saveIncidentSuppressionRequest, 
    loadIncidentSuppressionLogs,
    loadIncidentSuppressionLogsBySnowTicket,
    loadIncidentSuppressionEventLogs } from './incidentSuppressionSaga';

/**
 * Based on Action type, respective worker saga will be called
 */
function* watcherSaga() {
    yield all([
        takeLatest(GET_STORE_COMPLETE_DETAIL.INIT, loadStoresDetailById),
        takeLatest(GET_ALL_DEVICES.INIT, loadAllDevices),
        takeLatest(GET_DEVICE_STATUS.INIT, loadDeviceStatus),
        takeLatest(GET_DEVICE_TYPE.INIT, loadDeviceTypes),
        takeLatest(GET_DEVICE_ONBOARDING_LOGS.INIT, loadDeviceOnboardingLogs),
        takeLatest(GET_ALL_EVENTS.INIT, loadAllEvents),     
        takeLatest(GET_ALL_MESSAGES.INIT, loadAllMessages),
        takeLatest(GET_ALL_PENDING_JOBS.INIT, loadAllPendingJobs),
        takeLatest(POST_OUTPUT_FILE.INIT, postOutputFile),
        takeLatest(GET_OUTPUT_FILE_LIST.INIT, loadOutputFileList),
        takeLatest(GET_CP_INCIDENT_LIST.INIT, loadCpIncidentList),
        takeLatest(POST_CP_INCIDENT_LIST.INIT, postCpIncidentList),
        takeLatest(GET_STORE_ID_UPDATE_EVENT_LIST.INIT, loadStoreIdUpdateEventList),
        takeLatest(GET_ALL_STORE_LIST.INIT, loadAllStoreList),
        takeLatest(GET_CONFIG_DIVISION_DETAILS.INIT, loadConfigDivisionDetails),
        takeLatest(GET_EXCLUSION_RULE.INIT, loadExclusionRule),
        takeLatest(POST_EXCLUSION_RULE.INIT, postExclusionRule),
        takeLatest(GET_USER_PROFILE.INIT, loadUserProfile),
        takeLatest(GET_SHELLY_DEVICE_LIST.INIT, loadShellyDeviceList),
        takeLatest(GET_COMMAND_HISTORY_LIST.INIT, loadCommandHistoryList),
        takeLatest(SEND_COMMAND.INIT, loadSendCommand),
        takeLatest(GET_ETHERNET_CONFIGURATION.INIT, loadEthernetConfig),
        takeLatest(POST_NCM_UPDATE.INIT, postNcmUpdateManage),
        takeLatest(GET_CP_GROUP_LIST.INIT, loadCpGroupList),
        takeLatest(GET_CP_GROUP_NAME.INIT, loadCpGroupName),
        takeLatest(GET_ALL_SNOW_APPS.INIT, loadAllSnowApps),
        takeLatest(GET_ALL_SNOW_WRAPPER_LOGS.INIT, loadAllSnowWrapperLogs),
        takeLatest(GET_RPA_USECASES.INIT, loadRpaUsecases),
        takeLatest(GET_RPA_SUMMARY_LOGS.INIT, loadRpaSummaryLogs),
        takeLatest(GET_RPA_DETAIL_BY_CTASK_ID.INIT, loadRpaDetailByCtaskId),
        takeLatest(GET_SHELLY_REPLACEMENT_EXE_URL.INIT, loadShellyReplacementExeUrl),
        takeLatest(GET_FAULTY_SHELLY_DEVICE_DETAIL.INIT, loadFaultyShellyDeviceDetail),
        takeLatest(POST_FAULTY_SHELLY_DEVICE_DETAIL.INIT, postFaultyShellyDeviceDetail),
        takeLatest(GET_SHELLY_DEVICE_HISTORY.INIT, loadShellyDeviceHistory),
        takeLatest(GET_ALL_DIVISION_NAMES.INIT, loadAllDivisionNames),
        takeLatest(GET_CC_INCIDENT_USECASES.INIT, loadCCIncidentUsecases),
        takeLatest(GET_CC_INCIDENT_USECASES_LOGS.INIT, loadCCIncidentUsecasesLogs),
        takeLatest(GET_CC_INCIDENT_USECASES_DETAILS.INIT, loadCCIncidentUsecasesDetails),
        takeLatest(GET_CC_RIT_USECASES_LOGS.INIT, loadCCRITUsecasesLogs),
        takeLatest(GET_CC_RIT_USECASES_DETAILS.INIT, loadCCRITUsecasesDetails),
        takeLatest(GET_MERAKI_FIREWALL_AUTOMATION_LOGS.INIT, loadMerakiFirewallAutomationLogs),
        takeLatest(GET_DISTINCT_PINGPROBE_ASSET_TYPE.INIT, loadDistinctPingprobeAssetType),
        takeLatest(GET_PINGPROBE_INVENTORY.INIT, loadPingprobeInventory),
        takeLatest(GET_PINGPROBE_INCIDENTS_BY_ASSET.INIT, loadPingprobeIncidentByAsset),
        takeLatest(GET_PINGPROBE_ASSET_LAST_PINGTIME.INIT, loadPingprobeAssetLastPingTime),
        takeLatest(POST_PINGPROBE_SUPPRESSION_RULE.INIT, savePingprobeSuppressionRule),
        takeLatest(POST_PINGPROBE_LIVE_PING.INIT, postCreatePingprobeLivePing),
        takeLatest(GET_PING_DETAILS.INIT, loadPingDetails),
        takeLatest(GET_ALL_PINGPROBE_SUPPRESSION_RULES.INIT, loadAllPingprobeSuppressionRules),
        takeLatest(GET_PINGPROBE_ASSETS_BY_SUPPRESSION_RULE.INIT, loadPingprobeAssetsBySuppressionRule),
        takeLatest(DELETE_PINGPROBE_SUPPRESSION_RULE.INIT, removePingprobeSuppressionRule),
        takeLatest(GET_EPE_ERROR_CODES.INIT, loadEpeErrorCodes),
        takeLatest(GET_EPE_LOGS.INIT, loadEpeLogs),
        takeLatest(POST_MODIFY_MERAKI_FIREWALL_SCHEDULE.INIT,saveModifiedSchedule),
        takeLatest(CANCEL_MERAKI_FIREWALL_SCHEDULE.INIT,cancelMerakiFirewallSchedule),
        takeLatest(GET_SERVICE_MAIN_INCIDENT_USECASES.INIT, loadServiceMainIncidentUsecases),
        takeLatest(GET_SERVICE_MAIN_INCIDENT_USECASES_LOGS.INIT, loadServiceMainIncidentUsecasesLogs),
        takeLatest(GET_SERVICE_MAIN_INCIDENT_USECASES_DETAILS.INIT, loadServiceMainIncidentUsecasesDetails),
        takeLatest(GET_SHELLY_FIRMWARE_GROUP_DETAILS.INIT, loadShellyFirmwareGroupDetails),
        takeLatest(POST_SHELLYFIRMWARE_GROUP.INIT, saveCreatedGroup),
        takeLatest(POST_MODIFY_SHELLYFIRMWARE_GROUP.INIT, saveModifiedGroup),
        takeLatest(POST_SHELLYFIRMWARE_CAMPAIGN.INIT, saveCreatedCampaign),
        takeLatest(GET_SHELLY_FIRMWARE_CAMPAIGNS.INIT, loadShellyFirmwareCampaigns),
        takeLatest(GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME.INIT, loadCampaignEventLogsByCampaignName),
        takeLatest(GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS.INIT, loadShellyFirmwareCampaignDetails),
        takeLatest(GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID.INIT, loadCampaignDetailsEventLogsByDeviceId),
        takeLatest(GET_OTA_TYPES.INIT, loadAllOtaTypes),
        takeLatest(GET_SHELLY_PLUG_GROUPS.INIT, loadShellyPlugGroups),
        takeLatest(GET_SHELLY_PLUG_FIRMWARE_DETAILS.INIT, loadShellyPlugFirmwareDetails),
        takeLatest(CANCEL_SHELLY_FIRMWARE_CAMPAIGN.INIT, cancelShellyFirmwareCampaignName),
        takeLatest(CANCEL_SHELLY_FIRMWARE_GROUP.INIT,cancelShellyFirmwareGroup),
        takeLatest(MODIFY_SHELLY_FIRMWARE_CAMPAIGN.INIT, modifyShellyFirmwareCampaignDetails),
        takeLatest(GET_GA_REMOTE_COMMAND_DIVISION_DETAILS.INIT, loadGaRemoteCommandDivisionDetails),
        takeLatest(POST_CREATE_JOB.INIT, postCreatedJob),  
        takeLatest(GET_JOB_DETAILS.INIT, loadJobDetails),
        takeLatest(GET_SERVER_DETAILS_BY_JOB_ID.INIT, loadServerDetailsByJobId),
        takeLatest(CANCEL_GRAFANA_AGENT_REMOTE_COMMAND_SCHEDULE.INIT, cancelGrafanaAgentScheduledJob),
        takeLatest(POST_INCIDENT_SUPPRESSION_REQUEST.INIT, saveIncidentSuppressionRequest),
        takeLatest(GET_INCIDENT_SUPPRESSION_LOGS.INIT, loadIncidentSuppressionLogs),
        takeLatest(GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET.INIT, loadIncidentSuppressionLogsBySnowTicket),
        takeLatest(GET_INCIDENT_SUPPRESSION_EVENT_LOGS.INIT, loadIncidentSuppressionEventLogs),

    ])
}

export default watcherSaga;