import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import ShellyFirmwareCampaignDetailDialog from "./ShellyFirmwareCampaignDetailDialog";
import { getShellyFirmwareCampaignDetailsInit } from "../redux/action";

import "./ShellyFirmwareCampaignDetails.scss";

const ShellyFirmwareCampaignDevices = ({ campaignName }) => {
  const [tableFilters, setTableFilters] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const dispatch = useDispatch();
  const shellyPlugFirmwareCampaignDetails = useSelector(
    (state) => state.shellyFirmware.shellyFirmwareCampaignDetails
  );

  useEffect(() => {
    fetchShellyFirmwareCampaignDetails();
    initializeTableFilter();
  }, []);

  const fetchShellyFirmwareCampaignDetails = () => {
    dispatch(getShellyFirmwareCampaignDetailsInit(campaignName));
  };

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      campaignName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      deviceName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      deviceCode: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      store: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      deploymentStatus: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      targetOs: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      retryCount: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const detailTemplate = ({ id }) => (
    <i className="pi pi-eye" onClick={() => setDialogData({ id })} />
  );

  return (
    <div className="shelly-firmware-campaign-devices">
      <ShellyFirmwareCampaignDetailDialog
        dialogData={dialogData}
        setDialogData={setDialogData}
      />
      <div>
        <Button
          icon="pi pi-refresh"
          className="p-button-text"
          onClick={fetchShellyFirmwareCampaignDetails}
          style={{ float: "right", zIndex: 100 }}
        />
      </div>
      <DataTable
        value={shellyPlugFirmwareCampaignDetails}
        className="shelly-firmware-campaign-devices-table"
        filters={tableFilters}
        filterDisplay="menu"
        emptyMessage="No Devices found"
      >
        <Column header="Campaign Name" field="campaignName" sortable filter />
        <Column header="Device Name" field="deviceName" sortable filter />
        <Column header="Device Code" field="deviceCode" filter />
        <Column header="Store" field="store" sortable filter />
        <Column header="Deployment Status" field="deploymentStatus" filter />
        <Column header="Target OS" field="targetOs" filter />
        <Column header="Retry Count" field="retryCount" sortable filter />
        <Column header="Event Details" body={detailTemplate} />
      </DataTable>
    </div>
  );
};

export default ShellyFirmwareCampaignDevices;
