export const GET_SHELLY_REPLACEMENT_EXE_URL = {
    INIT: "GET_SHELLY_REPLACEMENT_EXE_URL_INIT",
    SUCCESS: "GET_SHELLY_REPLACEMENT_EXE_URL_SUCCESS",
    ERROR: "GET_SHELLY_REPLACEMENT_EXE_URL_ERROR"
}

export const GET_FAULTY_SHELLY_DEVICE_DETAIL = {
    INIT: "GET_FAULTY_SHELLY_DEVICE_DETAIL_INIT",
    SUCCESS: "GET_FAULTY_SHELLY_DEVICE_DETAIL_SUCCESS",
    ERROR: "GET_FAULTY_SHELLY_DEVICE_DETAIL_ERROR"
}

export const POST_FAULTY_SHELLY_DEVICE_DETAIL = {
    INIT: "POST_FAULTY_SHELLY_DEVICE_DETAIL_INIT",
    SUCCESS: "POST_FAULTY_SHELLY_DEVICE_DETAIL_SUCCESS",
    ERROR: "POST_FAULTY_SHELLY_DEVICE_DETAIL_ERROR"
}

export const GET_SHELLY_DEVICE_HISTORY = {
    INIT: "GET_SHELLY_DEVICE_HISTORY_INIT",
    SUCCESS: "GET_SHELLY_DEVICE_HISTORY_SUCCESS",
    ERROR: "GET_SHELLY_DEVICE_HISTORY_ERROR"
}