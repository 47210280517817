import axios from 'axios';
import moment from 'moment';

import { ApiPath } from '../../common/api-path/ApiPath';


export default class CradlepointService {

    async usageTracking(data) {
        const response = await axios.post(`${ApiPath.baseUrl}/cradlepointBilling/usageTracking`, data, { 
            timeout: 120000, timeoutErrorMessage: "request timeout",
            headers : {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
    
    async getOutputFileList() {
        const requestConfig = {
            params: {
                fromDate: moment().utc().subtract(3, "months").format("yyyy-MM-DD"),
                toDate: moment().utc().add(1, "days").format("yyyy-MM-DD"),
            },
            timeout: 120000, timeoutErrorMessage: "request timeout"
        }
        const response = await axios.get(`${ApiPath.baseUrl}/cradlepointBilling/usageTracking`, requestConfig);
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getCpIncidentList(reportUniqueId) {
        const requestConfig = {
            params: { reportId: reportUniqueId },
            timeout: 120000, timeoutErrorMessage: "request timeout"
        }
        const response = await axios.get(`${ApiPath.baseUrl}/cradlepointBilling/usageExceeded`, requestConfig);
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async postCpIncidentList(cpIncidentList) {
        const response = await axios.post(`${ApiPath.baseUrl}/cradlepointBilling/usageExceeded/createIncident`,
            cpIncidentList, { timeout: 120000, timeoutErrorMessage: "request timeout" })
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getStoreIdUpdateEventList(storeCodes) {
        const response = await axios.post(`${ApiPath.baseUrl}/getCPStoreUpdateEvents`, storeCodes, {
            timeout: 120000, timeoutErrorMessage: "request timeout"
        });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getEthernetConfiguration(storeCode, wan) {
        const response = await axios.get(`${ApiPath.baseUrl}/cradlepoint/static-ip-profile`, {
            params: {
                storeCode,
                wan
            },
            timeout: 120000, timeoutErrorMessage: "request timeout"
        });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async postNcmUpdateManage(cpDetails) {
        const response = await axios.post(`${ApiPath.baseUrl}/cradlepoint/management`,
            cpDetails, { timeout: 120000, timeoutErrorMessage: "request timeout" })
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getCpGroupList() {
        const response = await axios.get(`${ApiPath.baseUrl}/cradlepoint/groups`, {
            timeout: 120000, timeoutErrorMessage: "request timeout"
        });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getCpGroupName(macId, deviceType) {
        const response = await axios.get(`${ApiPath.baseUrl}/cradlepoint/group`, {
            params: {
                macId, deviceType
            },
            timeout: 120000, timeoutErrorMessage: "request timeout"
        });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}