/**
 * To check if two arrays are equal or not
 * @param {Array} array1 
 * @param {Array} array2 
 * @returns Boolean
 */
export const compareArrays = (array1, array2) => {
    if(array1.length===array2.length) {
        array1.sort();
        array2.sort();
        for(let i in array1) {
            if(array1[i]!==array2[i]) {
                return false;
            }
        }
        return true;
    }
    return false;
}