import React from "react";
import loader from "../../../images/loader.svg"
import "./Loader.scss"

function Loader() {

    return (
        <div className="loader">
            <img src={loader} alt="loader" />
        </div>
    );
}

export default Loader;