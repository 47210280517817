import { 
    GET_MERAKI_FIREWALL_AUTOMATION_LOGS,
    POST_MODIFY_MERAKI_FIREWALL_SCHEDULE,
    CANCEL_MERAKI_FIREWALL_SCHEDULE,

} 
from "./actionTypes";

export const getMerakiFirewallAutomationLogsInit = (startDate, endDate, offset) => ({
    type: GET_MERAKI_FIREWALL_AUTOMATION_LOGS.INIT,
    startDate, endDate, offset
});

export const getMerakiFirewallAutomationLogsSuccess = automationLogs => ({
    type: GET_MERAKI_FIREWALL_AUTOMATION_LOGS.SUCCESS,
    payload: automationLogs
});

export const getMerakiFirewallAutomationLogsFailure = errorMessage => ({
    type: GET_MERAKI_FIREWALL_AUTOMATION_LOGS.ERROR,
    payload: errorMessage
});
 
export const postModifyMerakiFirewallScheduleInit = (modifiedSchedule) => ({
    type: POST_MODIFY_MERAKI_FIREWALL_SCHEDULE.INIT,
    modifiedSchedule
});

export const postModifyMerakiFirewallScheduleSuccess = (response) => ({
    type: POST_MODIFY_MERAKI_FIREWALL_SCHEDULE.SUCCESS,
    payload: response
});

export const postModifyMerakiFirewallScheduleError = errorMessage => ({
    type: POST_MODIFY_MERAKI_FIREWALL_SCHEDULE.ERROR,
    payload: errorMessage
})
export const cancelMerakiFirewallScheduleInit = (cancelSchedule) => ({
    type: CANCEL_MERAKI_FIREWALL_SCHEDULE.INIT,
    cancelSchedule
});

export const cancelMerakiFirewallScheduleSuccess = (response) => ({
    type: CANCEL_MERAKI_FIREWALL_SCHEDULE.SUCCESS,
    payload: response

});

export const cancelMerakiFirewallScheduleFailure = (errorMessage) => ({
    type: CANCEL_MERAKI_FIREWALL_SCHEDULE.ERROR,
    payload: errorMessage,
});

