import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import EventLogsTable from "./EventLogsTable";
import EventLogsTimeline from "./EventLogsTimeline";
import "./EpeLogs.scss";

const EpeLogsDetailDialog = ({ dialogData, setDialogData }) => {
  const [showEventLogsTable, setShowEventLogsTable] = useState(true);

  useEffect(() => {
    if (!!dialogData) {
      setShowEventLogsTable(true);
    }
  }, [dialogData]);

  return (
    <Dialog
      className="epe-logs-detail-dialog"
      header={
        !!dialogData && dialogData.eventId 
          ? `Event ID : ${dialogData.eventId}`
          : `Sub Event ID : ${!!dialogData && dialogData.sub_event_id}`
      }
      visible={!!dialogData}
      onHide={() => setDialogData(null)}
    >
      {showEventLogsTable ? (
        <div>
          <Button
            icon="pi pi-clock"
            className="p-button-text"
            label=" Click to view timeline"
            onClick={() => setShowEventLogsTable(false)}
            style={{ paddingLeft: "0px" }}
          />
          <EventLogsTable eventDetails={!!dialogData && dialogData} />
        </div>
      ) : (
        <div>
          <Button
            icon="pi pi-table"
            className="p-button-text"
            label=" Click to view table"
            onClick={() => setShowEventLogsTable(true)}
            style={{ paddingLeft: "0px" }}
          />
          <EventLogsTimeline
            eventDetails={!!dialogData && dialogData.eventLogs}
          />
        </div>
      )}
    </Dialog>
  );
};

export default EpeLogsDetailDialog;
