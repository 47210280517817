import { call, put } from 'redux-saga/effects';

import {
    getOutputFileListSuccess, getOutputFileListFailure,
    postOutputFileSuccess, postOutputFileFailure,
    getCpIncidentListSuccess, getCpIncidentListFailure,
    postCpIncidentListSuccess, postCpIncidentListFailure,
    getStoreIdUpdateEventListSuccess, getStoreIdUpdateEventListFailure,
    getEthernetConfigurationSuccess, getEthernetConfigurationFailue,
    postNcmUpdateSuccess, postNcmUpdateFailure,
    getCpGroupListSuccess, getCpGroupListFailure,
    getCpGroupNameSuccess, getCpGroupNameFailure,
} from '../components/cp-usage-tracker/redux/actions';

import CradlepointService from '../components/cp-usage-tracker/CradlepointService';


const cradlepointService = new CradlepointService();

/**
 * Worker saga for uploading CP Billing Usage
 * @param {redux-action} action 
 */
function* postOutputFile(action) {
    try {
        yield call(cradlepointService.usageTracking, action.formData);
        yield put(postOutputFileSuccess());
        try {
            const outputFileList = yield call(cradlepointService.getOutputFileList);
            yield put(getOutputFileListSuccess(outputFileList.data));
        } catch(error) {
            yield put(getOutputFileListFailure(error.toString()));
        }
    } catch(error) {
        yield put(postOutputFileFailure(error.toString()));
    }
}

/**
 * Worker saga for loading list of CP Output file
 */
function* loadOutputFileList() {
    try {
        const outputFileList = yield call(cradlepointService.getOutputFileList);
        yield put(getOutputFileListSuccess(outputFileList.data));
    } catch(error) {
        yield put(getOutputFileListFailure(error.toString()));
    }
}

/**
 * Worker saga for loading list of CP Incident
 * @param {redux-action} action 
 */
function* loadCpIncidentList(action) {
    try {
        const cpIncidentList = yield call(cradlepointService.getCpIncidentList, action.reportUniqueId);
        yield put(getCpIncidentListSuccess(cpIncidentList.data));
    } catch(error) {
        yield put(getCpIncidentListFailure(error.toString()));
    }
}

/**
 * Worker saga for Creating Incident
 * @param {redux-action} action 
 */
function* postCpIncidentList(action) {
    try {
        yield call(cradlepointService.postCpIncidentList, action.cpIncidentList);
        yield put(postCpIncidentListSuccess());
        try {
            const cpIncidentList = yield call(cradlepointService.getCpIncidentList, action.reportUniqueId);
            yield put(getCpIncidentListSuccess(cpIncidentList.data));
        } catch(error) {
            yield put(getCpIncidentListFailure(error.toString()));
        }
    } catch(error) {
        yield put(postCpIncidentListFailure(error.toString()));
    }
}

/**
 * Worker saga for getting StoreId Update Events
 * @param {redux-action} action 
 */
function* loadStoreIdUpdateEventList(action) {
    try {
        const eventList = yield call(cradlepointService.getStoreIdUpdateEventList, action.storeCodes);
        yield put(getStoreIdUpdateEventListSuccess(eventList));
    } catch(error) {
        yield put(getStoreIdUpdateEventListFailure(error.toString()));
    }
}

function* loadEthernetConfig(action) {
    try {
        const ethernetConfig = yield call(cradlepointService.getEthernetConfiguration, action.store, action.wan);
        yield put(getEthernetConfigurationSuccess(ethernetConfig));
    } catch(error) {
        yield put(getEthernetConfigurationFailue(error.toString()));
    }
}

function* postNcmUpdateManage(action) {
    try {
        yield call(cradlepointService.postNcmUpdateManage, action.cpDetails);
        yield put(postNcmUpdateSuccess());
    } catch(error) {
        yield put(postNcmUpdateFailure(error.toString()))
    }
}

function* loadCpGroupList() {
    try {
        const groupList = yield call(cradlepointService.getCpGroupList);
        const tertiaryGroup = [];
        const nonTertiatyGroup = [];
        groupList.groups.forEach(group => {
            if(group.name.includes("Tertiary")) {
                tertiaryGroup.push(group);
            } else {
                nonTertiatyGroup.push(group);
            }
        });
        tertiaryGroup.sort();
        nonTertiatyGroup.sort();
        const arrangedGroupName = [...tertiaryGroup, ...nonTertiatyGroup];
        yield put(getCpGroupListSuccess(arrangedGroupName));
    } catch(error) {
        yield put(getCpGroupListFailure(error.toString()));
    }
}

function* loadCpGroupName({macId, deviceType}) {
    try {
        const groupName = yield call(cradlepointService.getCpGroupName, macId, deviceType);
        yield put(getCpGroupNameSuccess(groupName));
    } catch(error) {
        yield put(getCpGroupNameFailure(error.toString()));
    }
}

export {
    postOutputFile, loadOutputFileList,
    loadCpIncidentList, postCpIncidentList,
    loadStoreIdUpdateEventList, loadEthernetConfig,
    postNcmUpdateManage, loadCpGroupList, loadCpGroupName
};