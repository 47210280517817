import { POST_INCIDENT_SUPPRESSION_REQUEST,
         GET_INCIDENT_SUPPRESSION_LOGS,
         GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET,
         GET_INCIDENT_SUPPRESSION_EVENT_LOGS,
        //  SET_INCIDENT_SUPPRESSION_REQUEST,
} from "./actionTypes";

const initialState = {
    incidentSuppressionRequest: null,
    incidentSuppressionRequstSuccessMessage: null,
    incidentSuppressionRequestErrorMessage: null,
    incidentSuppressionLogs: [],
    incidentSuppressionLogsErrorMessage: null,
    incidentSuppressionLogsBySnowTicket: [],
    incidentSuppressionLogsBySnowTicketErrorMessage: null,
    incidentSuppressionEventLogs: [],
    incidentSuppressionEventLogsErrorMessage: null
}

const incidentSuppressionReducer = (state = initialState, action={type: "", payload: null}) => {
    switch(action.type) {
        case POST_INCIDENT_SUPPRESSION_REQUEST.SUCCESS:
            return {
                ...state,
                incidentSuppressionRequstSuccessMessage: action.payload
            };
        case POST_INCIDENT_SUPPRESSION_REQUEST.ERROR:
            return {
                ...state,
                incidentSuppressionRequestErrorMessage: action.payload
            };
        // case SET_INCIDENT_SUPPRESSION_REQUEST:
        //     return {
        //         ...state,
        //         incidentSuppressionRequest: action.payload
        //     };
        case GET_INCIDENT_SUPPRESSION_LOGS.SUCCESS:
            return {
                ...state,
                incidentSuppressionLogs: action.payload
            };
        case GET_INCIDENT_SUPPRESSION_LOGS.ERROR:
            return {
                ...state,
                incidentSuppressionLogsErrorMessage: action.payload
            };
        case GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET.SUCCESS:
            return {
                ...state,
                incidentSuppressionLogsBySnowTicket: action.payload
            };
        case GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET.ERROR:
            return {
                ...state,
                incidentSuppressionLogsBySnowTicketErrorMessage: action.payload
            };
        case GET_INCIDENT_SUPPRESSION_EVENT_LOGS.SUCCESS:
            return {
                ...state,
                incidentSuppressionEventLogs: action.payload
            };
        case GET_INCIDENT_SUPPRESSION_EVENT_LOGS.ERROR:
            return {
                ...state,
                incidentSuppressionEventLogsErrorMessage: action.payload
            }
        default:
            return {
                ...state
            };
    }
}

export default incidentSuppressionReducer;