const initialState = {
    loader: 0
}

export default function loaderReducer(state=initialState, action={type:""}) {
    switch (action.type) {
        case 'INCREMENT':
            return {
                loader: state.loader + 1
            }
        case 'DECREMENT':
            return  {
                loader: state.loader - 1
            }
        default:
            return {
                ...state
            }
    }
}