import axios from "axios";
import { ApiPath } from "../../common/api-path/ApiPath";

export default class ServiceMainLogsService {
    
    async getServiceMainIncidentUsecases() {
        const response = await axios.get(`${ApiPath.baseUrl}/servicemain/usecase`, {
            timeout: 120000,
            timeoutErrorMessage: "request timeout",
        });
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getServiceMainIncidentUsecasesLogs(usecaseType, startDate, endDate, offset) {

        const requestConfig = {
            params: { usecase: usecaseType, startDate, endDate, offset },
            timeout: 120000,
            timeoutErrorMessage: "request timeout",
        };
        const response = await axios.get(
            `${ApiPath.baseUrl}/servicemain/events`,
            requestConfig
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getServiceMainIncidentUsecasesDetails(incident) {

        const requestConfig = {
            params: { incident },
            timeout: 120000,
            timeoutErrorMessage: "request timeout",
        };
        const response = await axios.get(
            `${ApiPath.baseUrl}/servicemain/eventMessages`,
            requestConfig
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}