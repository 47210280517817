import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { getPingprobeIncidentByAssetInit } from "../redux/actions";
import { parseDateTime } from "../../../common/date-time";
import "./PingProbeInventory.scss";


/**
 * Functional Component to show PingProbe Asset's Incident
 * @returns JSX
 */
const PingProbeIncidentDialog = ({
    selectedIpAddress,
    setSelectedIpAddress,
}) => {
    const incidentList = useSelector(
        (state) => state.pingprobe.incidentsByAsset
    );
    const dispatch = useDispatch();
    const [tableFilters, setTableFilters] = useState(null);
    const incidentTableRef = useRef(null);

    /**
     * Initializing table filter
     */
    useEffect(() => {
        initializeTableFilter();
    }, []);

    /**
     * Fetching incident data
     */
    useEffect(() => {
        fetchIncidentDetail();
    }, [selectedIpAddress]);

    /**
     * Function to initialize datatable filters
     */
    const initializeTableFilter = () => {
        setTableFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            incidentNumber: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                ],
            },
            incidentStatus: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                ],
            },
            assignmentGroup: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                ],
            },
        });
    };

    /**
     * Function to load incident detail
     */
    const fetchIncidentDetail = () => {
        if (!!selectedIpAddress) {
            dispatch(getPingprobeIncidentByAssetInit(selectedIpAddress));
        }
    };

    /**
     * Function to perform activity when dialog is closed
     */
    const handleDialogHide = () => setSelectedIpAddress(null);

    /**
     * Function to export table data
     */
    const exportIncidentDetails = () => incidentTableRef.current.exportCSV();

    const incidentTimestampTemplate = ({ incidentTimestamp }) =>
        !!incidentTimestamp ? (
            parseDateTime(incidentTimestamp)
        ) : (
            <span style={{ marginLeft: "50%" }}>-</span>
        );
    const modifiedDateTimeTemplate = ({ modifiedDateTime }) =>
        !!modifiedDateTime ? (
            parseDateTime(modifiedDateTime)
        ) : (
            <span style={{ marginLeft: "50%" }}>-</span>
        );

    return (
        <Dialog
            header={selectedIpAddress}
            visible={!!selectedIpAddress}
            onHide={handleDialogHide}
        >
            <div className="pingprobe-incident-table-action">
                <Button
                    icon="pi pi-refresh"
                    className="p-button-text"
                    onClick={fetchIncidentDetail}
                />
                <Button
                    icon="pi pi-download"
                    className="p-button-text"
                    onClick={exportIncidentDetails}
                />
            </div>
            <DataTable
                className="pingprobe-incident-table"
                ref={incidentTableRef}
                sortField="incidentTimestamp"
                sortOrder={-1}
                value={incidentList}
                filters={tableFilters}
                filterDisplay="menu"
                paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                currentPageReportTemplate="Items per page"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No Incident created in last 2 weeks."
            >
                <Column
                    header="Incident #"
                    field="incidentNumber"
                    sortable
                    filter
                />
                <Column
                    header="Created At"
                    field="incidentTimestamp"
                    body={incidentTimestampTemplate}
                    sortable
                />
                <Column
                    header="Status"
                    field="incidentStatus"
                    sortable
                    filter
                />
                <Column
                    header="Assignment Group"
                    field="assignmentGroup"
                    sortable
                    filter
                />
                <Column
                    header="Modified At"
                    field="modifiedDateTime"
                    body={modifiedDateTimeTemplate}
                    sortable
                />
            </DataTable>
        </Dialog>
    );
};

export default PingProbeIncidentDialog;
