import { useMemo } from "react";
import { useLocation } from "react-router-dom";


/**
 * Custom Hook to parse query string
 * @returns URL Search Params
 */
const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQuery;
