import React, { useEffect, useState, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getAllMessagesInit } from './redux/actions';
import { DATETIME_FORMAT } from '../../../common/date-time';

import './Messages.scss';


const Messages = ({deviceId}) => {

    const messageDtRef = useRef(null);
    const messageData = useSelector(state => state.message.messageList);
    const deviceType = useSelector(state => state.deviceManagement.deviceItem ? state.deviceManagement.deviceItem.deviceType : "");
    const deviceName = useSelector(state => state.deviceManagement.deviceItem ? state.deviceManagement.deviceItem.deviceName : "");
    const dispatch = useDispatch();
    
    const fetchMessageData = () => dispatch(getAllMessagesInit(deviceId));
    const [ isModemOrCP, setIsModemOrCP ] = useState(false);
    const [ isISP, setIsIsp ] = useState(false);
    const [tableFilters, setTableFilters] = useState(null);

    /**
    * Fetch IOT Device messages data
    */
    useEffect(() => {
        fetchMessageData();
        initializeTableFilter();
    }, [deviceId]);

    useEffect(() => {
        setIsModemOrCP(deviceType.includes("Modem") || deviceType.toLowerCase().includes("cradlepoint") || deviceType.includes("RxPrinter") || deviceType.includes("GrafanaAgentCtrl")); ;
        setIsIsp(deviceType.includes("ISP"));
    }, [deviceType])

    const exportCSV = () => {
        messageDtRef.current.exportCSV();
       }

    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			leafId: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			detectedErrorState: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			printerStatus: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            extendedDetectedErrorState: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			extendedPrinterStatus: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			status: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            pendingBatch: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			}
		})
	}

    const paginatorLeft = <>
        <Tooltip target=".message-table-info-icon" />
        <i className="pi pi-info-circle message-table-info-icon" data-pr-tooltip="Last 2 Week Data" data-pr-position="right" />
    </>;

    /**
     * 
     * @param {HTMLFormEvent} e 
     * Custom sort function
     */
    const messageTimeStampSort = (e) => {
        return e?.data?.sort((data1, data2) => {
            const value1 = moment(data1[e.field], DATETIME_FORMAT);
            const value2 = moment(data2[e.field], DATETIME_FORMAT);
            const result = value1 > value2 ? 1 : -1;
            return e.order * result;
        });
    };

    /**
    * Display messages table view
    */
    return (
        <div>
            <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={fetchMessageData} style={{float: 'right'}}/>
            <Button type="button" icon="pi pi-download" className="p-button-text" onClick={exportCSV} style={{float: 'right'}}/>
            <br/> <br/>
            <DataTable ref ={messageDtRef} exportFilename={`${deviceName}-Messages`} value={messageData} sortField="telematicTimestamp" sortOrder={-1} className="p-datatable-gridlines" paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                currentPageReportTemplate="Items per page" rows={10} rowsPerPageOptions={[10, 20, 50]} paginatorLeft={paginatorLeft}
                filters={tableFilters} filterDisplay="menu" emptyMessage="No messages found.">
                
                {   !isModemOrCP && !isISP &&  <Column field="leafId" header="Printer Name" sortable filter />  }
                {   !isModemOrCP && !isISP &&   <Column field="detectedErrorState" header="Detected Error State" sortable filter />  }
                {   !isModemOrCP && !isISP && <Column field="printerStatus" header="Printer Status" sortable filter /> }
                {   !isModemOrCP && !isISP &&  <Column field="extendedDetectedErrorState" header="Extended Detected Error State" sortable filter /> }
                {   !isModemOrCP && !isISP &&  <Column field="extendedPrinterStatus" header="Extended Status" sortable filter />    }
                {   !isISP && <Column field="status" header="Status" sortable filter />    }              
                {   isISP && <Column field="pendingBatch" header="Pending Batch" sortable filter />  }
                <Column field="telematicTimestamp" header="Last Updated time" sortable="custom" sortFunction={messageTimeStampSort}/>
            </DataTable>
        </div>
    );
}

export default Messages;