import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";

import { parseDateTime, addDateTime } from "../../../common/date-time";
import {
    getAllPingprobeSuppressionRulesInit,
    setPingprobeSuppressionRule,
    deletePingprobeSuppressionRuleInit,
} from "../redux/actions";
import { tableFilters } from "../constants";
import PingprobeSuppressedAssetlist from "./PingprobeSuppressedAssetlist";
import "./PingProbeSuppressionLogs.scss";


/**
 * Functional Component to show PingProbe Suppression Rules
 * @returns JSX
 */
const PingProbeSuppressionLogs = () => {
    const suppressionRules = useSelector(
        (state) => state.pingprobe.suppressionRules
    );
    const dispatch = useDispatch();

    const [selectedSuppressionRule, setSelectedSuppressionRule] =
        useState(null);
    const history = useHistory();
    const logsTableRef = useRef(null);


    /**
     * Fetching all suppression rules
     */
    useEffect(() => {
        fetchSuppressionRules();
    }, []);

    /**
     * Function to load suppression rules
     */
    const fetchSuppressionRules = () => {
        dispatch(getAllPingprobeSuppressionRulesInit());
    };

    /**
     * Function to edit suppression rules
     */
    const editSuppressionRule = (suppressionRule) => {
        dispatch(
            setPingprobeSuppressionRule({
                ...suppressionRule,
                ruleValue: suppressionRule.ruleValue.replaceAll("'", ""),
                startDateTime: new Date(suppressionRule.startDateTime),
                endDateTime: new Date(suppressionRule.endDateTime),
            })
        );
        history.push("/admin/ping-probe/suppression-rule");
    };

    /**
     * Function to delete suppression rules
     */
    const deleteSuppressionRule = (suppressionId) => {
        dispatch(deletePingprobeSuppressionRuleInit(suppressionId));
    };

    const parsedRuleTemplate = ({ ruleType, ruleValue, parsedRule }) => (
        <>
            <Tooltip target=".parsed-rule" />
            <span
                className="parsed-rule"
                data-pr-tooltip={ruleType + "\u00a0 \u00a0" + ruleValue}
            >
                {parsedRule}
            </span>
        </>
    );

    const suppressionTypeTemplate = ({ suppressionType }) =>
        suppressionType.charAt(0).toUpperCase() + suppressionType.slice(1);

    const startDateTemplate = ({ startDateTime }) =>
        !!startDateTime ? parseDateTime(startDateTime) : "-";
    const endDateTemplate = ({ endDateTime }) =>
        !!endDateTime ? parseDateTime(endDateTime) : "-";

    const suppressionRuleActionTemplate = (rowdata) => {
        console.log("sprressiontype", rowdata.suppressionType)
        const deleteConfirmationMessage =
        rowdata.suppressionType === "decommission" ? 
        "Do you want to delete this decommission rule ? If yes, please re-onboard the device to enable monitoring." :
        "Do you want to delete this suppression rule ?"
         return(
        <div className="suppression-rule-actions-containter">
            <Button
                icon="pi pi-pencil"
                className="p-button-text"
                disabled={
                    !!rowdata.endDateTime &&
                    addDateTime("minutes", 30) >= new Date(rowdata.endDateTime)
                }
                onClick={() => editSuppressionRule(rowdata)}
            />
            <Button
                icon="pi pi-times-circle"
                className="p-button-text p-button-danger"
                disabled={
                    !!rowdata.endDateTime &&
                    addDateTime("minutes", 30) >= new Date(rowdata.endDateTime)
                }
                onClick={(event) =>
                    confirmPopup({
                        target: event.currentTarget,
                        icon: "pi pi-info-circle",
                        message: deleteConfirmationMessage,
                        accept: () =>
                            deleteSuppressionRule(rowdata.suppressionId),
                    })
                }
            />
            <Button
                icon="pi pi-info-circle"
                className="p-button-text p-button-info"
                onClick={() => setSelectedSuppressionRule(rowdata)}
            />
        </div>
         );
    };

    const paginatorLeft = (
        <>
            <Tooltip target=".suppression-log-info-icon" />
            <i
                className="pi pi-info-circle suppression-log-info-icon"
                data-pr-tooltip="Please note: Rule editing is not allowed when only 30 mins remain"
                data-pr-position="right"
            />
        </>
    );
    
    /**
   * Function to export table data
   */
const exportSuppressionRulesLogs = () => logsTableRef.current.exportCSV();


    return (
        <div className="pingprobe-suppression-logs container-fluid">
            <p className="page-label">
                Ping Probe
                <i className="pi pi-angle-right" />
                Suppression Logs
            </p>
            <ConfirmPopup />
            <PingprobeSuppressedAssetlist
                selectedSuppressionRule={selectedSuppressionRule}
                setSelectedSuppressionRule={setSelectedSuppressionRule}
            />
            <div className="pingprobe-suppression-logs-content">
                <div className="pingprobe-suppression-logs-action">
                    <Button
                        icon="pi pi-refresh"
                        className="p-button-text"
                        onClick={fetchSuppressionRules}
                    />
                    <Button 
                        icon="pi pi-download"
                        className="p-button-text"
                        onClick={exportSuppressionRulesLogs}
                    />
                </div>
                <DataTable
                    value={suppressionRules}
                    ref={logsTableRef}
                    filters={tableFilters}
                    sortField="startDateTime"
                    sortOrder={-1}
                    filterDisplay="menu"
                    paginator
                    paginatorLeft={paginatorLeft}
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    currentPageReportTemplate="Items per page"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    emptyMessage="No Rule found."
                >
                    <Column header="Field" field="field" sortable filter />
                    <Column
                        header="Rule"
                        field="parsedRule"
                        body={parsedRuleTemplate}
                        sortable
                        filter
                    />
                    <Column
                        header="Type"
                        field="suppressionType"
                        body={suppressionTypeTemplate}
                        sortable
                        filter
                    />
                    <Column
                        header="Start Date"
                        field="startDateTime"
                        body={startDateTemplate}
                        sortable
                    />
                    <Column
                        header="End Date"
                        field="endDateTime"
                        body={endDateTemplate}
                        sortable
                    />
                    <Column header="" body={suppressionRuleActionTemplate} />
                </DataTable>
            </div>
        </div>
    );
};

export default PingProbeSuppressionLogs;
