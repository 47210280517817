import { GET_ALL_DIVISION_NAMES } from "./actionTypes";

export const getAllDivisionNamesInit = () => ({
    type: GET_ALL_DIVISION_NAMES.INIT,
});

export const getAllDivisionNamesSuccess = divisionList => ({
    type: GET_ALL_DIVISION_NAMES.SUCCESS,
    payload: divisionList,
});

export const getAllDivisionNamesFailure = errorMessage => ({
    type: GET_ALL_DIVISION_NAMES.ERROR,
    payload: errorMessage,
});
