import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from "primereact/toast";


import { parseDateTime } from '../../../common/date-time';
import {
	getShellyFirmwareGroupDetailsInit, cancelShellyFirmwareGroupInit, postShellyFirmwareGroupInit,
	postModifyShellyFirmwareGroupInit,
} from '../redux/action';
import ShellyFirmwareGroupDetailDialog from './ShellyFirmwareGroupDetailDialog';
import "../ShellyFirmware.scss";
import './ShellyFirmwareGroupDetails.scss';

const ShellyFirmwareGroupDetails = () => {

	const [tableFilters, setTableFilters] = useState(null);
	const [dialogData, setDialogData] = useState(null);
	const [showGroupForm, setShowGroupForm] = useState(false);
	const shellyFirmwareGroupDetailsTableRef = useRef(null);

	const dispatch = useDispatch();

	const [groupName, setGroupName] = useState("");
	const [storeList, setStoreList] = useState("");
	const [isModifying, setIsModifying] = useState(false);
	const [formErrors, setFormErrors] = useState({
		groupName: "",
		storeList: "",
	});
	const toastRef = useRef();
	const regex = /^\d{4}$/

	const shellyFirmwareGroupDetails = useSelector((state) => state.shellyFirmware.shellyFirmwareGroupDetails);

	useEffect(() => {
		initializeTableFilter();
		fetchShellyFirmwareGroupDetails();
	}, []);

	const fetchShellyFirmwareGroupDetails = () => {
		dispatch(getShellyFirmwareGroupDetailsInit());
	}

	const createGroupHandler = () => {
		setShowGroupForm(true);
	}

	const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			groupName: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			storesList: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			modifiedDateTime: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			}
		});
	};
	const handleCancelClick = (groupName) => {
		const cancelGroup = {
			groupName: groupName,
		}
		dispatch(cancelShellyFirmwareGroupInit(cancelGroup));
	}

	const handleModifyClick = (rowdata) => {
		setIsModifying(true);
		setGroupName(rowdata.groupName);
		setStoreList(rowdata.storesList);
		setShowGroupForm(true);
	}


	const detailTemplate = (rowdata) => (
		<div className="shelly-group-details-action-icons">
			<Button
				icon="pi pi-pencil"
				className="p-button-text"
				onClick={() => handleModifyClick(rowdata)}
			/>
			<Button
				icon="pi pi-times-circle"
				className="p-button-text p-button-danger"
				onClick={(event) =>
					confirmPopup({
						target: event.currentTarget,
						icon: "pi pi-info-circle",
						message:
							"Do you want to delete this group ?",
						accept: () =>
							handleCancelClick(rowdata.groupName),
					})
				}
			/>
			<Button
				icon="pi pi-eye"
				className="p-button-text"
				onClick={() => setDialogData(rowdata)}
			/>
		</div>
	);

	const exportShellyFirmwareGroupDetails = () => shellyFirmwareGroupDetailsTableRef.current.exportCSV();

	const modifiedAtTemplate = ({ modifiedDateTime }) =>
		modifiedDateTime ? (
			parseDateTime(modifiedDateTime)
		) : (
			<span style={{ marginLeft: "50%" }}>-</span>
		);
	const storesListTemplate = ({ storesList }) => (
		<div className="payload-template">
			{storesList}
		</div>
	);
	const validateForm = () => {
		const errors = {};

		if (!groupName.trim()) {
			errors.groupName = "Group Name is required";
		}

		if (!storeList.trim()) {
			errors.storeList = "Store List is required";
		} else {
			const storeListArray = storeList.split(/[,\n]/).map((store) => store.trim())
			const invalidStore = storeListArray.filter(
                (store) => !store.match(regex)
            );

			if (invalidStore.length > 0) {
                errors.storeList = `Invalid store format: ${invalidStore.join(",")}.
                store number should be 4 digits and store list should be comma separated or new line separated`;
            }
			 else {
				const uniqueStores = new Set(storeListArray);
				if (uniqueStores.size !== storeListArray.length) {
					errors.storeList = "Duplicate stores are not allowed";
				}
			}
		}

		setFormErrors(errors);

		return Object.keys(errors).length === 0;
	};
	const resetForm = () => {
		setGroupName("");
		setStoreList("");
		setFormErrors({
			groupName: "",
			storeList: "",
		});
		setIsModifying(false);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		//Handle group creation logic
		if (validateForm()) {
			const storeListArray = storeList
				.split(/[,\n]/)
				.map((store) => store.trim())
				.filter((store) => store !== "")
				.join(",");
			//payload
			const groupPayload = {
				groupName: groupName,
				storesList: storeListArray,
			};
			if (isModifying) {
				dispatch(postModifyShellyFirmwareGroupInit(groupPayload));
			} else {
				dispatch(postShellyFirmwareGroupInit(groupPayload));
			}
			resetForm();
			setShowGroupForm(false);
		}
	};

	const cancelHandler = () => {
		resetForm()
		setShowGroupForm(false);

	}

	return (
		<div>
			{
				showGroupForm ? (
					<div className="shelly-firmware-container">
						<form>
							<div className="row group-field">
								<div className="col-12 col-lg-2">
									<label>Group Name</label>
								</div>
								<div className="col-12 col-lg-8 ">
									<InputText
										value={groupName}
										onChange={(e) => setGroupName(e.target.value)}
										disabled={isModifying}
										className={isModifying ? "disabled-input" : ""}
									/>
									{formErrors.groupName && (
										<div className="p-error">{formErrors.groupName}</div>
									)}
								</div>
							</div>
							<div className="row store-field  ">
								<div className="col-12 col-lg-2">
									<label>Store List</label>
								</div>
								<div className="col-12 col-lg-8 ">
									<InputTextarea
										value={storeList}
										onChange={(e) => setStoreList(e.target.value)}
										rows={10}
										cols={30}
									/>
									{formErrors.storeList && (
										<div className="p-error">{formErrors.storeList}</div>
									)}
									<br />
									<strong>Note: For multiple stores use ',' as separator</strong>
								</div>
							</div>
							{
								isModifying && (
									<div className="row modify-impact-note">
										<div className="col-12 ">
											<small>Modifying this group will impact only future campaigns</small>
										</div>
									</div>
								)
							}
							<div className="row create-group-btn">
								<Button
									label="Cancel"
									className="p-button-danger"
									onClick={() => cancelHandler()}
								/>
								<Button
									label={isModifying ? "Modify Group" : "Create Group"}
									onClick={(e) => handleSubmit(e)}
								/>
							</div>
							<Toast ref={toastRef} position="bottom-center" />
						</form>
					</div>
				) : (
					<div className="shelly-firmware-group-details">
						<ShellyFirmwareGroupDetailDialog dialogData={dialogData} setDialogData={setDialogData} />
						<div className="shelly-firmware-group-details-content">
							<div className="shelly-firmware-group-details-action">
								<div className="input-field">
									<Button
										label="Create Group"
										onClick={createGroupHandler}
									/>
								</div>
								<div className="input-field">
									<Button
										icon="pi pi-refresh"
										className="p-button-text"
										onClick={fetchShellyFirmwareGroupDetails}
									/>
									<Button
										icon="pi pi-download"
										className="p-button-text"
										onClick={exportShellyFirmwareGroupDetails}
									/>
								</div>
							</div>
							<ConfirmPopup />

							<DataTable
								value={shellyFirmwareGroupDetails}
								ref={shellyFirmwareGroupDetailsTableRef}
								className="p-datatable-gridlines"
								filters={tableFilters}
								filterDisplay="menu"
								sortField="modifiedDateTime"
								sortOrder={-1}
								paginator
								paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
								currentPageReportTemplate="Items per page"
								rows={10}
								rowsPerPageOptions={[10, 20, 50]}
								emptyMessage="No Data found"
							>
								<Column header="Group Name" field="groupName" sortable filter />
								<Column header="Stores List" field="storesList" body={storesListTemplate} sortable filter />
								<Column header="Modified At" field="modifiedDateTime" body={modifiedAtTemplate} sortable filter />
								<Column header="Actions" body={detailTemplate} />
							</DataTable>
						</div>
					</div>

				)
			}
		</div>

	)
}

export default ShellyFirmwareGroupDetails;