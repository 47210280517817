import { GET_USER_PROFILE } from './profileAction';

const initialState = {
    userRoles: null,
    userRolesErrorMessage: null
}

const profileReducer = (state=initialState, action={type: "", payload: null}) => {
    switch(action.type) {
        case GET_USER_PROFILE.SUCCESS:
            return {...state, userRoles: action.payload, userRolesErrorMessage: null}
        case GET_USER_PROFILE.ERROR:
            return {...state, userRoles: null, userRolesErrorMessage: action.payload}
        default:
            return {...state}
    }
}

export default profileReducer;