import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useParams, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceStatusInit, getAllDeviceInit } from "./redux/actions";
import Events from "./events/Events";
import Messages from "./messages/Messages";
import PendingJobs from "./pending-jobs/PendingJobs";


const DeviceMangementListItem = () => {
  const deviceList = useSelector(state => state.deviceManagement.deviceList);
  const deviceDetails = useSelector(state => state.deviceManagement.deviceItem);
  const selectedStores = useSelector(state => state.searchStore.selectedStoreList);
  const [alternativeDevice, setAlternativeDevice] = useState(null);
  const dispatch = useDispatch();

  const { deviceId } = useParams();

  const deviceName = deviceDetails ? deviceDetails.deviceName : '';
  const deviceType = deviceDetails ? deviceDetails.deviceType : '';

  const getDeviceStatus = () => !isNaN(deviceId) && dispatch(getDeviceStatusInit(deviceId));

  const history = useHistory();

  useEffect(() => {
    if(deviceList.length===0) {
      fetchDeviceDetail();
    }
    getDeviceStatus();
  }, [deviceId]);

  useEffect(() => {
    getAlternativeDeviceLink();
  }, [deviceName, deviceList])

  const fetchDeviceDetail = () => {
    if(selectedStores && selectedStores.length>0) {
        const storeCodes = []
        selectedStores.forEach(selectedStore => {
            storeCodes.push(selectedStore.name);
        });
        dispatch(getAllDeviceInit(storeCodes));
    }
  }

  /**
  * Navigate back to IOT Device list page
  */
  const navigateBack = () => {
    history.push('/admin/device-management/devices/list')
  }

  const getAlternativeDeviceLink = () => {
    let alternativeDeviceName = deviceName.substring(0,5);
    if(deviceName.includes("Wireless")) {
      alternativeDeviceName += "Switch-VirtualDevice";
    } else if(deviceName.includes("Switch")) {
      alternativeDeviceName += "Wireless-VirtualDevice";
    } else if(deviceName.includes("Modem-Wan-1")) {
      alternativeDeviceName += "Modem-Wan-2";
    } else if(deviceName.includes("Modem-Wan-2")) {
      alternativeDeviceName += "Modem-Wan-1";
    } else {
      setAlternativeDevice(null);
      return;
    }
    setAlternativeDevice(deviceList.find(device => device.deviceName===alternativeDeviceName));
  }

  /**
  * Display tab menu based on the device type
  */
   const tabDisplay = () => {

    if (isNaN(deviceId) || deviceName.toLowerCase().includes("virtual")) {
      return (
        <React.Fragment>
          <TabView>
            <TabPanel header="Events">
              <Events deviceId={deviceId} />
            </TabPanel>
          </TabView>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <TabView>
            <TabPanel header="Messages">
              <Messages deviceId={deviceId} />
            </TabPanel>
            <TabPanel header="Events">
              <Events deviceId={deviceId} />
            </TabPanel>
           {
            deviceName.toLowerCase().includes("grafanaagentctrl") && (
              <TabPanel header="Pending Jobs">
                <PendingJobs deviceNames={deviceName} />
                </TabPanel>

            )
           } 
           
          </TabView>
        </React.Fragment>
      )
    }
  }

  /**
  * IOT Device Management details view
  */
  return (
    <div className="device-list">
      <div className="device-management-list-header">
        <p className="page-label-devices">Device Management 
        <i className="pi pi-angle-right" />
        <span className="device-id">
          <a onClick={navigateBack}> IoT Devices List </a> <i className="pi pi-angle-right"></i> 
          {
            isNaN(deviceId) ? deviceId : `${deviceName} (${deviceDetails && deviceDetails.deviceCode})`
          }
        </span>
        </p>
        
        {alternativeDevice && (
          <div>
            <Link
              to={`/admin/device-management/devices/list/${alternativeDevice.deviceId}`}
            >
              Go to {alternativeDevice.deviceName}
            </Link>
          </div>
        )}
      </div>
      {["Printer", "ISP", "RxPrinter", "Modem Smart Switch","CradlePoint", "Cradlepoint Smart Switch"].includes(deviceType) && !!deviceDetails.version && (
        <span id="device-version"> Version:  {deviceDetails.version}</span>
      )}
      {tabDisplay()}
    </div>
  );
}

export default DeviceMangementListItem;
