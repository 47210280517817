import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button'; 
import { Column } from 'primereact/column';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import moment from 'moment';

import CommandHistoryDialog from './CommandHistoryDialog';
import SearchStoreInput from '../search-store/SearchStoreInput';
import { getShellyDeviceListInit, sendCommandInit } from './redux/actions';
import { parseDateTime } from '../../common/date-time';
import './CommandAndControl.scss';


const CommandAndControl = () => {

    const ONE_MINUTE_INTERVAL = 1000*60;

    const selectedStoreList = useSelector(state => state.searchStore.selectedStoreList);
    const shellyDeviceList = useSelector(state => state.commandAndControl.shellyDeviceList);
    const dispatch = useDispatch();

    const [deviceList, setDeviceList] = useState([]);
    const [showCommandHistory, setShowCommandHistory] = useState(false);
    const [tableFilters, setTableFilters] = useState(null);
    const logsTableRef = useRef(null);


    useEffect(() => {
        fetchShellyDeviceList();
        initializeTableFilter();
    }, []);

    useEffect(() => {
        updateShellyDevicesProperty();
        const interval = setInterval(() => {
            updateShellyDevicesProperty();
        }, ONE_MINUTE_INTERVAL);
        return () => clearInterval(interval);
    }, [shellyDeviceList, shellyDeviceList.length])

    const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			store: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			deviceName: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			deviceCode: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            deviceStatus: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
            lastCommand: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			}
		})
	}

    const fetchShellyDeviceList = () => {
        if(selectedStoreList && selectedStoreList.length>0) {
            const storeCodes = [];
            selectedStoreList.forEach(selectedStore => storeCodes.push(selectedStore.name));
            dispatch(getShellyDeviceListInit(storeCodes));
        }
    }

    const updateShellyDevicesProperty = () => {
        const devices = shellyDeviceList.map(device => ({...device, isDisabled: disablingButton(device)}));
        setDeviceList([...devices]);
    }

    /**
     * Disabling a device if command is sent for any other device in that store in last 5 mins
     *
     */
     const disablingButton = ({ deviceCode, store }) => {
        const alternativeDevices = shellyDeviceList.filter(
            (shellyDevice) =>
                shellyDevice.store === store &&
                shellyDevice.deviceCode !== deviceCode &&
                !!shellyDevice.lastCommandTimestamp
        );
        if (alternativeDevices.length >= 1) {
            for (const alternativeDevice of alternativeDevices) {
                const lastCommandTimestamp = moment(
                    alternativeDevice.lastCommandTimestamp
                );
                const duration = moment().diff(
                    lastCommandTimestamp,
                    "milliseconds"
                );
                if (duration < ONE_MINUTE_INTERVAL * 5) {
                    return true;
                }
            }
        }
        return false;
    };

    const onCommandClick = (event, deviceCode, command) => {
        const storeCodes = [];
        selectedStoreList.forEach(selectedStore => storeCodes.push(selectedStore.name));
        const requestPayload = {
            command : command,
            deviceCode : deviceCode
        }
        confirmPopup({
            target: event.target,
            message: `Do you want to send ${command} Command?`,
            accept: () => dispatch(sendCommandInit(requestPayload, storeCodes))
        });
    }

    const commandTemplate = ({deviceCode, isDisabled}) => (
        <>
            <ConfirmPopup />
            <Button icon="pi pi-power-off" className="p-button-rounded p-button-text p-button-success" onClick={e => onCommandClick(e, deviceCode,'ON')} disabled={isDisabled}/>
            <Button icon="pi pi-power-off" className="p-button-rounded p-button-text p-button-danger" onClick={e => onCommandClick(e, deviceCode,'OFF')} disabled={isDisabled}/>
            <Button icon="pi pi-refresh" className="p-button-rounded p-button-text p-button-secondary" onClick={e => onCommandClick(e, deviceCode,'REBOOT')} disabled={isDisabled}/>
        </>
    )

    const deviceStatusTemplate = ({deviceStatus, lastReportedTimestamp}) => {
        const timestamp = lastReportedTimestamp ? `at \n ${parseDateTime(lastReportedTimestamp)}` : "";
        return `${deviceStatus} ${timestamp}`;
    }

    const deviceLastCommandTemplate = ({lastCommand, lastCommandTimestamp}) => {
        const timestamp = lastCommandTimestamp ? `at \n ${parseDateTime(lastCommandTimestamp)}`: "";
        return `${lastCommand} ${timestamp}`;
    }

    const commandHistoryTemplate = ({deviceCode}) => (
        <Button icon="pi pi-clock" className="p-button-rounded p-button-text p-button-info"
            onClick={() => setShowCommandHistory(deviceCode)} />
    )

    /**
   * Function to export table data
   */
    const exportShellyDeviceList = () => logsTableRef.current.exportCSV();

    return (
        <div className="command-and-control-container">
            <p className="page-label">
				Device Management
				<i className="pi pi-angle-right" />
				Command and Control
			</p>
            <SearchStoreInput handleClick={fetchShellyDeviceList}/>
            <CommandHistoryDialog showCommandHistory={showCommandHistory} setShowCommandHistory={setShowCommandHistory} />
            <div className="legend-container">
                <span>
                    <Button icon="pi pi-power-off" className="p-button-rounded p-button-text p-button-success" />
                    On Command
                </span>
                <span>
                    <Button icon="pi pi-power-off" className="p-button-rounded p-button-text p-button-danger" />
                    Off Command
                </span>
                <span>
                    <Button icon="pi pi-refresh" className="p-button-rounded p-button-text p-button-secondary" />
                    Power Recycle Command
                </span>
            </div>
            <Button icon="pi pi-download"className="p-button-text cnc-refresh-icon" onClick={exportShellyDeviceList} />
            <Button type="button" icon="pi pi-refresh" className="p-button-text cnc-refresh-icon" onClick={fetchShellyDeviceList} />
            <DataTable value={deviceList} ref={logsTableRef} filters={tableFilters} filterDisplay="menu" paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="Items per page" rows={5} rowsPerPageOptions={[5, 10, 20]}>
                <Column header="Store" field="store" sortable filter />
                <Column header="Network Id" field="deviceName" sortable filter />
                <Column header="Shelly Id" field="deviceCode" sortable filter />
                <Column header="Device Status" body={deviceStatusTemplate} field="deviceStatus" sortable filter />
                <Column header="Command" body={commandTemplate} />
                <Column header="Last Command Status" body={deviceLastCommandTemplate} field="lastCommand" sortable filter />
                <Column header="Command History" body={commandHistoryTemplate} />
            </DataTable>
        </div>
    )
}

export default CommandAndControl;