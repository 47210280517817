import { call, put } from "redux-saga/effects";

import {
  getGaRemoteCommandDivisionDetailsSuccess,
  getGaRemoteCommandDivisionDetailsFailure,
  postCreateJobSuccess,
  postCreateJobFailure,
  getJobDetailsSuccess,
  getJobDetailsFailure,
  getServerDetailsByJobIdSuccess,
  getServerDetailsByJobIdFailure,
  cancelGrafanaAgentRemoteCommandScheduleSuccess,
  cancelGrafanaAgentRemoteCommandScheduleFailure,
} from "../components/ares/redux/actions";
import GrafanaAgentRemoteCommandService from "../components/ares/GrafanaAgentRemoteCommandService";

const gaRemoteCommandService = new GrafanaAgentRemoteCommandService();

/**
 * Worker saga for loading division detail in Grafana Agent Remote command Page
 */
function* loadGaRemoteCommandDivisionDetails() {
  try {
    const divisionDetail = yield call(gaRemoteCommandService.getDivisionDetail);
    yield put(getGaRemoteCommandDivisionDetailsSuccess(divisionDetail));
  } catch (error) {
    yield put(getGaRemoteCommandDivisionDetailsFailure(error.toString()));
  }
}

function* postCreatedJob(action) {
  try {
    const createJob = yield call(
      gaRemoteCommandService.postCreateJob,
      action.payload
    );
    console.log("Api response", createJob);
    yield put(postCreateJobSuccess(createJob));
  } catch (error) {
    yield put(postCreateJobFailure(error.toString()));
  }
}



function* loadJobDetails({ startDate, endDate, offset }) {
  try {
    const jobDetails = yield call(
      gaRemoteCommandService.getJobDetails,
      startDate,
      endDate,
      offset
    );
    yield put(getJobDetailsSuccess(jobDetails));
  } catch (error) {
    yield put(getJobDetailsFailure(error.toString()));
  }
}

function* loadServerDetailsByJobId({ jobId }) {
  try {
    const serverDetailsByJobId = yield call(
      gaRemoteCommandService.getServerDetailsById,
      jobId
    );
    yield put(getServerDetailsByJobIdSuccess(serverDetailsByJobId));
  } catch (error) {
    yield put(getServerDetailsByJobIdFailure(error.toString()));
  }
}

function* cancelGrafanaAgentScheduledJob({
  cancelSchedule,
  startDate,
  endDate,
  offset,
}) {
  console.log("cancelSchedule", cancelSchedule, startDate, endDate, offset);
  try {
    yield call(gaRemoteCommandService.cancelJobSchedule, cancelSchedule);
    yield put(cancelGrafanaAgentRemoteCommandScheduleSuccess());
    try {
      const jobDetails = yield call(
        gaRemoteCommandService.getJobDetails,
        startDate,
        endDate,
        offset
      );
      yield put(getJobDetailsSuccess(jobDetails));
    } catch (error) {
      yield put(getJobDetailsFailure(error.toString()));
    }
  } catch (error) {
    yield put(cancelGrafanaAgentRemoteCommandScheduleFailure(error.toString()));
  }
}

export {
  loadGaRemoteCommandDivisionDetails,
  postCreatedJob,
  loadJobDetails,
  loadServerDetailsByJobId,
  cancelGrafanaAgentScheduledJob,
};
