import {
    GET_CC_RIT_USECASES,
    GET_CC_RIT_USECASES_LOGS,
    GET_CC_RIT_USECASES_DETAILS,
    GET_CC_INCIDENT_USECASES,
    GET_CC_INCIDENT_USECASES_LOGS,
    GET_CC_INCIDENT_USECASES_DETAILS,
} from "./actionTypes";

export const getCCRITUsecasesInit = () => ({
    type: GET_CC_RIT_USECASES.INIT,
});

export const getCCRITUsecasesSuccess = (usecaseList) => ({
    type: GET_CC_RIT_USECASES.SUCCESS,
    payload: usecaseList,
});

export const getCCRITUsecasesFailure = (errorMessage) => ({
    type: GET_CC_RIT_USECASES.ERROR,
    payload: errorMessage,
});

export const getCCRITUsecasesLogsInit = (startDate, endDate, offset) => ({
    type: GET_CC_RIT_USECASES_LOGS.INIT,
    startDate,
    endDate,
    offset,
});

export const getCCRITUsecasesLogsSuccess = (usecaseLogs) => ({
    type: GET_CC_RIT_USECASES_LOGS.SUCCESS,
    payload: usecaseLogs,
});

export const getCCRITUsecasesLogsFailure = (errorMessage) => ({
    type: GET_CC_RIT_USECASES_LOGS.ERROR,
    payload: errorMessage,
});

export const getCCRITUsecasesDetailsInit = (requestId) => ({
    type: GET_CC_RIT_USECASES_DETAILS.INIT,
    requestId,
});

export const getCCRITUsecasesDetailsSuccess = (details) => ({
    type: GET_CC_RIT_USECASES_DETAILS.SUCCESS,
    payload: details,
});

export const getCCRITUsecasesDetailsFailure = (errorMessage) => ({
    type: GET_CC_RIT_USECASES_DETAILS.ERROR,
    payload: errorMessage,
});

export const getCCIncidentUsecasesInit = () => ({
    type: GET_CC_INCIDENT_USECASES.INIT,
});

export const getCCIncidentUsecasesSuccess = (usecaseList) => ({
    type: GET_CC_INCIDENT_USECASES.SUCCESS,
    payload: usecaseList,
});

export const getCCIncidentUsecasesFailure = (errorMessage) => ({
    type: GET_CC_INCIDENT_USECASES.ERROR,
    payload: errorMessage,
});

export const getCCIncidentUsecasesLogsInit = (
    usecaseType,
    startDate,
    endDate,
    offset
) => ({
    type: GET_CC_INCIDENT_USECASES_LOGS.INIT,
    usecaseType,
    startDate,
    endDate,
    offset,
});

export const getCCIncidentUsecasesLogsSuccess = (usecaseLogs) => ({
    type: GET_CC_INCIDENT_USECASES_LOGS.SUCCESS,
    payload: usecaseLogs,
});

export const getCCIncidentUsecasesLogsFailure = (errorMessage) => ({
    type: GET_CC_INCIDENT_USECASES_LOGS.ERROR,
    payload: errorMessage,
});

export const getCCIncidentUsecasesDetailsInit = (incident) => ({
    type: GET_CC_INCIDENT_USECASES_DETAILS.INIT,
    incident,
});

export const getCCIncidentUsecasesDetailsSuccess = (details) => ({
    type: GET_CC_INCIDENT_USECASES_DETAILS.SUCCESS,
    payload: details,
});

export const getCCIncidentUsecasesDetailsFailure = (errorMessage) => ({
    type: GET_CC_INCIDENT_USECASES_DETAILS.ERROR,
    payload: errorMessage,
});
