import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { getLdapId } from '../../../common/Utils/msalUtils';
import CPOutputFileList from './CPOutputFileList';
import { postOutputFileInit } from '../redux/actions';
import IncidentDialog from './IncidentDialog';
import '../Cradlepoint.scss';


const defaultUsageValue = [
    [null,null,null],
    [null,null,null]
]

const defaultUsageValueError = [
    [false, false, false],
    [false, false, false]
]

const usages = [100, 30, 1];

const CP_BILLING_USAGE_VALUE = "CP_BILLING_USAGE_VALUE";

const CPUsageTracker = () => {

    const fileUploadRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState();
    const [showIncidentDialog, setShowIncidentDialog] = useState(false);
    const [usageValue, setUsageValue] = useState(JSON.parse(localStorage.getItem(CP_BILLING_USAGE_VALUE)) || [...defaultUsageValue]);
    const [usageValueError, setUsageValueError] = useState([...defaultUsageValueError]);
    const [isFormInputHaveError, setFormInputHaveError] = useState(false);
    const [fileInvalidMessage, setFileInvalidMessage] = useState(null);

    const dispatch = useDispatch();

    const handleFileSelect = event => {
        console.log("CP Usage File ", event.files[0].name, event.files[0].size);
        if(event.files[0].name.endsWith('csv')) {
            const size = event.files[0].size;
            if(size > 50*1024*1024) {
                setFileInvalidMessage("Maximum File size is 50 MB");
                fileUploadRef.current.clear();
            } else {
                setFileInvalidMessage(null);
                setSelectedFile(event.files[0]);
            }
        } else {
            setFileInvalidMessage("File type must be a CSV")
            fileUploadRef.current.clear();
        }
    }

    const handleFileSelectionClear = () => {
        setSelectedFile(undefined);
    }

    const handleUsageValueChange = (value, i, j) => {
        const tempUsageValue = [...usageValue];
        tempUsageValue[i][j] = value;
        setUsageValue([...tempUsageValue]);
    }

    const handleSubmit = () => {
        const tempUsageValueError = [...usageValueError];
        let haveError = false;
        usages.forEach((_usage, i) => {
            tempUsageValueError[0][i] = !usageValue[0][i] || usageValue[0][i] > usages[i];
            tempUsageValueError[1][i] = !usageValue[1][i] || usageValue[1][i] < usages[i];
            haveError = haveError || tempUsageValueError[0][i] || tempUsageValueError[1][i];
        })
        setUsageValueError([...tempUsageValueError]);
        setFormInputHaveError(haveError);
        if(!haveError) {
            const requestPayload = {
                threshold: [
                    {
                        oneGBObject: {under: usageValue[0][2], over: usageValue[1][2]}
                    },
                    {
                        thirtyGBObject: {under: usageValue[0][1], over: usageValue[1][1]}
                    },
                    {
                        hundredGBObject: {under: usageValue[0][0], over: usageValue[1][0]}
                    }
                ],
                watcher_id: getLdapId()
            }
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('data', JSON.stringify(requestPayload));
            dispatch(postOutputFileInit(formData));
            fileUploadRef.current.clear();
            handleFileSelectionClear();
            localStorage.setItem(CP_BILLING_USAGE_VALUE, JSON.stringify(usageValue));
        }
    }

    const emptyTemplate = () => <div className="empty-template-msg">Drag and Drop Here</div>

    const cancelOptions = {label: 'Clear'};

    return (
        <div className="cp-usage-tracker-container">
            <p className="page-label">
                Cradlepoint
                <i className="pi pi-angle-right" />
                Billing Automation
            </p>
            <div className="file-upload-container">
                <span className="title-text">
                    Verizon unbilled usage file upload
                </span>
                <div className="upload-control">
                    <FileUpload ref={fileUploadRef} name="cp-file" mode="advanced" accept=".csv" customUpload={true}
                        emptyTemplate={emptyTemplate} onSelect={handleFileSelect} cancelOptions={cancelOptions} onClear={handleFileSelectionClear} />
                </div>
            </div>
            <table className="cp-usage-input">
                <thead>
                    <tr>
                        <th style={{textAlign: 'left'}}>Plan Type</th>
                        {
                            usages.map((usage) => (
                                <th key={usage}>{`${usage} GB`}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        usageValue.map((usageValueItem, position) => (
                            <tr key={uuidv4()}>
                                {position===0 && <td>Under usage</td>}
                                {position===1 && <td>Exceed usage</td>}
                                {
                                    usageValueItem.map((item, index) => (
                                        <td key={uuidv4()}>
                                            <InputNumber value={item} onValueChange={e => handleUsageValueChange(e.value, position, index)}
                                                minFractionDigits={3} maxFractionDigits={3} inputClassName={usageValueError[position][index] ? 'red-border': ''} /> GB
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {
                isFormInputHaveError && 
                <div className="error-status">Invalid Input for one or more Fields</div>
            }
            {
                fileInvalidMessage &&
                <div className="error-status">{fileInvalidMessage}</div>
            }
            <Button label="Calculate" onClick={handleSubmit} className="cp-file-upload-btn" />
            <Divider />
            <CPOutputFileList setShowIncidentDialog={setShowIncidentDialog} />
            <IncidentDialog showIncidentDialog={showIncidentDialog} setShowIncidentDialog={setShowIncidentDialog} />
        </div>
    )
}

export default CPUsageTracker;