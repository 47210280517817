import { call, put } from "redux-saga/effects";

import {
    postIncidentSuppressionRequestSuccess,
    postIncidentSuppressionRequestError,
    getIncidentSuppressionLogsSuccess,
    getIncidentSuppressionLogsError,
    getIncidentSuppressionLogsBySnowTicketSuccess,
    getIncidentSuppressionLogsBySnowTicketError,
    getIncidentSuppressionEventLogsSuccess,
    getIncidentSuppressionEventLogsError,
} from "../components/incident-suppression/redux/actions";

import {
    postIncidentSuppressionRequest,
    getIncidentSuppressionLogs,
    getIncidentSuppressionLogsBySnowTicket,
    getIncidentSuppressionEventLogs
} from "../components/incident-suppression/IncidentSuppressionService";

export function* saveIncidentSuppressionRequest({ suppressionRequest }) {
    try {
        const response = yield call(postIncidentSuppressionRequest, suppressionRequest);
        yield put(postIncidentSuppressionRequestSuccess(response));
        try {
            const incidentSuppressionLogs = yield call(getIncidentSuppressionLogs);
            yield put(getIncidentSuppressionLogsSuccess(incidentSuppressionLogs));
        } catch (error) {
            yield put(getIncidentSuppressionLogsError(error.toString()));
        }
    } catch (error) {
        yield put(postIncidentSuppressionRequestError(error.toString()));
    }
}

export function* loadIncidentSuppressionLogs() {
    try {
        const incidentSuppressionLogs = yield call(getIncidentSuppressionLogs);
        yield put(getIncidentSuppressionLogsSuccess(incidentSuppressionLogs));
    } catch (error) {
        yield put(getIncidentSuppressionLogsError(error.toString()));
    }
}

export function* loadIncidentSuppressionLogsBySnowTicket({ snowTicket }) {
    try {
        const incidentSuppressionLogsBySnowTicket = yield call(getIncidentSuppressionLogsBySnowTicket, snowTicket);
        yield put(getIncidentSuppressionLogsBySnowTicketSuccess(incidentSuppressionLogsBySnowTicket));
    } catch (error) {
        yield put(getIncidentSuppressionLogsBySnowTicketError(error.toString()));
    }
}

export function* loadIncidentSuppressionEventLogs({suppressionId, usecase}) {
    try {
        const incidentSuppressionEventLogs = yield call(getIncidentSuppressionEventLogs, suppressionId, usecase);
        yield put(getIncidentSuppressionEventLogsSuccess(incidentSuppressionEventLogs));
    } catch (error) {
        yield put(getIncidentSuppressionEventLogsError(error.toString()));
    }

}
