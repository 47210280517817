import axios from "axios";
import { ApiPath } from "../../common/api-path/ApiPath";

export default class GrafanaAgentRemoteCommandService {
	async getDivisionDetail() {
		const response = await axios.get(
			`${ApiPath.baseUrl}/grafanaAgent/inventorydetails`,
			{ timeout: 120000, timeoutErrorMessage: "request timeout" }
		);
		if (response.status >= 400) {
			throw new Error(response.errors);
		}
		return response.data;
	}

	async postCreateJob(job) {
		const response = await axios.post(
			`${ApiPath.baseUrl}/grafanaAgent/job`,
			job,
			{ timeout: 120000, timeoutErrorMessage: "request timeout" }
		);
		if (response.status >= 400) {
			throw new Error(response.errors);
		}
		return response.data;
	}

	async getJobDetails(startDate, endDate, offset) {
		const requestConfig = {
			params: { startDate, endDate, offset },
			timeout: 120000,
			timeoutErrorMessage: "request timeout",
		};
		const response = await axios.get(
			`${ApiPath.baseUrl}/grafanaAgent/jobdetail`,
			requestConfig
		);
		if (response.status >= 400) {
			throw new Error(response.errors);
		}
		return response.data;
	}

	async getServerDetailsById(id) {
		const requestConfig = {
			params: { jobId: id },
			timeout: 120000,
			timeoutErrorMessage: "request timeout",
		};
		const response = await axios.get(
			`${ApiPath.baseUrl}/grafanaAgent/job`,
			requestConfig
		);
		if (response.status >= 400) {
			throw new Error(response.errors);
		}
		return response.data;
	}

	async cancelJobSchedule(cancelSchedule) {
		console.log("cancelSchedule", cancelSchedule);
		const response = await axios.delete(
			`${ApiPath.baseUrl}/grafanaAgent/job/${cancelSchedule}`,
			{ timeout: 120000, timeoutErrorMessage: "request timeout" }
		);
		if (response.status >= 400) {
			throw new Error(response.errors);
		}
		return response.data;
	}
}
