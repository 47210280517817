import { call, put } from 'redux-saga/effects';

import {
    getShellyDeviceListSuccess, getShellyDeviceListFailure,
    getCommandHistoryListSuccess, getCommandHistoryListFailure,
    sendCommandSuccess,sendCommandFailure
} from '../components/command-and-control/redux/actions';

import CommandAndControlService from '../components/command-and-control/CommandAndControlService';


const commandAndControlService = new CommandAndControlService();

/**
 * Worker saga for loading Shelly Device List
 * @param {Object} action - Redux action
 */
function* loadShellyDeviceList(action) {
    try {
        const shellyDeviceList = yield call(commandAndControlService.getShellyDeviceList, action.storeCodes);
        yield put(getShellyDeviceListSuccess(shellyDeviceList));
    } catch(error) {
        yield put(getShellyDeviceListFailure(error.toString()))
    }
}

/**
 * Worker saga for loading Command History
 * @param {Object} action - Redux action
 */
function* loadCommandHistoryList(action) {
    try {
        const commandHistoryList = yield call(commandAndControlService.getCommandHistory, action.deviceCode);
        yield put(getCommandHistoryListSuccess(commandHistoryList));
    } catch(error) {
        yield put(getCommandHistoryListFailure(error.toString()))
    }
}

/**
 * Worker saga for sending Command 
 * @param {Object} action - Redux action
 */
 function* loadSendCommand(action) {
    try {
        const response = yield call(commandAndControlService.sendCommand, action.requestPayload);
        yield put(sendCommandSuccess(response));
    } catch(error) {
        yield put(sendCommandFailure(error.toString()))
    }
    try {
        const shellyDeviceList = yield call(commandAndControlService.getShellyDeviceList, action.storeCodes);
        yield put(getShellyDeviceListSuccess(shellyDeviceList));
    } catch(error) {
        yield put(getShellyDeviceListFailure(error.toString()))
    }
}

export { loadShellyDeviceList, loadCommandHistoryList, loadSendCommand };