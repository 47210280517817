import { GET_ALL_MESSAGES } from './actionTypes';

export const getAllMessagesInit = deviceId => ({
    type: GET_ALL_MESSAGES.INIT,
    deviceId
});

export const getAllMessagesSuccess = messageList => ({
    type: GET_ALL_MESSAGES.SUCCESS,
    payload: messageList
});

export const getAllMessageFailure = errorMessage => ({
    type: GET_ALL_MESSAGES.ERROR,
    payload: errorMessage
});