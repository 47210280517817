export const GET_OUTPUT_FILE_LIST = {
    INIT: "GET_OUTPUT_FILE_LIST_INIT",
    SUCCESS: "GET_OUTPUT_FILE_LIST_SUCCESS",
    ERROR: "GET_OUTPUT_FILE_LIST_ERROR"
}

export const POST_OUTPUT_FILE = {
    INIT: "POST_OUTPUT_FILE_INIT",
    SUCCESS: "POST_OUTPUT_FILE_SUCCESS",
    ERROR: "POST_OUTPUT_FILE_ERROR"
}

export const GET_CP_INCIDENT_LIST = {
    INIT: "GET_CP_INCIDENT_LIST_INIT",
    SUCCESS: "GET_CP_INCIDENT_LIST_SUCCESS",
    ERROR: "GET_CP_INCIDENT_LIST_ERROR",
    UPDATE: "GET_CP_INCIDENT_LIST_UPDATE"
}

export const POST_CP_INCIDENT_LIST = {
    INIT: "POST_CP_INCIDENT_LIST_INIT",
    SUCCESS: "POST_CP_INCIDENT_LIST_SUCCESS",
    ERROR: "POST_CP_INCIDENT_LIST_ERROR"
}

export const GET_STORE_ID_UPDATE_EVENT_LIST = {
    INIT: "GET_STORE_ID_UPDATE_EVENT_LIST_INIT",
    SUCCESS: "GET_STORE_ID_UPDATE_EVENT_LIST_SUCCESS",
    ERROR: "GET_STORE_ID_UPDATE_EVENT_LIST_ERROR"
}

export const GET_ETHERNET_CONFIGURATION = {
    INIT: "GET_ETHERNET_CONFIGURATION_INIT",
    SUCCESS: "GET_ETHERNET_CONFIGURATION_SUCCESS",
    ERROR: "GET_ETHERNET_CONFIGURATION_ERROR"
}

export const POST_NCM_UPDATE = {
    INIT: "POST_NCM_UPDATE_INIT",
    SUCCESS: "POST_NCM_UPDATE_SUCCESS",
    ERROR: "POST_NCM_UPDATE_ERROR"
}

export const GET_CP_GROUP_LIST = {
    INIT: "GET_CP_GROUP_LIST_INIT",
    SUCCESS: "GET_CP_GROUP_LIST_SUCCESS",
    ERROR: "GET_CP_GROUP_LIST_ERROR"
}

export const GET_CP_GROUP_NAME = {
    INIT: "GET_CP_GROUP_NAME_INIT",
    SUCCESS: "GET_CP_GROUP_NAME_SUCCESS",
    ERROR: "GET_CP_GROUP_NAME_ERROR"
}
