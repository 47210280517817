import { GET_EPE_ERROR_CODES, GET_EPE_LOGS } from "./actionTypes";

const initialState = {
  errorCodeList: [],
  errorCodeListErrorMessage: null,
  epeLogs: [],
  epeLogsErrorMessage: null,
};

const epeLogsReducer = (state = initialState, action = { type: "" }) => {
  switch (action.type) {
    case GET_EPE_ERROR_CODES.SUCCESS:
      return {
        ...state,
        errorCodeList: action.payload,
        errorCodeListErrorMessage: null,
      };
    case GET_EPE_ERROR_CODES.ERROR:
      return {
        ...state,
        errorCodeList: [],
        errorCodeListErrorMessage: action.payload,
      };
    case GET_EPE_LOGS.SUCCESS:
      return {
        ...state,
        epeLogs: action.payload,
        epeLogsErrorMessage: null,
      };
    case GET_EPE_LOGS.ERROR:
      return {
        ...state,
        epeLogs: [],
        epeLogsErrorMessage: action.payload,
      };
    default:
      return { ...state };
  }
};

export default epeLogsReducer;
