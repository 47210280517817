import React from 'react';
import { Carousel } from 'primereact/carousel';
import { Dialog } from 'primereact/dialog';

import './IncidentConfig.scss';


const EmailSampleDialog = ({usecase, showEmailTemplate, setShowEmailTemplate}) => {

    const CONTROLLER_DISCONNECTED_URL = process.env.REACT_APP_CONTROLLER_DISCONNECTED_TEMPLATE_URL;
    const SSD_USED_PERCENTAGE_THRESHOLD_URL = process.env.REACT_APP_SSD_USED_PERCENTAGE_THRESHOLD_TEMPLATE_URL;
    const PRINTHEAD_FAILURE_URL = process.env.REACT_APP_PRINTHEAD_FAILURE_TEMPLATE_URL;
    const SCALES_OFFLINE_URL = process.env.REACT_APP_SCALES_OFFLINE_TEMPLATE_URL;
    const SCALES_NOT_REPORTING_URL = process.env.REACT_APP_SCALES_NOT_REPORTING_TEMPLATE_URL;

    const imageUrl = {
        'Printer': [],
        'IP Router': [],
        'POS Printer': [PRINTHEAD_FAILURE_URL],
        'POS Controller': [CONTROLLER_DISCONNECTED_URL, SSD_USED_PERCENTAGE_THRESHOLD_URL],
        'Scale': [SCALES_OFFLINE_URL, SCALES_NOT_REPORTING_URL],
        'ISP':[],
        'CradlePoint': []
    } 

    /**
     * Email Template
     */
    const emailTemplate = emailSrc => (
        <img src={emailSrc} alt="&nbsp; No Preview Available" width="720px" height="auto" />
    )

    return (
        <Dialog header="Email Template(s)" visible={showEmailTemplate} style={{width:'880px'}} onHide={() => setShowEmailTemplate(false)}>
        {
            imageUrl[usecase] && imageUrl[usecase].length > 0 ? (
            <Carousel value={imageUrl[usecase]} numVisible={1} numScroll={1} className="custom-carousel" circular
                autoplayInterval={2000} itemTemplate={emailTemplate}>
            </Carousel>
            ) : (
                <div> No Template Available</div>
            )
        }
    </Dialog>
    )
}

export default EmailSampleDialog;