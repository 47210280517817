import { GET_CONFIG_DIVISION_DETAILS, GET_EXCLUSION_RULE, POST_EXCLUSION_RULE } from './actionTypes';

export const getConfigDivisionDetailsInit = () => ({
    type: GET_CONFIG_DIVISION_DETAILS.INIT
})

export const getConfigDivisionDetailsSuccess = divisionDetail => ({
    type: GET_CONFIG_DIVISION_DETAILS.SUCCESS,
    payload: divisionDetail
})

export const getConfigDivisionDetailsFailure = errorMessage => ({
    type: GET_CONFIG_DIVISION_DETAILS.ERROR,
    payload: errorMessage
})

export const getExclusionRuleInit = (division, assetType) => ({
    type: GET_EXCLUSION_RULE.INIT,
    division, assetType
})

export const getExclusionRuleSuccess = exclusionRule => ({
    type: GET_EXCLUSION_RULE.SUCCESS,
    payload: exclusionRule
})

export const getExclusionRuleFailure = errorMessage => ({
    type: GET_EXCLUSION_RULE.ERROR,
    payload: errorMessage
})

export const postExclusionRuleInit = (exclusionRule, division, assetType) => ({
    type: POST_EXCLUSION_RULE.INIT,
    exclusionRule, division, assetType
})

export const postExclusionRuleSuccess = exclusionRule => ({
    type: POST_EXCLUSION_RULE.SUCCESS,
    payload: exclusionRule
})

export const postExclusionRuleFailure = errorMessage => ({
    type: POST_EXCLUSION_RULE.ERROR,
    payload: errorMessage
})