import { GET_DEVICE_TYPE, GET_DEVICE_ONBOARDING_LOGS } from "./actionTypes";

export const getDeviceTypeInit = () => ({
    type: GET_DEVICE_TYPE.INIT,
});

export const getDeviceTypeSuccess = (deviceTypeList) => ({
    type: GET_DEVICE_TYPE.SUCCESS,
    payload: deviceTypeList,
});

export const getDeviceTypeFailure = (errroMessage) => ({
    type: GET_DEVICE_TYPE.ERROR,
    payload: errroMessage,
});

export const getDeviceOnboardingLogsInit = () => ({
    type: GET_DEVICE_ONBOARDING_LOGS.INIT,
});

export const getDeviceOnboardingLogsSuccess = (onboardingLogs) => ({
    type: GET_DEVICE_ONBOARDING_LOGS.SUCCESS,
    payload: onboardingLogs,
});

export const getDeviceOnboardingLogsFailure = (errroMessage) => ({
    type: GET_DEVICE_ONBOARDING_LOGS.ERROR,
    payload: errroMessage,
});
