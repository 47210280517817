import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { sanitize } from 'dompurify'

import './SnowWrapper.scss';


const SnowWrapperDetailDialog = ({dialogData, setDialogData}) => {

    const parseJson = json => sanitize(JSON.stringify(JSON.parse(json), null, 2));

    return (
        <Dialog className="snow-wrapper-detail-dialog" visible={!!dialogData} onHide={() => setDialogData(null)}>
            <h5>Request Sent</h5>
            {
                !!dialogData && !!dialogData.request &&
                <pre dangerouslySetInnerHTML={{
                    __html: parseJson(dialogData.request),
                }} />
            }
            <Divider />
            <h5>Response Received</h5>
            {
                !!dialogData && !!dialogData.response &&
                <pre dangerouslySetInnerHTML={{
                    __html: parseJson(dialogData.response),
                }} />
            }
        </Dialog>
    )
}

export default SnowWrapperDetailDialog;
