import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";
import Moment from "moment";

import { parseDateTime, addDateTime } from "../../../../common/date-time";
import { getCCRITUsecasesLogsInit } from "../../redux/actions";
import {
    alertPayloadTemplate,
    commonTableFilters,
    modifiedAtTemplate,
    receivedAtTemplate,
} from "../utils";
import DetailSummaryDialog from "./DetailSummaryDialog";
import "./RitSummary.scss";

const RitSummary = () => {
    const ritLogs = useSelector((state) => state.commandCenter.ritUsecasesLogs);
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState([
        addDateTime("day", -14),
        addDateTime("day", 0),
    ]);
    const [tableFilters, setTableFilters] = useState(null);
    const [dialogData, setDialogData] = useState(null);
    const logsTableRef = useRef(null);


    useEffect(() => {
        initializeTableFilter();
        fetchLogs();
    }, []);

    const initializeTableFilter = () => {
        setTableFilters({
            ...commonTableFilters,
            request_id: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.CONTAINS },
                ],
            },
        });
    };

    const fetchLogs = () => {
        const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
        const endDate = !!dateRange[1]
            ? Moment(dateRange[1]).format("yyyy-MM-DD")
            : Moment(dateRange[0]).format("yyyy-MM-DD");
        const offset = Moment(new Date()).utcOffset();
        dispatch(getCCRITUsecasesLogsInit(startDate, endDate, offset));
    };

    const detailTemplate = ({ request_id }) => (
        <i
            className="pi pi-info-circle"
            onClick={() => setDialogData(request_id)}
        ></i>
    );

    const changeRequestTemplate =({
        isCR_applicable,
        cr_number,
        cr_status,
        cr_updated_date
    })=>{
        if (!isCR_applicable) {
            return <div>N.A.</div>;
        } 
        let icon = "";
        if (cr_status && cr_status.toLowerCase() === "approved") {
            icon = "pi-check";
        } else if (cr_status && cr_status.toLowerCase() === "rejected") {
            icon = "pi-times";
        } else {
            return <div>N.A.</div>;
        } 
        return (
            <div className="cr-container">
                <Tooltip target={`.${cr_number}`}>
                    <table>
                        <tbody>
                            <tr>
                                <td>Status:</td>
                                <td>{cr_status || "Unknown"}</td>
                            </tr>
                            <tr>
                                <td>Modified At:</td>
                                <td>{!!cr_updated_date ? parseDateTime(cr_updated_date) : "Unknown"}</td>
                            </tr>
                        </tbody>
                    </table>
                </Tooltip>
                <i className={`pi ${icon} ${cr_number}`}></i>
                {cr_number}
            </div>
        ); 
    }
 /**
   * Function to export table data
   */
 const exportRitLogs = () => logsTableRef.current.exportCSV();

    return (
        <div className="rit-summary-container">
            <DetailSummaryDialog
                dialogData={dialogData}
                setDialogData={setDialogData}
            />
            <div className="row summary-action">
                <div className="col-12 col-lg-6 input-field">
                    <label htmlFor="dateRange">Events Date</label>
                    <Calendar
                        id="dateRange"
                        value={dateRange}
                        onChange={(e) => setDateRange(e.value)}
                        selectionMode="range"
                        minDate={addDateTime("month", -3)}
                        maxDate={addDateTime("month", 0)}
                        showIcon
                    />
                </div>
                <div className="col-12">
                    <Button label="Get Logs" onClick={fetchLogs} />
                    <Button 
                        icon="pi pi-download"
                        className="p-button-text"
                        onClick={exportRitLogs}
                        style={{float: "right"}}
                    />
                    <Button 
                        id='refreshBtn'
                        icon="pi pi-refresh"
                        className="p-button-text"
                        onClick={fetchLogs}                       
                        style={{float: "right"}}
                    />
                </div>
            </div>
            <DataTable
                value={ritLogs}
                ref={logsTableRef}
                filters={tableFilters}
                filterDisplay="menu"
                sortField="received_date"
                sortOrder={-1}
                paginator
                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                currentPageReportTemplate="Items per page"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No logs found."
            >
                <Column header="RITM" field="request_id" sortable filter />
                <Column header="Process Type" field="usecase" sortable filter />
                <Column
                    header="Received At"
                    field="received_date"
                    body={receivedAtTemplate}
                    sortable
                />
                <Column header="Status" field="status" sortable filter />
                <Column
                    header="Modified At"
                    field="modified_date"
                    body={modifiedAtTemplate}
                    sortable
                />
                <Column
                    header="Change Request"
                    field="cr_number"
                    body={changeRequestTemplate}
                    sortable
                    filter
                />
                <Column
                    header="Alert Payload"
                    field="alert_payload"
                    body={alertPayloadTemplate}
                />
                <Column header="Details" body={detailTemplate} />
            </DataTable>
        </div>
    );
};

export default RitSummary;
