import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import fileUpload from "../../images/file-upload.svg";
import DeviceOnboardingService from './DeviceOnboardingService';
import { useSelector, useDispatch } from 'react-redux';
import { getDeviceTypeInit, getDeviceOnboardingLogsInit } from './redux/actions';
import DeviceOnboardingLogs from './DeviceOnboardingLogs';
import './DeviceOnboarding.scss';

function DeviceOnboarding() {

    const fileInputRef = useRef(null);
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileName, setSelectedFileName] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);
    const [resultMessage, setResultMessage] = useState(null);
    const [showResultMessage, setShowResultMessage] = useState(false);
    const [fileUploadError, setFileUploadError] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [snowTicketNumber, setSnowTicketNumber] = useState("");
    const [description, setDescription] = useState("");


    const deviceService = new DeviceOnboardingService();
    const [downloadTemplateUrl, setDownloadTemplateUrl] = useState('');

    /**
    * Fetch the On Boarding Device types and list it into Device Type dropdown field
    */
    const deviceTypes = useSelector(state => state.deviceOnboarding.deviceTypeList);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDeviceTypeInit());
    }, [dispatch]);

    useEffect(() => {
        if(!!deviceTypes && deviceTypes.length>0) {
            deviceTypeChange({value: deviceTypes[0]})
        }
        return () => setDownloadTemplateUrl('');
    }, [deviceTypes])

    /**
    * To bind the selected dropdown value to Device Type field
    */
    const deviceTypeChange = (e) => {
        setSelectedDeviceType(e.value);
        deviceService.getTemplateUrl(e.value.name)
            .then(url => setDownloadTemplateUrl(url))
            .catch(() => setDownloadTemplateUrl(''));
    }

    /**
    * To select the Device Bulk upload Excel file
    * @param {Object} event - Selected file
    */
    const onFileSelect = (event) => {
        const fileName = event.files[0].name;
        const fileSize = event.files[0].size;
        const MAX_FILE_SIZE = 2*1024*1024;
        if ((fileName.toLowerCase().endsWith('.xls') || fileName.toLowerCase().endsWith('.xlsx')) && fileSize <= MAX_FILE_SIZE) {
            setSelectedFile(event.files[0]);
            setSelectedFileName(event.files[0].name);
            return true;
        }
        fileInputRef.current.clear();
        setDisplayDialog(true);
        return false;
    }

    /**
    * Upload the selected Device Bulk upload file into database
    */
    const onFileUpload = () => {
        if(selectedDeviceType.deviceTypeId === 10
            && (!snowTicketNumber || snowTicketNumber.trim().length === 0)) {
            setFileUploadError(["Snow Ticket # cannot be empty"]);
        }
        else if(selectedDeviceType.deviceTypeId === 10
            && (!description || description.trim().length === 0)) {
            setFileUploadError(["Description cannot be empty"]);
        } else if(selectedDeviceType.deviceTypeId === 10 && description.length>150) {
            setFileUploadError(["Description cannot have more than 150 characters"]);
        } else {
            setFileUploadError([]);
            const formData = new FormData();
            formData.append('file', selectedFile);
    
            deviceService.bulkUpload(formData, selectedDeviceType.deviceTypeId, snowTicketNumber.trim(), description.trim()).then(res => {
                if (res) {
                    dispatch(getDeviceOnboardingLogsInit());
                    setSuccessMessage(res.data);
                    setResultMessage('Uploaded Successfully');
                    setFileUploadError([]);
                    setShowResultMessage(true);
                    setTimeout(() => {
                        setResultMessage('');
                        setShowResultMessage(false);
                    }, 5000);
                }
            }).catch(error => {
                setFileUploadError(error?.response?.data?.detailList);
                setResultMessage('Uploaded Failed');
                setShowResultMessage(true);
                setTimeout(() => {
                    setResultMessage('');
                    setShowResultMessage(false);
                }, 5000);
            });
        }
    }

    return (
        <div className="device-onboarding-container container-fluid">
            <p className="page-label">
				Device Management
				<i className="pi pi-angle-right" />
				Onboarding
			</p>
            <div className="row" data-testid="onboarding-container-dropdown">
                <div className="col-12 col-lg-2">
                    <label>Device Type</label>
                </div>
                <div className="col-12 col-lg-10">
                    <Dropdown value={selectedDeviceType} options={deviceTypes} optionLabel="name" onChange={deviceTypeChange} 
                        className="input" placeholder="Select device type to onboard" data-testid="onboarding-dropdown" />
                </div>
            </div>
            { selectedDeviceType?.deviceTypeId===10 &&
                (
                    <>
                        <div className="row">
                            <div className="col-12 col-lg-2">
                                <label>Snow Ticket #</label>
                            </div>
                            <div className="col-12 col-lg-10">
                                <InputText value={snowTicketNumber} onChange={e => setSnowTicketNumber(e.target.value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-2">
                                <label>Description</label>
                            </div>
                            <div className="col-12 col-lg-10">
                                <InputText value={description} onChange={e=> setDescription(e.target.value)} />
                            </div>
                        </div>
                    </>
                )
            }
            <div className="bulk-upload">
                {showResultMessage &&
                    <div className="bulk-upload-success">
                        <div>{selectedFileName}</div>
                        <span>{resultMessage}</span>
                    </div>
                }
                {
                    fileUploadError && fileUploadError.map((error) => {
                        return <div className="error" key={error}>{error}</div>
                    })
                }
                <div className="url-container">
                    { selectedDeviceType && <a className="download-template" href={downloadTemplateUrl} download>Download Template</a> }
                    { successMessage && <a className="success-url" href={successMessage}>Download Output File</a> }
                </div>
                <FileUpload ref={fileInputRef} name="file" className="bulk-upload-button" customUpload={true} onSelect={onFileSelect} uploadHandler={onFileUpload} accept=".xls, .xlsx" disabled={!selectedDeviceType}
                    emptyTemplate={<div><span className="or-text">or</span><p className="p-m-0"><img src={fileUpload} alt="" /> Drag and Drop Excel File</p></div>} />
            </div>

            <Dialog header="Alert" visible={displayDialog} style={{ width: '30vw' }} onHide={() => setDisplayDialog(false)}>
                You can only upload Excel file with maximum size of 2MB
            </Dialog>

            <hr />
            <DeviceOnboardingLogs />
        </div>
    );
}

export default DeviceOnboarding;