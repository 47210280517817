import axios from 'axios';
import { ApiPath } from '../../common/api-path/ApiPath';
import { getLdapId } from '../../common/Utils/msalUtils';

export default class DeviceOnboardingService {

    async getDeviceTypes() {
        const response = await axios.get(`${ApiPath.baseUrl}/device/types`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
    
    bulkUpload(data, deviceTypeId, snowTicketNumber, description) {
        const requestConfig = {
            params: { 
                watcherId: getLdapId(),
                deviceTypeId,
                snowTicketNumber,
                description
        },
            timeout: 600000, timeoutErrorMessage: "request timeout"
        }
        return axios.post(`${ApiPath.baseUrl}/device/smartplug`, data, requestConfig);
    }

    async getTemplateUrl(deviceType) {
        const response = await axios.get(`${ApiPath.baseUrl}/template/${deviceType}`, { timeout: 120000, timeoutErrorMessage: "request timeout" });
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getDeviceOnboardingLogs() {
        const response = await axios.get(
            `${ApiPath.baseUrl}/device/device-onboarding-logs`,
            { timeout: 120000, timeoutErrorMessage: "request timeout" }
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getOnboardingFileSasUrl(deviceTypeId, filePath) {
        const params = {
            deviceTypeId,
            filePath,
        };
        const response = await axios.get(
            `${ApiPath.baseUrl}/device/device-onboarding-logs/sas-url`,
            { timeout: 120000, timeoutErrorMessage: "request timeout", params }
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}
