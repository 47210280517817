import axios from "axios";
import { ApiPath } from "../../common/api-path/ApiPath";

export default class CCAutomationService {
    
    async getCCIncidentUsecases() {
        const response = await axios.get(`${ApiPath.baseUrl}/cc/usecase`, {
            timeout: 120000,
            timeoutErrorMessage: "request timeout",
        });
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getCCIncidentUsecasesLogs(usecaseType, startDate, endDate, offset) {
        const requestConfig = {
            params: { usecase: usecaseType, startDate, endDate, offset },
            timeout: 120000,
            timeoutErrorMessage: "request timeout",
        };
        const response = await axios.get(
            `${ApiPath.baseUrl}/cc/events`,
            requestConfig
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getCCIncidentUsecasesDetails(incident) {
        const requestConfig = {
            params: { incident },
            timeout: 120000,
            timeoutErrorMessage: "request timeout",
        };
        const response = await axios.get(
            `${ApiPath.baseUrl}/cc/eventMessages`,
            requestConfig
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getCCRITUsecasesLogs(startDate, endDate, offset) {
        const requestConfig = {
            params: { startDate, endDate, offset },
            timeout: 120000,
            timeoutErrorMessage: "request timeout",
        };
        const response = await axios.get(
            `${ApiPath.baseUrl}/dns-changes/events`,
            requestConfig
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }

    async getCCRITUsecasesDetails(requestId) {
        const requestConfig = {
            params: { requestId },
            timeout: 120000,
            timeoutErrorMessage: "request timeout",
        };
        const response = await axios.get(
            `${ApiPath.baseUrl}/dns-changes/eventRecords`,
            requestConfig
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
    }
}
