export const GET_ALL_DEVICES = {
    INIT: "GET_ALL_DEVICE_INIT",
    SUCCESS: "GET_ALL_DEVICE_SUCCESS",
    ERROR: "GET_ALL_DEVICE_ERROR"
};

export const GET_DEVICE_STATUS = {
    INIT: "GET_DEVICE_STATUS_INIT",
    SUCCESS: "GET_DEVICE_STATUS_SUCCESS",
    ERROR: "GET_DEVICE_STATUS_ERROR"
};