import { 
    GET_RPA_USECASES,
    GET_RPA_SUMMARY_LOGS,
    GET_RPA_DETAIL_BY_CTASK_ID
} from './actionTypes';

export const getRpaUsecasesInit = () => ({
    type: GET_RPA_USECASES.INIT
});

export const getRpaUsecasesSuccess = usecaseList => ({
    type: GET_RPA_USECASES.SUCCESS,
    payload: usecaseList
});

export const getRpaUsecasesFailure = errorMessage => ({
    type: GET_RPA_USECASES.ERROR,
    payload: errorMessage
});

export const getRpaSummaryLogsInit = (usecaseType, startDate, endDate, offset) => ({
    type: GET_RPA_SUMMARY_LOGS.INIT,
    usecaseType, startDate, endDate, offset
});

export const getRpaSummaryLogsSuccess = summaryLogs => ({
    type: GET_RPA_SUMMARY_LOGS.SUCCESS,
    payload: summaryLogs
});

export const getRpaSummaryLogsFailure = errorMessage => ({
    type: GET_RPA_SUMMARY_LOGS.ERROR,
    payload: errorMessage
});

export const getRpaDetailByCtaskIdInit = ctaskId => ({
    type: GET_RPA_DETAIL_BY_CTASK_ID.INIT,
    ctaskId
});

export const getRpaDetailByCtaskIdSuccess = rpaDetail => ({
    type: GET_RPA_DETAIL_BY_CTASK_ID.SUCCESS,
    payload: rpaDetail
});

export const getRpaDetailByCtaskIdFailure = errorMessage => ({
    type: GET_RPA_DETAIL_BY_CTASK_ID.ERROR,
    payload: errorMessage
});