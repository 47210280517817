import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import CopyButton from "../../common/components/copy-button/CopyButton";
import { parseDateTime } from "../../common/date-time";


/**
 * Function to provide template for Received At column in datatable
 * @param  rawColumn 
 * @returns templated column
 */
export const receivedAtTemplate = ({ received_date }) => {
    return !!received_date ? (
        parseDateTime(received_date)
    ) : (
        <span style={{ marginLeft: "50%" }}>-</span>
    );
};

/**
 * Function to provide template for Modified At column in datatable
 * @param  rawColumn 
 * @returns templated column
 */
export const modifiedAtTemplate = ({ modified_date }) => {
    return !!modified_date ? (
        parseDateTime(modified_date)
    ) : (
        <span style={{ marginLeft: "50%" }}>-</span>
    );
};

/**
 * Function to provide template for Alert Payload column in datatable
 * @param  rawColumn 
 * @returns templated column
 */
export const alertPayloadTemplate = ({ alert_payload }) => (
    <div className="payload-template">
        <CopyButton
            className="copy-icon"
            textToCopy={JSON.stringify(alert_payload)}
        />
        <br />
       {alert_payload ? JSON.stringify(alert_payload) : '-'}
    </div>
);

/**
 * Function to provide template for Change Request column in datatable
 * @param  rawColumn 
 * @returns templated column
 */
export const changeRequestTemplate = ({
    isCR_applicable,
    cr_number,
    cr_status,
    cr_created_date,
    cr_updated_date,
}) => {
    if (!isCR_applicable) {
        return <div>N.A.</div>;
    }
    let icon = "";
    if (cr_status && cr_status.toLowerCase() === "approved") {
        icon = "pi-check";
    } else if (cr_status && cr_status.toLowerCase() === "rejected") {
        icon = "pi-times";
    } else {
        icon = "pi-question";
    }
    return (
        <div className="cr-container">
            <Tooltip target={`.${cr_number}`}>
                <table>
                    <tbody>
                        <tr>
                            <td>Status:</td>
                            <td>{cr_status || "Unknown"}</td>
                        </tr>
                        <tr>
                            <td>Created At:</td>
                            <td>{!!cr_created_date ? parseDateTime(cr_created_date): "Unknown"}</td>
                        </tr>
                        <tr>
                            <td>Modified At:</td>
                            <td>{!!cr_updated_date ? parseDateTime(cr_updated_date) : "Unknown"}</td>
                        </tr>
                    </tbody>
                </table>
            </Tooltip>
            <i className={`pi ${icon} ${cr_number}`}></i>
            {cr_number || "Unknown"}
        </div>
    );
};

/**
 * Common table filters
 */
export const commonTableFilters = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    usecase: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    description: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    cr_number: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    retry_count: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
};
