import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Moment from "moment";

import { parseDateTime, addDateTime } from "../../common/date-time";
import SelectedChips from "../../common/components/selected-chips/SelectedChips";
import { getEpeErrorCodesInit, getEpeLogsInit } from "./redux/actions";
import SubEventDetails from "./SubEventDetails";
import EpeLogsDetailDialog from "./EpeLogsDetailDialog";
import "./EpeLogs.scss";

const EpeLogs = () => {
  const [expandedRows, setExpandedRows] = useState(null);
  const [tableFilters, setTableFilters] = useState(null);
  const [selectedErrorCodes, setSelectedErrorCodes] = useState([]);
  const [dialogData, setDialogData] = useState(null);
  const [dateRange, setDateRange] = useState([
    addDateTime("day", -14),
    addDateTime("day", 0),
  ]);

  const errorCodeList = useSelector((state) => state.epe.errorCodeList);
  const epeLogs = useSelector((state) => state.epe.epeLogs);
  const epeLogsTableRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEpeErrorCodesInit());
    initializeTableFilter();
  }, []);

  /**
   * Select all error codes by default
   */
  useEffect(() => {
    setSelectedErrorCodes([...errorCodeList]);
    if (errorCodeList.length > 0) {
      fetchEpeLogs(true);
    }
  }, [errorCodeList]);

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      eventId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      errorCode: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      server: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      incidentNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  /**
   * Fetch EPE logs
   */
  const fetchEpeLogs = (initialLoad) => {
    let errorCodes = selectedErrorCodes.join(",");
    if (initialLoad === true) {
      errorCodes = errorCodeList.join(",");
    }
    const startDate = Moment(dateRange[0]).format("yyyy-MM-DD");
    const endDate = !!dateRange[1]
      ? Moment(dateRange[1]).format("yyyy-MM-DD")
      : Moment(dateRange[0]).format("yyyy-MM-DD");
    const offset = Moment(new Date()).utcOffset();
    dispatch(getEpeLogsInit(errorCodes, startDate, endDate, offset));
  };

  /**
   * Function to export table data
   */
  const exportEpeLogs = () => epeLogsTableRef.current.exportCSV();

  const rowExpansionTemplate = ({ subEventDetails }) => (
    <SubEventDetails subEventLogs={subEventDetails} />
  );

  const showEventLogs = ({ eventId, subEventDetails }) => {
    let eventLogs = [];
    !!subEventDetails &&
      subEventDetails.forEach((subEvent) => {
        !!subEvent.eventLogs &&
          subEvent.eventLogs.forEach((eventLog) =>
            eventLogs.push({ ...eventLog, server: subEvent.server_name })
          );
      });
    eventLogs.length > 0 &&
      eventLogs.sort((evenLog1, eventLog2) => {
        if (evenLog1.server === eventLog2.server) {
          return new Date(evenLog1.timestamp) - new Date(eventLog2.timestamp);
        }
        if (evenLog1.server < eventLog2.server) {
          return -1;
        }
        return 1;
      });
    setDialogData({ eventId, eventLogs });
  };

  const receivedDateTemplate = ({ receivedDate }) =>
    receivedDate ? (
      parseDateTime(receivedDate)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const modifiedDateTemplate = ({ modifiedDate }) =>
    modifiedDate ? (
      parseDateTime(modifiedDate)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const incidentTemplate = ({ incidentNumber }) =>
    incidentNumber ? (
      incidentNumber
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  const eventLogsTemplate = ({ eventId, subEventDetails }) => (
    <i
      className="pi pi-info-circle"
      onClick={() => showEventLogs({ eventId, subEventDetails })}
    ></i>
  );

  return (
    <div className="epe-logs-container container-fluid">
      <p className="page-label">
        Automation Logs
        <i className="pi pi-angle-right" />
        EPE Automation
      </p>
      <EpeLogsDetailDialog
        dialogData={dialogData}
        setDialogData={setDialogData}
      />
      <div className="epe-logs-action row">
        <div className="col-12 col-lg-6 input-field">
          <label htmlFor="error-codes">Error Codes</label>
          <MultiSelect
            id="error-codes"
            value={selectedErrorCodes}
            options={errorCodeList}
            onChange={(e) => setSelectedErrorCodes(e.value)}
          />
        </div>
        <div className="col-12 col-lg-6 input-field">
          <label htmlFor="dateRange">Events Date</label>
          <Calendar
            id="dateRange"
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            minDate={addDateTime("month", -1)}
            maxDate={addDateTime("month", 0)}
            showIcon
          />
        </div>
        <SelectedChips
          label="Selected Error Codes"
          selectedValues={selectedErrorCodes}
          setSelectedValues={setSelectedErrorCodes}
        />
        <div className="col-12">
          <Button
            label="Get Logs"
            onClick={fetchEpeLogs}
            disabled={selectedErrorCodes.length === 0}
          />
          <Button 
            icon="pi pi-download"
            className="p-button-text"
            onClick={exportEpeLogs}
            style={{float: "right"}}
          />
          <Button 
            icon="pi pi-refresh"
            className="p-button-text"
            onClick={fetchEpeLogs}
            style={{float: "right"}}
          />  
        </div>
      </div>
      <div className="epe-logs-table">
        <DataTable
          value={epeLogs}
          ref={epeLogsTableRef}
          className="p-datatable-gridlines"
          sortField="receivedDate"
          sortOrder={-1}
          filters={tableFilters}
          filterDisplay="menu"
          paginator
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Items per page"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          emptyMessage="No logs found"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "5em" }} />
          <Column field="eventId" header="Event ID" sortable filter />
          <Column field="errorCode" header="Error Code" sortable filter />
          <Column field="server" header="Servers" sortable filter />
          <Column
            field="receivedDate"
            header="Created At"
            body={receivedDateTemplate}
            sortable
          />
          <Column field="status" header="Status" sortable filter />
          <Column
            field="modifiedDate"
            header="Modified At"
            body={modifiedDateTemplate}
            sortable
          />
          <Column
            field="incidentNumber"
            header="Max Threshold Inc"
            body={incidentTemplate}
            sortable
            filter
          />
          <Column header="Details" body={eventLogsTemplate} />
        </DataTable>
      </div>
    </div>
  );
};

export default EpeLogs;
