import React from "react";
import { Timeline } from "primereact/timeline";

import { parseDateTime } from "../../common/date-time";
import "./EpeLogs.scss";

const EventLogsTimeline = ({ eventDetails }) => {
  const eventContent = (eventLog) => {
    return (
      <div style={{ marginBottom: "8px" }}>
        {!!eventLog.server && <p id="server-name">{eventLog.server}</p>}
        <div style={{ fontWeight: "bold" }}>{eventLog.event}</div>
        <div>{parseDateTime(eventLog.timestamp)}</div>
      </div>
    );
  };

  return (
    <div className="event-logs">
      <Timeline value={eventDetails} align="alternate" content={eventContent} />
    </div>
  );
};

export default EventLogsTimeline;
