import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import { parseDateTime } from "../../common/date-time";
import "./EpeLogs.scss";

const EventLogsTable = ({ eventDetails }) => {
  const [tableFilters, setTableFilters] = useState(null);
  const eventLogsRef = useRef(null);

  useEffect(() => {
    initializeTableFilter();
  }, []);

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      server: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      event: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      message: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const exportEventLogs = () => eventLogsRef.current.exportCSV();

  const timeStampTemplate = ({ timestamp }) =>
    timestamp ? (
      parseDateTime(timestamp)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  return (
    <div className="event-logs">
      <Button
        icon="pi pi-download"
        className="p-button-text"
        onClick={exportEventLogs}
        style={{ float: "right", zIndex: "1" }}
      />
      <DataTable
        ref={eventLogsRef}
        value={!!eventDetails && eventDetails.eventLogs}
        className="p-datatable-gridlines"
        filters={tableFilters}
        filterDisplay="menu"
        paginator
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Items per page"
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        emptyMessage="No logs found"
      >
        {!!eventDetails.eventId && (
          <Column field="server" header="Server Name" sortable filter />
        )}
        <Column field="event" header="Alert Type" sortable filter />
        <Column
          field="timestamp"
          header="Timestamp"
          body={timeStampTemplate}
          sortable
        />
        <Column field="message" header="Message" sortable filter />
      </DataTable>
    </div>
  );
};

export default EventLogsTable;
