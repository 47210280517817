import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";

import "./SidebarItemCollapsed.scss";

const SidebarItemCollapsed = ({
    id,
    path,
    icon,
    header,
    activeIcon,
    child,
    activeMenuItem,
    setActiveMenuItem,
}) => {
    const handleIconClick = () => {
        if (activeMenuItem === id) {
            setActiveMenuItem(-1);
        } else {
            setActiveMenuItem(id);
        }
    };

    const { pathname } = useLocation();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(pathname.includes(path));
    }, [pathname]);

    return (
        <div className="sidebar-item-collapsed-container">
            <Tooltip target=" .sidebar-item-collapsed-label "  position="right" />      
            {child.length === 0 ? (
                <NavLink to={path} className="sidebar-item-collapsed-label"  data-pr-tooltip={header} data-pr-classname="blue-tooltip"	>
                    {isActive ? activeIcon : icon}
                </NavLink>
            ) : (
                <div
                    className={`sidebar-item-collapsed-label ${
                        isActive ? "active" : ""
                    }`}
                    onClick={handleIconClick}
                    data-pr-tooltip={header}
                    data-pr-classname="blue-tooltip"
                >
                    {isActive ? activeIcon : icon}
                    <i className="pi pi-caret-down" />
                </div>
            )}
            {activeMenuItem === id && (
                <div className="collapsed-list-item">
                    {child.map((childNav) => (
                        <NavLink
                            key={childNav.key}
                            to={childNav.path}
                            className={
                                pathname.includes(childNav.path) ? "active" : ""
                            }
                        >
                            {childNav.header}
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SidebarItemCollapsed;
