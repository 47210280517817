export const GET_USER_PROFILE = {
    INIT: "GET_USER_PROFILE_INIT",
    SUCCESS: "GET_USER_PROFILE_SUCCESS",
    ERROR: "GET_USER_PROFILE_ERROR"
}

export const getUserProfileInit = () => ({
    type: GET_USER_PROFILE.INIT
})

export const getUserProfileSuccess = roles => ({
    type: GET_USER_PROFILE.SUCCESS,
    payload: roles
})

export const getUserProfileFailure = errorMessage => ({
    type: GET_USER_PROFILE.ERROR,
    payload: errorMessage
})