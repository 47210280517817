import React, { forwardRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import './AssetList.scss';

/**
 * To display Asset details for the respective selected Stores
 * @param {Array} data - List of Assets
 * @Param {HTMLDivElement} ref - Table Reference
 */

const AssetTable = forwardRef(({title, data}, ref) => {
  
    const isBackstageAP = (title==='Wireless Access Point' && !!data.find(({modelId}) => modelId?.toLowerCase().includes("group")));
    const isRetailAP = (title==='Wireless Access Point' && !isBackstageAP);

    return (
        <div className="asset-table">
          <DataTable ref={ref} value={data} className="p-datatable-gridlines" emptyMessage="No Data found.">
            <Column field="assetName" header="Asset Name" sortable />
            {title!=="Breakroom TV" && title!=="RX Printer" &&  <Column field="modelId" header={`${isBackstageAP?"Group ID":"Model ID"}`} sortable />}
            {title!== "RX Peripheral" && <Column field="assetClass" header="Class" sortable /> }
            { title!=="RX Printer" && title!=="Breakroom TV"  && <Column field="serialNumber" header="Serial Number" sortable />}
            { title=== "RX Peripheral" && <Column field="manufacturer" header="Manufacturer" sortable />}
            { (title==='Scale' || title ==='RX Printer') && (<Column field="ipAddress" header="IP Address" sortable /> )}
            { isRetailAP && <Column field="switchName" header="Switch Name" sortable /> }
            { isRetailAP && <Column field="port" header="Port" sortable /> }
            { title!=="Wireless Access Point" && title!=="IP Switch" &&  title!=="RX Printer" && title!=="Breakroom TV" && title!== "RX Peripheral" && <Column field="costCenter" header="Cost Center" sortable />}
            { title!== "RX Peripheral" && <Column field="state" header="State" sortable />}
          </DataTable>
        </div>
      )
})

export default AssetTable;