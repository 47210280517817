import {
  GET_SHELLY_FIRMWARE_GROUP_DETAILS,
  POST_SHELLYFIRMWARE_GROUP,
  POST_SHELLYFIRMWARE_CAMPAIGN,
  CANCEL_SHELLY_FIRMWARE_GROUP,
  GET_SHELLY_FIRMWARE_CAMPAIGNS,
  GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME,
  GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID,
  GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS,
  GET_OTA_TYPES,
  GET_SHELLY_PLUG_GROUPS,
  GET_SHELLY_PLUG_FIRMWARE_DETAILS,
  POST_MODIFY_SHELLYFIRMWARE_GROUP,
  CANCEL_SHELLY_FIRMWARE_CAMPAIGN,
  MODIFY_SHELLY_FIRMWARE_CAMPAIGN,
  SET_MODIFY_CAMPAIGN_DETAILS,
} from "./actionTypes";

const initialState = {
  shellyFirmwareGroupDetails: [],
  shellyFirmwareGroupDetailsErrorMessage: null,
  // group: null,
  groupSuccessMessage: null,
  groupErrorMessage: null,
  // campaign: null,
  campaignSuccessMessage: null,
  campaignErrorMessage: null,
  shellyFirmwareCampaigns: [],
  shellyFirmwareCampaignErrorMessage: null,
  shellyFirmwareCampaignDetails: [],
  shellyFirmwareCampaignDetailsErrorMessage: null,
  otaTypes: [],
  otaTypesErrorMessage: null,
  shellyPlugGroups: [],
  shellyPlugGroupsErrorMessage: null,
  shellyPlugFirmwareDetails: [],
  shellyPlugFirmwareDetailsErrorMessage: null,
  cancelCampaignMessage: "",
  cancelCampaignErrorMessage: null,
  // cancelGroup: null,
  cancelGroupSuccessMessage: null,
  cancelGroupErrorMessage: null,
  // modifyGroup: null,
  modifyGroupSuccessMessage: null,
  modifyGroupErrorMessage: null,
  modifyCampaignmessage: "",
  modifyCampaignErrorMessage: null,
  setModifyCampaignDetails: null,
  logDetailByCampaignName: [],
  logDetailByCampaignNameErrorMessage: null,
  logDetailByDeviceId: [],
  logDetailByDeviceIdErrorMessage: null,
};

const shellyFirmwareReducer = (state = initialState, action = { type: "" }) => {
  switch (action.type) {
    case GET_SHELLY_FIRMWARE_GROUP_DETAILS.SUCCESS:
      return {
        ...state,
        shellyFirmwareGroupDetails: action.payload,
        shellyFirmwareGroupDetailsErrorMessage: null,
      };

    case GET_SHELLY_FIRMWARE_GROUP_DETAILS.ERROR:
      return {
        ...state,
        shellyFirmwareGroupDetails: [],
        shellyFirmwareGroupDetailsErrorMessage: action.payload,
      };

    // case POST_SHELLYFIRMWARE_GROUP:
    //   return {
    //     ...state,
    //     group: action.payload,
    //   };
    case POST_SHELLYFIRMWARE_GROUP.SUCCESS:
      return {
        ...state,
        groupSuccessMessage: action.payload,
      };
    case POST_SHELLYFIRMWARE_GROUP.ERROR:
      return {
        ...state,
        groupErrorMessage: action.payload,
      };
    // case POST_MODIFY_SHELLYFIRMWARE_GROUP:
    //   return {
    //     ...state,
    //     modifyGroup: action.payload,
    //   };
    case POST_MODIFY_SHELLYFIRMWARE_GROUP.SUCCESS:
      return {
        ...state,
        modifyGroupSuccessMessage: action.payload,
      };
    case POST_MODIFY_SHELLYFIRMWARE_GROUP.ERROR:
      return {
        ...state,
        modifyGroupErrorMessage: action.payload,
      };
    // case POST_SHELLYFIRMWARE_CAMPAIGN:
    //   return {
    //     ...state,
    //     campaign: action.payload,
    //   };
    case POST_SHELLYFIRMWARE_CAMPAIGN.SUCCESS:
      return {
        ...state,
        campaignSuccessMessage: action.payload,
      };
    case POST_SHELLYFIRMWARE_CAMPAIGN.ERROR:
      return {
        ...state,
        campaignErrorMessage: action.payload,
      };

    case GET_SHELLY_FIRMWARE_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        shellyFirmwareCampaigns: action.payload,
        shellyFirmwareCampaignErrorMessage: null,
      };
    case GET_SHELLY_FIRMWARE_CAMPAIGNS.ERROR:
      return {
        ...state,
        shellyFirmwareCampaigns: [],
        shellyFirmwareCampaignErrorMessage: action.payload,
      };
    case GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS.SUCCESS:
      return {
        ...state,
        shellyFirmwareCampaignDetails: action.payload,
        shellyFirmwareCampaignDetailsErrorMessage: null,
      };
    case GET_SHELLY_FIRMWARE_CAMPAIGN_DETAILS.ERROR:
      return {
        ...state,
        shellyFirmwareCampaignDetails: [],
        shellyFirmwareCampaignDetailsErrorMessage: action.payload,
      };
    case GET_OTA_TYPES.SUCCESS:
      return {
        ...state,
        otaTypes: action.payload,
        otaTypesErrorMessage: null,
      };
    case GET_OTA_TYPES.ERROR:
      return {
        ...state,
        otaTypes: [],
        otaTypesErrorMessage: action.payload,
      };
    case GET_SHELLY_PLUG_GROUPS.SUCCESS:
      return {
        ...state,
        shellyPlugGroups: action.payload,
        shellyPlugGroupsErrorMessage: null,
      };
    case GET_SHELLY_PLUG_GROUPS.ERROR:
      return {
        ...state,
        shellyPlugGroups: [],
        shellyPlugGroupsErrorMessage: action.payload,
      };
    case GET_SHELLY_PLUG_FIRMWARE_DETAILS.SUCCESS:
      return {
        ...state,
        shellyPlugFirmwareDetails: action.payload,
        shellyPlugFirmwareDetailsErrorMessage: null,
      };
    case GET_SHELLY_PLUG_FIRMWARE_DETAILS.ERROR:
      return {
        ...state,
        shellyPlugFirmwareDetails: [],
        shellyPlugFirmwareDetailsErrorMessage: action.payload,
      };
    case CANCEL_SHELLY_FIRMWARE_CAMPAIGN.SUCCESS:
      return {
        ...state,
        cancelCampaignMessage: action.payload,
        cancelCampaignErrorMessage: null,
      };
    case CANCEL_SHELLY_FIRMWARE_CAMPAIGN.ERROR:
      return {
        ...state,
        cancelCampaignMessage: "",
        cancelCampaignErrorMessage: action.payload,
      };
    // case CANCEL_SHELLY_FIRMWARE_GROUP:
    //   return {
    //     ...state,
    //     cancelGroup: action.payload,
    //   };
    case CANCEL_SHELLY_FIRMWARE_GROUP.SUCCESS:
      return {
        ...state,
        cancelGroupSuccessMessage: action.payload,
      };
    case CANCEL_SHELLY_FIRMWARE_GROUP.ERROR:
      return {
        ...state,
        cancelGroupErrorMessage: action.payload,
      };
    case MODIFY_SHELLY_FIRMWARE_CAMPAIGN.SUCCESS:
      return {
        ...state,
        modifyCampaignmessage: action.payload,
        modifyCampaignErrorMessage: null,
      };
    case MODIFY_SHELLY_FIRMWARE_CAMPAIGN.ERROR:
      return {
        ...state,
        modifyCampaignmessage: null,
        modifyCampaignErrorMessage: action.payload,
      };
    case SET_MODIFY_CAMPAIGN_DETAILS:
      return {
        ...state,
        setModifyCampaignDetails: action.payload,
      };
    case GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME.SUCCESS:
      return {
        ...state,
        logDetailByCampaignName: action.payload,
        logDetailByCampaignNameErrorMessage: null,
      };
    case GET_CAMPAIGN_EVENT_LOGS_BY_CAMPAIGN_NAME.ERROR:
      return {
        ...state,
        logDetailByCampaignName: [],
        logDetailByCampaignNameErrorMessage: action.payload,
      };
    case GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID.SUCCESS:
      return {
        ...state,
        logDetailByDeviceId: action.payload,
        logDetailByDeviceIdErrorMessage: null,
      };
    case GET_CAMPAIGN_DETAILS_EVENT_LOGS_BY_DEVICE_ID.ERROR:
      return {
        ...state,
        logDetailByDeviceId: [],
        logDetailByDeviceIdErrorMessage: action.payload,
      };

    default:
      return { ...state };
  }
};

export default shellyFirmwareReducer;
