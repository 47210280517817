import { GET_ALL_DIVISION_NAMES } from "./actionTypes";

const initialState = {
    divisionNameList: [],
    divisionNameListErrorMessage: null,
};


const divisionDropdownReducer = (state = initialState, action = { type: "", payload: "" }) => {
    switch (action.type) {
        case GET_ALL_DIVISION_NAMES.SUCCESS:
            return {
                ...state,
                divisionNameList: action.payload,
                divisionNameListErrorMessage: null,
            };
        case GET_ALL_DIVISION_NAMES.ERROR:
            return {
                ...state,
                divisionNameList: [],
                divisionNameListErrorMessage: action.payload,
            };
        default:
            return { ...state };
    }
};

export default divisionDropdownReducer;
