import { call, put } from "redux-saga/effects";

import {
    getDeviceTypeFailure,
    getDeviceTypeSuccess,
    getDeviceOnboardingLogsSuccess,
    getDeviceOnboardingLogsFailure,
} from "../components/device-onboarding/redux/actions";

import DeviceOnboardingService from "../components/device-onboarding/DeviceOnboardingService";

const deviceOnboardingService = new DeviceOnboardingService();


/**
 * Worker saga for loading device types
 */
function* loadDeviceTypes() {
    try {
        const deviceTypeList = yield call(
            deviceOnboardingService.getDeviceTypes
        );
        const filteredDeviceTypeList = deviceTypeList.filter(
            ({ deviceTypeId }) => [2, 9, 10, 11].includes(deviceTypeId)
        );
        yield put(getDeviceTypeSuccess(filteredDeviceTypeList));
    } catch (error) {
        yield put(getDeviceTypeFailure(error.toString()));
    }
}

function* loadDeviceOnboardingLogs() {
    try {
        const onboardingLogs = yield call(
            deviceOnboardingService.getDeviceOnboardingLogs
        );
        yield put(getDeviceOnboardingLogsSuccess(onboardingLogs.map(log => ({
            ...log,
            deviceType: (() => {
                if(log.deviceTypeId===2) {
                    return "Modem Smart Switch"
                }
                if(log.deviceTypeId===9) {
                    return "Cradlepoint Smart Switch"
                }
                if(log.deviceTypeId===10) {
                    return "Pingprobe"
                }
                if(log.deviceTypeId===11) {
                    return "Aruba Controller"
                }
                return "Unknown";
            })()
        }))));
    } catch (error) {
        yield put(getDeviceOnboardingLogsFailure(error.toString()));
    }
}

export { loadDeviceTypes, loadDeviceOnboardingLogs };
