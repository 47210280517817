import axios from 'axios';
import {v4 as uuidv4} from 'uuid';

import Store from '../../redux/Store';
import { getAccessToken } from './msalUtils';
import { ApiPath } from '../api-path/ApiPath';


axios.interceptors.request.use(async req => {
  console.log(`${req.method} ${req.url}`);
  Store.dispatch({ type:'INCREMENT'});

  const token = await getAccessToken();
  req.headers['Authorization'] = token ? `Bearer ${token}` : '';
  req.headers['X-correlation-id'] = uuidv4();
  // Important: request interceptors **must** return the request.
  return req;
});

axios.interceptors.response.use(res => {
  // Important: response interceptors **must** return the response.
  Store.dispatch({ type:'DECREMENT'})
  if(res.config.url.includes("exclusionRule") && res.config.method==="post") {
    Store.dispatch({ type:'HIDE'})
    Store.dispatch({ type:'SHOW', payload: "Changes in Exclusion Rule will be reflected within 1 hour"})
  } else if(res.config.url===`${ApiPath.baseUrl}/modem/command`) {
    Store.dispatch({ type:'HIDE'})
    Store.dispatch({ type:'SHOW', payload: "Command Sent Successfully"})
  } else if(res.config.url===`${ApiPath.baseUrl}/merakifirewall/modifyschedule`) {
    Store.dispatch({ type:'HIDE'})
    Store.dispatch({ type:'SHOW', payload: "You are successfully modified the schedule.You can see the changes reflected against the Request ID."})
  } else if(res.config.url===`${ApiPath.baseUrl}/shelly-upgrade/createcampaign`) {
    Store.dispatch({ type:'HIDE'})
    Store.dispatch({ type:'SHOW', payload: "Campaign created successfully"})
  } else if(res.config.url===`${ApiPath.baseUrl}/shelly-upgrade/creategroup`) {
    Store.dispatch({ type:'HIDE'})
    Store.dispatch({ type:'SHOW', payload: "Group created successfully"})
  }else if(res.config.url===`${ApiPath.baseUrl}/shelly-upgrade/updategroup`) {
    Store.dispatch({ type:'HIDE'})
    Store.dispatch({ type:'SHOW', payload: "Group Details modified successfully"})
  }else if(res.config.url===`${ApiPath.baseUrl}/shelly-upgrade/updatecampaign`) {
    Store.dispatch({ type:'HIDE'})
    Store.dispatch({ type:'SHOW', payload: "Campaign Details modified successfully"})
  }else if(res.config.url===`${ApiPath.baseUrl}/incsuppression/request`) {
    Store.dispatch({ type:'HIDE'})
    Store.dispatch({ type:'SHOW', payload: "Request Submitted Successfully"})
  }
  // else if(res.config.url===`${ApiPath.baseUrl}/GrafanaAgent/create/job`) {
  //   Store.dispatch({ type:'HIDE'})
  //   Store.dispatch({ type:'SHOW', payload: res.data})}
  //   else if(res.config.url===`${ApiPath.baseUrl}/GrafanaAgent/execute/job`) {
  //   Store.dispatch({ type:'HIDE'})
  //   Store.dispatch({ type:'SHOW', payload: res.data})
  // }
  return res;
},err=>{
     Store.dispatch({ type:'DECREMENT'}) 
    if((err.config.url===`${ApiPath.baseUrl}/merakifirewall/modifyschedule`) || (err.config.url===`${ApiPath.baseUrl}/merakifirewall/cancel`)){
      Store.dispatch({ type:'HIDE'})
      Store.dispatch({ type:'SHOW', payload: err.response && err.response.data})
    }else if((err.config.url===`${ApiPath.baseUrl}/shelly-upgrade/createcampaign`) || (err.config.url===`${ApiPath.baseUrl}/shelly-upgrade/creategroup`)
    || (err.config.url===`${ApiPath.baseUrl}/shelly-upgrade/updategroup`) || (err.config.url===`${ApiPath.baseUrl}/shelly-upgrade/updatecampaign`)) {
      Store.dispatch({ type:'HIDE'})
      Store.dispatch({ type:'SHOW', payload: err.response && err.response.data.detailList[0]})
    }
    //  else if(err.config.url===`${ApiPath.baseUrl}/GrafanaAgent/execute/job`) {
    //   Store.dispatch({ type:'HIDE'})
    //   Store.dispatch({ type:'SHOW', payload: err.data})
    // }
    // else if(err.config.url===`${ApiPath.baseUrl}/GrafanaAgent/create/job`) {
    //   Store.dispatch({ type:'HIDE'})
    //   Store.dispatch({ type:'SHOW', payload: err.response && err.response.data })}
   
    else {
      Store.dispatch({ type:'HIDE'})
      Store.dispatch({ type:'SHOW', payload: err.response && err.response.data.message})
    } 
    console.error(err);
    return Promise.reject(err);
});
