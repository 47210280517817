import axios from "axios";
import { ApiPath } from "../../common/api-path/ApiPath";

export default class MerakiFirewallAutomationService {


    async getMerakiFirewallAutomationLogs (startDate, endDate, offset) {
        const requestConfig = {
            params: { startDate, endDate, offset },
            timeout: 120000, timeoutErrorMessage: "request timeout"
        }
        console.log("request configuration", requestConfig);
        const response = await axios.get(`${ApiPath.baseUrl}/merakifirewall/summary`, requestConfig);
        console.log("get service request url",response.config.url);
        if(response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
      }

       async postModifySchedule (modifiedSchedule) {
        const response = await axios.post(
            `${ApiPath.baseUrl}/merakifirewall/modifyschedule`,
            modifiedSchedule,
            { timeout: 120000, timeoutErrorMessage: "request timeout" }
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
      };

      async cancelSchedule (cancelSchedule) {
        const response = await axios.post(
            `${ApiPath.baseUrl}/merakifirewall/cancel`,
            cancelSchedule,
            { timeout: 120000, timeoutErrorMessage: "request timeout" }
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
      };

      async getMerakiFirewallLiveLogsUrl (filePath) {
        let Config = {
            headers: {
              "Content-Type": "application/text",
            }
          };
        const response = await axios.post(
            `${ApiPath.baseUrl}/merakiLiveLogurl`,
            filePath, Config
        );
        if (response.status >= 400) {
            throw new Error(response.errors);
        }
        return response.data;
      } 
      
    }
    