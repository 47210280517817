import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { addDateTime } from '../../../common/date-time';
import { postIncidentSuppressionRequestInit } from '../redux/actions';

import "./IncidentSuppressionRequest.scss";

const IncidentSuppressionRequest = () => {

    const incidentSuppressionTypes = [
        {label: "Temporary", value: "Temporary"},
        {label: "Permanent", value: "Permanent"},
        {label: "Usecase", value: "Usecase"},
    ]

    const deviceTypes = [
        {label: "Modem-WAN1", value: "modem-wan1"},
        {label: "Modem-WAN2", value: "modem-wan2"},
        {label: "CP", value: "cp"},
        {label: "AP", value: "ap"},
        {label: "Switch", value: "switch"},
    ]

    const UsecaseTypes = [
        {label: "Modem", value: "modem"},
        {label: "CP", value: "cp"},
        {label: "AP", value: "ap"},
        {label: "Switch", value: "switch"},
    ]

    const defaultState = {
        incidentSuppressionType: incidentSuppressionTypes[0].value,
        deviceType: [],
        stores: "",
        startDateTime: null,
        endDateTime: null,
        snowTicket: "",
        description: "",
    }

    const [defaultValues, setDefaultValues] = useState(defaultState);
    const dispatch = useDispatch();
    const history = useHistory();
    const regex = /^\d{4}$/

    const {
        values,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        resetForm
    } = useFormik({
        enableReinitialize: true,
        initialValues: defaultValues,
        validate: (data) => {
            let errors = {};
            validateDeviceType(data, errors);
            validateStores(data, errors);
            validateStartDatetime(data, errors);
            validateEndDatetime(data, errors);
            validateSnowTicket(data, errors);
            validateDescription(data, errors);
            return errors;
        },
        onSubmit: (data) => {
            const storesArray = data.stores.split(/[,\n]/).map(store => store.trim()).filter(store => store !== "").join(",");
            let payload = {
                ...data,
                endDateTime: data.incidentSuppressionType === "Permanent" ? null : data.endDateTime,
                stores: data.incidentSuppressionType === "Usecase" ? null : storesArray,
            }
            dispatch(postIncidentSuppressionRequestInit(payload));
            clearForm();
            history.push("/admin/incident-suppression/suppression-logs");
        }
    })

    const radioButtonHandler = () => (e) => {
        setDefaultValues({...defaultValues, incidentSuppressionType: e.value});
    }

    /**
     * Functions to validate form fields
     * @param {FormData} data
     * @param {Object} errors
     */

    const validateDeviceType = (data, errors) => {
        if (!data.deviceType || data.deviceType.length === 0) {
            errors.deviceType = data.incidentSuppressionType !== "Usecase" ? "Please select the Device Type(s)" : "Please select the Usecase(s)";
        }
    }

    const validateStores = (data, errors) => {
        if (data.incidentSuppressionType !== "Usecase") {

        if (!data.stores) {
            errors.stores = "Please enter the Store(s)";
        }
        else {
            const storesArray = data.stores.split(/[,\n]/).map(store => store.trim());
            const invalidStores = storesArray.filter(store => !store.match(regex));
            if (invalidStores.length > 0) {
                errors.stores = `Invalid store format: ${invalidStores.join(",")}. Store number should be 4 digits and store list should be comma separated or new line separated`;
            }
            else {
                const uniqueStores = new Set(storesArray);
                if (uniqueStores.size !== storesArray.length) {
                    errors.stores = "Duplicate stores are not allowed";
                }
            }
        }

        }
    }

    const validateStartDatetime = (data, errors) => {
        if (!data.startDateTime) {
            errors.startDateTime = "Please select the Start Datetime";
        }
        else if (data.startDateTime <= addDateTime("minutes", 14, new Date())) {
            errors.startDateTime = "Start Datetime should be atleast 15 minutes from now";
        }
    }

    const validateEndDatetime = (data, errors) => {
        if (data.incidentSuppressionType !== "Permanent") {
        if (!data.endDateTime) {
            errors.endDateTime = "Please select the End Datetime";
        }
        else if (data.endDateTime < addDateTime("minutes", 30, data.startDateTime)) {
            errors.endDateTime = "End Datetime should be at least 30 minutes from start datetime";
        }
        }
    }

    const validateSnowTicket = (data, errors) => {
        if (!data.snowTicket) {
            errors.snowTicket = "Please enter the SNOW Ticket number";
        }
    }

    const validateDescription = (data, errors) => {
        if (!data.description) {
            errors.description = "Please enter the Description";
        }
        if (data.description.trim().length > 150) {
            errors.description = "Max of 150 characters allowed for description";
        }
    }

    /**
     * Function to reset form state
     */
        const clearForm = () => {
            resetForm();
        };

        const getMinDate = () => {
            let date = new Date();
            date.setSeconds(0);
            date.setMilliseconds(0);
            // Add 15 minutes
            date.setMinutes(date.getMinutes() + 15);
            // Round to next 15 minutes
            let minutes = date.getMinutes();
            let m = minutes % 15;
            if (m > 0) {
                date.setMinutes(minutes + 15 - m);
            }
            return date;
        }

  return (
    <div className="incident-suppression-container container-fluid">
      <p className="page-label">
        EIOT Incident Suppression
        <i className="pi pi-angle-right"/>
        Suppression Request
      </p>
      <form className="container-fluid" onSubmit={handleSubmit}>
        <div className="row input-field">
            <div className="col-12 col-lg-2">
                <label>Suppression Type</label>
            </div>
            <div className="col-12 col-lg-10">
                {incidentSuppressionTypes.map(({label, value}) => (
                    <span key={value} style={{display: "flex", alignItems:"center"}}>
                        <RadioButton
                        name="incidentSuppressionType"
                        value={value}
                        checked={values.incidentSuppressionType === value}
                        onChange={radioButtonHandler()}
                        />
                        <span> {label} </span>
                    </span>
                ))}
            </div>
        </div>
        <div className="row input-field">
            <div className="col-12 col-lg-2">
                {values.incidentSuppressionType === "Usecase" ? <label>Usecase(s)</label> : <label>Device Type(s)</label>}
            </div>
            <div className="col-12 col-lg-10">
                <span>
                <MultiSelect
                    name="deviceType"
                    options={(values.incidentSuppressionType === "Usecase" ? UsecaseTypes : deviceTypes)}
                    optionLabel="label"
                    optionValue="value"
                    value={values.deviceType}
                    placeholder={values.incidentSuppressionType === "Usecase" ? "Select Usecase" : "Select Device Type"}
                    onChange={handleChange}
                />
                <br/>
                {!!touched.deviceType && errors.deviceType && <span className="p-error">{errors.deviceType}</span>}
                </span>
            </div>
        </div>
        {values.incidentSuppressionType !== "Usecase" && <div className="row input-field">
            <div className="col-12 col-lg-2">
                <label>Store(s)</label>
            </div>
            <div className="col-12 col-lg-10">
                <span style={{width: "100%"}}>
                <InputTextarea
                name="stores"
                style={{height: "60%"}}
                value={values.stores}
                onChange={handleChange}
                />
                <br />
				<strong>Note: For multiple stores use ',' as separator</strong>
                <br/>
                {!!touched.stores && errors.stores && <span className="p-error">{errors.stores}</span>}
                </span>
            </div>
        </div>}
        <div className="row input-field">
            <div className="col-12 col-lg-2">
                <label>Start Datetime</label>
            </div>
            <div className="col-12 col-lg-10">
                <span>
                <Calendar
                name="startDatetime"
                value={values.startDateTime}
                onChange={(e) =>setFieldValue("startDateTime", e.value)}
                minDate={getMinDate()}
                stepMinute={15}
                showTime
                showIcon
                />
                <br/>
                {!!touched.startDateTime && errors.startDateTime && <span className="p-error">{errors.startDateTime}</span>}
                </span>
            </div>
        </div>
        {values.incidentSuppressionType !== "Permanent" && <div className="row input-field">
            <div className="col-12 col-lg-2">
                <label>End Datetime</label>
            </div>
            <div className="col-12 col-lg-10">
                <span>
                <Calendar
                name="endDatetime"
                value={values.endDateTime}
                stepMinute={15}
                onChange={(e) => setFieldValue("endDateTime", e.value)}
                minDate={addDateTime("minutes", 30, values.startDateTime)}
                showTime
                showIcon
                />
                <br/>
                {!!touched.endDateTime && errors.endDateTime && <span className="p-error">{errors.endDateTime}</span>}
                </span>
            </div>
        </div>}
        <div className="row input-field">
            <div className="col-12 col-lg-2">
                <label>SNOW Ticket #</label>
            </div>
            <div className="col-12 col-lg-10">
                <span style={{width: "100%"}}>
                <InputText
                name="snowTicket"
                value={values.snowTicket}
                onChange={handleChange}
                />
                <br/>
                {!!touched.snowTicket && errors.snowTicket && <span className="p-error">{errors.snowTicket}</span>}
                </span>
            </div>
        </div>
        <div className="row input-field">
            <div className="col-12 col-lg-2">
                <label>Description</label>
            </div>
            <div className="col-12 col-lg-10">
                <span style={{width: "100%"}}>
                <InputText
                name="description"
                value={values.description}
                onChange={handleChange}
                />
                <br/>
                {!!touched.description && errors.description && <span className="p-error">{errors.description}</span>}
                </span>
            </div>
        </div>
        <div className="row input-field">
            <Button label="Save" type="submit"/>
            <Button label="Clear" type="reset" onClick={clearForm} className="p-button-secondary"/>
        </div>

      </form>
    </div>
  );
}

export default IncidentSuppressionRequest;