import { useEffect } from 'react'


const AcuviewMonitoring = () => {

    const handleTabClick = () => {
        const acuViewPowerBiUrl = process.env.REACT_APP_ACUVIEW_MONITORING_POWER_BI_URL;
        window.open(acuViewPowerBiUrl, "_blank")
    }
    useEffect(() => {
        handleTabClick();
    },[])
  return null;
}

export default AcuviewMonitoring