import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import AssetListTemplate from './AssetListTemplate';
import SearchStoreInput from '../../search-store/SearchStoreInput';
import { getStoreCompleteDetailInit } from '../redux/actions';
import './AssetList.scss';

function AssetList() {

	const datatableRef = useRef(null);

	const [expandedRows, setExpandedRows] = useState(null);
	const [tableFilters, setTableFilters] = useState(null);

	const assetData = useSelector(state => state.asset.storesCompleteDetailList);
	const selectedStores = useSelector(state => state.searchStore.selectedStoreList);
	const dispatch = useDispatch();

	/**
	 * Fetch Stores Code details and Tranform it for Multi Select
	 */
	useEffect(() => {
		searchAssetbyAssetID();
		initializeTableFilter();
	}, []);

	/**
	 * Search the Asset by the user input in the store
	 */
	const searchAssetbyAssetID = () => {

		if (selectedStores && selectedStores.length > 0) {
			const storeCodes = []
			selectedStores.forEach(selectedStore => {
				storeCodes.push(selectedStore.name)
			})
			dispatch(getStoreCompleteDetailInit(storeCodes));
		}
	}

	const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			storeCode: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			storeName: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			},
			storeAddress: {
				operator: FilterOperator.AND,
        		constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			}
		})
	}

	/**
	 * Generate table rows for Printer and Modem
	 * @param {Array} data - List of Assets
	 */
	const rowExpansionTemplate = data => <AssetListTemplate ref={datatableRef} assetList={data} />


	/**
	 * Asset List view
	 */
	return (
		<div className="store-list">
			<p className="page-label">
				Device Management
				<i className="pi pi-angle-right" />
				Assets
			</p>
			<SearchStoreInput handleClick={searchAssetbyAssetID}/>

			<div className="store-table">
				<DataTable ref={datatableRef} value={assetData} className="p-datatable-gridlines" filters={tableFilters} filterDisplay="menu" paginator
				paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
				currentPageReportTemplate="Items per page" rows={10} rowsPerPageOptions={[10, 20, 50]}
				emptyMessage="Please select Store code and Search to get Asset related information." onRowToggle={(e) => setExpandedRows(e.data)} expandedRows={expandedRows} rowExpansionTemplate={rowExpansionTemplate} >
					<Column field="storeCode" header="Store ID" sortable filter />
					<Column field="storeName" header="Store Name" sortable filter />
					<Column field="storeAddress" header="Address" sortable filter />
					<Column header="Store Assets" expander style={{ width: '8em' }} />
				</DataTable>
			</div>
		</div>
	);
}

export default AssetList;