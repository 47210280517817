import { call, put } from "redux-saga/effects";

import {
    getAllDivisionNamesSuccess,
    getAllDivisionNamesFailure,
} from "../common/components/division-dropdown/redux/actions";

import DivisionDropdownService from "../common/components/division-dropdown/DivisionDropdownService";


const divisionDropdownService = new DivisionDropdownService();

/**
 * Worker saga for loading all division number
 */
function* loadAllDivisionNames() {
    try {
        const divisionList = yield call(divisionDropdownService.getAllDivisionName);
        yield put(getAllDivisionNamesSuccess(divisionList));
    } catch (error) {
        yield put(getAllDivisionNamesFailure(error.toString()));
    }
}

export { loadAllDivisionNames };
