export const GET_RPA_USECASES = {
    INIT: "GET_RPA_USECASES_INIT",
    SUCCESS: "GET_RPA_USECASES_SUCCESS",
    ERROR: "GET_RPA_USECASES_ERROR"
}

export const GET_RPA_SUMMARY_LOGS = {
    INIT: "GET_RPA_SUMMARY_LOGS_INIT",
    SUCCESS: "GET_RPA_SUMMARY_LOGS_SUCCESS",
    ERROR: "GET_RPA_SUMMARY_LOGS_ERROR"
}

export const GET_RPA_DETAIL_BY_CTASK_ID = {
    INIT: "GET_RPA_DETAIL_BY_CTASK_ID_INIT",
    SUCCESS: "GET_RPA_DETAIL_BY_CTASK_ID_SUCCESS",
    ERROR: "GET_RPA_DETAIL_BY_CTASK_ID_ERROR"
}