import { call, put } from "redux-saga/effects";

import {
  getShellyFirmwareGroupDetailsSuccess,
  getShellyFirmwareGroupDetailFailure,
  postShellyFirmwareGroupSuccess,
  postShellyFirmwareGroupFailure,
  postShellyFirmwareCampaignSuccess,
  postShellyFirmwareCampaignFailure,
  getShellyFirmwareCampaignsSuccess,
  getShellyFirmwareCampaignsFailure,
  getShellyFirmwareCampaignDetailsSuccess,
  getShellyFirmwareCampaignDetailsFailure,
  getCampaignEventLogsByCampaignNameSuccess,
  getCampaignEventLogsByCampaignNameFailure,
  getCampaignDetailsEventLogsByDeviceIdSuccess,
  getCampaignDetailsEventLogsByDeviceIdFailure,
  getOtaTypesSuccess,
  getOtaTypesFailure,
  getShellyPlugGroupsSuccess,
  getShellyPlugGroupsFailure,
  getShellyPlugFirmwareDetailsSuccess,
  getShellyPlugFirmwareDetailsFailure,
  cancelShellyFirmwareCampaignSuccess,
  cancelShellyFirmwareCampaignFailure,
  cancelShellyFirmwareGroupSuccess,
  cancelShellyFirmwareGroupFailure,
  modifyShellyFirmwareCampaignSuccess,
  modifyShellyFirmwareCampaignFailure,
} from "../components/shelly-firmware/redux/action";

import ShellyFirmwareService from "../components/shelly-firmware/ShellyFirmwareService";

const shellyFirmwareService = new ShellyFirmwareService();

function* loadShellyFirmwareGroupDetails() {
  try {
    const shellyFirmwareGroupDetails = yield call(
      shellyFirmwareService.getShellyFirmwareGroupDetails
    );
    yield put(getShellyFirmwareGroupDetailsSuccess(shellyFirmwareGroupDetails));
  } catch (error) {
    yield put(getShellyFirmwareGroupDetailFailure(error.toString()));
  }
}

function* saveCreatedGroup({ group }) {
  try {
    const groupDetails = yield call(
      shellyFirmwareService.postCreateGroup,
      group
    );
    yield put(postShellyFirmwareGroupSuccess(groupDetails));
    try {
      const shellyFirmwareGroupDetails = yield call(
        shellyFirmwareService.getShellyFirmwareGroupDetails
      );
      yield put(
        getShellyFirmwareGroupDetailsSuccess(shellyFirmwareGroupDetails)
      );
    } catch (error) {
      yield put(getShellyFirmwareGroupDetailFailure(error.toString()));
    }
  } catch (error) {
    yield put(postShellyFirmwareGroupFailure(error.toString()));
  }
}
function* saveModifiedGroup({ modifyGroup }) {
  try {
    yield call(shellyFirmwareService.postModifiedGroup, modifyGroup);
    yield put(postShellyFirmwareGroupSuccess());
    try {
      const shellyFirmwareGroupDetails = yield call(
        shellyFirmwareService.getShellyFirmwareGroupDetails
      );
      yield put(
        getShellyFirmwareGroupDetailsSuccess(shellyFirmwareGroupDetails)
      );
    } catch (error) {
      yield put(getShellyFirmwareGroupDetailFailure(error.toString()));
    }
  } catch (error) {
    yield put(postShellyFirmwareGroupFailure(error.toString()));
  }
}

function* saveCreatedCampaign({ campaign }) {
  try {
    const campaignDetails = yield call(
      shellyFirmwareService.postCreateCampaign,
      campaign
    );
    yield put(postShellyFirmwareCampaignSuccess(campaignDetails));
    try {
      const shellyFirmwareCampaigns = yield call(
        shellyFirmwareService.getShellyFirmwareCampaigns,
        campaign.otaType
      );
      yield put(getShellyFirmwareCampaignsSuccess(shellyFirmwareCampaigns));
    } catch (error) {
      yield put(getShellyFirmwareCampaignsFailure(error.toString()));
    }
  } catch (error) {
    yield put(postShellyFirmwareCampaignFailure(error.toString()));
  }
}

function* loadShellyFirmwareCampaigns({ otaType }) {
  try {
    const shellyFirmwareCampaigns = yield call(
      shellyFirmwareService.getShellyFirmwareCampaigns,
      otaType
    );
    yield put(getShellyFirmwareCampaignsSuccess(shellyFirmwareCampaigns));
  } catch (error) {
    yield put(getShellyFirmwareCampaignsFailure(error.toString()));
  }
}

function* loadCampaignEventLogsByCampaignName(action) {
  try {
    const campaignEventLogs = yield call(
      shellyFirmwareService.getEventDetailByCampaignName,
      action.campaignName
    );
    yield put(getCampaignEventLogsByCampaignNameSuccess(campaignEventLogs));
  } catch (error) {
    yield put(getCampaignEventLogsByCampaignNameFailure(error.toString()));
  }
}

function* loadShellyFirmwareCampaignDetails({ campaignName }) {
  try {
    const shellyFirmwareCampaignDetails = yield call(
      shellyFirmwareService.getShellyFirmwareCampaignDetails,
      campaignName
    );
    yield put(
      getShellyFirmwareCampaignDetailsSuccess(shellyFirmwareCampaignDetails)
    );
  } catch (error) {
    yield put(getShellyFirmwareCampaignDetailsFailure(error.toString()));
  }
}

function* loadCampaignDetailsEventLogsByDeviceId(action) {
  try {
    const campaignDetailsEventLogs = yield call(
      shellyFirmwareService.getEventDetailByDeviceId,
      action.id
    );
    yield put(
      getCampaignDetailsEventLogsByDeviceIdSuccess(campaignDetailsEventLogs)
    );
  } catch (error) {
    yield put(getCampaignDetailsEventLogsByDeviceIdFailure(error.toString()));
  }
}
function* loadAllOtaTypes() {
  try {
    const otaTypes = yield call(shellyFirmwareService.getAllOtaTypes);
    yield put(getOtaTypesSuccess(otaTypes));
  } catch (error) {
    yield put(getOtaTypesFailure(error.toString()));
  }
}

function* loadShellyPlugGroups() {
  try {
    const shellyPlugGroups = yield call(
      shellyFirmwareService.getShellyPlugGroups
    );
    yield put(getShellyPlugGroupsSuccess(shellyPlugGroups));
  } catch (error) {
    yield put(getShellyPlugGroupsFailure(error.toString()));
  }
}

function* loadShellyPlugFirmwareDetails() {
  try {
    const shellyPlugFirmwareDetails = yield call(
      shellyFirmwareService.getShellyPlugFirmwareDetails
    );
    yield put(getShellyPlugFirmwareDetailsSuccess(shellyPlugFirmwareDetails));
  } catch (error) {
    yield put(getShellyPlugFirmwareDetailsFailure(error.toString()));
  }
}

function* cancelShellyFirmwareCampaignName({ campaignName, rowData }) {
  try {
    const cancelCampaignMessage = yield call(
      shellyFirmwareService.cancelShellyFirmwareCampaign,
      campaignName
    );
    yield put(cancelShellyFirmwareCampaignSuccess(cancelCampaignMessage));
    try {
      const shellyFirmwareCampaigns = yield call(
        shellyFirmwareService.getShellyFirmwareCampaigns,
        rowData.otaType
      );
      yield put(getShellyFirmwareCampaignsSuccess(shellyFirmwareCampaigns));
    } catch (error) {
      yield put(getShellyFirmwareCampaignsFailure(error.toString()));
    }
  } catch (error) {
    yield put(cancelShellyFirmwareCampaignFailure(error.toString()));
  }
}

function* cancelShellyFirmwareGroup({ cancelGroup }) {
  try {
    yield call(shellyFirmwareService.cancelGroup, cancelGroup);
    yield put(cancelShellyFirmwareGroupSuccess());
    try {
      const shellyFirmwareGroupDetails = yield call(
        shellyFirmwareService.getShellyFirmwareGroupDetails
      );
      yield put(
        getShellyFirmwareGroupDetailsSuccess(shellyFirmwareGroupDetails)
      );
    } catch (error) {
      yield put(getShellyFirmwareGroupDetailFailure(error.toString()));
    }
  } catch (error) {
    yield put(cancelShellyFirmwareGroupFailure(error.toString()));
  }
}

function* modifyShellyFirmwareCampaignDetails({ campaignDetails }) {
  try {
    yield call(
      shellyFirmwareService.modifyShellyFirmwareCampaign,
      campaignDetails
    );
    yield put(modifyShellyFirmwareCampaignSuccess());
    try {
      const shellyFirmwareCampaigns = yield call(
        shellyFirmwareService.getShellyFirmwareCampaigns,
        campaignDetails.otaType
      );
      yield put(getShellyFirmwareCampaignsSuccess(shellyFirmwareCampaigns));
    } catch (error) {
      yield put(getShellyFirmwareCampaignsFailure(error.toString()));
    }
  } catch (error) {
    yield put(modifyShellyFirmwareCampaignFailure(error.toString()));
  }
}

export {
  loadShellyFirmwareGroupDetails,
  saveCreatedGroup,
  saveModifiedGroup,
  saveCreatedCampaign,
  loadShellyFirmwareCampaigns,
  loadCampaignEventLogsByCampaignName,
  loadShellyFirmwareCampaignDetails,
  loadCampaignDetailsEventLogsByDeviceId,
  loadAllOtaTypes,
  loadShellyPlugGroups,
  loadShellyPlugFirmwareDetails,
  cancelShellyFirmwareGroup,
  cancelShellyFirmwareCampaignName,
  modifyShellyFirmwareCampaignDetails,
};
