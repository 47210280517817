import { GET_ALL_DEVICES, 
    GET_DEVICE_STATUS,
} from './actionTypes';

const initialState = {
    deviceList: [],
    deviceListErrorMessage: null,
    deviceItem: null,
    deviceItemErrorMessage: null
}

const deviceManagementReducer = (state=initialState, action={type:""}) => {
    switch(action.type) {
        case GET_ALL_DEVICES.SUCCESS:
            return {
                ...state, 
                deviceList: action.payload, 
                deviceListErrorMessage: null
            }
        case GET_ALL_DEVICES.ERROR:
            return {
                ...state, 
                deviceList: [], 
                deviceListErrorMessage: action.payload
            }
        case GET_DEVICE_STATUS.SUCCESS:
            return {
                ...state,
                deviceItem: action.payload,
                deviceItemErrorMessage: null
            }
        case GET_DEVICE_STATUS.ERROR:
            return {
                ...state,
                deviceItem: null,
                deviceItemErrorMessage: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default deviceManagementReducer;