import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";

import { Column } from "primereact/column";
import { parseDateTime } from "../../../common/date-time";

const SubRequestDetails = ({ subRequestDetails }) => {
  const [tableFilters, setTableFilters] = useState(null);
  const pingSubDetailsTableRef = useRef(null);

  useEffect(() => {
    initializeTableFilter();
  }, []);

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      ipAddress: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      pingStatus: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };
  const exportPingSubDetails = () => pingSubDetailsTableRef.current.exportCSV();

  const pingTimeTemplate = ({ pingDateTime }) =>
  pingDateTime ? (
      parseDateTime(pingDateTime)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

  return (
    <div className="sub-request-details">
      <div>
        <Button
          icon="pi pi-download"
          className="p-button-text"
          onClick={exportPingSubDetails}
          style={{ float: "right", zIndex: 1 }}
        />
      </div>
      <DataTable
        value={!!subRequestDetails && subRequestDetails}
        ref={pingSubDetailsTableRef}
        className="sub-request-table"
        filters={tableFilters}
        filterDisplay="menu"
        emptyMessage="No logs found"
      >
        <Column field="ipAddress" header="IP Address" sortable filter />
        <Column field="pingStatus" header=" Ping Status" sortable filter />
        <Column
          field="pingDateTime"
          header=" Ping Time"
          body={pingTimeTemplate}
          sortable
        />
      </DataTable>
    </div>
  );
};

export default SubRequestDetails;
