import { call, put } from 'redux-saga/effects';

import { 
    getAllDeviceSuccess, getAllDeviceFailure, 
    getDeviceStatusSuccess, getDeviceStatusFailure, 
} from '../components/device-management/redux/actions';

import DeviceManagementService from '../components/device-management/DeviceManagementService';

const deviceService = new DeviceManagementService();

/**
 * Worker saga for loading all device details
 */
function* loadAllDevices(action) {
    try {
        const deviceList = yield call(deviceService.getDevices, action.storeCodes);
        yield put(getAllDeviceSuccess(deviceList));
    } catch (error) {
        yield put(getAllDeviceFailure(error.toString()));
    }
}

/**
 * Worker saga for loading device detail by device id
 * @param {Object} action - Redux action
 */
function* loadDeviceStatus(action) {
    try {
        const deviceItem = yield call(deviceService.getDeviceStatus, action.deviceId);
        yield put(getDeviceStatusSuccess(deviceItem));
    } catch (error) {
        yield put(getDeviceStatusFailure(error.toString()))
    }
}

export { loadAllDevices,
    loadDeviceStatus,
};

