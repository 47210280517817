import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { parseDateTime } from "../../../common/date-time";
import { getCampaignEventLogsByCampaignNameInit } from "../redux/action";

const ShellyFirmwareCampaignEventLogs = ({ dialogData, setDialogData }) => {
  const logDetailByCampaignName = useSelector(
    (state) => state.shellyFirmware.logDetailByCampaignName
  );

  const [tableFilters, setTableFilters] = useState(null);
  const dispatch = useDispatch();
  const CampaignEventTableRef = useRef(null);

  useEffect(() => {
    fetchDetailByCampaingName();
    initializeTableFilter();
  }, [dialogData]);

  const fetchDetailByCampaingName = () => {
    if (!!dialogData) {
      dispatch(getCampaignEventLogsByCampaignNameInit(dialogData.campaignName));
    }
  };

  const initializeTableFilter = () => {
    setTableFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      event: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      message: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      timestamp: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const timeStampTemplate = ({ timestamp }) =>
    timestamp ? (
      parseDateTime(timestamp)
    ) : (
      <span style={{ marginLeft: "50%" }}>-</span>
    );

      /**
   * Function to export table data
   */
  const exportCampaignEventLogs = () => CampaignEventTableRef.current.exportCSV();

  return (
    <Dialog
      className="shelly-firmware-campaign-event-logs"
      header={!!dialogData && `${dialogData.campaignName}`}
      visible={!!dialogData}
      onHide={() => setDialogData(null)}
    >
      <div>
        <Button
          icon="pi pi-download"
          className="p-button-text"
          onClick={exportCampaignEventLogs}
          style={{ float: "right", zIndex: 100 }}
        />
        <Button
          icon="pi pi-refresh"
          className="p-button-text"
          onClick={fetchDetailByCampaingName}
          style={{ float: "right", zIndex: 100 }}
        />
      </div>
      <DataTable
        value={logDetailByCampaignName}
        ref={CampaignEventTableRef}
        filters={tableFilters}
        filterDisplay="menu"
        sortField="timestamp"
        sortOrder={-1}
        paginator
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
        currentPageReportTemplate="Items per page"
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        emptyMessage="No Details found"
      >
        <Column header="Event" field="event" filter />
        <Column header="Message" field="message" filter />
        <Column
          header="Time Stamp"
          field="timestamp"
          body={timeStampTemplate}
          sortable
          filter
        />
      </DataTable>
    </Dialog>
  );
};
export default ShellyFirmwareCampaignEventLogs;
