import { POST_INCIDENT_SUPPRESSION_REQUEST, 
         GET_INCIDENT_SUPPRESSION_LOGS,
         GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET,
         GET_INCIDENT_SUPPRESSION_EVENT_LOGS
        } from "./actionTypes"

export const postIncidentSuppressionRequestInit = (suppressionRequest) => ({
    type: POST_INCIDENT_SUPPRESSION_REQUEST.INIT,
    suppressionRequest,
})

export const postIncidentSuppressionRequestSuccess = (successResponse) => ({
    type: POST_INCIDENT_SUPPRESSION_REQUEST.SUCCESS,
    payload: successResponse
})

export const postIncidentSuppressionRequestError = (errorMessage) => ({
    type: POST_INCIDENT_SUPPRESSION_REQUEST.ERROR,
    payload: errorMessage
})

export const getIncidentSuppressionLogsInit = () => ({
    type: GET_INCIDENT_SUPPRESSION_LOGS.INIT
})

export const getIncidentSuppressionLogsSuccess = (incidentSuppressionLogs) => ({
    type: GET_INCIDENT_SUPPRESSION_LOGS.SUCCESS,
    payload: incidentSuppressionLogs
})

export const getIncidentSuppressionLogsError = (errorMessage) => ({
    type: GET_INCIDENT_SUPPRESSION_LOGS.ERROR,
    payload: errorMessage
})

export const getIncidentSuppressionLogsBySnowTicketInit = (snowTicket) => ({
    type: GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET.INIT,
    snowTicket
})

export const getIncidentSuppressionLogsBySnowTicketSuccess = (suppressionLogsBySnowTicket) => ({
    type: GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET.SUCCESS,
    payload: suppressionLogsBySnowTicket
})

export const getIncidentSuppressionLogsBySnowTicketError = (errorMessage) => ({
    type: GET_INCIDENT_SUPPRESSION_LOGS_BY_SNOW_TICKET.ERROR,
    payload: errorMessage
})

export const getIncidentSuppressionEventLogsInit = (suppressionId, usecase) => ({
    type: GET_INCIDENT_SUPPRESSION_EVENT_LOGS.INIT,
    suppressionId, usecase
})

export const getIncidentSuppressionEventLogsSuccess = (eventLogs) => ({
    type: GET_INCIDENT_SUPPRESSION_EVENT_LOGS.SUCCESS,
    payload: eventLogs
})

export const getIncidentSuppressionEventLogsError = (errorMessage) => ({
    type: GET_INCIDENT_SUPPRESSION_EVENT_LOGS.ERROR,
    payload: errorMessage
})