import React, { useState, useEffect, useRef} from 'react';
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useDispatch, useSelector } from 'react-redux';

import { getAllPendingJobsInit } from './redux/actions';

import SubPendingJobs from './SubPendingJobs';

import './PendingJobs.scss';


const PendingJobs = ({deviceNames}) => {

  const pendingJobRef = useRef(null);
  const pendingJobsData = useSelector(state =>  state.pendingJobs.pendingJobsList);

  const deviceName = useSelector(state => state.deviceManagement.deviceItem ? state.deviceManagement.deviceItem.deviceName : "");
  const dispatch = useDispatch();

  const [tableFilters, setTableFilters] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);


  const fetchPendingJobsData = () => dispatch(getAllPendingJobsInit(deviceNames));


  /**
  * Fetch IOt device events data
  */
  useEffect(() => { 
    fetchPendingJobsData();
    initializeTableFilter();
  }, [deviceNames])


  const exportCSV = () => {
    pendingJobRef.current.exportCSV();
  }
  
  const initializeTableFilter = () => {
		setTableFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			job: {
				operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
			}
		})
	}


const rowExpansionTemplate = ({ jobDetail}) => (
  <SubPendingJobs jobDetail={jobDetail} />
);
  

  /**
  * Display Pending jobs table view
  */
  return (
    <div>
      <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={fetchPendingJobsData} style={{ float: 'right' }} />
      <Button type="button" icon="pi pi-download" className="p-button-text" onClick={exportCSV} style={{ float: 'right'}}/>
      <br/> <br/>
      <DataTable
          value={pendingJobsData}
          ref={pendingJobRef}
          exportFilename={`${deviceName}-PendingJobs`}
          className="p-datatable-gridlines"
          filters={tableFilters}
          filterDisplay="menu"
          paginator
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Items per page"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          emptyMessage="No logs found"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "5em" }} />
          <Column field="job" header="Job ID" sortable filter />
        </DataTable>
    </div>)
}

export default PendingJobs;